import { gql } from "@apollo/client";

export const CLAIM_ITEM = gql`
  query claim($id: ID!) {
    claim(id: $id) {
      ... on ClaimType {
        id
        candidateId
        candidate {
          actualAddress {
            block
            blockType
            city
            cityType
            house
            lat
            region
            value
            streetType
            street
            settlement
            regionType
            lng
            houseType
          }
          age
          badge
          calcDismissDate
          callCompleted
          walkingDuration
          transitDuration
          cardDeliveryAddress {
            block
            blockType
            city
            cityType
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            street
            streetType
            value
          }
          contacts {
            contact
            id
            isMain
          }
          comments {
            comment
            createdAt
            manager {
              city
              companyId
              departmentId
              email
              firstName
              id
              phone
              role
              secondName
            }
          }
          citizenshipId
          city
          companyId
          createdAt
          dateOfBirth
          deletedAt
          departmentId
          dismissDate
          dismissReason
          driverLicense
          email
          experienceFull
          experienceInCompany
          firstName
          fullName
          hasChat
          hasHistory
          haveCar
          id
          inBlacklist
          isSpam
          timezone
          lastShiftRate
          managerComment
          managerId
          metro {
            distance
            line
            name
          }
          ownerId
          patronymic
          positionId
          positions {
            key
          }
          positionsNew {
            createdAt
            id
            position {
              categoryId
              id
              key
              name
              restriction
            }
          }
          receptionLastDate
          region
          russianCitizenship
          salaryExpectation
          secondName
          sex
          shiftMaxDate
          source
          shiftMinDate
          status
          updatedAt
          updatedBy
        }
        address {
          block
          blockType
          city
          cityType
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        category
        chatLink
        companyId
        contacts {
          email
          fullName
          phone
        }
        createdAt
        deletedAt
        departmentId
        expiredCallTime
        extra {
          remoteId
          remoteUrl
          remoteVacancyId
          service
        }
        isSent
        manager {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        managerComment
        managerId
        owner {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        ownerId
        position
        refusalId
        refusalText
        replyId
        salary
        sendTo
        source
        sourceUrl
        status
        updatedAt
        updatedBy
        userComment
        userContent
        userWish
        utm {
          campaign
          content
          medium
          source
          term
        }
      }
      ... on Error {
        statusCode
        errors
        message
      }
    }
  }
`;

import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";
import { DefaultRootState } from "react-redux";

const selectSettings = (state: RootState) => state.settings;

export const selectPositionsRelations = createSelector(
  selectSettings,
  (state) => state.positionsRelations
);

export const selectPositionRelationsById = createSelector(
  selectPositionsRelations,
  (_: DefaultRootState, positionId: number) => positionId,
  (list, positionId) =>
    list.data?.find(({ positionIdFrom }) => positionIdFrom === positionId)
);

import { VALIDATION_MESSAGE } from "common/const/messages";
import * as yup from "yup";

export const schema = yup
  .object({
    firstName: yup.string().required("Необходимо ввести имя"),
    secondName: yup.string().required("Необходимо ввести фамилию"),
    salaryExpectation: yup.string().required("Необходимо ввести зп ожидание"),
    actualAddress: yup.object().shape({
      value: yup.string().required("Необходимо ввести фактический адрес"),
      data: yup.object().shape({
        house: yup
          .string()
          .required(VALIDATION_MESSAGE.FULL_ADDRESS)
          .nullable(true),
      }),
    }),
    sex: yup.string().required("Необходимо ввести пол"),
    email: yup
      .string()
      .notRequired()
      .email("Адрес электронной почты заполнен неверно"),
    contacts: yup.array().of(
      yup.object().shape({
        contact: yup
          .string()
          .test(
            "len",
            "Необходимо ввести номер телефона",
            (val) => (val?.length ?? 0) > 2
          ),
      })
    ),
  })
  .required();

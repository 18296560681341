import React from "react";
import cn from "classnames/dedupe";
import {
  Collapse as AntCollapse,
  CollapseProps as AntCollapseProps,
} from "antd";
import "./styles.scss";
import { DownOutlined } from "@ant-design/icons";

const { Panel } = AntCollapse;

export interface PanelProps {
  key: string;
  panelTitle: string;
  contentComponent: React.ReactNode;
  panelClassName?: string;
}

export interface CollapseProps extends AntCollapseProps {
  className?: string;
  panels: PanelProps[];
}

export const Collapse: React.FC<CollapseProps> = ({
  className,
  panels,
  ...restProps
}) => {
  return (
    <AntCollapse
      className={cn("ui-collapse", className)}
      expandIcon={({ isActive }) => (
        <DownOutlined rotate={isActive ? -180 : 0} />
      )}
      {...restProps}
    >
      {panels.map(({ key, panelTitle, contentComponent, panelClassName }) => (
        <Panel
          header={panelTitle}
          key={key}
          className={cn("ui-collapse-panel-header", panelClassName)}
        >
          {contentComponent}
        </Panel>
      ))}
    </AntCollapse>
  );
};

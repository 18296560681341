import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";


export type HeaderState = {
  title: string;
};

const initialHeaderState: HeaderState = {
  title: "",
}

const headerSlice = createSlice({
  name: "header",
  initialState: initialHeaderState,
  reducers: {
    setHeaderTitle(
      state,
      { payload }: PayloadAction<{ title: string }>
    ) {
      state.title = payload.title;
    },
  },
});

export const { setHeaderTitle } = headerSlice.actions;

export const appReducer = combineReducers({
  header: headerSlice.reducer,
});
import React from "react";
import { useAppDispatch } from "services/store/store";
import { FieldInput } from "components/UI/Table/FieldInput";
import { PositionType } from "graphql/types/types";
import { setPositionCountPeople } from "services/store/modules/directory";
import { updatePositionCount } from "services/store/modules/company";

type Props = {
  id: PositionType["id"];
  countPeople?: PositionType["countPeople"];
  isOpen: () => void;
  setModalMessage: (message: string) => void;
};

export const FieldPositionCount: React.FC<Props> = ({
  countPeople,
  id,
  isOpen,
  setModalMessage,
}) => {
  const dispatch = useAppDispatch();

  const handleUpdatePosition = async (value: string | number) => {
    const payload = {
      id: Number(id),
      count: Number(value),
    };
    const result = await dispatch(updatePositionCount(payload)).unwrap();

    if (!result) {
      return;
    }
    "updatePositionCount" in result &&
    result?.updatePositionCount?.countPeopleNew
      ? setModalMessage(
          "Константа будет изменена в 2.00 по Московскому времени"
        )
      : setModalMessage("Константа изменена");

    isOpen();
    dispatch(setPositionCountPeople(payload));
  };

  return (
    <FieldInput
      type="number"
      fieldValue={countPeople || 0}
      onSave={handleUpdatePosition}
    />
  );
};

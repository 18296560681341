import * as yup from "yup";

export const schema = yup
  .object({
    name: yup.string().required("Необходимо указать название"),
    businessArea: yup
      .number()
      .required("Необходимо указать сферу деятельности"),
    experience: yup.string().required("Необходимо указать опыт работы"),
    schedule: yup.string().required("Необходимо указать график работы"),
  })
  .required();

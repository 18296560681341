import { RootState } from "services/store/rootReducer";
import { createSelector } from "reselect";

export const selectPlans = (state: RootState) => state.plans;

export const selectPlansData = createSelector(
  selectPlans,
  (state) => state.data || []
);

export const selectPlansLoading = createSelector(
  selectPlans,
  (state) => state.loading
);

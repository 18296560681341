import { apiRequest } from "../apiRequest";

export interface CandidateHistoryItem {
  id: string;
  created_at: string;
  candidate_id: string;
  user_id: number;
  field: string;
  old_value: string;
  new_value: string;
  model_type: ModelTypeHistory;
}

export enum ModelTypeHistory {
  Event = "event",
  Comment = "comment",
}

export interface CandidateHistoryResponse {
  items: CandidateHistoryItem[];
  total: number;
  page: number;
  size: number;
}

export const fetchHistoryCandidate = <T>(id) => {
  return apiRequest<T>("API", "get", `/activity/history/candidate/${id}`);
};

import * as yup from "yup";

export const schema = yup
  .object({
    managerIds: yup
      .array()
      .of(yup.number().required())
      .test({
        message: "Необходимо указать кому отправить письмо",
        test: (arr) => (arr?.length || 0) > 0,
      }),
  })
  .required();

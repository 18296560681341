import { createAsyncThunk } from "@reduxjs/toolkit";
import { POSITION_RELATIONS } from "graphql/requests/query/positionRelations";
import { client as apollo } from "graphql/apollo";
import { AsyncThunkConfig } from "services/store/types";
import {
  PositionsRelationsResponse,
  UpdateRelationPositionResponse,
} from "./types";
import { UPDATE_RELATION_POSITION } from "graphql/requests/mutation/updateRelationPosition";
import { MutationUpdateRelationPositionArgs } from "graphql/types/types";

export const fetchPositionsRelations = createAsyncThunk<
  PositionsRelationsResponse,
  void,
  AsyncThunkConfig
>("settings/positionsRelations", async (_, { rejectWithValue }) => {
  try {
    const response = await apollo.query<PositionsRelationsResponse>({
      query: POSITION_RELATIONS,
    });

    if (response.errors) {
      return rejectWithValue("Невозможно загрузить словарь должностей");
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const updateRelationPosition = createAsyncThunk<
  UpdateRelationPositionResponse | null | undefined,
  MutationUpdateRelationPositionArgs,
  AsyncThunkConfig
>("settings/updateRelationPosition", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate<UpdateRelationPositionResponse>({
      mutation: UPDATE_RELATION_POSITION,
      variables: payload,
    });

    if (response.errors) {
      return rejectWithValue("Невозможно обновить словарь должностей");
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

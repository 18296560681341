import { VacancyType } from "graphql/types/types";
import { ZarplataState } from "./types";
import { transformToDaDataAddressSuggestion } from "common/helpers/transformDaData";

export const getInitialState = (vacancy: VacancyType): ZarplataState => ({
  name: vacancy.name ?? "",
  description: vacancy.description ?? "",
  shortDescription: vacancy.shortDescription ?? "",
  terms: vacancy.terms ?? "",
  claims: vacancy.claims ?? "",
  platformTags: vacancy.platformTags ?? "",
  businessArea: vacancy.additionalAvito?.businessArea ?? 0,
  experience: vacancy.additionalAvito?.experience ?? "",
  salaryFrom: vacancy.salary.from ?? 0,
  salaryTo: vacancy.salary.to ?? 0,
  managerId: vacancy?.manager?.id,
  address: transformToDaDataAddressSuggestion(vacancy.address),
  schedule: "",
  profession: "",
  registrationMethod: [],
  bonuses: [],
  isRemote: undefined,
  isSideJob: undefined,
});

import { UserType, VacancyType } from "graphql/types/types";
import { AvitoState } from ".";
import { transformToDaDataAddressSuggestion } from "common/helpers/transformDaData";
import { getAddress, getContacts, getDescription, getSalary } from "../helpers";

export const getInitialState = (vacancy: VacancyType): AvitoState => ({
  name: vacancy.name ?? "",
  description: vacancy.description ?? "",
  shortDescription: vacancy.shortDescription ?? "",
  terms: vacancy.terms ?? "",
  claims: vacancy.claims ?? "",
  platformTags: vacancy.platformTags ?? "",
  businessArea: vacancy.additionalAvito?.businessArea ?? 0,
  experience: vacancy.additionalAvito?.experience ?? "",
  salaryFrom: vacancy.salary.from ?? 0,
  salaryTo: vacancy.salary.to ?? 0,
  managerId: vacancy?.manager?.id,
  address: transformToDaDataAddressSuggestion(vacancy.address),
  schedule: "",
  profession: "",
  registrationMethod: [],
  bonuses: [],
  isRemote: undefined,
  isSideJob: undefined,
});

export const getPayload = (
  {
    businessArea,
    description,
    shortDescription,
    terms,
    claims,
    salaryFrom,
    salaryTo,
    managerId,
    address,
    profession,
    isRemote,
    isSideJob,
    platformTags,
    ...props
  }: AvitoState,
  managerList: UserType[]
) => ({
  description: getDescription({
    description,
    shortDescription,
    terms,
    claims,
    platformTags,
  }),
  address: getAddress(address),
  salary: getSalary(salaryFrom, salaryTo),
  contacts: getContacts(managerId, managerList),
  business_area: businessArea,
  profession: Number(profession),
  ...(isRemote ? { is_remote: isRemote === "true" } : {}),
  ...(isSideJob ? { is_side_job: isSideJob === "true" } : {}),
  ...props,
});

import React from "react";
import { PhoneCall } from "components/UI/icons";
import { Button } from "components/UI/Button";
import {
  CandidateType,
  ContactType,
  HotCallInputType,
  SourceHotCall,
} from "graphql/types/types";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { authInfo } from "services/store/modules/auth";
import { selectCallsLoading } from "services/store/modules/callsMango/selectors";
import { fetchCall } from "services/store/modules/callsMango/action";

import "./styles.scss";

interface MangoButtonProps {
  candidate: CandidateType;
  contact: ContactType["contact"];
}

export const MangoButton: React.FC<MangoButtonProps> = ({
  candidate,
  contact,
}) => {
  const dispatch = useAppDispatch();
  const authData = useAppSelector(authInfo);
  const loading = useAppSelector(selectCallsLoading);

  const handleClickMango = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!authData) {
      return;
    }
    const payload: HotCallInputType = {
      contacts: {
        phone: contact,
        fullName: candidate?.fullName,
      },
      isMango: true,
      managerId: String(authData?.id),
      //эти поля не нужны для манго, отправлям т.к. обязательные для oktell
      sourceType: SourceHotCall.Candidate,
      sourceId: candidate.id,
      status: "START",
      managerRemoteId: "",
    };
    dispatch(fetchCall(payload));
    e.stopPropagation();
  };
  return (
    <Button
      className="mango-btn"
      onClick={handleClickMango}
      icon={<PhoneCall />}
      loading={loading}
    >
      Позвонить
    </Button>
  );
};

import { gql } from "@apollo/client";

export const UPDATE_HOT_CALL = gql`
  mutation updateHotCall($form: HotCallInputType!, $id: ID!) {
    updateHotCall(form: $form, id: $id) {
      ... on HotCallType {
        contacts {
          email
          fullName
          phone
        }
        createdAt
        deletedAt
        finishAt
        id
        managerId
        managerRemoteId
        result
        sourceId
        sourceType
        startAt
        status
        updatedAt
        notThroughReason
      }
      ... on Error {
        errors
        message
        statusCode
      }
    }
  }
`;

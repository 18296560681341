import React, { useRef } from "react";

import { useOnClickOutside } from "common/hooks/useOnClickOutside";
import { CandidateType, ContactType, UserRole } from "graphql/types/types";
import { OktellButton } from "./components/OktellButton";
import { Dropdown, Props } from "components/UI/Dropdown";

import "./styles.scss";
import { MangoButton } from "./components/MangoButton";
import { useAppSelector } from "../../../services/store/store";
import { authInfo } from "../../../services/store/modules/auth";

export interface DropdownMenuProps {
  isVisible: boolean;
  candidate: CandidateType;
  vacancyId: number;
  contact: ContactType["contact"];
  setDropdownVisible: (visible: boolean) => void;
}

export const DropdownCallsMenu: React.FC<DropdownMenuProps> = ({
  isVisible,
  contact,
  candidate,
  vacancyId,
  setDropdownVisible,
}) => {
  const authData = useAppSelector(authInfo);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setDropdownVisible(false));

  const items: Props["items"] = [
    {
      key: "1",
      label: (
        <div className="dropdown-item" ref={dropdownRef}>
          Октелл{" "}
          <OktellButton
            contact={contact}
            candidate={candidate}
            vacancyId={vacancyId}
          />
        </div>
      ),
    },
    ...(authData && authData.mangoId
      ? [
          {
            key: "2",
            label: (
              <div className="dropdown-item" ref={dropdownRef}>
                Mango <MangoButton contact={contact} candidate={candidate} />
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <Dropdown
      className="dropdown-calls-menu"
      overlayClassName="dropdown-calls-menu-overlay"
      items={items}
      trigger={["click"]}
      placement="bottomLeft"
      open={isVisible}
    >
      <span />
    </Dropdown>
  );
};

import React from "react";
import { IconType } from "common/commonTypes/types";

export const Warning: React.FC<IconType> = ({
  width = 20,
  height = 20,
  color = "#FEC500",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.30661 3.9716C9.04467 2.67613 10.9553 2.67613 11.6933 3.9716L18.0867 15.1937C18.804 16.4527 17.8704 18 16.3934 18H3.60653C2.12951 18 1.19588 16.4527 1.91318 15.1937L8.30661 3.9716ZM10.8332 14.6667C10.8332 15.1269 10.4601 15.5 9.99991 15.5C9.53967 15.5 9.16657 15.1269 9.16657 14.6667C9.16657 14.2064 9.53967 13.8333 9.99991 13.8333C10.4601 13.8333 10.8332 14.2064 10.8332 14.6667ZM10.6249 8C10.6249 7.65482 10.3451 7.375 9.99991 7.375C9.65473 7.375 9.37491 7.65482 9.37491 8V12.1667C9.37491 12.5118 9.65473 12.7917 9.99991 12.7917C10.3451 12.7917 10.6249 12.5118 10.6249 12.1667V8Z"
        fill={color}
      />
    </svg>
  );
};

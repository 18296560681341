import React, { useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Title } from "components/UI/Title";
import { InputMask } from "components/UI/InputMask";
import { Button } from "components/UI/Button";
import { useAppSelector, useAppDispatch } from "services/store/store";
import {
  companySettings,
  updateCompanySetting,
} from "services/store/modules/company";
import { getTimeMask } from "common/helpers/time";
import { CompanySettingsInputType } from "graphql/types/types";
import { getInitialValues, validateTime } from "./helpers";
import toaster from "components/UI/Notifications/Notification";

import "./styles.scss";

export const CallTimePicker: React.FC = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(companySettings);
  const [timeError, setTimeError] = useState<string | null>(null);

  const initialState = useMemo<CompanySettingsInputType>(
    () => getInitialValues(company),
    [company]
  );
  const { control, watch, handleSubmit } = useForm<CompanySettingsInputType>({
    defaultValues: initialState,
  });

  const watchCallTimeStart = watch("callTimeStart");
  const watchCallTimeEnd = watch("callTimeEnd");

  const updateSettings = async (data: CompanySettingsInputType) => {
    try {
      await dispatch(updateCompanySetting(data)).unwrap();
      toaster.success({ title: "Данные успешно сохранены" });
    } catch (error) {
      toaster.error({ title: `Ошибка: ${error.message}` }, { autoClose: 3000 });
    }
  };

  const onSubmit = async (data: CompanySettingsInputType) => {
    if (validateTime(watchCallTimeStart, watchCallTimeEnd, setTimeError)) {
      await updateSettings(data);
    }
  };

  return (
    <div className="callTime-container">
      <Title type="h4" color="blue">
        Время для звонка кандидату
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="callTime-wrapper">
          <Controller
            name="callTimeStart"
            control={control}
            render={({ field }) => (
              <InputMask
                maskChar={null}
                mask={getTimeMask(watchCallTimeStart || company?.callTimeStart)}
                className="callTime-wrapper--input"
                {...field}
              />
            )}
          />
          <span className="callTime-wrapper--dash"> - </span>
          <Controller
            name="callTimeEnd"
            control={control}
            render={({ field }) => (
              <InputMask
                maskChar={null}
                mask={getTimeMask(watchCallTimeEnd || company?.callTimeEnd)}
                className="callTime-wrapper--input"
                {...field}
              />
            )}
          />
          <Button type="submit"> Сохранить </Button>
        </div>
        {timeError && <p className="error-message">{timeError}</p>}
      </form>
    </div>
  );
};

import React from "react";
import { Input as AntInput } from "antd";
import classNames from "classnames";
import "./styles.scss";
import { TextAreaProps as AntTextAreaProps } from "antd/lib/input/TextArea";

const { TextArea } = AntInput;
interface TextAreaProps extends AntTextAreaProps {}

const InputTextArea: React.FC<TextAreaProps> = ({
  style,
  className,
  disabled,
  required,
  ...props
}) => {
  const textAreaClassNames = classNames(
    "ui-text-area",
    {
      "ui-text-area--disabled": disabled,
      "ui-input--required": required,
    },
    className
  );
  return (
    <TextArea
      className={textAreaClassNames}
      style={{ ...style }}
      disabled={disabled}
      {...props}
    />
  );
};

export default InputTextArea;

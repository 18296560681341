import { Breadcrumb, Row, Col } from "antd";
import styled from "styled-components";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { routes } from "../routes";

const BreadcrumbsRows = styled(Row)`
  margin: 16px 25px;
`;

export const Breadcrumbs = () => {
  const { pathname } = useLocation();

  const matchedRoute = routes.find((route) => matchPath(pathname, route));

  if (!matchedRoute || !matchedRoute.breadcrumbs) {
    return null;
  }

  return (
    <BreadcrumbsRows>
      <Col>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/">Главная</a>
          </Breadcrumb.Item>
          {matchedRoute.breadcrumbs.map(({ text, url }, id) => (
            <Breadcrumb.Item key={id}>
              <a href={url}>{text}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </Col>
    </BreadcrumbsRows>
  );
};

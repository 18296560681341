import { useEffect, useRef } from "react";

export const useBeforeUnload = (fn: EventListenerOrEventListenerObject) => {
  const cb = useRef(fn);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [cb]);
};

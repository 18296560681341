import { Link } from "react-router-dom";

import { LogoIcon } from "components/UI/icons";

import "./styles.scss";

type Props = {
  isCollapsed: boolean;
};

export const Logo: React.FC<Props> = ({ isCollapsed }) => {
  return (
    <div className="logo">
      <Link to={"/"}>
        <LogoIcon type={isCollapsed ? "small" : "large"} />
      </Link>
    </div>
  );
};

import { gql } from "@apollo/client";

export const CHANGE_REPLY_STATUS = gql`
  mutation changeReplyStatus($id: Int!, $status: ReplyStatus!) {
    changeReplyStatus(id: $id, status: $status) {
      ... on ReplyType {
        expiredTime
        citizenshipId
        managerId
        claimId
        source
        s2Id
        notCallCount
        employmentId
        createdAt
        updatedAt
        deletedAt
        id
        refusalId
        updatedBy
        expiredCallTime
        contacts {
          fullName
          email
          phone
        }
        vacancyId
        positionId
        ownerId
        companyId
        departmentId
        status
        address {
          value
          city
        }
        userContent
        userComment
        managerComment
        utm {
          source
          campaign
          content
          term
          medium
        }
        expiredTime
        citizenshipId
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

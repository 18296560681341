import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CandidateSuitableListRequestPayload,
  CandidateSuitableListResponse,
  ICandidateListByVacancyPayload,
  ICandidateListByVacancyRequestResponse,
  ICandidateListPayload,
  ICandidateRequestResponse,
} from "./types";
import { client as apollo } from "graphql/apollo";
import { CANDIDATES_LIST } from "graphql/requests/query/candidatesList";
import { CANDIDATES_LIST_BY_VACANCY } from "graphql/requests/query/candidateListByVacancy";
import { CANDIDATE_SUITABLE_LIST } from "graphql/requests/query/candidateSuitableList";
import { AsyncThunkConfig } from "services/store/types";

export const fetchCandidateList = createAsyncThunk(
  "candidates/fetchCandidateList",
  async (payload: ICandidateListPayload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<ICandidateRequestResponse>({
        query: CANDIDATES_LIST,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchCandidateListByVacancy = createAsyncThunk(
  "candidates/fetchCandidateListByVacancy",
  async (payload: ICandidateListByVacancyPayload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<ICandidateListByVacancyRequestResponse>({
        query: CANDIDATES_LIST_BY_VACANCY,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCandidateSearchList = createAsyncThunk(
  "candidates/fetchCandidateSearchList",
  async (payload: ICandidateListPayload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<ICandidateRequestResponse>({
        query: CANDIDATES_LIST,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCandidateSuitableList = createAsyncThunk<
  CandidateSuitableListResponse,
  CandidateSuitableListRequestPayload,
  AsyncThunkConfig
>(
  "candidates/fetchCandidateSuitableList",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<CandidateSuitableListResponse>({
        query: CANDIDATE_SUITABLE_LIST,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

import { gql } from "@apollo/client";

export const UPDATE_RELATION_POSITION = gql`
  mutation updateRelationPosition($form: PositionsRelationsForm!) {
    updateRelationPosition(form: $form) {
      ... on PositionsRelationsType {
        avitoTags
        categoryId
        restriction
        positionIdFrom
        positionIdTo
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

import React from "react";
import { Form } from "antd";
import classNames from "classnames";
import "./styles.scss";
import TextArea from "../../TextArea";
import { TextAreaProps } from "antd/lib/input/TextArea";

interface FormTextAreaProps extends TextAreaProps {
  label: string;
}
const FormTextArea: React.FC<FormTextAreaProps> = ({
  className,
  required,
  label,
  ...props
}) => {
  const formInputClassName = classNames(
    "ui-form-input",
    { "ui-form-input--required": required },
    className
  );

  return (
    <Form.Item
      label={label}
      className={formInputClassName}
      rules={[{ required, message: `, введите ${label}` }]}
      required={required}
    >
      <TextArea {...props} />
    </Form.Item>
  );
};

export default FormTextArea;

import React from "react";
import { useAppDispatch, useAppSelector } from "services/store/store";

import Select from "components/UI/Select";
import { Notification } from "components/UI/Notifications/Notification";
import { getCallsPlanList } from "services/store/modules/plans/actions";
import { selectFilteredDepartments } from "services/store/modules/directory";

export const PlanHeader: React.FC = () => {
  const dispatch = useAppDispatch();

  const departmentDirectory = useAppSelector(selectFilteredDepartments);

  const handleDepartmentChange = (value: number) => {
    dispatch(getCallsPlanList({ departmentId: value }));
  };
  return (
    <div className="plan-header">
      <div className="plan-header-filters">
        <Select
          placeholder="Департамент"
          options={departmentDirectory?.map((el) => ({
            label: el.name,
            value: el.id,
          }))}
          onChange={handleDepartmentChange}
          style={{ width: "220px" }}
        />
      </div>
      <Notification
        type="info"
        title="План на следующий день формируется из плана прошлого дня"
      />
    </div>
  );
};

import { gql } from "@apollo/client";

export const DAILY_PLAN_LIST = gql`
  query dailyPlanList($date: DateTime!) {
    dailyPlanList(date: $date) {
      ... on DailyPlanListSuccess {
        data {
          categoryId
          createdAt
          criticalPeopleCount
          criticalPeopleCountClosed
          deletedAt
          id
          managerId
          peopleCount
          peopleCountClosed
          planDate
          plannerId
          updatedAt
        }
      }
      ... on Error {
        message
        statusCode
        errors
      }
    }
  }
`;

import React from "react";
import { IconType } from "common/commonTypes/types";

export const Excel: React.FC<IconType> = ({
  width = 20,
  height = 20,
  color = "#0057AC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3333 9V13.5C18.3333 15.7091 16.5424 17.5 14.3333 17.5H5.66663C3.45749 17.5 1.66663 15.7091 1.66663 13.5V6.5C1.66663 4.29086 3.45749 2.5 5.66663 2.5H6.99996C7.86544 2.5 8.70757 2.78071 9.39996 3.3L10.6 4.2C11.2923 4.71929 12.1345 5 13 5H14.3333C16.5424 5 18.3333 6.79086 18.3333 9ZM9.26339 11.7129C9.29878 11.7483 9.33608 11.7806 9.37496 11.8099V8.33338C9.37496 7.9882 9.65478 7.70838 9.99996 7.70838C10.3451 7.70838 10.625 7.9882 10.625 8.33338V11.8099C10.6638 11.7806 10.7011 11.7483 10.7365 11.7129L12.058 10.3914C12.3021 10.1474 12.6978 10.1474 12.9419 10.3914C13.186 10.6355 13.186 11.0312 12.9419 11.2753L11.6204 12.5968C10.7255 13.4918 9.27446 13.4918 8.37951 12.5968L7.05802 11.2753C6.81394 11.0312 6.81394 10.6355 7.05802 10.3914C7.3021 10.1474 7.69782 10.1474 7.9419 10.3914L9.26339 11.7129Z"
        fill={color}
      />
    </svg>
  );
};

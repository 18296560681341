import { gql } from "@apollo/client";

export const DECLINE_VACANCY = gql`
  mutation declineVacancy($id: Int!, $refusalId: Int!, $refusalText: String!) {
    declineVacancy(id: $id, refusalId: $refusalId, refusalText: $refusalText) {
      ... on VacancyType {
        id
        name
        refusalText
      }
      ... on Error {
        message
        statusCode
        errors
      }
    }
  }
`;

import React from "react";
import { Form } from "antd";

import classNames from "classnames";
import "./styles.scss";
import Text from "../../Text";

interface FormTextProps {
  label: string;
  className?: string;
  text: React.ReactNode;
  style?: React.CSSProperties;
}

const FormText: React.FC<FormTextProps> = ({ label, className, ...props }) => {
  const formTextClassName = classNames("ui-form-text", className);

  return (
    <Form.Item label={label} className={formTextClassName}>
      <Text {...props} />
    </Form.Item>
  );
};

export default FormText;

import React from "react";
import { YMaps } from "@pbe/react-yandex-maps";
import { MapCustomDisplay } from "./MapCustomDisplay";

export type PlacemarkData = {
  id: string;
  lat: number;
  lng: number;
  name?: string;
  title?: string;
  isCurrentVacancy?: boolean;
};

type Props = {
  className?: string;
  placemarks: PlacemarkData[];
  width?: string | number;
  height?: string | number;
  zoom?: number;
};

const YANDEX_API_KEY = "8e05150c-3ec7-4fd0-a2f2-17628644f088";

export const Maps: React.FC<Props> = ({
  placemarks,
  width,
  height,
  zoom,
  className,
}) => {
  return (
    <YMaps query={{ apikey: YANDEX_API_KEY }}>
      <MapCustomDisplay
        className={className}
        placemarks={placemarks}
        width={width}
        height={height}
        zoom={zoom}
      />
    </YMaps>
  );
};

// @ts-nocheck
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RepliesState,
  IFeedback,
  IFeedbackResponse,
  IFeedbackRequest,
  IReplyInput,
  IClaimRequestPayload,
  IClaimComment,
  IClaimCommentRequestPayload,
  IClaimHistory,
  ClaimActivityType,
} from "./types";
import { RootState } from "../../rootReducer";
import { client as apollo } from "../../../../graphql/apollo";
import { CLAIM_LIST } from "../../../../graphql/requests/query/claimList";
import { UPDATE_CLAIM } from "../../../../graphql/requests/mutation/updateClaim";
import { IDepartment } from "../directory/types";
import { CONVERT_CLAIM_TO_REPLY } from "../../../../graphql/requests/mutation/convertClaimToReply";
import { IActivityMeta, IActivityRequestPayload, IMeta } from "../../types";
import axios from "axios";
import { IHistoryUser, IUser } from "../user/types";
import { camelize } from "../../../../common/helpers/keyConverter";
import { fillUserHistoryList, fillUsersHistory } from "../user/action";
import toaster from "components/UI/Notifications/Notification";
import { normalizedDate } from "../../../../common/helpers/toISOStringWithTimezone";
import { IRefusal, IReplyHistory } from "../replies/types";
import { AddressType, ContactsType } from "../../../../graphql/types/types";
import { setLoadingReplyActivity } from "../replies";

export const initialState: RepliesState = {
  claimList: [],
  meta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  currentClaim: undefined,
  claimComments: [],
  isLoadingClaimActivity: false,
  claimHistory: [],
  claimActivity: [],
  claimCommentsMeta: {
    total: 0,
    page: 0,
    size: 10,
  },
  claimHistoryMeta: {
    total: 0,
    page: 0,
    size: 10,
  },
  claimActivityMeta: {
    total: 0,
    page: 0,
    size: 10,
  },
};

export const claimsModule = createSlice({
  name: "claims",
  initialState,
  reducers: {
    setClaimsList(
      state: RepliesState,
      { payload }: PayloadAction<IFeedback[]>
    ) {
      state.claimList = payload;
    },
    setMeta(state: RepliesState, { payload }: PayloadAction<IMeta>) {
      state.meta = payload;
    },
    setCurrentClaim(
      state: RepliesState,
      { payload }: PayloadAction<IFeedback>
    ) {
      state.currentClaim = payload;
    },
    setClaimComments(
      state: RepliesState,
      { payload }: PayloadAction<IClaimComment[]>
    ) {
      const commentList = state.claimComments;
      state.claimComments = [...commentList, ...payload];
    },
    setLoadingClaimActivity(
      state: RepliesState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isLoadingClaimActivity = payload;
    },
    setClaimHistory(
      state: RepliesState,
      { payload }: PayloadAction<IClaimHistory[]>
    ) {
      const historyList = state.claimHistory;
      state.claimHistory = [...historyList, ...payload];
    },
    setClaimActivity(
      state: RepliesState,
      { payload }: PayloadAction<ClaimActivityType[]>
    ) {
      const activityList = state.claimActivity;
      state.claimActivity = [...activityList, ...payload];
    },

    setClaimHistoryMeta(
      state: RepliesState,
      { payload }: PayloadAction<IActivityMeta>
    ) {
      state.claimHistoryMeta = payload;
    },
    setClaimCommentsMeta(
      state: RepliesState,
      { payload }: PayloadAction<IActivityMeta>
    ) {
      state.claimCommentsMeta = payload;
    },
    setClaimActivityMeta(
      state: RepliesState,
      { payload }: PayloadAction<IActivityMeta>
    ) {
      state.claimActivityMeta = payload;
    },
    clearClaimHistory(state: RepliesState) {
      state.claimHistory = [];
      state.claimHistoryMeta = {
        total: 0,
        page: 0,
        size: 10,
      };
    },
    clearClaimActivity(state: RepliesState) {
      state.claimActivity = [];
      state.claimActivityMeta = {
        total: 0,
        page: 0,
        size: 10,
      };
    },
    clearClaimComments(state: RepliesState) {
      state.claimComments = [];
      state.claimCommentsMeta = {
        total: 0,
        page: 0,
        size: 10,
      };
    },
  },
});
// * mutations
export const {
  setClaimsList,
  setMeta,
  setCurrentClaim,
  setClaimComments,
  setLoadingClaimActivity,
  setClaimHistory,
  setClaimActivity,
  setClaimHistoryMeta,
  clearClaimHistory,
  clearClaimActivity,
  clearClaimComments,
  setClaimCommentsMeta,
  setClaimActivityMeta,
} = claimsModule.actions;

// * getters
export const claims = (state: RootState) => state.claims.claimList;
export const claimsMeta = (state: RootState) => state.claims.meta;
export const currentClaim = (state: RootState) => state.claims.currentClaim;
export const isLoadingClaimActivity = (state: RootState) =>
  state.claims.isLoadingClaimActivity;
export const currentClaimComments = (state: RootState) => {
  const userHistoryList = state.user.historyUserList;
  const comments = state.claims.claimComments;
  const data = comments.map((item: IClaimComment) => {
    const created = item.createdAt;
    const preparedDate = normalizedDate(created);
    return {
      ...item,
      manager: item?.userId ? userHistoryList?.[item?.userId] : undefined,
      createdAt: preparedDate,
      dateSortValue: new Date(created)?.valueOf(),
    };
  }) as IClaimComment[];
  return data.sort(
    (a: IClaimComment, b: IClaimComment) =>
      (b?.dateSortValue || 0) - (a?.dateSortValue || 0)
  );
};
export const currentClaimHistory = (state: RootState) => {
  const userHistoryList = state.user.historyUserList;
  const activities = state.claims.claimHistory;
  const data = activities.map((item: IClaimHistory) => {
    const created = item.createdAt;
    const preparedDate = normalizedDate(created);
    const el = {
      ...item,
      manager: item?.userId ? (userHistoryList?.[item?.userId] as IUser) : 0,
      createdAt: preparedDate,
      dateSortValue: new Date(created)?.valueOf(),
    };
    if (item?.field === "owner_id" || item?.field === "manager_id") {
      el.newManager = userHistoryList?.[item?.newValue as number] as IUser;
      el.oldManager = userHistoryList?.[item?.oldValue as number] as IUser;
    }
    return el;
  }) as IClaimHistory[];
  return data.sort(
    (a: IClaimHistory, b: IClaimHistory) =>
      (b?.dateSortValue || 0) - (a?.dateSortValue || 0)
  );
};
export const claimHistoryMeta = (state: RootState) =>
  state.claims.claimHistoryMeta;

export const currentClaimActivity = (state: RootState) => {
  const userHistoryList = state.user.historyUserList;
  const userActivityList = state.claims.claimActivity;
  const data = userActivityList.map((item: ClaimActivityType) => {
    const created = item.createdAt;
    const preparedDate = normalizedDate(created);
    const el = {
      ...item,
      manager: item?.userId ? (userHistoryList?.[item?.userId] as IUser) : 0,
      createdAt: preparedDate,
      dateSortValue: new Date(created)?.valueOf(),
    } as ClaimActivityType;
    if (
      item.modelType === "event" &&
      (item?.field === "owner_id" || item?.field === "manager_id")
    ) {
      if ("newManager" in el) {
        el.newManager = userHistoryList?.[item?.newValue as number] as IUser;
      }
      if ("oldManager" in el) {
        el.oldManager = userHistoryList?.[item?.oldValue as number] as IUser;
      }
    }
    return el;
  }) as ClaimActivityType[];
  return data.sort(
    (a: ClaimActivityType, b: ClaimActivityType) =>
      (b?.dateSortValue || 0) - (a?.dateSortValue || 0)
  );
};

export const claimCommentsMeta = (state: RootState) =>
  state.claims.claimCommentsMeta;

export const claimActivityMeta = (state: RootState) =>
  state.claims.claimActivityMeta;

// * actions
export const getFeedback = createAsyncThunk(
  "user/onSearch",
  // @ts-ignore
  async (payload: IClaimRequestPayload, thunkAPI) => {
    try {
      const res = await apollo.query<IFeedbackResponse>({
        query: CLAIM_LIST,
        variables: {
          pagination: payload.pagination,
          filter: payload.filter,
        },
      });
      if (res?.data?.claimList?.data) {
        const departmentList =
          // @ts-ignore
          thunkAPI.getState()?.directory?.departments || [];
        let resClaims = {
          data: res.data.claimList?.data.map((item: IFeedback) => ({
            ...item,
            manager: item.manager?.departmentId
              ? {
                  ...item.manager,
                  department: departmentList.find(
                    (el: IDepartment) =>
                      el.id ===
                      parseInt(item.manager?.departmentId?.toString() || "", 10)
                  ),
                }
              : item.manager,
            owner: item?.owner?.departmentId
              ? {
                  ...item.owner,
                  department: departmentList.find(
                    (el: IDepartment) =>
                      el.id ===
                      parseInt(item.owner?.departmentId?.toString() || "", 10)
                  ),
                }
              : item.owner,
          })),
          meta: res.data.claimList?.meta,
        };
        thunkAPI.dispatch(setClaimsList(resClaims?.data || []));
        thunkAPI.dispatch(setMeta(resClaims?.meta));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const createReply = createAsyncThunk(
  "reply/createReply",
  async (payload: IReplyInput) => {
    try {
      const res = await apollo.mutate({
        mutation: CONVERT_CLAIM_TO_REPLY,
        variables: {
          vacancyId: payload.vacancyID,
          claimId: payload.claimID,
        },
      });
      await Promise.resolve(res);
    } catch (err) {
      console.error(err);
      await Promise.reject();
    }
  }
);

export const updateClaim = createAsyncThunk(
  "claims/updateClaim",
  async (payload: { form: IFeedbackRequest; id: string }, thunkAPI) => {
    try {
      await apollo.mutate({
        mutation: UPDATE_CLAIM,
        variables: {
          id: payload.id,
          form: payload.form,
        },
      });
      const payloadClaim = {
        filters: {},
        pagination: {
          limit: 10,
          offset: 0,
        },
      };
      thunkAPI.dispatch(getFeedback(payloadClaim));
      await Promise.resolve();
    } catch (err) {
      console.error(err);
      await Promise.reject();
    }
  }
);

export const createClaimComment = createAsyncThunk(
  "claim/createComment",
  async (payload: IClaimCommentRequestPayload, thunkAPI) => {
    const { userId, claimId, content } = payload;
    try {
      thunkAPI.dispatch(setLoadingClaimActivity(true));
      // @ts-ignore
      const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
      await axios.post(`${activityApiUrl}/comment/claim/`, {
        user_id: userId,
        content: content,
        claim_id: claimId,
      });
    } catch (err) {
      console.error(`cliam id ${claimId}`);
      console.error(err);
      toaster.error({ title: err?.message || "Ошибка отправки комментария" });
    } finally {
      thunkAPI.dispatch(setLoadingClaimActivity(false));
    }
  }
);

export const getCurrentClaimComments = createAsyncThunk(
  "claim/getComments",
  async (payload: IActivityRequestPayload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoadingClaimActivity(true));
      const { id, page } = payload;

      const nextPage = page + 1;

      const size = 10;

      // @ts-ignore
      const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
      const res = await axios.get(
        `${activityApiUrl}/comment/claim/${id}?page=${nextPage}&size=${size}`
      );
      if (res?.data?.items?.length) {
        // @ts-ignore
        const historyUserCollection: IHistoryUser =
          thunkAPI.getState()?.user?.historyUserList;
        const loadedUserIdList: string[] = Object.keys(historyUserCollection);
        const payloadIdList: string[] = [];

        const data = camelize(res.data.items) as IClaimComment[];
        data.map((item) => {
          const userId = item?.userId;
          if (
            userId &&
            !loadedUserIdList?.includes(String(userId)) &&
            !payloadIdList?.includes(String(userId))
          ) {
            payloadIdList.push(String(userId));
          }
          return null;
        });
        if (payloadIdList?.length) {
          thunkAPI.dispatch(fillUserHistoryList(payloadIdList));
        }
        const meta = {
          page: res?.data?.page,
          size: res?.data?.size,
          total: res?.data?.total,
        };
        thunkAPI.dispatch(setClaimCommentsMeta(meta));
        thunkAPI.dispatch(setClaimComments(data));
      }
    } catch (err) {
      console.error(`claim id ${payload}`);
      console.error(err);
      toaster.error({ title: err?.message || "Ошибка загрузки комментариев" });
    } finally {
      thunkAPI.dispatch(setLoadingClaimActivity(false));
    }
  }
);

export const getClaimHistory = createAsyncThunk(
  "claim/getHistory",
  async (payload: IActivityRequestPayload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoadingClaimActivity(true));
      const { id, page } = payload;

      const nextPage = page + 1;

      const size = 10;

      // @ts-ignore
      const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
      const res = await axios.get(
        `${activityApiUrl}/history/claim/${id}?page=${nextPage}&size=${size}`
      );
      if (res?.data?.items?.length) {
        const preparedData = camelize(res?.data?.items).sort(
          (a: IClaimHistory, b: IClaimHistory) =>
            new Date(b.createdAt)?.valueOf() - new Date(a.createdAt)?.valueOf()
        );
        const data = preparedData.map((item: IClaimHistory) => {
          const el = item as IClaimHistory;
          if (item.field === "manager_id" || item.field === "owner_id") {
            // @ts-ignore
            const historyUserCollection: IHistoryUser =
              thunkAPI.getState()?.user?.historyUserList;
            if (!historyUserCollection?.[item?.newValue as number]) {
              const payload = [item.newValue];
              if (item.newValue && item.newValue !== "0") {
                thunkAPI.dispatch(fillUserHistoryList(payload));
              }
            } else {
              el.newManager = historyUserCollection?.[
                item?.newValue as number
              ] as IUser;
            }
            if (!historyUserCollection?.[item?.oldValue as number]) {
              const payload = [item.oldValue];
              if (item.oldValue && item.oldValue !== "0") {
                thunkAPI.dispatch(fillUserHistoryList(payload));
              }
            } else {
              el.oldManager = historyUserCollection?.[
                item?.oldValue as number
              ] as IUser;
            }
          }
          if (
            item.field === "contacts" ||
            item.field === "refusal" ||
            item.field === "address"
          ) {
            if (item?.newValue) {
              const newValue = JSON.parse(item.newValue as string);
              el.newValue = camelize(newValue) as
                | ContactsType
                | IUser
                | AddressType
                | IRefusal;
            }
            if (item.oldValue) {
              const oldValue = JSON.parse(item.oldValue as string);
              el.oldValue = camelize(oldValue) as
                | ContactsType
                | IUser
                | AddressType
                | IRefusal;
            }
          }
          return el;
        });
        const meta = {
          page: res?.data?.page,
          size: res?.data?.size,
          total: res?.data?.total,
        };
        thunkAPI.dispatch(setClaimHistoryMeta(meta));
        thunkAPI.dispatch(setClaimHistory(data));
        thunkAPI.dispatch(fillUsersHistory());
      }
    } catch (err) {
      console.error(`claim id ${payload}`);
      console.error(err);
      toaster.error({
        title: err?.message || "Ошибка загрузки истории изменения заявки",
      });
    } finally {
      thunkAPI.dispatch(setLoadingClaimActivity(false));
    }
  }
);

export const getClaimActivity = createAsyncThunk(
  "claim/getReplyActivity",
  async (payload: IActivityRequestPayload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoadingReplyActivity(true));
      const { id, page } = payload;

      const nextPage = page + 1;

      const size = 10;

      // @ts-ignore
      const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
      const res = await axios.get(
        `${activityApiUrl}/claim/${id}?page=${nextPage}&size=${size}`
      );
      if (res?.data?.items?.length) {
        const allActivity = camelize(res.data.items) || [];
        //* заполняем пользователей
        // @ts-ignore
        const historyUserCollection: IHistoryUser =
          thunkAPI.getState()?.user?.historyUserList;
        const loadedUserIdList: string[] = Object.keys(historyUserCollection);
        const payloadIdList: string[] = [];
        allActivity.map((item: ClaimActivityType) => {
          const userId = item?.userId;
          if (
            userId &&
            !loadedUserIdList?.includes(String(userId)) &&
            !payloadIdList?.includes(String(userId))
          ) {
            payloadIdList.push(String(userId));
          }
          return null;
        });
        if (payloadIdList?.length) {
          thunkAPI.dispatch(fillUserHistoryList(payloadIdList));
        }

        const commentList = allActivity.filter(
          (item: ClaimActivityType) => item.modelType === "comment"
        );
        const historyList = allActivity.filter(
          (item: ClaimActivityType) => item.modelType === "event"
        );

        const preparedHistoryList = historyList.map((item: IReplyHistory) => {
          const el = item as IReplyHistory;
          if (item.field === "manager_id" || item.field === "owner_id") {
            if (!historyUserCollection?.[item?.newValue as number]) {
              const payload = [item.newValue];
              if (item?.newValue && item.newValue !== "0")
                thunkAPI.dispatch(fillUserHistoryList(payload));
              el.newManager = undefined;
            } else {
              el.newManager = historyUserCollection?.[
                item?.newValue as number
              ] as IUser;
            }
            if (!historyUserCollection?.[item?.oldValue as number]) {
              const payload = [item.oldValue];
              if (item?.oldValue && item.oldValue !== "0") {
                thunkAPI.dispatch(fillUserHistoryList(payload));
                el.oldManager = undefined;
              }
            } else {
              el.oldManager = historyUserCollection?.[
                item?.oldValue as number
              ] as IUser;
            }
          }
          if (
            item.field === "contacts" ||
            item.field === "refusal" ||
            item.field === "address"
          ) {
            if (item?.newValue) {
              const newValue = JSON.parse(item.newValue as string);
              el.newValue = camelize(newValue) as
                | ContactsType
                | IUser
                | IRefusal;
            }
            if (item.oldValue) {
              const oldValue = JSON.parse(item.oldValue as string);
              el.oldValue = camelize(oldValue) as
                | ContactsType
                | IUser
                | IRefusal;
            }
          }
          return el;
        });

        const meta = {
          page: res?.data?.page,
          size: res?.data?.size,
          total: res?.data?.total,
        };
        thunkAPI.dispatch(setClaimActivityMeta(meta));
        const allActivityList = [...commentList, ...preparedHistoryList];

        thunkAPI.dispatch(setClaimActivity(allActivityList));
      }
    } catch (err) {
      console.error(`reply id ${payload}`);
      console.error(err);
      toaster.error({ title: err?.message || "Ошибка загрузки активности" });
    } finally {
      thunkAPI.dispatch(setLoadingReplyActivity(false));
    }
  }
);

export default claimsModule.reducer;

import moment from "moment-timezone";

type TimeZone = "Europe/Moscow";

export const getDateTimezone = (timeZone: TimeZone) => {
  return moment().tz(timeZone).format();
};

export const isCallTimeValid = (
  timezone: string,
  callTimeStart: string,
  callTimeEnd: string
): boolean => {
  const now = moment().tz(timezone);
  const startTime = moment.tz(callTimeStart, "HH:mm:ss", timezone);
  const endTime = moment.tz(callTimeEnd, "HH:mm:ss", timezone);

  return now.isBetween(startTime, endTime, null, "[]");
};

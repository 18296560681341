import { gql } from "@apollo/client";

export const UPDATE_CLAIM = gql`
  mutation updateClaim($form: ClaimUpdateType!, $id: Int!) {
    updateClaim(form: $form, id: $id) {
      ... on ClaimType {
        id
        category
        status
        position
        contacts {
          phone
          email
          fullName
        }
        source
        createdAt
        updatedAt
        deletedAt
        address {
          city
          value
          region
        }
        userComment
        userWish
        userContent
        managerComment
        utm {
          source
          campaign
          content
          medium
          term
        }
        extra {
          remoteId
          remoteUrl
          service
        }
        chatLink
        owner {
          city
          email
          phone
          id
          secondName
          role
          firstName
        }
        ownerId
        managerId
        companyId
        departmentId
        isSent
        sendTo
        sourceUrl
        replyId
        refusalId
        refusalText
        updatedBy
        expiredCallTime
        manager {
          city
          email
          phone
          id
          secondName
          role
          firstName
        }
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

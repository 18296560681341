import React, { ReactNode } from "react";
import { Form as FormAnt, FormProps } from "antd";
import classNames from "classnames";
import "./styles.scss";

interface FormWrapperProps extends FormProps {
  initialValues?: Record<string, any>;
  children?: ReactNode;
  className?: string;
}

export const Form: React.FC<FormWrapperProps> = ({
  initialValues,
  children,
  disabled,
  className,
  ...formProps
}) => {
  const formClassName = classNames(
    "ui-form",
    {
      "ui-form--disabled": disabled,
    },
    className
  );

  return (
    <FormAnt
      {...formProps}
      initialValues={initialValues}
      disabled={disabled}
      className={formClassName}
    >
      {children}
    </FormAnt>
  );
};

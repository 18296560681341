import React from "react";
import { Form, RadioGroupProps } from "antd";
import Radio from "../../Radio";
import classNames from "classnames";
import "./styles.scss";

interface FormRadioProps extends RadioGroupProps {
  label: string;
}
const FormRadio: React.FC<FormRadioProps> = ({
  className,
  label,
  ...props
}) => {
  const formRadioClassName = classNames("ui-form-radio", className);

  return (
    <Form.Item label={label} className={formRadioClassName}>
      <Radio {...props} />
    </Form.Item>
  );
};

export default FormRadio;

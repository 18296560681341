import { PositionCategoryType, DailyPlanType } from "graphql/types/types";

export const CLAIM_CATEGORY_ID = 69;

export const DAILY_PLAN_CLAIMS = {
  categoryId: CLAIM_CATEGORY_ID,
  peopleCount: "-",
  criticalPeopleCount: "-",
  criticalPeopleCountClosed: "-",
} as unknown as DailyPlanType;

export const POSITION_CATEGORY_CLAIM: PositionCategoryType = {
  id: CLAIM_CATEGORY_ID,
  key: "claim",
  name: "Заявки",
};

export const transformAndSortCategories = (
  categories: PositionCategoryType[]
) => {
  return categories
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id }) => ({
      categoryId: id,
      peopleCount: "-",
      criticalPeopleCount: "-",
      criticalPeopleCountClosed: "-",
    })) as unknown as DailyPlanType[];
};

import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      ... on UserType {
        id
        email
        city
        companyId
        deletedAt
        createdAt
        updatedAt
        secondName
        phone
        role
        id
        firstName
        mangoId
        email
        departmentId
        company {
          name
          id
          owner {
            id
          }
          phone
          email
        }
        tz
        department {
          id
          name
          owner {
            id
          }
        }
        oktellLogin
      }
      ... on Error {
        statusCode
        message
      }
    }
  }
`;

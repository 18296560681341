import { gql } from "@apollo/client";

export const SET_COMPANY_SETTINGS = gql`
  mutation setCompanySettings($form: CompanySettingsInputType!) {
    setCompanySettings(form: $form) {
      ... on CompanySettingsType {
        id
        companyId
        createdAt
        deletedAt
        enabledExpire
        enabledRecall
        expireDepartmentId
        expireManagerId
        expireValue
        recallValue
        updatedAt
        workHoursEnd
        workHoursEndFriday
        workHoursStart
        workHoursStartFriday
        callTimeEnd
        callTimeStart
      }
      ... on Error {
        errors
        message
        statusCode
      }
    }
  }
`;

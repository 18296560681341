import React from "react";
import { Form, TreeSelectProps } from "antd";
import { TreeSelect } from "../../TreeSelect";
import classNames from "classnames";
import "./styles.scss";

interface FormTreeSelectProps extends TreeSelectProps {
  label: string;
  required?: boolean;
  className?: string;
  error?: string;
}

export const FormTreeSelect: React.FC<FormTreeSelectProps> = ({
  label,
  required,
  className,
  error,
  ...props
}) => {
  const formSelectClassName = classNames(
    "ui-form-tree-select",
    { "ui-form-tree-select--required": required },
    className
  );

  return (
    <Form.Item
      label={label}
      className={formSelectClassName}
      rules={[{ required, message: `Пожалуйста выберите ${label}` }]}
      required={required}
    >
      <TreeSelect error={error} {...props} />
      {error && <div className="ui-form-tree-select-error">{error}</div>}
    </Form.Item>
  );
};

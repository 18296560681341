import React from "react";
import {
  Control,
  Controller,
  UseFormSetError,
  useWatch,
  UseFormClearErrors,
} from "react-hook-form";

import FormDatePicker from "components/UI/Form/FormDatePicker";
import { FormInput } from "components/UI/Form/FormInput";
import { PilotsFiltersState } from "../../types";
import { getDisabledDateFrom, getDisabledDateTo } from "../../helpers";
import { FormSelect } from "components/UI/Form/FormSelect";
import { filterOption } from "components/UI/Select/helpers";
import { DepartmentType } from "graphql/types/types";
import { IUser } from "services/store/modules/user/types";

type Props = {
  control: Control<PilotsFiltersState>;
  departmentList: DepartmentType[];
  managerList: IUser[];
  setError: UseFormSetError<PilotsFiltersState>;
  clearErrors: UseFormClearErrors<PilotsFiltersState>;
};

export const PilotFilters: React.FC<Props> = ({
  control,
  departmentList,
  managerList,
  setError,
  clearErrors,
}) => {
  const dateFrom = useWatch({ control, name: "dateFrom" });
  const dateTo = useWatch({ control, name: "dateTo" });

  return (
    <>
      <Controller
        name="dateFrom"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormDatePicker
            label="Период от"
            format="DD.MM.YYYY"
            picker="date"
            error={error?.message}
            disabledDate={(date) => getDisabledDateFrom(date, dateTo)}
            value={value}
            onChange={(value) => {
              clearErrors("dateFrom");

              if (value === null) {
                setError("dateFrom", {
                  message: "Период должен быть заполнен",
                });
              }

              onChange(value);
            }}
          />
        )}
      />

      <Controller
        name="dateTo"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormDatePicker
            label="Период до"
            format="DD.MM.YYYY"
            picker="date"
            error={error?.message}
            disabledDate={(date) => getDisabledDateTo(dateFrom, date)}
            value={value}
            onChange={(value) => {
              clearErrors("dateTo");

              if (value === null) {
                setError("dateTo", { message: "Период должен быть заполнен" });
              }

              onChange(value);
            }}
          />
        )}
      />

      <Controller
        name="managerId"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="ФИО менеджера КЦ"
            placeholder="Выберите менеджера"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            options={managerList?.map(({ fullName, id }) => ({
              label: fullName,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="departamentId"
        control={control}
        rules={{ required: false }}
        render={({ field }) => (
          <FormSelect
            label={"Департамент"}
            placeholder="Выберите департамент"
            options={departmentList.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="projectName"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"Проект"}
            placeholder="Выберите проект"
            {...field}
          />
        )}
      />

      <Controller
        name="vacancyId"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"ID вакансии"}
            placeholder="Укажите ID вакансии"
            {...field}
          />
        )}
      />

      <Controller
        name="vacancyName"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"Название вакансии"}
            placeholder="Укажите название вакансии"
            {...field}
          />
        )}
      />

      <Controller
        name="candidateName"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"ФИО кандидата"}
            placeholder="Укажите ФИО кандидата"
            {...field}
          />
        )}
      />
    </>
  );
};

export const STATUS_CODES = {
  400: "400", // Bad Request
  401: "401", // Token has expired
  403: "403", // Permission error
  404: "404", // NotFound error
  409: "409", // Conflict error
  500: "500", // Server error
};

export const STATUS_CODES_MESSAGE = {
  [STATUS_CODES["400"]]: "Запрос к серверу содержит синтаксическую ошибку",
  [STATUS_CODES["401"]]: "Время жизни токена истекло",
  [STATUS_CODES["403"]]: "Доступ к указанному ресурсу ограничен",
  [STATUS_CODES["404"]]: "Информации по заданному запросу нет",
  [STATUS_CODES["409"]]: "Конфликт запроса с текущим состоянием сервера.",
  [STATUS_CODES["500"]]: "Ошибка обработки запроса, попробуйте позже",
};

export const AVAILABLE_STATUS_CODES = [
  STATUS_CODES["400"],
  STATUS_CODES["403"],
  STATUS_CODES["404"],
  STATUS_CODES["409"],
  STATUS_CODES["500"],
];

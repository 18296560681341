import {
  IDirectoryTreeItem,
  IAreasTreeItem,
} from "../../services/store/modules/directory/types";

export function parseTree(
  list: IDirectoryTreeItem[] = [],
  parent_id: string | number = 0
) {
  const children = list.filter(
    (item) =>
      item.parentId && item?.parentId.toString() === parent_id.toString()
  );
  if (children.length) {
    children.forEach((item) => {
      const sub_items = parseTree(list, item.id);
      if (sub_items.length) {
        item.children = sub_items;
      }
    });
  }
  return children;
}

export function parseTreeRole(
  list: IDirectoryTreeItem[] = [],
  parent_id: string | number = 0,
  targetLevel: number = 1
) {
  const result: IDirectoryTreeItem[] = [];

  function findChildren(
    items: IDirectoryTreeItem[],
    parentId: string | number,
    level: number
  ) {
    const children = items.filter(
      (item) =>
        item.parentId &&
        item.parentId.toString() === parentId.toString() &&
        item.level === level
    );

    for (const child of children) {
      const subItems = findChildren(items, child.id, level + 1);
      if (subItems.length > 0) {
        child.children = subItems;
      }
    }

    return children;
  }

  // Начать поиск с корневых элементов (уровень 1)
  const rootItems = findChildren(list, parent_id, 1);

  // Рекурсивно добавить элементы на целевом уровне к результату
  function addItemsAtTargetLevel(
    items: IDirectoryTreeItem[],
    currentLevel: number
  ) {
    if (currentLevel === targetLevel) {
      result.push(...items);
    } else {
      for (const item of items) {
        if (item.children) {
          //@ts-ignore
          addItemsAtTargetLevel(item.children, currentLevel + 1);
        }
      }
    }
  }

  addItemsAtTargetLevel(rootItems, 1);
  return result;
}
export function removeUnderscoreAndAfter(string): string[] {
  return [string.split("_")[0]];
}

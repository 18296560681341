import React, { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { MenuProps } from "antd";
import cn from "classnames/dedupe";

import { useAppSelector } from "services/store/store";
import { Title } from "components/UI/Title";
import { Table, Props as TableProps } from "components/UI/Table";
import {
  PageInfoType,
  PositionCategoryType,
  ReplyStatus,
  ReplyType,
} from "graphql/types/types";
import { categories } from "services/store/modules/directory";
import { Pagination, PaginationPayload } from "components/UI/Pagination";
import { ROUTE } from "routes";
import { Ellipsis } from "components/UI/icons";
import { REPLY_STATUS_TYPE } from "common/const/status";
import { COLOR_BY_REPLY_STATUS } from "common/const/colors";
import { Dropdown } from "components/UI/Dropdown";
import { getIsLink } from "common/utils/routes";
import { Spinner } from "components/UI/Spinner";
import { SOURCE_TYPE } from "common/const/source";
import { getTableRowDisabled } from "common/helpers/table";
import useDraggableScroll from "common/hooks/useDraggableScroll";
import { authInfo } from "services/store/modules/auth";
import { selectDisabledRepliesList } from "services/store/modules/repliesList/selectors";

import "./styles.scss";

type Props = {
  className?: string;
  replies: ReplyType[];
  categoryById: PositionCategoryType;
  activeCategory: number | null;
  meta: PageInfoType | null;
  loading: boolean;
  onChangePagination: (
    categoryId: number,
    pagination: PaginationPayload
  ) => void;
};

export const RepliesTable: React.FC<Props> = ({
  replies,
  categoryById,
  activeCategory,
  meta,
  loading,
  onChangePagination,
}) => {
  const history = useHistory();
  const tableRef = useRef<HTMLDivElement>(null);
  const { onMouseDown, isDragging } = useDraggableScroll(tableRef);

  const scheduleList = useAppSelector(categories);
  const disabledRepliesList = useAppSelector(selectDisabledRepliesList);
  const userData = useAppSelector(authInfo);

  const getLinkItems = (link: string): MenuProps["items"] => [
    {
      label: <Link to={link}>Просмотр</Link>,
      key: link,
    },
  ];

  const columns: TableProps<ReplyType>["columns"] = [
    {
      title: "Торговая точка",
      dataIndex: "vacancy",
      key: "vacancy",
      render: (vacancy) => {
        const { project = "" } = vacancy?.callCenterInfo || {};
        return (
          <div
            className={cn("nowrap", "replies-table-column-info")}
            title={project}
          >
            {project}
          </div>
        );
      },
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      render: (address) => (
        <div
          className={cn("nowrap", "replies-table-column-address")}
          title={address?.value}
        >
          {address?.value ?? ""}
        </div>
      ),
    },
    {
      title: "Фамилия",
      dataIndex: "secondName",
      key: "secondName",
      render: (secondName) => (
        <div className="replies-table-column-name" title={secondName}>
          {secondName}
        </div>
      ),
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName) => (
        <div className="replies-table-column-name" title={firstName}>
          {firstName}
        </div>
      ),
    },
    {
      title: "Отчество",
      dataIndex: "patronymic",
      key: "patronymic",
      render: (patronymic) => (
        <div className="replies-table-column-name" title={patronymic}>
          {patronymic}
        </div>
      ),
    },
    {
      title: "Вакансия",
      dataIndex: "vacancy",
      key: "vacancy",
      width: 180,
      render: (vacancy) => (
        <div className="nowrap" title={vacancy.name}>
          {vacancy.name}
        </div>
      ),
    },
    {
      title: "График работы",
      dataIndex: "vacancy",
      key: "vacancy",
      width: 180,
      render: (vacancy) =>
        vacancy.schedule
          ? scheduleList.find(({ key }) => key === vacancy.schedule)?.name ?? ""
          : null,
    },
    {
      title: "Приоритет",
      dataIndex: "vacancy",
      key: "vacancy",
      render: (vacancy) => vacancy.priority,
    },
    {
      title: "Источник",
      dataIndex: "source",
      key: "source",
      render: (source) => SOURCE_TYPE[source],
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: ReplyStatus) => (
        <div
          className="nowrap"
          title={REPLY_STATUS_TYPE[status]}
          style={{ color: COLOR_BY_REPLY_STATUS[status] }}
        >
          {REPLY_STATUS_TYPE[status]}
        </div>
      ),
    },
    {
      title: "Резюме",
      dataIndex: "candidate",
      key: "candidate",
      width: 75,
      render: (candidate: ReplyType["candidate"]) => {
        if (!candidate?.resumeUrl) {
          return null;
        }

        return getIsLink(candidate.resumeUrl) ? (
          <Dropdown
            trigger={["click"]}
            items={getLinkItems(candidate.resumeUrl)}
          >
            <span
              className="replies-table-link-dropdown-trigger"
              onClick={(e) => e.stopPropagation()}
            >
              <Ellipsis />
            </span>
          </Dropdown>
        ) : null;
      },
    },
  ];

  return (
    <div className="replies-table">
      <Title type="h3">{`Отклики для работы «${categoryById.name}»`}</Title>

      <div
        className="replies-table-container"
        ref={tableRef}
        onMouseDown={onMouseDown}
      >
        {loading ? (
          <Spinner className="replies-table-spinner" loading={loading} />
        ) : (
          <Table
            tableLayout="auto"
            dataSource={replies}
            columns={columns}
            onRow={(reply) => ({
              onClick: () => {
                if (!isDragging) {
                  history.push(`${ROUTE.REPLY}/${reply.id}`);
                }
              },
              className: cn({
                "replies-table-row--disabled": getTableRowDisabled({
                  id: reply.id,
                  disabledList: disabledRepliesList,
                  ownerId: userData?.id,
                }),
              }),
            })}
          />
        )}
      </div>
      <Pagination
        className="candidates-container-pagination"
        total={meta?.total}
        limit={meta?.limit}
        offset={meta?.offset}
        onChange={(pagination) =>
          onChangePagination(Number(activeCategory), pagination)
        }
      />
    </div>
  );
};

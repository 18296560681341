import {
  CandidateStatus,
  ReplyStatus,
  VacancyStatus,
} from "graphql/types/types";

export const COLORS = {
  GREEN: "#1eb22d",
  YELLOW: "#fec500",
  BLUE: "#43bfe8",
  RED: "#e62249",
} as const;

export type Colors = (typeof COLORS)[keyof typeof COLORS];

export const COLOR_BY_CANDIDATE_STATUS: Record<CandidateStatus, Colors> = {
  [CandidateStatus.WithHigherPosition]: COLORS.GREEN,
  [CandidateStatus.SelfSearch]: COLORS.GREEN,

  [CandidateStatus.Callback]: COLORS.YELLOW,
  [CandidateStatus.DoesntGetTouch]: COLORS.YELLOW,

  [CandidateStatus.New]: COLORS.BLUE,
  [CandidateStatus.Candidate]: COLORS.BLUE,

  [CandidateStatus.Denied]: COLORS.RED,
  [CandidateStatus.RefusalCandidate]: COLORS.RED,
  [CandidateStatus.NotLookingForJob]: COLORS.RED,
  [CandidateStatus.NotThrough]: COLORS.RED,
};

export const COLOR_BY_REPLY_STATUS: Record<ReplyStatus, Colors> = {
  [ReplyStatus.Interview]: COLORS.GREEN,
  [ReplyStatus.Decorated]: COLORS.GREEN,
  [ReplyStatus.TransferredToHrFromCc]: COLORS.GREEN,

  [ReplyStatus.Callback]: COLORS.YELLOW,
  [ReplyStatus.Expired]: COLORS.YELLOW,
  [ReplyStatus.DoesntGetTouch]: COLORS.YELLOW,

  [ReplyStatus.New]: COLORS.BLUE,
  [ReplyStatus.Candidate]: COLORS.BLUE,
  [ReplyStatus.Other]: COLORS.BLUE,
  [ReplyStatus.Reserve]: COLORS.BLUE,

  [ReplyStatus.Denied]: COLORS.RED,
  [ReplyStatus.RefusalCandidate]: COLORS.RED,
  [ReplyStatus.NotProcessed]: COLORS.RED,
  [ReplyStatus.NotThrough]: COLORS.RED,
};

export const COLOR_BY_VACANCY_STATUS: Record<VacancyStatus, Colors> = {
  [VacancyStatus.Published]: COLORS.GREEN,
  [VacancyStatus.Moderation]: COLORS.YELLOW,
  [VacancyStatus.Draft]: COLORS.YELLOW,
  [VacancyStatus.Archived]: COLORS.YELLOW,
  [VacancyStatus.InWork]: COLORS.BLUE,
  [VacancyStatus.Refused]: COLORS.RED,
};

import React from "react";
import { IconType } from "common/commonTypes/types";

export const Edit: React.FC<IconType> = ({
  width = 24,
  height = 24,
  color = "#0057AC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fi:edit" clipPath="url(#clip0_111_5215)">
        <path
          id="Vector"
          d="M9.16669 3.33331H3.33335C2.89133 3.33331 2.4674 3.50891 2.15484 3.82147C1.84228 4.13403 1.66669 4.55795 1.66669 4.99998V16.6666C1.66669 17.1087 1.84228 17.5326 2.15484 17.8452C2.4674 18.1577 2.89133 18.3333 3.33335 18.3333H15C15.442 18.3333 15.866 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6666V10.8333"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M15.4167 2.08332C15.7482 1.7518 16.1978 1.56555 16.6667 1.56555C17.1355 1.56555 17.5852 1.7518 17.9167 2.08332C18.2482 2.41484 18.4345 2.86448 18.4345 3.33332C18.4345 3.80216 18.2482 4.2518 17.9167 4.58332L10 12.5L6.66669 13.3333L7.50002 9.99999L15.4167 2.08332Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_111_5215">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

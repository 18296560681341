import React from "react";
import { Row, Col, RowProps } from "antd";
import { Button } from "components/UI/Button";
import classNames from "classnames";
import "./styles.scss";

interface SaveCancelButtonsProps extends RowProps {
  onSave: () => void;
  onCancel: () => void;
  saveText?: string;
  cancelText?: string;
  saveButtonProps?: React.ComponentProps<typeof Button>;
  cancelButtonProps?: React.ComponentProps<typeof Button>;
}

export const FormSaveBar: React.FC<SaveCancelButtonsProps> = ({
  onSave,
  onCancel,
  saveText = "Сохранить изменения",
  cancelText = "Отменить",
  saveButtonProps,
  cancelButtonProps,
  className,
}) => {
  const formSaveBarClassName = classNames("ui-form-save", className);
  return (
    <Row justify="end" gutter={16} className={formSaveBarClassName}>
      <Col>
        <Button
          variant={"primary"}
          {...cancelButtonProps}
          onClick={onCancel}
          style={{ width: "208px" }}
        >
          {cancelText}
        </Button>
      </Col>
      <Col>
        <Button
          {...saveButtonProps}
          onClick={onSave}
          style={{ width: "208px" }}
        >
          {saveText}
        </Button>
      </Col>
    </Row>
  );
};

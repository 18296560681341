import React from "react";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { DashboardHeader } from "../components";
import "./layout.css";
import LogoImage from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

export const SystemLayout: React.FC = (props) => {
  return (
    <Layout className="layout layout--system">
      <DashboardHeader layout="system" className="layout__header">
        <Link to={"/"}>
          <img src={LogoImage} alt="logo" className="layout__logo" />
        </Link>
      </DashboardHeader>
      <Content className="layout__content">{props.children}</Content>
    </Layout>
  );
};

export default SystemLayout;

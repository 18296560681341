import moment, { Moment } from "moment";
import "moment/locale/ru";

type Format =
  | "DD.MM.YYYY"
  | "YY-MM-DD"
  | "DD.MM.YY"
  | "YYYY-MM-DD"
  | "DD.MM.YY HH:mm"
  | "D MMMM YYYY"
  | "HH:mm";

type DateFormat = string | Date | Moment;

moment.locale("ru");

export const getDateFormat = (date: DateFormat, format: Format) =>
  moment(date).format(format);

export const getUtcDateFormat = (date: DateFormat, format: Format) =>
  moment.utc(date).format(format);

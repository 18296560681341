import { gql } from "@apollo/client";

export const SAVE_OKTELL_CREDENTIALS = gql`
  mutation saveOktellCredentials($id: Int!, $form: OktellCredentialsForm!) {
    saveOktellCredentials(id: $id, form: $form) {
      ... on UserType {
        id
        email
        city
        companyId
        deletedAt
        createdAt
        updatedAt
        secondName
        phone
        role
        id
        firstName
        email
        departmentId
        company {
          name
          id
          owner {
            id
          }
          phone
          email
        }
        tz
        department {
          id
          name
          owner {
            id
          }
        }
        oktellLogin
      }
      ... on Error {
        statusCode
        message
      }
    }
  }
`;

import { useDispatch } from "react-redux";
import { getDictionaries } from "../services/store/modules/directory";
import React from "react";

export const DictionaryLoader: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getDictionaries());
  });

  return null;
};

export default DictionaryLoader;

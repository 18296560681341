import { gql } from "@apollo/client";

export const CREATE_REFUSAL = gql`
  mutation createRefusal($form: RefusalInputType!) {
    createRefusal(form: $form) {
      ... on RefusalReasonType {
        id
        key
        name
        type
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

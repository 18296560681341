import { gql } from "@apollo/client";

export const CREATE_DEPARTMENT = gql`
  mutation createDepartment($form: DepartmentForm!) {
    createDepartment(form: $form) {
      ... on DepartmentType {
        id
        name
        companyId
        ownerId
        createdAt
        updatedAt
        deletedAt
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

import React, { useMemo, useState } from "react";
import RowLayout from "layouts/RowLayout";
import { VacancyInfo } from "components/VacancyInfo";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { replyStatuses } from "services/store/modules/directory";
import { setReplyInWork } from "services/store/modules/repliesList";
import {
  selectDisabledRepliesList,
  selectReplyLoading,
} from "services/store/modules/repliesList/selectors";
import toaster from "components/UI/Notifications/Notification";
import { ActionPanel } from "components/ActionPanel";
import { getTableRowDisabled } from "common/helpers/table";
import {
  ReplyType,
  VacancyType,
  UserRole,
  ReplyStatus,
} from "graphql/types/types";
import { PanelProps } from "components/UI/Collapse";
import { authInfo } from "services/store/modules/auth";
import { changeReplyStatus } from "services/store/modules/repliesList/actions";
import { IStatus } from "services/store/modules/directory/types";
import { EDIT_REPLY_STATUS_MODERATOR } from "common/const/status";
import { ReplyDetails } from "../ReplyDetails";
import { useChangeVisible } from "common/hooks/visible/useChangeVisible";

interface Props {
  vacancy: VacancyType;
  reply: ReplyType;
  className?: string;
}

const getFilteredStatusList = (
  statuses: IStatus[],
  role: UserRole
): IStatus[] => {
  return statuses
    .filter(({ key }) =>
      EDIT_REPLY_STATUS_MODERATOR.includes(String(key) as ReplyStatus)
    )
    .filter(({ key }) => {
      if (role === UserRole.EmployeeCc) {
        return key !== ReplyStatus.Candidate;
      }

      if (role === UserRole.Manager) {
        return key !== ReplyStatus.TransferredToHrFromCc;
      }

      return true;
    });
};

export const ReplyInfo: React.FC<Props> = ({ vacancy, reply, className }) => {
  const dispatch = useAppDispatch();
  const { onChangeReplyVisible } = useChangeVisible({ id: reply.id });

  const replyStatusDictionary = useAppSelector(replyStatuses);
  const authData = useAppSelector(authInfo);
  const disabledRepliesList = useAppSelector(selectDisabledRepliesList);
  const replyLoading = useAppSelector(selectReplyLoading);

  const [currentStatus, setCurrentStatus] = useState(reply.status);

  const statusList: IStatus[] = useMemo(() => {
    if (!replyStatusDictionary?.length) {
      return [];
    }
    return authData?.role
      ? getFilteredStatusList(replyStatusDictionary, authData.role)
      : [];
  }, [replyStatusDictionary, authData?.role]);

  const handleStatusChange = async (status: ReplyStatus) => {
    setCurrentStatus(status);

    try {
      await dispatch(
        changeReplyStatus({
          id: reply.id,
          status,
        })
      );
      toaster.success({ title: "Статус успешно изменен" }, { autoClose: 3000 });
    } catch (error) {
      toaster.error({ title: `Ошибка: ${error}` }, { autoClose: 3000 });
    }
  };

  const handleReplyVisibleChange = async () => {
    await onChangeReplyVisible({ inWork: true });

    toaster.success({ title: "Отклик в работе" });
    dispatch(setReplyInWork({ inWork: true }));
  };

  const panels: PanelProps[] = [
    {
      key: "1",
      panelTitle: "Справочная информация",
      contentComponent: <ReplyDetails reply={reply} />,
    },
  ];

  return (
    <RowLayout
      className={className}
      leftSpan={19}
      leftComponent={
        authData && (
          <VacancyInfo
            isHidden={true}
            vacancy={vacancy}
            mapLoading={replyLoading}
            userData={authData}
          />
        )
      }
      centerSpan={5}
      centerComponent={
        <ActionPanel
          statuses={statusList}
          currentStatus={currentStatus}
          isDisabled={
            getTableRowDisabled({
              id: reply.id,
              disabledList: disabledRepliesList,
              ownerId: authData?.id,
            }) || Boolean(reply.inWork)
          }
          onStatusChange={handleStatusChange}
          onVisibilityChange={handleReplyVisibleChange}
          panels={panels}
        />
      }
    />
  );
};

import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input/TextArea";
import React from "react";
import styled from "styled-components";

const { TextArea } = Input;

export interface IBaseTextAreaProps extends TextAreaProps {
  label?: string;
  maxLength?: number;
  showCount?: boolean;
  error?: string;
}

const TextAreaWrapper = styled.div`
  position: relative;
  padding-bottom: 1.15rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;
`;

const TextAreaLabel = styled.span`
  display: inline-block;
  font-size: 0.8rem;
`;

const Required = styled.sup`
  color: red;
`;

const InputError = styled.span`
  position: absolute;
  bottom: 0;
  display: inline-block;
  font-size: 0.8rem;
  color: red;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BaseTextArea: React.FC<IBaseTextAreaProps> = (
  props: IBaseTextAreaProps
) => (
  <TextAreaWrapper>
    <TextAreaLabel>
      {props.label}
      {props.required && <Required>*</Required>}
    </TextAreaLabel>
    <TextArea
      value={props.value}
      onChange={props.onChange}
      onInput={props.onInput}
      maxLength={props.maxLength}
      showCount={props.showCount}
      autoSize={props.autoSize}
      onPressEnter={props.onPressEnter}
    />
    {props.error && <InputError>{props.error}</InputError>}
  </TextAreaWrapper>
);

import React, { useRef } from "react";
import { columnsCallCenter, columnsPilot } from "./helpers";
import { ColumnsType } from "antd/lib/table";

import { Pagination, PaginationPayload } from "components/UI/Pagination";
import { PageInfoType } from "graphql/types/types";
import { Table } from "components/UI/Table";
import { PilotReport, Report } from "common/commonTypes/reports";
import useDraggableScroll from "common/hooks/useDraggableScroll";
import { ReportName } from "common/const/reports";
import { FiltersState } from "../../types";

import "./styles.scss";

type Props = {
  reportList: Report[] | PilotReport[];
  meta: PageInfoType | null;
  formData: FiltersState;
  reportName: ReportName;
  onChangePagination: (
    filter: FiltersState,
    pagination?: PaginationPayload
  ) => void;
};

const columnsByReportName: Record<ReportName, ColumnsType<Report> | undefined> =
  {
    [ReportName.ReportCallCenter]: columnsCallCenter,
    [ReportName.ReportPilot]: columnsPilot,
    [ReportName.ReportMango]: [], // TODO в будущем будет таблица с данными манго
  };

export const ReportTable: React.FC<Props> = ({
  reportList,
  meta,
  formData,
  reportName,
  onChangePagination,
}) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const { onMouseDown } = useDraggableScroll(tableRef);

  return (
    <div className="report-table">
      <div
        className="report-table-container"
        ref={tableRef}
        onMouseDown={onMouseDown}
      >
        <Table
          tableLayout="auto"
          dataSource={reportList}
          columns={columnsByReportName[reportName]}
        />
      </div>
      <Pagination
        className="candidates-container-pagination"
        total={meta?.total}
        limit={meta?.limit}
        offset={meta?.offset}
        onChange={(pagination) => onChangePagination(formData, pagination)}
      />
    </div>
  );
};

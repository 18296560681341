import { gql } from "@apollo/client";

export const CREATE_MANGO_USER = gql`
  mutation createMangoUser($userId: Int!) {
    createMangoUser(userId: $userId) {
      ... on UserType {
        id
        email
        city
        company {
          blocked
          createdAt
          deletedAt
          description
          email
          id
          logo
          name
          owner {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
          ownerId
          phone
          shortDescription
          stats {
            replyCount
            userCount
            vacancyCount
          }
          updatedAt
        }
        companyId
        createdAt
        deletedAt
        department {
          companyId
          createdAt
          deletedAt
          group {
            createdAt
            id
            name
            updatedAt
          }
          groupId
          id
          name
          owner {
            city
            departmentId
            companyId
            email
            firstName
            id
            phone
            role
            secondName
          }
          ownerId
          updatedAt
        }
        departmentId
        firstName
        idpId
        isDeleted
        mangoId
        oktellId
        oktellLogin
        oktellPassword
        phone
        role
        s2Id
        secondName
        tz
        updatedAt
      }
      ... on Error {
        errors
        message
        statusCode
      }
    }
  }
`;

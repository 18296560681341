import { gql } from "@apollo/client";

export const DECLINE_REPLY = gql`
  mutation declineReply(
    $id: Int!
    $status: ReplyStatus!
    $reasonKey: String!
    $refusalText: String!
  ) {
    declineReply(
      id: $id
      status: $status
      reasonKey: $reasonKey
      refusalText: $refusalText
    ) {
      ... on ReplyType {
        expiredTime
        citizenshipId
        managerId
        claimId
        source
        s2Id
        notCallCount
        employmentId
        createdAt
        updatedAt
        deletedAt
        id
        refusalId
        updatedBy
        expiredCallTime
        contacts {
          fullName
          email
          phone
        }
        vacancyId
        positionId
        ownerId
        companyId
        departmentId
        status
        address {
          value
          city
        }
        userContent
        userComment
        managerComment
        utm {
          source
          campaign
          content
          term
          medium
        }
        expiredTime
        citizenshipId
      }
      ... on Error {
        statusCode
        errors
        message
      }
    }
  }
`;

import axios from "axios";
import Cookies from "js-cookie";

export type SendReportPayload = {
  emails: string[];
  reportUrl: string;
  reportText: string;
};

export type SendReportResponse = Record<string, "email has been sent">;

export const sendReport = <T>({
  reportUrl,
  reportText,
  emails,
}: SendReportPayload) => {
  return axios.post<T>(
    `${process.env.REACT_APP_API_URL}/service/mailer/mails/reportUsers/send?report_url=${reportUrl}&report_text=${reportText}`,
    emails,
    {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    }
  );
};

import { ClaimCategory } from "graphql/types/types";

export const CLAIMS_CATEGORY_TYPE: Record<ClaimCategory, string> = {
  [ClaimCategory.Education]: "Обучение",
  [ClaimCategory.Reserve]: "Резерв",
  [ClaimCategory.WorkWithUs]: "Работа у нас",
  [ClaimCategory.Other]: "Другое",
  [ClaimCategory.SearchWork]: "Поиск работы",
  [ClaimCategory.Partnership]: "Партнерство",
};

import React from "react";
import { Form, DatePickerProps } from "antd";
import DatePicker from "../../DatePicker";
import classNames from "classnames";
import "./styles.scss";

type FormDatePickerProps = DatePickerProps & {
  label: string;
  error?: string;
};

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  className,
  label,
  error,
  ...props
}) => {
  const formDatePickeClassName = classNames("ui-form-date-picker", className);

  return (
    <Form.Item label={label} className={formDatePickeClassName}>
      <DatePicker {...props} />
      {error && <div className="ui-form-date-picker-error">{error}</div>}
    </Form.Item>
  );
};

export default FormDatePicker;

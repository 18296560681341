import { xml2js } from "xml-js";

interface IOktellStructure {
  oktellxmlmapper?: {
    data?: {
      property_set?: {
        property_simple?: IOktellStructure[];
        property_cdata?: IOktellStructure[];
      };
    };
  };
}

interface IOktellStructure {
  _attributes?: {
    key?: string;
    value?: string;
    name?: string;
  };
  _cdata?: string;
}

export interface IOktellCallData {
  [key: string]: IOktellField;
}

interface IOktellField {
  key?: string;
  value?: string | Date;
  name?: string;
}

export const oktellResponseParser = (xml: string) => {
  try {
    const initValue = xml2js(xml, {
      compact: true,
      ignoreDeclaration: true,
      ignoreAttributes: false,
      ignoreDoctype: true,
      instructionHasAttributes: true,
    }) as IOktellStructure;
    const propertySimple =
      initValue?.oktellxmlmapper?.data?.property_set?.property_simple || [];
    const propertyCdata =
      initValue?.oktellxmlmapper?.data?.property_set?.property_cdata || [];
    const arrCdata = Array.isArray(propertyCdata)
      ? propertyCdata
      : [propertyCdata];
    const arrPropertySimple = Array.isArray(propertySimple)
      ? propertySimple
      : [propertySimple];
    const attributes = [...arrPropertySimple, ...arrCdata]; //array
    const data: IOktellCallData = {};
    attributes.map((item) => {
      const key = item?._attributes?.key;
      const value = item?._cdata || item?._attributes?.value;
      const name = item?._attributes?.name;
      const newItem: IOktellField = {
        value,
        key,
      };
      if (name) {
        newItem.name = name;
      }
      if (key) {
        data[key] = newItem;
      }
      return null;
    });
    return data;
  } catch {
    console.error("Type error");
  }
};

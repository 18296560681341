import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialState } from "services/store/helpers";
import { CandidateType, ClaimType } from "graphql/types/types";
import { getClaim, getClaimsList } from "./actions";

export type ClaimsListByIdState = ClaimType[];

const claimsListSlice = createSlice({
  name: "claimsList",
  initialState: getInitialState<ClaimsListByIdState>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClaimsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClaimsList.fulfilled, (state, { payload }) => {
        state.data = payload?.claimList.data ?? [];
        state.meta = payload?.claimList.meta ?? null;
        state.loading = false;
      })
      .addCase(getClaimsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});
const claimSlice = createSlice({
  name: "claim",
  initialState: getInitialState<ClaimType>(),
  reducers: {
    updateCandidateInClaim(state, { payload }: PayloadAction<CandidateType>) {
      if (!state.data) return;
      state.data.candidate = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClaim.fulfilled, (state, { payload }) => {
        state.data = payload.claim ?? [];
        state.loading = false;
      })
      .addCase(getClaim.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});
export const { updateCandidateInClaim } = claimSlice.actions;

export const claimsListReducer = combineReducers({
  claimsList: claimsListSlice.reducer,
  claim: claimSlice.reducer,
});

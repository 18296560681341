import React from "react";

export const Copy: React.FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666748 11.3333V7.33325C0.666748 6.22868 1.56218 5.33325 2.66675 5.33325H3.66675C4.58722 5.33325 5.33342 6.07944 5.33342 6.99992C5.33342 7.92039 6.07961 8.66658 7.00008 8.66658C7.92056 8.66658 8.66675 9.41278 8.66675 10.3333V11.3333C8.66675 12.4378 7.77132 13.3333 6.66675 13.3333H2.66675C1.56218 13.3333 0.666748 12.4378 0.666748 11.3333Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3335 6.66663V2.66663C5.3335 1.56206 6.22893 0.666626 7.3335 0.666626H11.3335C12.4381 0.666626 13.3335 1.56206 13.3335 2.66663V6.66663C13.3335 7.7712 12.4381 8.66663 11.3335 8.66663H7.3335C6.22893 8.66663 5.3335 7.7712 5.3335 6.66663Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

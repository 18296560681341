import { omit } from "lodash";
import axios from "axios";
import Cookies from "js-cookie";

import { Report } from "common/commonTypes/reports";
import { ReportName } from "common/const/reports";

export type FetchReportPayload = {
  dateFrom: string;
  dateTo: string;
  limit?: string;
  offset?: string;
  departamentId?: string;
  managerId?: string;
  sendExcel?: string;
  projectName?: string;
  reportName: ReportName;
};

export type FetchReportResponse = {
  url: string;
  total: number;
  limit: number;
  offset: number;
  data: Report[];
};

export const fetchReport = <T>(payload: FetchReportPayload) => {
  const params = { ...omit(payload, ["reportName"]) };
  const query = new URLSearchParams(params);

  return axios.get<T>(
    `${process.env.REACT_APP_API_URL}/reports/get_${payload.reportName}?${query}`,
    {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    }
  );
};

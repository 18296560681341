import React from "react";
import { Radio as AntRadio, RadioGroupProps } from "antd";
import classNames from "classnames";
import "./styles.scss";

interface RadioProps extends RadioGroupProps {}

const Radio: React.FC<RadioProps> = ({
  onChange,
  className,
  disabled,
  style,
  ...props
}) => {
  const radioClassNames = classNames(
    "ui-radio",
    {
      "ui-radio--disabled": disabled,
    },
    className
  );
  return (
    <AntRadio.Group
      className={radioClassNames}
      style={style}
      disabled={disabled}
      onChange={onChange}
      {...props}
    />
  );
};

export default Radio;

import { gql } from "@apollo/client";

export const REPLY_LIST_BY_CATEGORY = gql`
  query replyListByCategory($categoryId: Int!, $pagination: PaginationType) {
    replyListByCategory(categoryId: $categoryId, pagination: $pagination) {
      ... on ReplyListSuccess {
        data {
          candidate {
            acceptedAt
            actualAddress {
              block
              blockType
              city
              cityType
              house
              houseType
              lat
              lng
              region
              regionType
              settlement
              street
              streetType
              value
            }
            age
            badge
            calcDismissDate
            callCompleted
            cardDeliveryAddress {
              block
              blockType
              city
              cityType
              house
              houseType
              lat
              lng
              region
              regionType
              settlement
              street
              streetType
              value
            }
            citizenshipId
            city
            comments {
              comment
              createdAt
              manager {
                city
                companyId
                departmentId
                email
                firstName
                id
                phone
                role
                secondName
              }
            }
            companyId
            contacts {
              contact
              id
              isMain
            }
            createdAt
            dateOfBirth
            deletedAt
            departmentId
            dismissDate
            dismissReason
            driverLicense
            email
            experienceFull
            experienceInCompany
            firstName
            fullName
            hasChat
            hasHistory
            haveCar
            id
            inBlacklist
            isSpam
            lastShiftRate
            location {
              lat
              lng
            }
            managerComment
            managerId
            metro {
              distance
              line
              name
            }
            ownerId
            patronymic
            positionId
            positions {
              key
            }
            positionsNew {
              createdAt
              id
              position {
                categoryId
                id
                key
                name
                restriction
              }
            }
            receptionLastDate
            region
            resumeUrl
            russianCitizenship
            salaryExpectation
            secondName
            sex
            shiftMaxDate
            shiftMinDate
            source
            status
            timezone
            transitDuration
            updatedAt
            updatedBy
            updater {
              city
              companyId
              departmentId
              email
              firstName
              id
              phone
              role
              secondName
            }
            vacancyCount
            walkingDuration
          }
          address {
            block
            blockType
            city
            cityType
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            street
            streetType
            value
          }
          utm {
            campaign
            content
            medium
            source
            term
          }
          vacancy {
            additionalAvito {
              businessArea
              experience
              name
            }
            additionalHh {
              acceptHandicapped
              acceptKids
              acceptTemporary
              area
              driverLicense
              experience
              languages
              name
              professionalRoles
            }
            address {
              block
              blockType
              city
              cityType
              house
              houseType
              lat
              lng
              region
              regionType
              settlement
              street
              streetType
              value
            }
            age {
              from
              to
            }
            callCenterInfo {
              addresses
              description
              driverLicense
              experience
              farePayment
              goodsCategory
              mobilePayment
              personalCar
              presenceOfPda
              project
              vaccination
            }
            candidateCount
            citizenship {
              id
              key
              name
            }
            city
            claims
            closedAt
            contacts {
              email
              fullName
              phone
            }
            counterparty
            createdAt
            deletedAt
            description
            employer {
              id
              key
              logo
              name
            }
            employment {
              id
              key
              name
            }
            extraAvito {
              publicationFinishTime
              remoteId
              remoteUrl
            }
            extraHh {
              publicationFinishTime
              remoteId
              remoteUrl
            }
            id
            inWork
            kladrId
            location {
              lat
              lng
            }
            manager {
              city
              companyId
              departmentId
              email
              firstName
              id
              phone
              role
              secondName
            }
            medicalBook
            metro {
              distance
              line
              name
            }
            name
            peopleCount
            peopleCountClose
            peopleCountOpen
            phonePresence
            platformTags
            position {
              categoryId
              countPeople
              id
              key
              name
              restriction
            }
            positionId
            priority
            publishedAt
            publishedOnAvito
            publishedOnHh
            publishedOnRabotut
            reason
            refusal {
              id
              key
              name
              type
            }
            refusalText
            region
            relevance
            replyCount
            replyCountNew
            responsiblePerson
            route
            salary {
              currency
              from
              gross
              to
            }
            schedule
            shiftEnd
            shiftStart
            shortDescription
            showContacts
            status
            subdivision
            tag {
              color
              colorBg
              key
              name
            }
            terms
            tradePoint
            updatedAt
          }
          actionPayClick
          age
          candidateId
          chatLink
          citizenshipId
          cityAdsClick
          claimId
          companyId
          contacts {
            email
            fullName
            phone
          }
          createdAt
          deletedAt
          departmentId
          employmentId
          expiredAt
          expiredAtAfterTransferFromCc
          expiredCallTime
          expiredTime
          expiredTimeAfterTransferFromCc
          firstName
          hasChat
          id
          inWork
          isCreatedAtBusinessHours
          isExpiredAtBusinessHours
          isMain
          isTransferFromCcAtBusinessHours
          manager {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
          managerComment
          managerId
          notCallCount
          owner {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
          ownerId
          patronymic
          positionId
          refusalId
          refusalText
          russianCitizenship
          s2Id
          secondName
          source
          status
          updatedAt
          updatedBy
          userComment
          userContent
          vacancyId
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        message
        statusCode
        errors
      }
    }
  }
`;

import React from "react";

import { Close, Check } from "components/UI/icons";
import { Loader } from "components/UI/Loader";

type Props = {
  status: Status;
  classNames?: string;
};

export type Status = "success" | "error" | "pending";

const iconByStatus: Record<Status, React.ReactElement> = {
  success: <Check width={16} height={16} />,
  error: <Close width={16} height={16} color="#E62249" />,
  pending: <Loader size="small" color="#0057AC" />,
};

export const IconStatus: React.FC<Props> = ({ status }) => iconByStatus[status];

import React, { useState } from "react";
import { Title } from "components/UI/Title";
import TextArea from "components/UI/TextArea";

import "./styles.scss";

export const SmsTextInput: React.FC = () => {
  const [message, setMessage] = useState<string>("");

  return (
    <div className="smsText-container">
      <Title type="h4" color="blue">
        Текст сообщения
      </Title>
      <TextArea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="smsText-container--input"
      />
    </div>
  );
};

import React from "react";
import { Popover } from "antd";
import "../../components/Reply/Reply.css";
import { HOT_CALL_SELECT_STATUS_TYPE } from "../../common/const/hotCallStatuses";

interface INotThrowPopupProps {
  onChange: (status: string) => void;
  isLoading?: boolean;
}

export const NotThrowPopup = ({ onChange, isLoading }: INotThrowPopupProps) => {
  //Data

  // Computed

  // Methors
  const onSelect = (status?: string) => {
    if (status && !isLoading) {
      onChange(status);
    } else {
      const message = isLoading
        ? "please wait, request is being processed"
        : "status is not define";
      console.error(message);
    }
  };

  return (
    <Popover
      content={
        <div className="not-throw-popup">
          {HOT_CALL_SELECT_STATUS_TYPE.map((item, index) => {
            return (
              <p
                key={item?.key || index}
                className="not-throw-reason"
                onClick={() => onSelect(item?.key)}
              >
                {item.label}
              </p>
            );
          })}
        </div>
      }
      title="Выберите причину"
      trigger="click"
    >
      <button className="status-button status--NOT_THROUGH">
        Не дозвонились
      </button>
    </Popover>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as apollo } from "graphql/apollo";
import { HOT_CALLS_PLAN_LIST } from "graphql/requests/query/hotCallsPlanList";
import { AsyncThunkConfig } from "../../types";
import {
  MutationUpdateHotCallPlanArgs,
  QueryHotCallsPlanListArgs,
} from "graphql/types/types";
import { UPDATE_HOT_CALL_PLAN } from "graphql/requests/mutation/updateHotCallPlan";
import { CallsPlanMangoResponse, UpdateCallPlanResponse } from "./types";

export const getCallsPlanList = createAsyncThunk<
  CallsPlanMangoResponse,
  QueryHotCallsPlanListArgs,
  AsyncThunkConfig
>("hotCallsPlan/getCallsPlanList", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.query<CallsPlanMangoResponse>({
      query: HOT_CALLS_PLAN_LIST,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const updateCallPlan = createAsyncThunk<
  UpdateCallPlanResponse,
  MutationUpdateHotCallPlanArgs,
  AsyncThunkConfig
>("hotCallsPlan/updateHotCallPlan", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate<UpdateCallPlanResponse>({
      mutation: UPDATE_HOT_CALL_PLAN,
      variables: payload,
    });
    return response.data ?? rejectWithValue("Нет данных в ответе от сервера");
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

import React from "react";
import { Maps, PlacemarkData } from "components/UI/Maps";
import { VacancySuitableType, VacancyType } from "graphql/types/types";

type Props = {
  recommendedVacancies: VacancySuitableType[];
  vacancyId?: VacancyType["id"];
};

export const RecommendMaps: React.FC<Props> = ({
  recommendedVacancies,
  vacancyId,
}) => {
  const placemarks: PlacemarkData[] = recommendedVacancies.map(
    ({ address, name, salary, id }) => ({
      id: String(id),
      lat: address.lat ?? 0,
      lng: address.lng ?? 0,
      name: name ?? "",
      title: salary.to ? String(salary.to) : "",
      isCurrentVacancy: vacancyId === id,
    })
  );

  return (
    <Maps
      zoom={9}
      width="calc(100% - 16px)"
      height={364}
      placemarks={placemarks}
    />
  );
};

export const getPathnameWithoutId = () => {
  return window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf("/")
  );
};

export const getIsSpecificPage = () => {
  const path = window.location.pathname;
  const regex =
    /^\/(vacancies|replies|claims|control|candidates|vacancy|vacancies-view|reports|replies-view|admin-setting)(\/\d+)?$/;

  return regex.test(path);
};

import React from "react";
import { IconType } from "../../../common/commonTypes/types";

type Props = React.SVGAttributes<SVGElement> & {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
};

export const Chevron: React.FC<IconType> = ({
  width = 16,
  height = 16,
  color = "#0057AC",
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M7.3335 4.66671L4.00016 8.00004L7.3335 11.3334"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4.66671L8.66667 8.00004L12 11.3334"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React, { useCallback, useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  getCurrentVacancyComments,
  vacancyCommentsMeta,
  currentVacancyComments,
  isLoadingVacancyActivity,
  clearVacancyComments,
} from "services/store/modules/vacancies";
import { Notification } from "components/UI/Notifications/Notification";
import { VacancyType } from "graphql/types/types";
import { Loader } from "components/UI/Loader";
import { CommentItem } from "components/CommentItem";
import { Button } from "components/UI/Button";

import "./styles.scss";

type Props = {
  vacancyId: VacancyType["id"];
  activeKey: string;
};

export const VacancyComments: React.FC<Props> = ({ vacancyId, activeKey }) => {
  const dispatch = useAppDispatch();

  const { page, size, total } = useAppSelector(vacancyCommentsMeta);
  const comments = useAppSelector(currentVacancyComments);
  const loading = useAppSelector(isLoadingVacancyActivity);

  const isShowPagination = useMemo(
    () => total > page * size,
    [page, size, total]
  );

  const getVacancyComments = useCallback(() => {
    dispatch(
      getCurrentVacancyComments({
        id: vacancyId,
        page: page || 0,
      })
    );
  }, [vacancyId, page, dispatch]);

  useEffect(() => {
    if (activeKey !== "comments") {
      dispatch(clearVacancyComments());
    } else {
      getVacancyComments();
    }
  }, [activeKey, dispatch, getVacancyComments]);

  useEffect(() => {
    return () => {
      dispatch(clearVacancyComments());
    };
  }, []);

  if (loading) {
    return <Loader color="blue" size="large" />;
  }

  return (
    <div className="comments">
      {comments.length ? (
        <>
          {comments.map((comment) => (
            <CommentItem
              className="comments-item"
              key={comment.id}
              comment={comment}
            />
          ))}
          {isShowPagination && (
            <Button
              className="comments-showMore"
              variant="link"
              onClick={getVacancyComments}
            >
              Показать еще
            </Button>
          )}
        </>
      ) : (
        <Notification type="warning" title="Пока нет ни одного комментария" />
      )}
    </div>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { ClaimsListResponse, ClaimsResponse } from "./types";
import {
  MutationChangeClaimStatusArgs,
  QueryClaimArgs,
  QueryClaimListArgs,
} from "graphql/types/types";
import { AsyncThunkConfig } from "services/store/types";
import { client as apollo } from "graphql/apollo";
import { CLAIM_LIST } from "graphql/requests/query/claimList";
import { CLAIM_ITEM } from "graphql/requests/query/claim";
import { CHANGE_CLAIM_STATUS } from "graphql/requests/mutation/changeClaimStatus";

export const getClaimsList = createAsyncThunk<
  ClaimsListResponse,
  QueryClaimListArgs,
  AsyncThunkConfig
>("claims/getClaimsList", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.query<ClaimsListResponse>({
      query: CLAIM_LIST,
      variables: payload,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const getClaim = createAsyncThunk<
  ClaimsResponse,
  QueryClaimArgs,
  AsyncThunkConfig
>("claims/getClaim", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.query<ClaimsResponse>({
      query: CLAIM_ITEM,
      variables: payload,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});
export const changeClaimStatus = createAsyncThunk<
  ClaimsResponse,
  MutationChangeClaimStatusArgs,
  AsyncThunkConfig
>("claims/changeClaimStatus", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: CHANGE_CLAIM_STATUS,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

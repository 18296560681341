import { Middleware } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import getCookie, { parseJwt, removeCookie } from "common/helpers/cookies";

export const tokenExpirationMiddleware: Middleware<{}, RootState> =
  () => (next) => (action) => {
    const token = getCookie("access_token");

    if (token) {
      const decodedToken = parseJwt(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const tokenExpiration = decodedToken.exp;
      if (currentTime >= tokenExpiration) {
        removeCookie("access_token");
        window.location.reload();
      }
    }

    return next(action);
  };

import { gql } from "@apollo/client";

export const CALLS = gql`
  subscription calls {
    calls {
      id
      createdAt
      updatedAt
      deletedAt
      status
      managerId
      managerRemoteId
      startAt
      finishAt
      sourceType
      sourceId
      contacts {
        fullName
        phone
        email
      }
      result
    }
  }
`;

import React from "react";
import classNames from "classnames";
import { Tabs as AntTabs, TabsProps } from "antd";

import "./styles.scss";

interface Props extends TabsProps {
  className?: string;
  popupClassName?: string;
}

export const Tabs: React.FC<Props> = ({
  className,
  popupClassName,
  ...props
}) => {
  return (
    <AntTabs
      className={classNames("ui-tabs", className)}
      popupClassName={classNames("ui-tabs-popup", popupClassName)}
      {...props}
    />
  );
};

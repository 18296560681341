import React from "react";

import { Recommended } from "components/Recommended";
import { ReplyInfo } from "./components/ReplyInfo";
import { HOUR } from "common/const/time";
import { useTimeout } from "common/hooks/useTimeout";
import { ReplyType, VacancyType } from "graphql/types/types";
import { useChangeVisible } from "common/hooks/visible/useChangeVisible";
import { ModalConfirm } from "components/ModalConfirm";

import "./styles.scss";

const MAX_TIME_REPLY_BLOCK = 2 * HOUR;
const CONFIRM_MESSAGE =
  "При выходе из отклика он перестанет быть закреплен за вами";

interface Props {
  vacancy: VacancyType;
  reply: ReplyType;
}

export const Reply: React.FC<Props> = ({ reply, vacancy }) => {
  const { onChangeReplyVisible } = useChangeVisible({ id: reply.id });

  const handleNotVisible = () => onChangeReplyVisible({ inWork: false });

  useTimeout(handleNotVisible, MAX_TIME_REPLY_BLOCK);

  return (
    <div className="reply-container">
      <ReplyInfo
        className="reply-container-info"
        vacancy={vacancy}
        reply={reply}
      />

      {reply.candidate ? (
        <Recommended
          candidate={reply.candidate}
          vacancyId={vacancy.id}
          mode="reply"
        />
      ) : null}

      <ModalConfirm
        message={CONFIRM_MESSAGE}
        isFactor={Boolean(reply.inWork)}
        onNavigate={handleNotVisible}
      />
    </div>
  );
};

import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";

const selectSockets = (state: RootState) => state.sockets;

export const statusSocketSelector = createSelector(
  selectSockets,
  (state) => state.statusSocket
);
export const statusWorkerSelector = createSelector(
  selectSockets,
  (state) => state.statusWorker
);
export const workerClientIdSelector = createSelector(
  selectSockets,
  (state) => state.workerClientId
);
export const oktellStatusSelector = createSelector(
  selectSockets,
  (state) => state.oktellStatus
);
export const isActiveCallSelector = createSelector(
  selectSockets,
  (state) => state.isActiveCall
);
export const currentOktellCallDataSelector = createSelector(
  selectSockets,
  (state) => state.currentOktellCallData
);
export const oktellUserLoginSelector = createSelector(
  selectSockets,
  (state) => state.oktellUserLogin
);
export const currentHotCallDataSelector = createSelector(
  selectSockets,
  (state) => state.currentHotCallData
);
export const isOktellAccessSelector = createSelector(
  selectSockets,
  (state) =>
    state.statusSocket === "Онлайн" &&
    state.statusWorker === "Онлайн" &&
    state.oktellStatus === "Онлайн"
);

export const selectOktellStatus = createSelector(
  selectSockets,
  (state) => state.oktellStatus
);

export const selectIsActiveCall = createSelector(
  selectSockets,
  (state) => state.isActiveCall
);

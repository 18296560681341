import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";

const selectReports = (state: RootState) => state.reports;

export const selectReport = createSelector(
  selectReports,
  (state) => state.report
);

export const selectReportUrl = createSelector(
  selectReports,
  (state) => state.report.url
);

export const selectReportList = createSelector(
  selectReports,
  (state) => state.report.data
);

export const selectReportName = createSelector(
  selectReports,
  (state) => state.reportName.value
);

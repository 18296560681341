import { gql } from "@apollo/client";

export const CHANGE_VACANCY_STATUS = gql`
  mutation changeVacancyStatus($id: Int!, $status: VacancyStatus!) {
    changeVacancyStatus(id: $id, status: $status) {
      ... on VacancyType {
        id
        status
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

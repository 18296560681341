import { ReplyStatus } from "graphql/types/types";

/**
 * Подмена статусов https://jira.mytechhelp.ru/browse/RABT-253
 */
const REPLACED_STATUSES: string[] = [
  ReplyStatus.New,
  ReplyStatus.NotThrough,
  ReplyStatus.Callback,
];

export const replyStatusResolver = (
  status?: string,
  isCreatedAtBusinessHours?: boolean
) => {
  if (
    !isCreatedAtBusinessHours &&
    status &&
    REPLACED_STATUSES.includes(status)
  ) {
    return "TRANSFERRED_TO_CC";
  }
  return status as string;
};

import { gql } from "@apollo/client";

export const AVITO_INFO = gql`
  subscription avitoInfo {
    avitoInfo {
      content
      status
      vacancyId
      userId
    }
  }
`;

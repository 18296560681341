import { gql } from "@apollo/client";

export const CHANGE_VISIBLE_REPLY = gql`
  mutation changeVisibleReply($channel: String!, $id: Int!, $inWork: Boolean!) {
    changeVisibleReply(channel: $channel, id: $id, inWork: $inWork) {
      ... on ReplyType {
        id
        contacts {
          email
          fullName
          phone
        }
        actionPayClick
        address {
          block
          blockType
          cityType
          city
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        chatLink
        citizenshipId
        cityAdsClick
        claimId
        companyId
        createdAt
        candidateId
        inWork
        deletedAt
        departmentId
        employmentId
        expiredAt
        expiredAtAfterTransferFromCc
        expiredCallTime
        expiredTime
        expiredTimeAfterTransferFromCc
        isCreatedAtBusinessHours
        isExpiredAtBusinessHours
        isTransferFromCcAtBusinessHours
        manager {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        managerComment
        managerId
        notCallCount
        owner {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        ownerId
        positionId
        refusalId
        refusalText
        s2Id
        source
        status
        updatedAt
        updatedBy
        userComment
        utm {
          campaign
          content
          medium
          source
          term
        }
        userContent
        vacancyId
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

import { useEffect } from "react";
import { setHeaderTitle } from "services/store/modules/app";
import { useAppDispatch } from "services/store/store";

type Props = {
  title: string | null;
};

export const useChangeTitle = ({ title }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (title) {
      dispatch(setHeaderTitle({ title }));
    }
  }, [dispatch, title]);
};

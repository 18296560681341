import React from "react";
import { Row, Col, RowProps } from "antd";

interface ColumnLayoutProps extends RowProps {
  leftComponent?: React.ReactNode;
  centerComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  leftSpan?: number;
  centerSpan?: number;
  rightSpan?: number;
  leftStyle?: React.CSSProperties;
  centerStyle?: React.CSSProperties;
  rightStyle?: React.CSSProperties;
}

const RowLayout: React.FC<ColumnLayoutProps> = ({
  leftComponent,
  centerComponent,
  rightComponent,
  leftSpan = 8,
  centerSpan = 8,
  rightSpan = 8,
  leftStyle,
  centerStyle,
  rightStyle,
  ...props
}) => {
  return (
    <Row gutter={16} {...props}>
      {leftComponent && (
        <Col span={leftSpan} style={leftStyle}>
          {leftComponent}
        </Col>
      )}
      {centerComponent && (
        <Col span={centerSpan} style={centerStyle}>
          {centerComponent}
        </Col>
      )}
      {rightComponent && (
        <Col span={rightSpan} style={rightStyle}>
          {rightComponent}
        </Col>
      )}
    </Row>
  );
};

export default RowLayout;

import React from "react";
import { Tooltip } from "components/UI/Tooltip";
import { MessageSquare } from "components/UI/icons";

import "./styles.scss";

interface CommentsTooltipProps {
  comments: string[];
}
function renderComments(comments: string[]) {
  return comments.map((comment, index) => <div key={index}>{comment}</div>);
}

export const CommentClaims: React.FC<CommentsTooltipProps> = ({ comments }) => {
  return (
    <div className="comments-container">
      <Tooltip placement="bottomRight" title={renderComments(comments)}>
        <>
          <MessageSquare />
        </>
      </Tooltip>
      <div>{comments.length}</div>
    </div>
  );
};

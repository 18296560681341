import React from "react";
import {
  Map,
  Placemark,
  ZoomControl,
  Clusterer,
  useYMaps,
} from "@pbe/react-yandex-maps";
import "./styles.scss";
import { PlacemarkData } from "../index";

type Props = {
  className?: string;
  placemarks: PlacemarkData[];
  width?: string | number;
  height?: string | number;
  zoom?: number;
};

export const MapCustomDisplay: React.FC<Props> = ({
  placemarks,
  width,
  height,
  zoom,
  className,
}) => {
  const ymaps = useYMaps(["templateLayoutFactory"]);

  const customPlacemarkIconLayout = (mode = false) =>
    ymaps?.templateLayoutFactory.createClass(`
    <div class="custom-placemark">
      <div class="placemark-content ${
        mode ? "placemark-content--mode" : ""
      }">от $[properties.iconContent] ₽</div>
      <div class="placemark-svg">
        <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 9L0.00480921 -2.69824e-07L12.9952 8.65831e-07L6.5 9Z" fill="${
            mode ? "#e69e19" : "#0057AC"
          }"/>
        </svg>
      </div>
    </div>
  `);

  const customClustererLayout = ymaps?.templateLayoutFactory.createClass(`
    <div class="custom-cluster-icon">$[properties.geoObjects.length]</div>
  `);

  return (
    <Map
      className={className}
      defaultState={{
        center:
          placemarks.length > 0
            ? [placemarks[0].lat, placemarks[0].lng]
            : [55.751574, 37.573856],
        zoom: zoom || 9,
        controls: ["fullscreenControl"],
      }}
      width={width}
      height={height}
      modules={["control.FullscreenControl"]}
    >
      <Clusterer
        options={{
          clusterIcons: [
            {
              href: "",
              size: [40, 40],
              offset: [-15, -15],
            },
          ],
          clusterIconContentLayout: customClustererLayout,
          groupByCoordinates: false,
          clusterDisableClickZoom: true,
          clusterHideIconOnBalloonOpen: false,
          geoObjectHideIconOnBalloonOpen: false,
        }}
      >
        {placemarks.map(({ lat, lng, name, title, isCurrentVacancy, id }) => (
          <Placemark
            key={id}
            geometry={[lat, lng]}
            properties={{
              hintContent: name,
              balloonContent: title ?? "Информация отсутствует",
              iconContent: title ?? "",
            }}
            options={
              title
                ? {
                    iconLayout: customPlacemarkIconLayout(isCurrentVacancy),
                    iconOffset: [-50, -39],
                  }
                : {
                    preset: isCurrentVacancy
                      ? "islands#darkOrangeIcon"
                      : "islands#blueDotIcon",
                  }
            }
          />
        ))}
      </Clusterer>
      <ZoomControl options={{ position: { top: 16, left: 8 } }} />
    </Map>
  );
};

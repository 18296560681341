import React from "react";
import {
  Control,
  Controller,
  UseFormSetError,
  useWatch,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import FormDatePicker from "components/UI/Form/FormDatePicker";
import { FormSelect } from "components/UI/Form/FormSelect";
import { filterOption } from "components/UI/Select/helpers";
import { IUser } from "services/store/modules/user/types";
import { MangoFiltersState } from "../../types";
import { getDisabledDateFrom, getDisabledDateTo } from "../../helpers";

type Props = {
  managerList: IUser[];
  control: Control<MangoFiltersState>;
  errors: FieldErrors<MangoFiltersState>;
  setError: UseFormSetError<MangoFiltersState>;
  clearErrors: UseFormClearErrors<MangoFiltersState>;
};

export const MangoFilters: React.FC<Props> = ({
  control,
  errors,
  managerList,
  setError,
  clearErrors,
}) => {
  const dateFrom = useWatch({ control, name: "dateFrom" });
  const dateTo = useWatch({ control, name: "dateTo" });

  return (
    <>
      <Controller
        name="dateFrom"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormDatePicker
            label="Период от"
            format="DD.MM.YYYY"
            picker="date"
            error={error?.message}
            disabledDate={(date) => getDisabledDateFrom(date, dateTo)}
            value={value}
            onChange={(value) => {
              clearErrors("dateFrom");

              if (value === null) {
                setError("dateFrom", {
                  message: "Период должен быть заполнен",
                });
              }

              onChange(value);
            }}
          />
        )}
      />

      <Controller
        name="dateTo"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormDatePicker
            label="Период до"
            format="DD.MM.YYYY"
            picker="date"
            error={error?.message}
            disabledDate={(date) => getDisabledDateTo(dateFrom, date)}
            value={value}
            onChange={(value) => {
              clearErrors("dateTo");

              if (value === null) {
                setError("dateTo", { message: "Период должен быть заполнен" });
              }

              onChange(value);
            }}
          />
        )}
      />

      <Controller
        name="managerId"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Кому"
            required={true}
            showSearch
            optionFilterProp="children"
            error={errors.managerId?.message || error?.message}
            filterOption={filterOption}
            options={managerList.map(({ fullName, id }) => ({
              label: fullName,
              value: id,
            }))}
            {...field}
          />
        )}
      />
    </>
  );
};

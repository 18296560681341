import React, { useRef } from "react";
import ReactQuill from "react-quill";
import cn from "classnames/dedupe";

import "react-quill/dist/quill.snow.css";
import "./styles.scss";

export interface IBaseContentEditorProps {
  className?: string;
  containerClassName?: string;
  label?: string;
  value: string;
  error?: string;
  onChange: (event: string) => void;
  required?: boolean;
  count?: number;
  disabled?: boolean;
}

export const Editor: React.FC<IBaseContentEditorProps> = ({
  className,
  containerClassName,
  required,
  value,
  count,
  label,
  onChange,
  error,
  disabled,
}) => {
  const editRef = useRef<ReactQuill | null>(null);
  const { current: editorRef } = editRef;

  return (
    <div className={cn("ui-editor-container", containerClassName)}>
      {label && (
        <div className="ui-editor-label">
          {label}
          {required && <span className="ui-editor-required">*</span>}
        </div>
      )}

      <ReactQuill
        className={cn("ui-editor", className, {
          "ui-editor--disable": disabled,
        })}
        theme="snow"
        ref={editRef}
        value={value || ""}
        onChange={(value) => {
          (editorRef?.getEditor()?.getLength() ?? value?.trim().length) > 1
            ? onChange(value)
            : onChange("");
        }}
        modules={{
          toolbar: false,
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
        ]}
      />
      {count && (
        <div className="ui-editor-character">
          {value.length <= count ? (
            <div className="ui-editor-character-text">
              Количество символов подсчитывается с учетом HTML-разметки.
            </div>
          ) : (
            <div
              className="ui-editor-character-text--red"
              style={{ color: "red" }}
            >
              {error ??
                "Превышен лимит символов для этого поля с учетом HTML-разметки. Сократите текст."}
            </div>
          )}
          <div className="ui-editor-character-count">
            {(editorRef?.getEditor()?.getLength() || 0) > 1
              ? `${value.length}/${count}`
              : `0/${count}`}
          </div>
        </div>
      )}
    </div>
  );
};

import { SelectProps } from "antd";

export const filterOption: SelectProps["filterOption"] = (input, option) => {
  const optionLabel = (option?.label as unknown as string) ?? "";
  return optionLabel.toLowerCase().includes(input.toLowerCase());
};

type Options = Array<{
  id?: string | number | null;
  name?: string;
  key?: string;
}>;

export const getSelectOptions = <T extends Options>(options: T) => {
  return options.map(({ name, id, key }) => ({
    label: name,
    value: id || key,
  }));
};

import React from "react";
import { Form } from "antd";
import classNames from "classnames";
import "./styles.scss";
import { CheckboxGroupProps } from "antd/es/checkbox";
import Checkbox from "../../Checkbox";

export interface FormCheckboxProps extends CheckboxGroupProps {
  label: string;
  type?: "single" | "multi";
}
export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  className,
  label,
  type = "multi",
  ...props
}) => {
  const formCheckboxClassName = classNames("ui-form-checkbox", className);

  return (
    <Form.Item label={label} className={formCheckboxClassName}>
      <Checkbox type={type} {...props} />
    </Form.Item>
  );
};

import { UserRole } from "graphql/types/types";

export const isAdmin = (userRole: UserRole) => UserRole.Admin === userRole;
export const isOwner = (userRole: UserRole) => UserRole.Owner === userRole;
export const isHrbp = (userRole: UserRole) => UserRole.Hrbp === userRole;
export const isModerator = (userRole: UserRole) =>
  UserRole.Moderator === userRole;
export const isManager = (userRole: UserRole) => UserRole.Manager === userRole;
export const isEmployeeCc = (userRole: UserRole) =>
  UserRole.EmployeeCc === userRole;

export const hasUpdateAccess = (userRole: UserRole) =>
  isAdmin(userRole) || isModerator(userRole) || isEmployeeCc(userRole);

export const hasAcceptAccess = (userRole: UserRole) =>
  isAdmin(userRole) ||
  isModerator(userRole) ||
  isEmployeeCc(userRole) ||
  isHrbp(userRole);

export const hasModerationAccess = (userRole: UserRole) =>
  isAdmin(userRole) || isHrbp(userRole) || isModerator(userRole);

export const hasEditAccess = (userRole: UserRole) =>
  isAdmin(userRole) ||
  isOwner(userRole) ||
  isHrbp(userRole) ||
  isModerator(userRole);

import React from "react";
import cn from "classnames/dedupe";

import { Title } from "components/UI/Title";
import {
  IVacancyHistory,
  VacancyHistoryField,
} from "services/store/modules/vacancies/types";
import { Tag } from "components/UI/Tag";
import { tagColorByStatus } from "components/redezign/Vacancy/components/RabotutForm/helpers";
import { ChevronDown, ChevronForward } from "components/UI/icons";
import { VACANCY_STATUS_TYPE } from "common/const/status";
import { ROLE } from "common/const/role";
import HTMLRenderer from "common/helpers/HTMLRenderer";
import { AvatarPopover } from "components/AvatarPopover";

import "./styles.scss";

export type Props = {
  history: IVacancyHistory;
  className?: string;
};

const titleByField: Record<VacancyHistoryField, string> = {
  refusal: "Добавлена причина отказа",
  status: "Изменение статуса вакансии",
  short_description: "Изменение краткого описания вакансии",
  description: "Изменение описания вакансии",
  region: "Изменение региона вакансии",
  terms: "Изменение условий вакансии",
  claims: "Изменение требований вакансии",
  city: "Изменение города вакансии",
  manager_id: "Передает свой отклик",
  owner_id: "Изменение владельца",
  contacts: "Изменение контактных данных",
  salary: "Изменение данных заработной платы",
  show_contacts: "Изменение отображения контактов",
  manager_comment: "Оставлен комментарий",
  address: "Изменение адреса",
  position_id: "Изменение вакансии заявки",
  relevance: "Изменение актуальноси вакансии",
  call_center_info: "Изменение данных для КЦ",
};

export const HistoryItem: React.FC<Props> = ({ history, className }) => {
  const {
    manager,
    createdAt,
    field,
    oldValue,
    newValue,
    oldManager,
    newManager,
  } = history;

  const departmentByField = {
    refusal: manager?.department?.name ?? "",
    status: "Система CRM Rabotut",
  };
  const title = `${manager?.firstName ?? ""} ${manager?.secondName ?? ""}`;

  const descriptionField = (
    <div className="history-field-wrapper">
      <HTMLRenderer htmlContent={oldValue ?? undefined} />
      <ChevronDown width={16} height={16} />
      <HTMLRenderer htmlContent={newValue ?? undefined} />
    </div>
  );

  const managerField = (
    <div className="history-field-wrapper">
      {oldManager?.id ? (
        <AvatarPopover userDate={oldManager} isGray />
      ) : (
        <span>#{oldValue}</span>
      )}
      <ChevronDown width={16} height={16} />
      {newManager?.id ? (
        <AvatarPopover userDate={newManager} isGray />
      ) : (
        <span>#{newManager}</span>
      )}
    </div>
  );

  const contentByField: Record<VacancyHistoryField, React.ReactElement> = {
    refusal: (
      <>
        <div>{title}</div>
        <Tag color="default">{manager ? ROLE[manager.role] : ""}</Tag>
      </>
    ),
    manager_comment: (
      <>
        <div>{title}</div>
        <Tag color="default">{manager ? ROLE[manager.role] : ""}</Tag>
      </>
    ),
    status: (
      <>
        <Tag className="history-field-tag" color={tagColorByStatus[oldValue]}>
          {VACANCY_STATUS_TYPE[oldValue]}
        </Tag>
        <ChevronForward />
        <Tag className="history-status-tag" color={tagColorByStatus[newValue]}>
          {VACANCY_STATUS_TYPE[newValue]}
        </Tag>
      </>
    ),
    salary: (
      <>
        <div>
          {oldValue?.start && oldValue?.end
            ? `${oldValue.start} - ${oldValue.end}`
            : "Договорная"}
        </div>
        <ChevronForward />
        <div>
          {newValue?.start && newValue?.end
            ? `${newValue.start} - ${newValue.end}`
            : "Договорная"}
        </div>
      </>
    ),
    contacts: (
      <div className="history-field-wrapper">
        <div>
          <span>{oldValue?.fullName || "Не указано"}</span>
          <a href={`tel: ${oldValue?.phone}`}>{oldValue?.phone}</a>
          <a href={`mailto:${oldValue?.email}`}>{oldValue?.email}</a>
        </div>
        <ChevronDown />
        <div>
          <span>{newValue?.fullName || "Не указано"}</span>
          <a href={`tel: ${newValue?.phone}`}>{newValue?.phone}</a>
          <a href={`mailto:${newValue?.email}`}>{newValue?.email}</a>
        </div>
      </div>
    ),
    show_contacts: (
      <>
        <div>{oldValue === "True" ? "Показывать" : "Не показывать"}</div>
        <ChevronForward />
        <div>{newValue === "True" ? "Показывать" : "Не показывать"}</div>
      </>
    ),
    address: (
      <>
        <div>{oldValue?.value ?? "Не указан"}</div>
        <ChevronForward />
        <div>{newValue?.value ?? "Не указан"}</div>
      </>
    ),
    position_id: (
      <>
        <div>{oldValue}</div>
        <ChevronForward />
        <div>{newValue}</div>
      </>
    ),
    call_center_info: (
      <>
        <HTMLRenderer htmlContent={oldValue?.description ?? undefined} />
        <ChevronForward />
        <HTMLRenderer htmlContent={newValue?.description ?? undefined} />
      </>
    ),
    relevance: (
      <>
        <div>
          {oldValue === "RELEVANT"
            ? "Актуальная вакансия"
            : "Не актуальная вакансия"}
        </div>
        <ChevronForward />
        <div>
          {newValue === "RELEVANT"
            ? "Актуальная вакансия"
            : "Не актуальная вакансия"}
        </div>
      </>
    ),
    description: descriptionField,
    short_description: descriptionField,
    region: descriptionField,
    terms: descriptionField,
    claims: descriptionField,
    city: descriptionField,
    manager_id: managerField,
    owner_id: managerField,
  };

  const refusalItems = [
    {
      name: "Причина отказа",
      content: newValue.refusal,
    },
    {
      name: "Комментарий менеджера",
      content: newValue.refusalText,
    },
  ];

  return (
    <div className={cn("history", className)}>
      <div className="history-createdAt">{createdAt}</div>
      <Title className="history-title" type="h3">
        {titleByField[field]}
      </Title>
      <div className="history-department">{departmentByField[field]}</div>

      <div className="">
        <div className="history-field">{contentByField[field]}</div>
        {field === "refusal" && (
          <>
            {refusalItems.map(({ name, content }, i) => (
              <div className="history-item" key={String(i)}>
                <div className="history-item-name">{name}</div>
                <span>{content}</span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

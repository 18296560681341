import React from "react";

import { Table, Props as TableProps } from "components/UI/Table";
import { PositionsRelationsType } from "graphql/types/types";
import { useAppSelector } from "services/store/store";
import { positions } from "services/store/modules/directory";
import {
  getCategoryById,
  getPositionById,
} from "services/store/modules/directory/getters";
import { selectPositionCategories } from "services/store/modules/vacanciesList/selectors";
import { FieldPosition } from "./components/FieldPosition";
import { FieldRestriction } from "./components/FieldRestriction";
import { FieldAvitoTags } from "./components/FieldAvitoTags";

import "./styles.scss";

type Props = {
  positionsRelations: PositionsRelationsType[];
  updatePositionsRelations: (
    positionsValue: PositionsRelationsType
  ) => Promise<void>;
};

export const PositionsTable: React.FC<Props> = ({
  positionsRelations,
  updatePositionsRelations,
}) => {
  const positionsList = useAppSelector(positions);
  const categories = useAppSelector(selectPositionCategories);

  const columns: TableProps<PositionsRelationsType>["columns"] = [
    {
      title: "ID",
      dataIndex: "positionIdFrom",
      key: "positionIdFrom",
      width: 60,
    },
    {
      title: "Должность",
      dataIndex: "positionIdFrom",
      key: "positionIdFrom",
      render: (positionIdFrom) =>
        getPositionById(positionsList, positionIdFrom)?.name ?? "",
    },
    {
      title: "Исключение для должностей",
      dataIndex: "restriction",
      key: "restriction",
      render: (restriction, positionsRelations) => (
        <FieldRestriction
          restriction={restriction}
          positionsRelations={positionsRelations}
          updatePositionsRelations={updatePositionsRelations}
        />
      ),
    },
    {
      title: "Категория",
      dataIndex: "categoryId",
      key: "categoryId",
      render: (categoryId) =>
        getCategoryById(categories, categoryId)?.name ?? "",
    },
    {
      title: "Должность для подбора",
      dataIndex: "positionIdTo",
      key: "positionIdTo",
      render: (positionIdTo, positionsRelations) => (
        <FieldPosition
          positions={positionIdTo}
          positionsRelations={positionsRelations}
          updatePositionsRelations={updatePositionsRelations}
        />
      ),
    },
    {
      title: "Ключевые слова для Авито",
      dataIndex: "avitoTags",
      key: "avitoTags",
      render: (avitoTags, positionsRelations) => (
        <FieldAvitoTags
          avitoTags={avitoTags}
          positionsRelations={positionsRelations}
          updatePositionsRelations={updatePositionsRelations}
        />
      ),
    },
  ];

  return (
    <Table
      tableLayout="fixed"
      dataSource={positionsRelations}
      columns={columns}
    />
  );
};

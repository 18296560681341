import React from "react";
import "../../components/Activity/activity.css";

interface HTMLProps {
  htmlContent: string | undefined;
}

const HTMLRenderer: React.FC<HTMLProps> = ({ htmlContent }) => {
  if (!htmlContent) {
    return <p className="action-text">Не указано</p>;
  }

  return (
    <div
      className="action-text"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default HTMLRenderer;

import React from "react";
import { ReplyType } from "graphql/types/types";
import Text from "components/UI/Text";
import { SOURCE_TYPE } from "common/const/source";

import "./styles.scss";

interface Props {
  reply: ReplyType;
}

interface DetailProps {
  label: string;
  value: React.ReactNode;
}

const Detail: React.FC<DetailProps> = ({ label, value }) => (
  <div className="reply-detail">
    <Text className="reply-detail-label">{label}</Text>
    <Text className="reply-detail-value">{value}</Text>
  </div>
);

export const ReplyDetails: React.FC<Props> = ({ reply }) => {
  const utmDetails = [
    { label: "campaign", value: reply.utm?.campaign },
    { label: "content", value: reply.utm?.content },
    { label: "medium", value: reply.utm?.medium },
    { label: "source", value: reply.utm?.source },
    { label: "term", value: reply.utm?.term },
  ];

  const details = [
    {
      label: "Источник:",
      value: SOURCE_TYPE[reply.source],
    },
    {
      label: "UTM:",
      value: (
        <>
          {utmDetails.map(({ label, value }, index) => (
            <Text className="reply-detail-value">
              {label}: {value}
            </Text>
          ))}
        </>
      ),
    },
    {
      label: "click_id:",
      value: reply.cityAdsClick ?? "Не указан",
    },
    {
      label: "id отклика:",
      value: reply.id,
    },
    {
      label: "Информация:",
      value: reply.userContent ?? "Не указана",
    },
  ];

  return (
    <div className="reply-details global-scrollbar">
      {details.map((detail, index) => (
        <Detail label={detail.label} value={detail.value} key={index} />
      ))}
    </div>
  );
};

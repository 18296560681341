import React from "react";
import { Tooltip as AntTooltip, TooltipProps } from "antd";

export type Props = TooltipProps;

export const Tooltip: React.FC<Props> = ({ className, children, ...props }) => {
  return (
    <AntTooltip color="black" {...props}>
      {children}
    </AntTooltip>
  );
};

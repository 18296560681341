import queryString, { StringifyOptions } from "query-string";

/**
 * '?foo=bar' => {foo: 'bar'}
 */
export const parseQuery = (query: string) => {
  return queryString.parse(query);
};

/**
 * {foo: 'bar', like: 'pizza'} => '?foo=bar&ilike=pizza'
 */
export const stringifyQuery = (
  query: Record<string, unknown>,
  options?: StringifyOptions
) => {
  return queryString.stringify(query, options);
};

import React from "react";
import cn from "classnames/dedupe";

import "./styles.scss";

type Props = {
  name: React.ReactNode;
  value: React.ReactElement;
  className?: string;
};

export const InfoItem: React.FC<Props> = ({ name, value, className }) => {
  return (
    <div className={cn("info-item", className)}>
      <span className="info-item-name">{name}</span>
      <span className="info-item-value">{value}</span>
    </div>
  );
};

import React, { useCallback } from "react";

import { PositionsTable } from "../PositionsTable";
import { useAppDispatch } from "services/store/store";
import { updateRelationPosition } from "services/store/modules/settings/actions";
import {
  PositionsRelationsForm,
  PositionsRelationsType,
} from "graphql/types/types";

import "./styles.scss";
import { setPositionRelations } from "services/store/modules/settings";

type Props = {
  positionsRelations: PositionsRelationsType[];
};

export const PositionsMap: React.FC<Props> = ({ positionsRelations }) => {
  const dispatch = useAppDispatch();

  const updatePositionsRelations = useCallback(
    async (positionsValues: PositionsRelationsForm) => {
      const result = await dispatch(
        updateRelationPosition({
          form: positionsValues,
        })
      ).unwrap();

      if (!result?.updateRelationPosition) {
        return;
      }

      dispatch(setPositionRelations(result.updateRelationPosition));
    },
    [dispatch]
  );

  return (
    <div className="positions">
      <PositionsTable
        positionsRelations={positionsRelations}
        updatePositionsRelations={updatePositionsRelations}
      />
    </div>
  );
};

import React, { useCallback, useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  isLoadingVacancyActivity,
  clearVacancyHistory,
  currentVacancyHistory,
  getCurrentVacancyHistory,
  vacancyHistoryMeta,
} from "services/store/modules/vacancies";
import { Notification } from "components/UI/Notifications/Notification";
import { VacancyType } from "graphql/types/types";
import { Loader } from "components/UI/Loader";
import { Button } from "components/UI/Button";
import { HistoryItem } from "components/HistoryItem";

import "./styles.scss";

type Props = {
  vacancyId: VacancyType["id"];
  activeKey: string;
};

export const VacancyHistory: React.FC<Props> = ({ vacancyId, activeKey }) => {
  const dispatch = useAppDispatch();

  const { page, size, total } = useAppSelector(vacancyHistoryMeta);
  const historyList = useAppSelector(currentVacancyHistory);
  const loading = useAppSelector(isLoadingVacancyActivity);

  const isShowPagination = useMemo(
    () => total > page * size,
    [page, size, total]
  );

  const getVacancyHistory = useCallback(() => {
    dispatch(
      getCurrentVacancyHistory({
        id: vacancyId,
        page: page || 0,
      })
    );
  }, [page, vacancyId, dispatch]);

  useEffect(() => {
    if (activeKey !== "history") {
      dispatch(clearVacancyHistory());
    } else {
      getVacancyHistory();
    }
  }, [activeKey, dispatch, getVacancyHistory]);

  useEffect(() => {
    return () => {
      dispatch(clearVacancyHistory());
    };
  }, []);

  if (loading) {
    return <Loader color="blue" size="large" />;
  }

  return (
    <div className="historyList">
      {historyList.length ? (
        <>
          {historyList.map((history) => (
            <HistoryItem
              className="historyList-item"
              key={history.id}
              history={history}
            />
          ))}
          {isShowPagination && (
            <Button
              className="historyList-showMore"
              variant="link"
              onClick={getVacancyHistory}
            >
              Показать еще
            </Button>
          )}
        </>
      ) : (
        <Notification type="warning" title="Пока нет истории" />
      )}
    </div>
  );
};

import Cookies from "js-cookie";

export const logout = () => {
  const rootDomain = process.env.REACT_APP_ROOT_DOMAIN ?? "";
  let domain = "";
  if (rootDomain?.indexOf(":") >= 0) {
    [domain] = rootDomain.split(":");
  } else {
    domain = rootDomain;
  }
  Cookies.remove("access_token", { domain: domain });
  Cookies.remove("refresh_token", { domain: domain });
  window.location.replace(
    `${
      process.env.REACT_APP_API_URL
    }/auth/logout?redirect_uri=${encodeURIComponent(
      `${process.env.REACT_APP_APP_URL}/login`
    )}`
  );
};

import React from "react";
import cn from "classnames";

interface IndicatorBlockProps {
  isOnline: boolean;
  label: string;
  isCollapsed?: boolean;
}

export const IndicatorBlock: React.FC<IndicatorBlockProps> = ({
  isOnline,
  label,
  isCollapsed,
}) => (
  <span className="debug-text">
    <div className="popover-div">
      <div
        className={cn("indicator", {
          online: isOnline,
          offline: !isOnline,
          "custom-margin": isCollapsed,
        })}
      />
      {!isCollapsed && <span>{label}</span>}
    </div>
  </span>
);

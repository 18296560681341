import { gql } from "@apollo/client";

export const COMPANY = gql`
  query company {
    company {
      ... on CompanyType {
        id
        logo
        name
        ownerId
        phone
        shortDescription
        updatedAt
        email
        description
        deletedAt
        createdAt
        blocked
        owner {
          id
          firstName
          secondName
          phone
          email
          city
        }
        stats {
          userCount
          replyCount
          vacancyCount
        }
      }
      ... on Error {
        message
        statusCode
        errors
      }
    }
  }
`;

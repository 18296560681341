import React, { useState } from "react";
import cn from "classnames/dedupe";

import { PositionsRelationsType } from "graphql/types/types";
import { Button } from "components/UI/Button";
import { Popover } from "components/UI/Popover";
import FormTextArea from "components/UI/Form/FormTextArea";

import "./styles.scss";

type Props = {
  avitoTags: PositionsRelationsType["avitoTags"];
  positionsRelations: PositionsRelationsType;
  className?: string;
  updatePositionsRelations: (
    positionsValue: PositionsRelationsType
  ) => Promise<void>;
};

export const FieldAvitoTags: React.FC<Props> = ({
  avitoTags,
  positionsRelations,
  className,
  updatePositionsRelations,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(avitoTags ?? "");
  const [loading, setLoading] = useState(false);

  const handleOpenChange = (visible: boolean) => {
    setOpen(visible);
  };

  const changePositionsRelations = async () => {
    setLoading(true);
    await updatePositionsRelations({
      ...positionsRelations,
      avitoTags: value.trim(),
    })
      .then(() => setOpen(false))
      .finally(() => setLoading(false));
  };

  return (
    <div className={cn("field-avitoTags", className)}>
      <Popover
        trigger="click"
        placement="bottomRight"
        open={open}
        onOpenChange={handleOpenChange}
        content={
          <div className="field-avitoTags-content">
            <FormTextArea
              label="Будем рады видеть соискателей с опытом работы"
              value={value}
              rows={5}
              onChange={(e) => setValue(e.target.value)}
            />

            <div className="field-avitoTags-group">
              <Button variant="primary" onClick={() => setValue("")}>
                Очистить
              </Button>
              <Button loading={loading} onClick={changePositionsRelations}>
                Подтвердить
              </Button>
            </div>
          </div>
        }
      >
        <div className="field-avitoTags-short">
          {avitoTags ? avitoTags : "Укажите ключевые слова для Авито"}
        </div>
      </Popover>
    </div>
  );
};

import * as yup from "yup";

export const schema = yup
  .object({
    name: yup.string().required("Необходимо указать название"),
    experience: yup.string().required("Необходимо указать опыт работы"),
    employment: yup.string().required("Необходимо указать тип занятости"),
    schedule: yup.string().required("Необходимо указать режим работы"),
    area: yup
      .object({
        id: yup.string(),
        name: yup.string().required("Необходимо указать город"),
      })
      .required("Необходимо указать город"),
    professionalRoles: yup
      .object({
        id: yup.string(),
        name: yup.string().required("Необходимо указать специализацию"),
      })
      .required("Необходимо указать специализацию"),
  })
  .required();

import React from "react";
import { RouteItem } from "../../routes";
import { Route, Redirect } from "react-router";
import SystemLayout from "../../layouts/SystemLayout";
import MainLayout from "../../layouts/MainLayout";
import { isAuth } from "../../services/store/modules/auth";
import { useSelector } from "react-redux";

interface IPreparedRouteProps extends RouteItem {}

export const PreparedRoute: React.FC<IPreparedRouteProps> = ({
  privateRoute,
  children,
  ...rest
}) => {
  const isAuthenticated = useSelector(isAuth);
  const authRoute = isAuthenticated ? (
    <MainLayout>
      <Route {...rest}>{children}</Route>
    </MainLayout>
  ) : (
    <Redirect to={"/login"} />
  );
  const systemRoute = (
    <SystemLayout>
      <Route {...rest}>{children}</Route>
    </SystemLayout>
  );

  const prepareRoute = () => (privateRoute ? authRoute : systemRoute);

  return prepareRoute();
};

export default PreparedRoute;

import { gql } from "@apollo/client";

export const CREATE_CITY = gql`
  mutation createCity($form: CityInputType!) {
    createCity(form: $form) {
      ... on CityType {
        key
        city
        region
        areaWithType
        timezone
        regionWithType
        regionType
        federalDistrict
        country
        location {
          lat
          lng
        }
        cityType
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

import React from "react";

import { Recommended } from "components/Recommended";
import { ClaimMapPanel } from "./components/ClaimMapPanel";
import { ClaimType } from "graphql/types/types";

import "./styles.scss";

interface Props {
  claim: ClaimType;
}

export const Claim: React.FC<Props> = ({ claim }) => {
  return (
    <div className="claim-container">
      <ClaimMapPanel className="claim-container-info" claim={claim} />

      {claim.candidate ? (
        <Recommended candidate={claim.candidate} mode="claim" />
      ) : null}
    </div>
  );
};

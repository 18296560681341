import { PayloadAction, combineReducers, createSlice } from "@reduxjs/toolkit";
import { PositionsRelationsType } from "graphql/types/types";
import { fetchPositionsRelations } from "./actions";
import { getInitialState } from "services/store/helpers";

const positionsRelationsSlice = createSlice({
  name: "positionsRelations",
  initialState: getInitialState<Array<PositionsRelationsType>>(),
  reducers: {
    setPositionRelations(
      state,
      { payload }: PayloadAction<PositionsRelationsType>
    ) {
      if (!state.data) return;
      state.data = state.data.map((position) => {
        return position.positionIdFrom === payload.positionIdFrom
          ? payload
          : position;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPositionsRelations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPositionsRelations.fulfilled, (state, { payload }) => {
        state.data = payload.positionRelations.data;
        state.loading = false;
      })
      .addCase(fetchPositionsRelations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? action.error.message ?? "";
      });
  },
});

export const { setPositionRelations } = positionsRelationsSlice.actions;

export const settingsReducer = combineReducers({
  positionsRelations: positionsRelationsSlice.reducer,
});

import { gql } from "@apollo/client";

export const PRIVATE_VACANCY_LIST = gql`
  query privateVacancyList(
    $filter: PrivateVacancyFilterType
    $pagination: PaginationType
  ) {
    privateVacancyList(filter: $filter, pagination: $pagination) {
      ... on PrivateVacancyListSuccess {
        data {
          address {
            block
            blockType
            city
            cityType
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            street
            streetType
            value
          }
          age {
            from
            to
          }
          callCenterInfo {
            addresses
            description
            driverLicense
            experience
            farePayment
            goodsCategory
            mobilePayment
            personalCar
            presenceOfPda
            project
            vaccination
          }
          citizenship {
            id
            key
            name
          }
          claims
          closedAt
          contacts {
            email
            fullName
            phone
          }
          createdAt
          deletedAt
          description
          id
          location {
            lat
            lng
          }
          manager {
            id
            firstName
            secondName
            email
            phone
            city
            role
            companyId
            departmentId
          }
          metro {
            distance
            line
            name
          }
          name
          publishedAt
          candidateCount
          salary {
            currency
            from
            gross
            to
          }
          shortDescription
          showContacts
          status
          terms
          updatedAt
          employment {
            key
            name
            id
          }
          employer {
            name
            key
            id
            logo
          }
          replyCount
          position {
            id
            key
            name
          }
          replyCountNew
          tag {
            key
            name
            colorBg
            color
          }
          refusal {
            name
            key
          }
          refusalText
          relevance
          extraAvito {
            publicationFinishTime
            remoteId
            remoteUrl
          }
          extraHh {
            remoteUrl
            remoteId
            publicationFinishTime
          }
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        statusCode
        errors
        message
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const UPDATE_HOT_CALL_PLAN = gql`
  mutation updateHotCallPlan($countCalls: Int!, $managerId: Int!) {
    updateHotCallPlan(countCalls: $countCalls, managerId: $managerId) {
      ... on HotCallPlanInputType {
        id
        countCalls
        createdAt
        createdBy
        dateBeginning
        userId
      }
      ... on Error {
        statusCode
        message
        errors
      }
    }
  }
`;

import React from "react";
import { Form, InputProps, InputRef } from "antd";
import Input from "../../Input";
import classNames from "classnames";
import "./styles.scss";

interface FormInputProps extends InputProps {
  label: string;
  error?: string;
}

export const FormInput = React.forwardRef<InputRef, FormInputProps>(
  ({ className, required, label, error, ...props }, ref) => {
    const formInputClassName = classNames(
      "ui-form-input",
      { "ui-form-input--required": required },
      className
    );
    const LabelWithAsterisk = (
      <span>
        {label}
        {required && <span className="required-asterisk">*</span>}
      </span>
    );

    return (
      <Form.Item
        label={LabelWithAsterisk}
        className={formInputClassName}
        rules={[{ required, message: `, введите ${label}` }]}
        required={required}
      >
        <Input
          ref={ref}
          {...props}
          status={Boolean(error) ? "error" : undefined}
        />
        {error && <div className="ui-form-input-error">{error}</div>}
      </Form.Item>
    );
  }
);

import { gql } from "@apollo/client";

export const POSITION_RELATIONS = gql`
  query positionRelations {
    positionRelations {
      ... on PositionListResponseSuccess {
        data {
          avitoTags
          categoryId
          positionIdTo
          positionIdFrom
          restriction
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

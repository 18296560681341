import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialState } from "services/store/helpers";
import { getReport } from "./actions";
import { Report } from "common/commonTypes/reports";
import { ReportName } from "common/const/reports";

const reportSlice = createSlice({
  name: "report",
  initialState: getInitialState<Report[]>(),
  reducers: {
    resetReportList(state) {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReport.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.meta = {
          limit: payload.limit,
          offset: payload.offset,
          total: payload.total,
        };
        state.url = payload.url;
        state.loading = false;
      })
      .addCase(getReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

type ReportNameState = {
  value: ReportName | null;
};

const reportNameState: ReportNameState = {
  value: null,
};

const reportNameSlice = createSlice({
  name: "reportName",
  initialState: reportNameState,
  reducers: {
    setReportName(
      state,
      { payload }: PayloadAction<{ reportName: ReportName }>
    ) {
      state.value = payload.reportName;
    },
  },
});

export const { resetReportList } = reportSlice.actions;
export const { setReportName } = reportNameSlice.actions;

export const reportsReducer = combineReducers({
  report: reportSlice.reducer,
  reportName: reportNameSlice.reducer,
});

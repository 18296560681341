import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  from,
  Observable,
  split,
} from "@apollo/client";
import { DefaultOptions } from "@apollo/client";
import Cookies from "js-cookie";
import axios from "axios";
import { RetryLink } from "@apollo/client/link/retry";
import toaster from "components/UI/Notifications/Notification";
import {
  AVAILABLE_STATUS_CODES,
  STATUS_CODES,
  STATUS_CODES_MESSAGE,
} from "../common/const/responseErrors";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";

const wsUrl = process.env.REACT_APP_WS_API_URL ?? "";

export const wsClient = new SubscriptionClient(wsUrl, {
  reconnect: true,
  connectionParams: {
    authorization: Cookies.get("access_token")
      ? `Bearer ${Cookies.get("access_token")}`
      : null,
  },
});

const wsLink = new WebSocketLink(wsClient);

const rootDomain = process.env.REACT_APP_ROOT_DOMAIN ?? "";
let domain = "";
if (rootDomain?.indexOf(":") >= 0) {
  [domain] = rootDomain.split(":");
} else {
  domain = rootDomain;
}

const URL = process.env.REACT_APP_API_URL + "/query";
const apiUrl = process.env.REACT_APP_REFRESH_TOKEN_URL ?? "";

const httpLink = createHttpLink({
  uri: URL,
});

// Using this logic, queries and mutations will use HTTP as normal, and subscriptions will use WebSocket.
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = Cookies.get("access_token");

  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  });
  return forward(operation);
});

const getNewToken = () => {
  return new Promise((resolve, reject) => {
    const refreshToken = Cookies.get("refresh_token");
    axios
      .post(apiUrl, {
        refresh_token: refreshToken,
      })
      .then((res) => {
        const dateNow = new Date().getTime();
        const expiredDateAccess = new Date(
          parseInt(res.data.expires_in as string, 10) * 1000 + dateNow
        );
        const expiredDateRefresh = new Date(
          parseInt(res.data.refresh_expires_in as string, 10) * 1000 + dateNow
        );
        Cookies.set("access_token", res.data.access_token, {
          expires: expiredDateAccess,
          domain: domain,
        });
        Cookies.set("refresh_token", res.data.refresh_token, {
          expires: expiredDateRefresh,
          domain: domain,
        });
        resolve(res.data.access_token);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
};

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const ErrorDetector = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  return new Observable((observer) => {
    // @ts-ignore
    let subscription;
    const name = operation.operationName;
    try {
      subscription = forward(operation).subscribe({
        next: (result) => {
          let data = result.data;
          if (data?.[name]?.["statusCode"]) {
            if (data[name].statusCode === parseInt(STATUS_CODES["401"])) {
              console.log("Token has expired");
              getNewToken().catch((e) => {
                console.error(e);
                Cookies.remove("access_token", { domain: domain });
                Cookies.remove("refresh_token", { domain: domain });
                Cookies.remove("openedCandidatesIds");
                const url = window.location.origin;
                const redirectUrl = url + "/login";
                window.location.replace(redirectUrl);
              });
            }
            if (
              AVAILABLE_STATUS_CODES.includes(data[name].statusCode.toString())
            ) {
              const errorMessage =
                STATUS_CODES_MESSAGE[data[name].statusCode.toString()];
              toaster.error({ title: errorMessage });
            }
            observer.error(new Error(data?.[name]?.message));
          } else {
            observer.next(result);
          }
        },
        error: (networkError) => {
          // console.log("CONNECTION_ERROR");
          if (context.retryCount === 1) {
            // console.log("CONNECTION_RETRY");
          }
          if (context.retryCount === 2) {
            // console.log("CONNECTION_TIMEOUT");
          }
          observer.error(networkError);
        },
        complete: () => {
          observer.complete.bind(observer)();
        },
      });
    } catch (e) {
      // console.log("CATCH");
      console.log(e);
      observer.error(e);
    }
    return () => {
      // @ts-ignore
      if (subscription) {
        // @ts-ignore
        subscription.unsubscribe();
      }
    };
  });
});

const RetryOnError = new RetryLink({
  delay: {
    initial: 800,
    max: Infinity,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    // console.log("RetryOnError");
    // console.log(count);
    if (
      !error.message ||
      error.message !== "incorrect or expired access token"
    ) {
      // If error is not related to connection, do not retry
      return false;
    }
    operation.setContext({ retryCount: count });
    return count <= 3;
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    const errorMessage = `[Network error]: ${networkError || ""}`;
    toaster.error({ title: errorMessage });
  }
  if (graphQLErrors) {
    const error = graphQLErrors?.[0];
    if (error) {
      const errorMessage = error.message;
      toaster.error({
        title: errorMessage || "Ошибка выполнения запроса. Попробуйте позже",
      });
    }
  }
});

export const client = new ApolloClient({
  uri: URL,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: from([
    RetryOnError,
    ErrorDetector,
    errorLink,
    authMiddleware,
    splitLink,
  ]),
  defaultOptions: defaultOptions,
});

import React from "react";

export const BookOutlined: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4 7.20002C6.71517 7.20002 7.02726 7.13795 7.31844 7.01734C7.60962 6.89672 7.8742 6.71994 8.09706 6.49708C8.31992 6.27422 8.4967 6.00965 8.61731 5.71846C8.73792 5.42728 8.8 5.1152 8.8 4.80002C8.8 4.48485 8.73792 4.17277 8.61731 3.88158C8.4967 3.5904 8.31992 3.32583 8.09706 3.10297C7.8742 2.88011 7.60962 2.70332 7.31844 2.58271C7.02726 2.4621 6.71517 2.40002 6.4 2.40002C5.76348 2.40002 5.15303 2.65288 4.70294 3.10297C4.25286 3.55306 4 4.1635 4 4.80002C4 5.43654 4.25286 6.04699 4.70294 6.49708C5.15303 6.94717 5.76348 7.20002 6.4 7.20002V7.20002Z"
        stroke="#0057AC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4001 2.40002C10.6443 2.60743 10.8463 2.90064 10.9865 3.25112C11.1267 3.6016 11.2003 3.99741 11.2001 4.40002C11.2003 4.80264 11.1267 5.19844 10.9865 5.54892C10.8463 5.89941 10.6443 6.19262 10.4001 6.40002"
        stroke="#0057AC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6001 13.9885V14.4H11.2001V13.9885C11.2001 12.4525 11.2001 11.6845 10.9211 11.0976C10.6756 10.5815 10.284 10.1619 9.80234 9.89895C9.2545 9.59998 8.5377 9.59998 7.1041 9.59998H5.6961C4.2625 9.59998 3.5457 9.59998 2.99786 9.89895C2.51618 10.1619 2.12457 10.5815 1.87914 11.0976C1.6001 11.6845 1.6001 12.4525 1.6001 13.9885Z"
        stroke="#0057AC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4 14.4V14.0344C14.4 12.6694 14.4 11.9869 14.0806 11.4652C13.7997 11.0066 13.3514 10.6337 12.8 10.4"
        stroke="#0057AC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React from "react";
import { Modal as AntModal, ModalProps as AntModalProps } from "antd";
import classNames from "classnames";

import "./styles.scss";
import { Close } from "../icons";

interface InputProps extends AntModalProps {
  size?: "small" | "normal" | "large";
  okText?: React.ReactNode;
  noSidePanel?: boolean;
}

export const Modal: React.FC<InputProps> = ({
  className,
  size = "small",
  onCancel,
  children,
  noSidePanel = false,
  ...props
}) => {
  const modalClassNames = classNames("ui-modal", className, {
    [`ui-modal--${size}`]: size,
    "no-side-panel": noSidePanel,
  });
  return (
    <AntModal
      className={modalClassNames}
      wrapClassName="ui-modal-wrapper"
      closeIcon={
        <div onClick={onCancel}>
          <Close color="#0057AC" />
        </div>
      }
      {...props}
    >
      {children}
    </AntModal>
  );
};

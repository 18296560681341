import { createSelector } from "reselect";
import { DefaultRootState } from "react-redux";
import { RootState } from "../../rootReducer";
import {
  selectDailyPlanListVacancy,
  selectPositionCategories,
  selectVacanciesDictionaries,
} from "../vacanciesList/selectors";
import { ReplyType } from "graphql/types/types";
import {
  DAILY_PLAN_CLAIMS,
  POSITION_CATEGORY_CLAIM,
} from "common/helpers/transformAndSortCategories";

const selectRepliesList = (state: RootState) => state.repliesList;

export const selectRepliesListByCategory = createSelector(
  selectRepliesList,
  (state) => state.repliesListByCategory
);

export const selectActiveCategory = createSelector(
  selectRepliesList,
  (state) => state.activeCategory
);
export const selectReply = createSelector(
  selectRepliesList,
  (state) => state.reply.data
);
export const selectReplyLoading = createSelector(
  selectRepliesList,
  (state) => state.reply.loading
);

export const selectDailyPlanListReplies = createSelector(
  selectDailyPlanListVacancy,
  (dailyPlanList) => {
    return [DAILY_PLAN_CLAIMS, ...dailyPlanList];
  }
);

export const selectPositionCategoriesReplies = createSelector(
  selectPositionCategories,
  (positionCategories) => {
    return [POSITION_CATEGORY_CLAIM, ...positionCategories];
  }
);

export const selectReplies = createSelector(
  selectRepliesListByCategory,
  (state) => state.data ?? []
);

export const selectRepliesMeta = createSelector(
  selectRepliesListByCategory,
  (state) => state.meta || null
);

export const selectRepliesLoading = createSelector(
  selectRepliesListByCategory,
  (state) => state.loading
);

export const selectCategoryById = createSelector(
  selectVacanciesDictionaries,
  selectActiveCategory,
  (items, { activeCategory }) =>
    items.data.positionCategories?.find((item) => item.id === activeCategory) ??
    null
);

export const selectDisabledRepliesList = createSelector(
  selectRepliesList,
  (state) => state.disabledReplies.list
);

export const selectVacancyDisabled = createSelector(
  selectDisabledRepliesList,
  (_: DefaultRootState, replyId: ReplyType["id"]) => replyId,
  (list, replyId) => list[replyId] || {}
);

import React from "react";

import { Modal } from "components/UI/Modal";
import RowLayout from "layouts/RowLayout";
import { Button } from "components/UI/Button";
import { useBeforeunloadRouteConfirm } from "common/hooks/useBeforeunloadRouteConfirm";
import { clearVacancy } from "services/store/modules/vacancy";
import { removeCookies } from "common/utils/cookies";
import { useAppDispatch } from "services/store/store";

interface Props {
  message: string;
  isFactor: boolean;
  blackRoute?: string;
  onNavigate: () => void;
}

export const ModalConfirm: React.FC<Props> = ({
  message,
  isFactor,
  blackRoute,
  onNavigate,
}) => {
  const { isVisible, closeAndNavigate, close } = useBeforeunloadRouteConfirm({
    isFactor,
    message,
    blackRoute,
  });
  const dispatch = useAppDispatch();
  const handleNavigate = () => {
    dispatch(clearVacancy());
    removeCookies("openedRecommendVacancy");
    onNavigate();
    closeAndNavigate();
  };

  return (
    <Modal
      title={message}
      open={isVisible}
      onCancel={close}
      size="small"
      footer={
        <RowLayout
          justify="start"
          leftComponent={
            <Button variant="primary" onClick={handleNavigate}>
              Уйти
            </Button>
          }
          rightComponent={<Button onClick={close}>Остаться</Button>}
        />
      }
    />
  );
};

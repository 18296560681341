import { PositionRestrictions } from "graphql/types/types";

export const PLATFORM_OPTIONS = [
  { label: "Rabotut.ru", value: "rabotut", disabled: true },
  { label: "Headhunter", value: "headhunter" },
  { label: "Авито работа", value: "avito" },
];

export const BOOLEAN_OPTIONS = [
  { label: "Да", value: "true" },
  { label: "Нет", value: "false" },
];

export const POSITION_RESTRICTIONS_OPTIONS = [
  { label: "Только женщины", value: PositionRestrictions.OnlyFemale },
  { label: "Только мужчины", value: PositionRestrictions.OnlyMale },
  { label: "Выбрать исключение", value: null },
];

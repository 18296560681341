import React from "react";
import cn from "classnames/dedupe";

import { Loader as LoaderIcon } from "../icons";

import "./styles.scss";

interface Props {
  size?: Size;
  color?: string;
  className?: string;
}

type Size = "small" | "normal" | "large";

const SIZE: Record<Size, number> = {
  small: 16,
  normal: 24,
  large: 40,
};

export const Loader: React.FC<Props> = ({
  size = "normal",
  color,
  className,
}) => {
  return (
    <span className={cn("ui-loader", className)}>
      <LoaderIcon color={color} width={SIZE[size]} height={SIZE[size]} />
    </span>
  );
};

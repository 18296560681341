import Cookies, { CookieAttributes } from "js-cookie";

export const getSessionIds = (name: string): string[] => {
  const ids = getCookie(name);

  return ids ? JSON.parse(ids) : [];
};

export const setSessionIds = (name: string, id: string) => {
  const sessionIds = getSessionIds(name);
  const ids = new Set(sessionIds);

  ids.add(id);

  setCookie(name, JSON.stringify(Array.from(ids)), {
    expires: 0.5,
  });
};

export const getCookie = (name: string) => {
  return Cookies.get(name);
};

export const setCookie = (
  name: string,
  value: string,
  attributes?: CookieAttributes
) => {
  Cookies.set(name, value, attributes);
};

export const removeCookies = (name: string) => {
  Cookies.remove(name);
};

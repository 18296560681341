import { gql } from "@apollo/client";

export const MESSAGES = gql`
  subscription messages($channel: String)  {
    messages(channel: $channel) {
      content
      owner
      timestamp
    }
  }
`;

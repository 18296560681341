import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as apollo } from "graphql/apollo";

import {
  QueryReplyArgs,
  MutationChangeReplyStatusArgs,
  MutationChangeVisibleReplyArgs,
  QueryReplyListByCategoryArgs,
} from "graphql/types/types";
import { AsyncThunkConfig } from "services/store/types";
import { RepliesListByCategoryResponse, ReplyResponse } from "./types";
import { REPLY_ITEM } from "graphql/requests/query/reply";
import { CHANGE_REPLY_STATUS } from "graphql/requests/mutation/changeReplyStatus";
import { CHANGE_VISIBLE_REPLY } from "graphql/requests/mutation/changeVisibleReply";
import { REPLY_LIST_BY_CATEGORY } from "graphql/requests/query/replyListByCategory";

export const getReplyListByCategory = createAsyncThunk<
  RepliesListByCategoryResponse | null,
  QueryReplyListByCategoryArgs,
  AsyncThunkConfig
>("replies/replyListByCategory", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.query<RepliesListByCategoryResponse>({
      query: REPLY_LIST_BY_CATEGORY,
      variables: payload,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const getReply = createAsyncThunk<
  ReplyResponse,
  QueryReplyArgs,
  AsyncThunkConfig
>("replies/getReply", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.query<ReplyResponse>({
      query: REPLY_ITEM,
      variables: payload,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const changeReplyStatus = createAsyncThunk<
  ReplyResponse,
  MutationChangeReplyStatusArgs,
  AsyncThunkConfig
>("replies/changeReplyStatus", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: CHANGE_REPLY_STATUS,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const changeReplyVisible = createAsyncThunk<
  ReplyResponse,
  MutationChangeVisibleReplyArgs,
  AsyncThunkConfig
>("replies/changeReplyVisible", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: CHANGE_VISIBLE_REPLY,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

import { gql } from "@apollo/client";

export const CONVERT_CLAIM_TO_REPLY = gql`
  mutation convertClaimToReply($claimId: Int!, $vacancyId: Int!) {
    convertClaimToReply(claimId: $claimId, vacancyId: $vacancyId) {
      ... on ReplyType {
        id
        vacancyId
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

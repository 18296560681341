import { gql } from "@apollo/client";

export const CREATE_HOT_CALL = gql`
  mutation createHotCall($form: HotCallInputType!) {
    createHotCall(form: $form) {
      ... on HotCallType {
        contacts {
          email
          fullName
          phone
        }
        createdAt
        deletedAt
        finishAt
        id
        managerId
        managerRemoteId
        result
        sourceId
        sourceType
        startAt
        status
        updatedAt
      }
      ... on Error {
        errors
        message
        statusCode
      }
    }
  }
`;

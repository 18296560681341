import { CompanySettingsInputType } from "graphql/types/types";
import { TIME_STRING_LENGTH } from "common/const/time";

export const validateTime = (
  callTimeStart: string,
  callTimeEnd: string,
  setTimeError: (error: string | null) => void
): boolean => {
  if (callTimeStart.length < TIME_STRING_LENGTH) {
    setTimeError("Неправильная длина строки для времени начала");
    return false;
  }

  if (callTimeEnd.length < TIME_STRING_LENGTH) {
    setTimeError("Неправильная длина строки для времени окончания");
    return false;
  }

  const startTime = getTimeAsHHMM(callTimeStart);
  const endTime = getTimeAsHHMM(callTimeEnd);

  if (endTime <= startTime) {
    setTimeError(
      "Время окончания не может быть меньше или равно времени начала"
    );
    return false;
  } else {
    setTimeError(null);
    return true;
  }
};

export const getInitialValues = (company?: CompanySettingsInputType) => ({
  callTimeStart: company?.callTimeStart,
  callTimeEnd: company?.callTimeEnd,
});

export const getTimeAsHHMM = (time: string) =>
  parseInt(time.replace(":", ""), 10);

import { transformToDaDataAddressSuggestion } from "common/helpers/transformDaData";
import { UserType, VacancyType } from "graphql/types/types";
import { HHState } from ".";
import { getAddress, getContacts, getDescription, getSalary } from "../helpers";

export const getInitialState = (vacancy: VacancyType): HHState => ({
  name: vacancy.name ?? "",
  description: vacancy.description ?? "",
  shortDescription: vacancy.shortDescription ?? "",
  terms: vacancy.terms ?? "",
  claims: vacancy.claims ?? "",
  salaryFrom: vacancy.salary.from ?? 0,
  salaryTo: vacancy.salary.to ?? 0,
  experience: vacancy.additionalHh?.experience ?? "",
  address: transformToDaDataAddressSuggestion(vacancy.address),
  managerId: vacancy?.manager?.id,
  driverLicense: vacancy.additionalHh?.driverLicense ?? [],
  languages: vacancy.additionalHh?.languages ?? "",
  area: { name: vacancy.additionalHh?.area ?? "", id: "" },
  professionalRoles: {
    name: vacancy.additionalHh?.professionalRoles
      ? vacancy.additionalHh?.professionalRoles[0] ?? ""
      : "",
    id: "",
  },
  acceptHandicapped: vacancy.additionalHh?.acceptHandicapped ?? false,
  acceptKids: vacancy.additionalHh?.acceptKids ?? false,
  acceptTemporary: vacancy.additionalHh?.acceptTemporary ?? false,
  employment: undefined,
  schedule: undefined,
});

export const getPayload = (
  {
    name,
    experience,
    address,
    salaryFrom,
    salaryTo,
    managerId,
    description,
    shortDescription,
    terms,
    claims,
    driverLicense,
    languages,
    area,
    professionalRoles,
    acceptHandicapped,
    acceptKids,
    acceptTemporary,
    employment,
    schedule,
  }: HHState,
  managerList: UserType[]
) => ({
  description: getDescription({ description, shortDescription, terms, claims }),
  address: getAddress(address),
  employment: { id: employment },
  name,
  salary: getSalary(salaryFrom, salaryTo),
  contacts: getContacts(managerId, managerList),
  experience: { id: experience },
  area: { id: area.id },
  schedule: { id: schedule },
  professional_roles: [professionalRoles.id],
  accept_handicapped: acceptHandicapped,
  accept_kids: acceptKids,
  accept_temporary: acceptTemporary,
  ...(languages ? { languages } : {}),
  ...(driverLicense.length ? { driver_license: driverLicense } : {}),
});

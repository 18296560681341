import React from "react";
import { IconType } from "common/commonTypes/types";

export const Unlock: React.FC<IconType> = ({
  width = 24,
  height = 24,
  color = "#A6ACB9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2499 8.25H3.74988C2.92145 8.25 2.24988 8.92157 2.24988 9.75V15C2.24988 15.8284 2.92145 16.5 3.74988 16.5H14.2499C15.0783 16.5 15.7499 15.8284 15.7499 15V9.75C15.7499 8.92157 15.0783 8.25 14.2499 8.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24988 8.24999V5.24999C5.24895 4.32003 5.59359 3.42289 6.21691 2.73274C6.84023 2.04259 7.69775 1.60867 8.62301 1.51521C9.54826 1.42175 10.4752 1.67542 11.224 2.22698C11.9727 2.77854 12.4898 3.58863 12.6749 4.49999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React from "react";
import cn from "classnames";

import { List, Grid } from "components/UI/icons";

import "./styles.scss";

export type ViewMode = "list" | "grid";

type Props = {
  mode: ViewMode;
  onChangeMode: (mode: ViewMode) => void;
}

const getClassNameByName = (mode: ViewMode, name: ViewMode) => cn("viewmode-toggle", {"active": mode === name});
const getColorByName = (mode: ViewMode, name: ViewMode) => mode === name ? "#0057AC" : "#000000";

export const ViewModeToggle: React.FC<Props> = ({
  mode,
  onChangeMode
}) => {
  const modes = [
    {
      className: getClassNameByName(mode, "list"),
      icon: <List color={getColorByName(mode, "list")} />,
      onClick: () => onChangeMode("list"),
    },
    {
      className: getClassNameByName(mode, "grid"),
      icon: <Grid color={getColorByName(mode, "grid")} />,
      onClick: () => onChangeMode("grid"),
    },
  ];

  return (
    <div className="viewmode">
      {modes.map(({className, icon, onClick}, i) => (
        <span
          key={i}
          className={className}
          onClick={onClick}
        >
          {icon}
        </span>
      ))}
    </div>
  );
};

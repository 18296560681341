function getMonthDeclension(months: number) {
  if (months >= 11 && months <= 14) return "месяцев";
  const lastDigit = months % 10;
  if (lastDigit === 1) return "месяц";
  if (lastDigit >= 2 && lastDigit <= 4) return "месяца";
  return "месяцев";
}
export const formatDurationMonths = (months: number) => {
  if (months === 0) {
    return "Не указано";
  }

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  let result = "";

  if (years > 0) {
    result += `${years} ${
      years === 1 ? "год" : years >= 2 && years <= 4 ? "года" : "лет"
    }`;
  }

  if (remainingMonths > 0) {
    if (result.length > 0) {
      result += " ";
    }
    result += `${remainingMonths} ${getMonthDeclension(remainingMonths)}`;
  }

  return result || "Не указано";
};

import { gql } from "@apollo/client";

export const CREATE_POSITION = gql`
  mutation createPosition($form: PositionInputType!) {
    createPosition(form: $form) {
      ... on PositionType {
        id
        key
        name
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

import { useEffect } from "react";
import { useAppDispatch } from "services/store/store";
import {
  subscribeAvitoInfo,
  subscribeEnableMessages,
} from "services/store/modules/sockets/actions";
import { Channel } from "services/store/modules/sockets/types";

export const useSockets = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const messagesVacancyPromise = dispatch(
      subscribeEnableMessages({
        channel: Channel.EnableVacancy,
      })
    ).unwrap();
    const messagesReplayPromise = dispatch(
      subscribeEnableMessages({
        channel: Channel.EnableReply,
      })
    ).unwrap();
    const avitoInfoPromise = dispatch(subscribeAvitoInfo()).unwrap();

    return () => {
      messagesVacancyPromise.then((subscription) => subscription.unsubscribe());
      messagesReplayPromise.then((subscription) => subscription.unsubscribe());
      avitoInfoPromise.then((subscription) => subscription.unsubscribe());
    };
  }, [dispatch]);
};

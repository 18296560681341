type IHotCallStatus = {
  [key in string]: string;
};

export const HOT_CALL_STATUS_VALUE: IHotCallStatus = {
  START: "START",
  IN_PROGRESS: "IN_PROGRESS",
  FINISH: "FINISH",
};

export const HOT_CALL_STATUS_TYPE: IHotCallStatus = {
  [HOT_CALL_STATUS_VALUE.START]: "Начался",
  [HOT_CALL_STATUS_VALUE.IN_PROGRESS]: "В процессе",
  [HOT_CALL_STATUS_VALUE.FINISH]: "Завершен",
};

export const HOT_CALL_SELECT_STATUS_TYPE = [
  {
    label: "Занято",
    key: "BUSY",
  },
  {
    label: "Не отвечают",
    key: "NOT_ANSWER",
  },
  {
    label: "Автоответчик",
    key: "VOICEMAIL",
  },
];

import { RootState } from "../../rootReducer";
import { createSelector } from "reselect";

const selectClaimsList = (state: RootState) => state.claimsList;

export const selectClaims = createSelector(
  selectClaimsList,
  (state) => state.claimsList.data ?? []
);
export const selectClaimsMeta = createSelector(
  selectClaimsList,
  (state) => state.claimsList.meta || null
);

export const selectClaimsLoading = createSelector(
  selectClaimsList,
  (state) => state.claimsList.loading
);
export const selectClaimData = createSelector(
  selectClaimsList,
  (state) => state.claim.data
);
export const selectClaim = createSelector(
  selectClaimsList,
  (state) => state.claim
);

import React from "react";
import { useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { useChangeTitle } from "common/hooks/header/useChangeTitle";
import { PlansName, titleByPlanName } from "common/const/plans";
import { Plan } from "components/redezign/Plan";

export interface RouteParams {
  id: PlansName;
}

export const PlanPage: React.FC = () => {
  const { id: planName } = useParams<RouteParams>();

  useChangeTitle({ title: titleByPlanName[planName] });

  return (
    <ContentLayout centralColumn={{ content: <Plan planName={planName} /> }} />
  );
};

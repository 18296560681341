import React from "react";
import { Tag as AntTag, TagProps } from "antd";
import { PresetColorType, PresetStatusColorType } from "antd/es/_util/colors";
import cn from "classnames";

import "./styles.scss";

export interface PropsTags extends TagProps {
  color?: PresetColorType | PresetStatusColorType;
}

export const Tag: React.FC<PropsTags> = ({
  className,
  color = "default",
  ...props
}) => {
  return (
    <AntTag
      className={cn("ui-tag", `ui-tag--${color}`, className)}
      {...props}
    />
  );
};

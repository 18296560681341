import React from "react";
import cn from "classnames/dedupe";

import { Title } from "components/UI/Title";
import { IVacancyComment } from "services/store/modules/vacancies/types";

import "./styles.scss";

export type Props = {
  comment: IVacancyComment;
  className?: string;
};

export const CommentItem: React.FC<Props> = ({ comment, className }) => {
  const { manager, createdAt, content } = comment;
  const title = `${manager?.firstName ?? ""} ${manager?.secondName ?? ""}`;

  return (
    <div className={cn("comment", className)}>
      <div className="comment-createdAt">{createdAt}</div>
      <Title className="comment-title" type="h3">
        {title}
      </Title>
      <div className="comment-department">
        {manager?.department?.name ?? ""}
      </div>

      <div>{content}</div>
    </div>
  );
};

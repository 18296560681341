import React, { memo, useState } from "react";

import { Modal } from "components/UI/Modal";
import { AvitoForm } from "../AvitoForm";
import { HHForm } from "../HHForm";
import { UserRole, UserType, VacancyType } from "graphql/types/types";
import { Tabs } from "components/UI/Tabs";
import { Platform, platformName } from "common/const/platform";
import { RabotutForm } from "../RabotutForm";
import { ZarplataForm } from "../ZarplataForm";
import { selectUsersByRoles } from "services/store/modules/user/selectors";
import { useAppSelector } from "services/store/store";

interface Props {
  open: boolean;
  vacancy: VacancyType;
  publishItems: Platform[];
  onChangeOpen: () => void;
  userData: UserType;
}

const PublishModalComponent: React.FC<Props> = ({
  open,
  vacancy,
  publishItems,
  onChangeOpen,
  userData,
}) => {
  const managerList = useAppSelector((state) =>
    selectUsersByRoles(state, [UserRole.Manager, UserRole.Hrbp])
  );

  const [activeKey, setActiveKey] = useState("rabotut");

  const handleTabClick = (key: string) => {
    setActiveKey(key);
  };
  const platformForm: Record<Platform, React.ReactElement> = {
    headhunter: (
      <HHForm
        vacancy={vacancy}
        managerList={managerList}
        publishItems={publishItems}
        changeTab={handleTabClick}
      />
    ),
    avito: (
      <AvitoForm
        vacancy={vacancy}
        managerList={managerList}
        changeTab={handleTabClick}
      />
    ),
    rabotut: (
      <RabotutForm
        vacancy={vacancy}
        managerList={managerList}
        user={userData}
        publishItems={publishItems}
        changeTab={handleTabClick}
      />
    ),
    zarplata: (
      <ZarplataForm
        vacancy={vacancy}
        managerList={managerList}
        publishItems={publishItems}
        changeTab={handleTabClick}
      />
    ),
  };

  const items = publishItems.map((platform) => ({
    key: platform,
    label: platformName[platform],
    children: platformForm[platform],
  }));

  return (
    <Modal open={open} size="large" onCancel={onChangeOpen} footer={null}>
      <Tabs
        defaultActiveKey="rabotut"
        activeKey={activeKey}
        onTabClick={handleTabClick}
        items={items}
      />
    </Modal>
  );
};

export const PublishModal = memo(PublishModalComponent);

import React, { useState } from "react";

import { Tabs } from "components/UI/Tabs";
import { VacancyComments } from "../VacancyComments";
import { VacancyHistory } from "../VacancyHistory";
import { VacancyType } from "graphql/types/types";
import { VacancyAllHistory } from "../VacancyAllHistory";

type Props = {
  vacancyId: VacancyType["id"];
};

export type ActiveKey = "history" | "comments" | "all";

export const VacancyActivity: React.FC<Props> = ({ vacancyId }) => {
  const [activeKey, setActiveKey] = useState<ActiveKey>("all");

  const handleTabClick = (key: string) => {
    setActiveKey(key as ActiveKey);
  };

  const items = [
    {
      key: "all",
      label: "Все",
      children: <VacancyAllHistory activeKey={activeKey} vacancyId={vacancyId} />,
    },
    {
      key: "history",
      label: "История",
      children: <VacancyHistory activeKey={activeKey} vacancyId={vacancyId} />,
    },
    {
      key: "comments",
      label: "Комментарии",
      children: <VacancyComments activeKey={activeKey} vacancyId={vacancyId} />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey="all"
      activeKey={activeKey}
      onTabClick={handleTabClick}
      items={items}
    />
  );
};

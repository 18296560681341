import React, { useState } from "react";
import RowLayout from "layouts/RowLayout";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectVacancySuitableListData } from "services/store/modules/vacanciesList/selectors";
import { RecommendMaps } from "components/Recommended/components/RecommendMaps";
import { claimStatuses } from "services/store/modules/directory";
import { ClaimStatus, ClaimType } from "graphql/types/types";
import toaster from "components/UI/Notifications/Notification";
import { changeClaimStatus } from "services/store/modules/claimsList/actions";
import { PanelProps } from "components/UI/Collapse";
import { ClaimDetails } from "../ClaimDetails";
import { ActionPanel } from "components/ActionPanel";

interface Props {
  className?: string;
  claim: ClaimType;
}

export const ClaimMapPanel: React.FC<Props> = ({ claim, className }) => {
  const dispatch = useAppDispatch();
  const claimStatusDictionary = useAppSelector(claimStatuses);
  const recommendedVacancies = useAppSelector(selectVacancySuitableListData);

  const [currentStatus, setCurrentStatus] = useState(claim.status ?? undefined);

  const handleStatusChange = async (status: ClaimStatus) => {
    setCurrentStatus(status);

    try {
      await dispatch(
        changeClaimStatus({
          id: claim.id,
          status,
        })
      );
      toaster.success({ title: "Статус успешно изменен" }, { autoClose: 3000 });
    } catch (error) {
      toaster.error({ title: `Ошибка: ${error}` }, { autoClose: 3000 });
    }
  };
  const handleClaimVisibleChange = async () => {
    toaster.success({ title: "Заявка в работе" });
  };

  const panels: PanelProps[] = [
    {
      key: "1",
      panelTitle: "Справочная информация",
      contentComponent: <ClaimDetails claim={claim} />,
    },
  ];

  return (
    <RowLayout
      style={{ marginLeft: 0, marginRight: 0 }}
      className={className}
      leftSpan={19}
      leftComponent={
        <RecommendMaps recommendedVacancies={recommendedVacancies} />
      }
      centerSpan={5}
      centerComponent={
        <ActionPanel
          statuses={claimStatusDictionary}
          currentStatus={currentStatus}
          onStatusChange={handleStatusChange}
          onVisibilityChange={handleClaimVisibleChange}
          panels={panels}
        />
      }
    />
  );
};

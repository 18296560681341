import { PlansName, titleByPlanName } from "common/const/plans";
import { Report } from "components/UI/icons";

type PlansCard = {
  title: string;
  icon: React.ReactElement;
  isVisible?: boolean;
  onClick: () => void;
};

export const getPlansCards = (
  handleClick: (name: PlansName) => void
): PlansCard[] => [
  {
    title: titleByPlanName[PlansName.PlanMango],
    icon: <Report />,
    isVisible: true,
    onClick: () => handleClick(PlansName.PlanMango),
  },
];

import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  combineReducers,
} from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import {
  Chat,
  FetchChatsParams,
  Message,
  FetchMessagesParams,
  NotificationsResponse,
  Notification,
  UploadLinks,
  IMessageChat,
} from "./types";

const chatApiUrl = process.env.REACT_APP_CHAT_API_URL;
const username = process.env.REACT_APP_BASIC_AUTH_USERNAME;
const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;

function groupByUserId(data: Chat[]): { [key: string]: Chat } {
  const groupedData = _.groupBy(data, "candidate_id");
  const result: { [key: string]: Chat } = {};

  for (const userId in groupedData) {
    if (groupedData.hasOwnProperty(userId)) {
      result[String(userId)] = groupedData[userId][0];
    }
  }
  return result;
}
export const fetchChats = createAsyncThunk<
  { [key: number]: Chat },
  FetchChatsParams,
  {}
>("chats/fetchChats", async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get<Chat[]>(
      `${chatApiUrl}/chats?managerID=${params.managerID}`,
      {
        headers: {
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
      }
    );
    return groupByUserId(response.data);
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchMessages = createAsyncThunk<
  IMessageChat,
  FetchMessagesParams,
  {}
>("messages/fetchMessages", async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get<IMessageChat>(
      `${chatApiUrl}/chatMessages/${params.chatID}?since=${params.since}&limit=${params.limit}`,
      {
        headers: {
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const updateManagerInChat = createAsyncThunk<
  { [key: number]: Chat },
  { chat_id: string; manager_id: number },
  {}
>("chat/updateManagerInChat", async (params, { rejectWithValue }) => {
  try {
    const response = await axios.put<Chat[]>(
      `${chatApiUrl}/chats`,
      {
        chat_id: params.chat_id,
        manager_id: params.manager_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
      }
    );

    return groupByUserId(response.data);
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const fetchNotifications = createAsyncThunk<
  NotificationsResponse,
  number,
  {}
>("notifications/fetchNotifications", async (managerID: number) => {
  try {
    const response = await axios.get<NotificationsResponse>(
      `${chatApiUrl}/notifications?managerID=${managerID}`,
      {
        headers: {
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

export const deleteNotification = createAsyncThunk<void, string>(
  "notifications/deleteNotification",
  async (chatID: string) => {
    try {
      await axios.delete(`${chatApiUrl}/notifications/${chatID}`, {
        headers: {
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
      });
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const uploadFile = createAsyncThunk<
  UploadLinks,
  { chatID: string; file: string },
  {}
>("files/uploadFile", async ({ chatID, file }) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post<{
      chat_id: string;
      document: string;
      thumbnail: string;
    }>(`${chatApiUrl}/uploadFile/${chatID}`, formData, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

const chatSlice = createSlice({
  name: "chats",
  initialState: {
    chats: {} as { [key: number]: Chat },
    loading: false,
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChats.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.loading = false;
        state.chats = action.payload;
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateManagerInChat.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateManagerInChat.fulfilled, (state, action) => {
        state.loading = false;
        state.chats = action.payload;
      })
      .addCase(updateManagerInChat.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

const messageSlice = createSlice({
  name: "messages",
  initialState: {
    messages: {} as IMessageChat,
    loading: false,
    error: null as string | null,
  },
  reducers: {
    incrementByMessages: (state, action: PayloadAction<Message>) => {
      state.messages.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action.payload;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

const fileSlice = createSlice({
  name: "files",
  initialState: {
    fileURL: { chat_id: "", document: "", thumbnail: "" },
    fileLoading: false,
    fileError: null as string | null,
  },
  reducers: {
    deleteFile: (state) => {
      state.fileURL = { chat_id: "", document: "", thumbnail: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.fileLoading = true;
        state.fileError = null;
        state.fileURL = { chat_id: "", document: "", thumbnail: "" };
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.fileLoading = false;
        state.fileURL = action.payload;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.fileLoading = false;
        state.fileError = action.payload as string;
      });
  },
});

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    data: {
      count: 0,
      notifies: [],
    } as NotificationsResponse,
    loading: "idle",
    error: null as string | null,
  },
  reducers: {
    updateNotifications: (state, action: PayloadAction<Notification>) => {
      state.data?.notifies?.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "Ошибка при загрузке уведомлений";
      });
  },
});

export const { incrementByMessages } = messageSlice.actions;

export const { updateNotifications } = notificationsSlice.actions;
export const { deleteFile } = fileSlice.actions;

export const chatsReducer = combineReducers({
  file: fileSlice.reducer,
  chat: chatSlice.reducer,
  message: messageSlice.reducer,
  notifications: notificationsSlice.reducer,
});

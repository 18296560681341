import React from "react";
import classNames from "classnames";
import { Popover as AntPopover, PopoverProps } from "antd";

import "./styles.scss";

interface Props extends PopoverProps {}

export const Popover: React.FC<Props> = ({ className, children, ...props }) => {
  return (
    <AntPopover className={classNames("ui-popover", className)} {...props}>
      {children}
    </AntPopover>
  );
};

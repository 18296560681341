import React, { useMemo, useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";

import { VacancyType } from "graphql/types/types";
import { IUser } from "services/store/modules/user/types";
import { Platform } from "common/const/platform";
import { Title } from "components/UI/Title";
import { useAppSelector } from "services/store/store";
import { FormInput } from "components/UI/Form/FormInput";
import { ZarplataState } from "./types";
import { getInitialState } from "./helpers";
import { PublicationStatusModal } from "../PublicationStatusModal";
import { Button } from "components/UI/Button";
import { Editor } from "components/UI/Editor";
import { VACANCY_CHARACTER_LIMITS } from "common/const/characterLimits";
import { FormSelect } from "components/UI/Form/FormSelect";
import { filterOption, getSelectOptions } from "components/UI/Select/helpers";
import { selectProfessions } from "services/store/modules/directory";
import { EMPTY_PUBLICATION, getContacts } from "../helpers";
import { FormSelectDadata } from "components/UI/Form/FormSelectDadata";

interface Props {
  vacancy: VacancyType;
  managerList: IUser[];
  publishItems: Platform[];
  changeTab: (key: Platform) => void;
}

export const ZarplataForm: React.FC<Props> = ({ vacancy, managerList }) => {
  const history = useHistory();

  const professionsOptions = useAppSelector(selectProfessions);
  const [loading, setLoading] = useState(false);
  const [publication, setPublication] = useState(EMPTY_PUBLICATION);

  const initialState = useMemo<ZarplataState>(
    () => getInitialState(vacancy),
    [vacancy]
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<ZarplataState>({
    // @ts-expect-error
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const managerId = useWatch({
    control,
    name: "managerId",
  });

  const { phone, email, fullName } = useMemo(
    () => getContacts(managerId, managerList),
    [managerId, managerList]
  );

  const handleClosePublishModal = () => {
    setPublication((prev) => ({ ...prev, message: null }));
    history.goBack();
  };

  useEffect(() => {
    reset(initialState);
  }, [initialState, reset]);

  return (
    <div className="avito-form">
      <Title type="h2">Опубликовать вакансию</Title>

      <Title type="h3" color="blue">
        Описание
      </Title>

      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormInput
            disabled={true}
            error={error?.message}
            label="Название обьявления"
            required={true}
            {...field}
          />
        )}
      />

      <Controller
        name="profession"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Профессия"
            showSearch
            placeholder="Укажите профессию"
            optionFilterProp="children"
            filterOption={filterOption}
            options={getSelectOptions(professionsOptions)}
            {...field}
          />
        )}
      />

      <Controller
        name="shortDescription"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Описание вакансии"
            count={VACANCY_CHARACTER_LIMITS.SHORT_DESCRIPTION}
            {...field}
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Обязанности"
            count={VACANCY_CHARACTER_LIMITS.DESCRIPTION}
            {...field}
          />
        )}
      />

      <Controller
        name="terms"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Условия"
            count={VACANCY_CHARACTER_LIMITS.TERMS}
            {...field}
          />
        )}
      />

      <Controller
        name="claims"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Требования"
            count={VACANCY_CHARACTER_LIMITS.CLAIMS}
            {...field}
          />
        )}
      />

      <Controller
        name="platformTags"
        control={control}
        render={({ field }) => (
          <Editor
            label="Ключевые слова"
            disabled={true}
            containerClassName="avito-form-desc"
            count={VACANCY_CHARACTER_LIMITS.PLATFORM_TAGS}
            {...field}
          />
        )}
      />

      <Title type="h3" color="blue">
        Условия для кандидата
      </Title>

      <Title type="h3" color="blue">
        Место работы
      </Title>

      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <FormSelectDadata label="Где будет работать сотрудник" {...field} />
        )}
      />

      <Title type="h3" color="blue">
        Контакты
      </Title>

      <Button
        loading={loading}
        disabled={Boolean(Object.values(errors).length)}
        onClick={handleSubmit(() => undefined)}
      >
        Опубликовать
      </Button>

      <PublicationStatusModal
        isVisible={Boolean(publication.message)}
        onClose={handleClosePublishModal}
        publication={publication}
        jobBoard="zarplata"
      />
    </div>
  );
};

import React from "react";
import { Form, SelectProps } from "antd";
import Select from "../../Select";
import classNames from "classnames";
import "./styles.scss";

interface FormSelectProps extends SelectProps {
  label: string;
  required?: boolean;
  className?: string;
  error?: string;
}

export const FormSelect: React.FC<FormSelectProps> = ({
  label,
  required,
  className,
  error,
  ...props
}) => {
  const formSelectClassName = classNames(
    "ui-form-select",
    { "ui-form-select--required": required },
    className
  );

  return (
    <Form.Item
      label={label}
      className={formSelectClassName}
      rules={[{ required, message: `Пожалуйста выберите ${label}` }]}
      required={required}
    >
      <Select status={error ? "error" : undefined} {...props} />
      {error && <div className="ui-form-select-error">{error}</div>}
    </Form.Item>
  );
};

import { gql } from "@apollo/client";

export const UPDATE_REPLY = gql`
  mutation updateReply($form: ReplyUpdateType!, $id: Int!) {
    updateReply(form: $form, id: $id) {
      ... on ReplyType {
        expiredTime
        citizenshipId
        managerId
        claimId
        source
        s2Id
        notCallCount
        employmentId
        createdAt
        updatedAt
        deletedAt
        id
        refusalId
        updatedBy
        expiredCallTime
        contacts {
          fullName
          email
          phone
        }
        vacancyId
        positionId
        ownerId
        companyId
        departmentId
        status
        address {
          value
          city
        }
        userContent
        userComment
        managerComment
        utm {
          source
          campaign
          content
          term
          medium
        }
        expiredTime
        citizenshipId
        chatLink
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

import React from "react";
import { IconType } from "common/commonTypes/types";

export const ChevronDown: React.FC<IconType> = ({
  width = 12,
  height = 12,
  color = "#A6ACB9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5L6 8L3 5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

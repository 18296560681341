import { gql } from "@apollo/client";

export const UPDATE_COMPANY = gql`
  mutation updateCompany($form: CompanyInputType!, $id: Int!) {
    updateCompany(form: $form, id: $id) {
      ... on CompanyType {
        id
        name
        phone
        email
        ownerId
        owner {
          email
          id
          city
          companyId
          departmentId
          phone
          firstName
          role
          secondName
        }
        createdAt
        updatedAt
        deletedAt
        logo
        description
        shortDescription
        blocked
        stats {
          vacancyCount
          replyCount
          userCount
        }
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

import "./styles.scss";
export const renderLetters = (
  letters: string | null,
  formFieldLetters: string
) => {
  const cellLetters = letters ? letters.split("") : [];

  return (
    <>
      {cellLetters.map((letter, i) => {
        const formLettersCell = formFieldLetters.split("");
        const formLetter = formLettersCell[i] ?? "";
        const isMatch =
          formLetter.toLocaleLowerCase() === letter.toLocaleLowerCase();
        const letterClass = isMatch
          ? "candidates-container-table-match-letter"
          : "candidates-container-default-letter";

        return (
          <span key={String(i)} className={letterClass}>
            {letter}
          </span>
        );
      })}
    </>
  );
};

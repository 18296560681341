import { useEffect } from "react";
import { Spin } from "antd";
import styled from "styled-components";

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Auth = () => {
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    if (apiUrl) {
      // window.location.replace(`${apiUrl}/auth/login?state=http://rabotut.local:3000/vacancies`);
      const redirectUrl = `${apiUrl}/auth/login?state=${process.env.REACT_APP_APP_URL}/authorizer`;
      window.location.replace(redirectUrl);
    }
  });

  return (
    <Block>
      <Spin />
    </Block>
  );
};

export default Auth;

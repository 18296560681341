import { createSelector } from "reselect";

import { RootState } from "services/store/rootReducer";

export const selectCandidateRoot = (state: RootState) => state.candidate;

export const selectCandidate = createSelector(
  selectCandidateRoot,
  (state) => state.value
);
export const selectCandidateData = createSelector(
  selectCandidate,
  (candidate) => candidate.data
);
export const selectCandidateLoading = createSelector(
  selectCandidate,
  (candidate) => candidate.loading
);
export const selectCandidateError = createSelector(
  selectCandidate,
  (candidate) => candidate.error
);

export const selectCandidateHistory = createSelector(
  selectCandidateRoot,
  (candidate) => candidate.history.data
);

export const selectDictionaries = createSelector(
  selectCandidateRoot,
  (candidate) => candidate.dictionaries
);

export const selectSources = createSelector(
  selectDictionaries,
  (dictionaries) => dictionaries.data?.sources ?? []
);

export const selectCandidateStatuses = createSelector(
  selectDictionaries,
  (state) => state.data?.candidateStatuses ?? []
);

export const selectPositions = createSelector(
  selectDictionaries,
  (state) => state.data?.positions ?? []
);

export const selectPositionNameById = createSelector(
  selectPositions,
  (positions) => (positionId: number) => {
    return positions.find(({ id }) => id === positionId)?.name ?? "";
  }
);

export const selectSourcesNameByKey = createSelector(
  selectSources,
  (selectSources) => (sourceKey: string) => {
    return selectSources.find(({ key }) => key === sourceKey)?.name ?? "";
  }
);

export const selectCandidateMainPhone = createSelector(
  selectCandidateData,
  (candidate) =>
    candidate?.contacts && Array.isArray(candidate.contacts)
      ? candidate.contacts.find((contact) => contact.isMain) ??
        candidate.contacts[0]
      : null
);

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GeoJSON: any;
  Time: any;
};

/** Address */
export type AddressInputType = {
  /** Район в регионе */
  area?: InputMaybe<Scalars["String"]>;
  /** Тип района в регионе */
  areaType?: InputMaybe<Scalars["String"]>;
  /** Корпус */
  block?: InputMaybe<Scalars["String"]>;
  /** Тип корпуса */
  blockType?: InputMaybe<Scalars["String"]>;
  /** Название города, например: Москва, Котлас, Благовещенск */
  city?: InputMaybe<Scalars["String"]>;
  /** Тип города, например: г */
  cityType?: InputMaybe<Scalars["String"]>;
  /** Подъезд */
  entrance?: InputMaybe<Scalars["String"]>;
  /** Номер дома */
  house?: InputMaybe<Scalars["String"]>;
  /** Тип дома */
  houseType?: InputMaybe<Scalars["String"]>;
  /** Координаты, Latitude, например: 56.128101, обязательны для отображения на карте */
  lat?: InputMaybe<Scalars["Float"]>;
  /** Координаты, Longitude, например: 40.40834, обязательны для отображения на карте */
  lng?: InputMaybe<Scalars["Float"]>;
  /** Регион, например: Алтайский, Амурская, Башкортостан */
  region?: InputMaybe<Scalars["String"]>;
  /** Тип региона, например: край, обл, респ */
  regionType?: InputMaybe<Scalars["String"]>;
  /** Населенный пункт */
  settlement?: InputMaybe<Scalars["String"]>;
  /** Тип населенного пункта */
  settlementType?: InputMaybe<Scalars["String"]>;
  /** Улица */
  street?: InputMaybe<Scalars["String"]>;
  /** Тип улицы */
  streetType?: InputMaybe<Scalars["String"]>;
  /** Полный адрес, например: г Новосибирск, ул Державина, д 47; Московская обл, г Домодедово, село Вельяминово; Московская обл, г Люберцы, ул Юбилейная, д 2А */
  value?: InputMaybe<Scalars["String"]>;
};

/** Address */
export type AddressType = {
  __typename?: "AddressType";
  /** Район в регионе */
  area?: Maybe<Scalars["String"]>;
  /** Тип района в регионе */
  areaType?: Maybe<Scalars["String"]>;
  /** Корпус */
  block?: Maybe<Scalars["String"]>;
  /** Тип корпуса */
  blockType?: Maybe<Scalars["String"]>;
  /** Название города, например: Москва, Котлас, Благовещенск */
  city?: Maybe<Scalars["String"]>;
  /** Тип города, например: г */
  cityType?: Maybe<Scalars["String"]>;
  /** Подъезд */
  entrance?: Maybe<Scalars["String"]>;
  /** Номер дома */
  house?: Maybe<Scalars["String"]>;
  /** Тип дома */
  houseType?: Maybe<Scalars["String"]>;
  /** Координаты, Latitude, например: 56.128101, обязательны для отображения на карте */
  lat?: Maybe<Scalars["Float"]>;
  /** Координаты, Longitude, например: 40.40834, обязательны для отображения на карте */
  lng?: Maybe<Scalars["Float"]>;
  /** Регион, например: Алтайский, Амурская, Башкортостан */
  region?: Maybe<Scalars["String"]>;
  /** Тип региона, например: край, обл, респ */
  regionType?: Maybe<Scalars["String"]>;
  /** Населенный пункт */
  settlement?: Maybe<Scalars["String"]>;
  /** Тип населенного пункта */
  settlementType?: Maybe<Scalars["String"]>;
  /** Улица */
  street?: Maybe<Scalars["String"]>;
  /** Тип улицы */
  streetType?: Maybe<Scalars["String"]>;
  /** Полный адрес, например: г Новосибирск, ул Державина, д 47; Московская обл, г Домодедово, село Вельяминово; Московская обл, г Люберцы, ул Юбилейная, д 2А */
  value?: Maybe<Scalars["String"]>;
};

/** Age */
export type AgeInputType = {
  /** От, по умолчанию 18 */
  from?: Scalars["Int"];
  /** До, по умолчанию 65 */
  to?: Scalars["Int"];
};

/** Age */
export type AgeType = {
  __typename?: "AgeType";
  /** От, по умолчанию 18 */
  from: Scalars["Int"];
  /** До, по умолчанию 65 */
  to: Scalars["Int"];
};

/** Areas Dictionary */
export type AreasType = {
  __typename?: "AreasType";
  id: Scalars["String"];
  /** Уровень в дереве */
  level?: Maybe<Scalars["Int"]>;
  /** Название */
  name: Scalars["String"];
  /** ID родительского элемента в дереве */
  parentId: Scalars["String"];
};

/** Avito message */
export type AvitoMessageType = {
  __typename?: "AvitoMessageType";
  content?: Maybe<Scalars["String"]>;
  status?: Maybe<AvitoStatus>;
  userId?: Maybe<Scalars["Int"]>;
  vacancyId: Scalars["Int"];
};

/**
 *
 *     Статус вакансии avito
 *
 *     SUCCESS = 'Успешно'
 *     ERROR = 'Ошибка'
 *
 */
export enum AvitoStatus {
  Error = "ERROR",
  Success = "SUCCESS",
}

/** Input type Banners */
export type BannerInputType = {
  announcement?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  href?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  pageId?: InputMaybe<Scalars["String"]>;
  photoUrl?: InputMaybe<Scalars["String"]>;
  slot?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** CallCenterInfo */
export type CallCenterInfoType = {
  __typename?: "CallCenterInfoType";
  /** Доп. мотивация */
  additionalMotivation?: Maybe<Scalars["String"]>;
  /** Адреса мест работ */
  addresses?: Maybe<Scalars["String"]>;
  /** Компенсация за оформление ЛМК */
  compensationMedicalBook?: Maybe<Scalars["String"]>;
  /** Описание компании */
  description?: Maybe<Scalars["String"]>;
  /** Вод. права (категории) */
  driverLicense?: Maybe<Array<Scalars["String"]>>;
  /** Опыт работы */
  experience?: Maybe<Scalars["String"]>;
  /** Оплата проезда */
  farePayment?: Maybe<Scalars["String"]>;
  /** Категория товара */
  goodsCategory?: Maybe<Scalars["String"]>;
  /** Мобильная связь (оплата) */
  mobilePayment?: Maybe<Scalars["Boolean"]>;
  /** Выплаты по АПД */
  payoutsRfp?: Maybe<Scalars["String"]>;
  /** Личный авто */
  personalCar?: Maybe<Scalars["Boolean"]>;
  /** Наличие КПК */
  presenceOfPda?: Maybe<Scalars["String"]>;
  /** Название магазина, торговой точки */
  project?: Maybe<Scalars["String"]>;
  /** Требование по оформлению иностранных граждан */
  registrationOfForeignCitizens?: Maybe<Scalars["String"]>;
  /** Виды оформления */
  typesOfJobRegistration?: Maybe<Scalars["String"]>;
  /** Вакцинация */
  vaccination?: Maybe<Scalars["String"]>;
};

/** Comments */
export type CandidateCommentType = {
  __typename?: "CandidateCommentType";
  /** Комментарий менеджера */
  comment?: Maybe<Scalars["String"]>;
  /** Дата создания комментария */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Менеджер, оставивший комментарий */
  manager?: Maybe<ManagerType>;
};

/** Candidate list available filters */
export type CandidateFilterType = {
  /** Бейдж */
  badge?: InputMaybe<Scalars["String"]>;
  /** Город кандидата */
  city?: InputMaybe<Scalars["String"]>;
  /** candidate employment key of dict_employments */
  employment?: InputMaybe<Scalars["String"]>;
  /** Фамилия */
  firstName?: InputMaybe<Scalars["String"]>;
  hasChat?: InputMaybe<Scalars["Boolean"]>;
  hasHistory?: InputMaybe<Scalars["Boolean"]>;
  /** ID кандидата */
  id?: InputMaybe<Scalars["String"]>;
  inBlacklist?: InputMaybe<Scalars["Boolean"]>;
  /** owner id */
  owner?: InputMaybe<Scalars["Int"]>;
  /** Отчество */
  patronymic?: InputMaybe<Scalars["String"]>;
  /** Телефон в формате (+7 999 999-99-99) */
  phone?: InputMaybe<Scalars["String"]>;
  /** key of dictionaries -> positions */
  position?: InputMaybe<Scalars["String"]>;
  russianCitizenship?: InputMaybe<Scalars["Boolean"]>;
  /** search query by name */
  search?: InputMaybe<Scalars["String"]>;
  /** Имя */
  secondName?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Source>;
  /** key of dictionaries -> replyStatuses */
  status?: InputMaybe<CandidateStatus>;
};

/** Input type candidate */
export type CandidateInputType = {
  /** Фактический адрес проживания сотрудника */
  actualAddress?: InputMaybe<AddressInputType>;
  /** Возраст */
  age?: InputMaybe<Scalars["Int"]>;
  /** Бейдж,Идентификационный номер бейджа сотрудника */
  badge?: InputMaybe<Scalars["String"]>;
  /** Расчетная дата увольнения */
  calcDismissDate?: InputMaybe<Scalars["Date"]>;
  /** Адрес для отправки карты зарплатного проекта */
  cardDeliveryAddress?: InputMaybe<AddressInputType>;
  /** Гражданство, id из словаря dict_citizenship, заполняется вручную менеджером */
  citizenshipId?: InputMaybe<Scalars["Int"]>;
  /** Город */
  city?: InputMaybe<Scalars["String"]>;
  /** ID компании */
  companyId?: InputMaybe<Scalars["Int"]>;
  /** Список контактов */
  contacts?: InputMaybe<Array<ContactForm>>;
  /** Дата рождения сотрудника */
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  /** ID департамента */
  departmentId?: InputMaybe<Scalars["Int"]>;
  /** Не выходил на смену */
  didntWork?: InputMaybe<Scalars["Boolean"]>;
  /** Дата увольнения сотрудника */
  dismissDate?: InputMaybe<Scalars["Date"]>;
  /** Причина увольнения */
  dismissReason?: InputMaybe<Scalars["String"]>;
  /** Категории прав кандидата,поля id из словаря driver_license */
  driverLicense?: InputMaybe<Array<Scalars["String"]>>;
  /** Email */
  email?: Scalars["String"];
  /** Общий стаж работника в месяцах */
  experienceFull?: InputMaybe<Scalars["Int"]>;
  /** Стаж в компании в месяцах */
  experienceInCompany?: InputMaybe<Scalars["Int"]>;
  /** Имя */
  firstName?: InputMaybe<Scalars["String"]>;
  /** Полное ФИО */
  fullName?: Scalars["String"];
  /** Наличие чата */
  hasChat?: InputMaybe<Scalars["Boolean"]>;
  /** Наличие автомобиля */
  haveCar?: InputMaybe<Scalars["Boolean"]>;
  /** Сумма тарифа за день по последней отработанной смене */
  lastShiftRate?: InputMaybe<Scalars["Decimal"]>;
  /** ID актуального менеджера для кандидата */
  managerId?: InputMaybe<Scalars["Int"]>;
  metro?: InputMaybe<Array<MetroItemInputType>>;
  /** ID первого менеджера, работавшего с кандидатом */
  ownerId?: InputMaybe<Scalars["Int"]>;
  /** Отчество */
  patronymic?: InputMaybe<Scalars["String"]>;
  /** Занимаемая должность, id из словаря position */
  positionId?: InputMaybe<Scalars["Int"]>;
  /** OLD LOGIC!!! Массив должностей, на которые откликался кандидат */
  positions?: InputMaybe<Array<CandidatePositionItemInput>>;
  /** Для импорта из LT */
  positionsFromLt?: InputMaybe<Array<Scalars["String"]>>;
  /** Массив должностей, на которые откликался кандидат */
  positionsNew?: InputMaybe<Array<Scalars["Int"]>>;
  /** Последняя дата приёма */
  receptionLastDate?: InputMaybe<Scalars["Date"]>;
  /** URL резюме, если есть */
  resumeUrl?: InputMaybe<Scalars["String"]>;
  /** Гражданство РФ */
  russianCitizenship?: InputMaybe<Scalars["Boolean"]>;
  /** Зарплатные ожидания кандидата (за смену) */
  salaryExpectation?: InputMaybe<Scalars["Int"]>;
  /** Фамилия */
  secondName?: InputMaybe<Scalars["String"]>;
  /** Пол сотрудника */
  sex?: InputMaybe<CandidateSex>;
  /** Максимальная дата смены из всех смен сотрудника */
  shiftMaxDate?: InputMaybe<Scalars["Date"]>;
  /** Минимальная дата смены из всех смен сотрудника */
  shiftMinDate?: InputMaybe<Scalars["Date"]>;
  /** Источник */
  source?: InputMaybe<Source>;
  /** Статус */
  status?: InputMaybe<CandidateStatus>;
  /** Название таймзоны кандидата(UTC+2 ---> UTC+12) */
  timezone?: InputMaybe<Scalars["String"]>;
};

export type CandidateListResponse = CandidateListSuccess | Error;

export type CandidateListSuccess = {
  __typename?: "CandidateListSuccess";
  data: Array<CandidateType>;
  meta: PageInfoType;
};

/** Candidate position item input */
export type CandidatePositionItemInput = {
  key: Scalars["String"];
};

/** Candidate position item */
export type CandidatePositionItemType = {
  __typename?: "CandidatePositionItemType";
  key: Scalars["String"];
};

/** A single position */
export type CandidatePositionType = {
  __typename?: "CandidatePositionType";
  /** Date add position on candidate */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** id */
  id?: Maybe<Scalars["String"]>;
  /** position */
  position?: Maybe<PositionsForm>;
};

/**
 *
 *     Пол кандидата
 *
 *     MALE = 'Мужской'
 *     FEMALE = 'Женский'
 *
 */
export enum CandidateSex {
  Female = "FEMALE",
  Male = "MALE",
}

/**
 *
 *     Статус кандидата
 *
 *     NEW = "Новый"
 *     NOT_THROUGH = "Не дозвонились"
 *     CALLBACK = "Нужно перезвонить"
 *     DOESNT_GET_TOUCH = "Не выходит на связь"
 *     CANDIDATE = "Кандидат"
 *     DENIED = "Отказано"
 *     REFUSAL_CANDIDATE = "Отказ кандидата"
 *     NOT_LOOKING_FOR_JOB = "Не в поиске работы"
 *     WITH_HIGHER_POSITION = "Кандидат выше по должности"
 *     SELF_SEARCH = "Самостоятельный поиск"
 *
 *
 *
 */
export enum CandidateStatus {
  Callback = "CALLBACK",
  Candidate = "CANDIDATE",
  Denied = "DENIED",
  DoesntGetTouch = "DOESNT_GET_TOUCH",
  New = "NEW",
  NotLookingForJob = "NOT_LOOKING_FOR_JOB",
  NotThrough = "NOT_THROUGH",
  RefusalCandidate = "REFUSAL_CANDIDATE",
  SelfSearch = "SELF_SEARCH",
  WithHigherPosition = "WITH_HIGHER_POSITION",
}

/** Candidate Statuses Dictionary */
export type CandidateStatusesType = {
  __typename?: "CandidateStatusesType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** Candidate list available filters */
export type CandidateSuitableFilterType = {
  /** ID of vacancy */
  vacancyId?: InputMaybe<Scalars["String"]>;
};

/** A single candidate */
export type CandidateType = {
  __typename?: "CandidateType";
  /** Дата, в которую кандидат был принят на вакансию */
  acceptedAt?: Maybe<Scalars["DateTime"]>;
  /** Фактический адрес проживания сотрудника */
  actualAddress?: Maybe<AddressType>;
  /** Возраст */
  age?: Maybe<Scalars["Int"]>;
  /** Бейдж,Идентификационный номер бейджа сотрудника */
  badge?: Maybe<Scalars["String"]>;
  /** Расчетная дата увольнения */
  calcDismissDate?: Maybe<Scalars["Date"]>;
  /** Совершался ли кандидату звонок */
  callCompleted?: Maybe<Scalars["Boolean"]>;
  /** Адрес для отправки карты зарплатного проекта */
  cardDeliveryAddress?: Maybe<AddressType>;
  /** Гражданство, id из словаря dict_citizenship, заполняется вручную менеджером */
  citizenshipId?: Maybe<Scalars["Int"]>;
  /** Город */
  city?: Maybe<Scalars["String"]>;
  /** Комментарии менеджеров */
  comments?: Maybe<Array<CandidateCommentType>>;
  /** ID компании */
  companyId?: Maybe<Scalars["Int"]>;
  /** Список контактов */
  contacts?: Maybe<Array<ContactType>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Дата рождения сотрудника */
  dateOfBirth?: Maybe<Scalars["Date"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  /** ID департамента */
  departmentId?: Maybe<Scalars["Int"]>;
  /** Не выходил на смену */
  didntWork?: Maybe<Scalars["Boolean"]>;
  /** Дата увольнения сотрудника */
  dismissDate?: Maybe<Scalars["Date"]>;
  /** Причина увольнения */
  dismissReason?: Maybe<Scalars["String"]>;
  /** Категории прав кандидата,поля id из словаря driver_license */
  driverLicense?: Maybe<Array<Scalars["String"]>>;
  /** Email */
  email: Scalars["String"];
  /** Общий стаж работника в месяцах */
  experienceFull?: Maybe<Scalars["Int"]>;
  /** Стаж в компании в месяцах */
  experienceInCompany?: Maybe<Scalars["Int"]>;
  /** Имя */
  firstName?: Maybe<Scalars["String"]>;
  /** Полное ФИО */
  fullName: Scalars["String"];
  /** Наличие чата */
  hasChat?: Maybe<Scalars["Boolean"]>;
  /** Наличие истории у кандидата */
  hasHistory?: Maybe<Scalars["Boolean"]>;
  /** Наличие автомобиля */
  haveCar?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  /** Кандидат в черном списке, да/нет */
  inBlacklist?: Maybe<Scalars["Boolean"]>;
  /** Спам,да/нет */
  isSpam?: Maybe<Scalars["Boolean"]>;
  /** Сумма тарифа за день по последней отработанной смене */
  lastShiftRate?: Maybe<Scalars["Decimal"]>;
  location?: Maybe<CoordsType>;
  /** Комментарий менеджера */
  managerComment?: Maybe<Scalars["String"]>;
  /** ID актуального менеджера для кандидата */
  managerId?: Maybe<Scalars["Int"]>;
  metro?: Maybe<Array<MetroItemType>>;
  /** ID первого менеджера, работавшего с кандидатом */
  ownerId?: Maybe<Scalars["Int"]>;
  /** Отчество */
  patronymic?: Maybe<Scalars["String"]>;
  /** Занимаемая должность, id из словаря position */
  positionId?: Maybe<Scalars["Int"]>;
  /** OLD LOGIC!!! Массив должностей, на которые откликался кандидат */
  positions?: Maybe<Array<CandidatePositionItemType>>;
  /** Массив должностей, на которые откликался кандидат */
  positionsNew?: Maybe<Array<CandidatePositionType>>;
  /** Последняя дата приёма */
  receptionLastDate?: Maybe<Scalars["Date"]>;
  /** Регион, указывается вручную менеджером */
  region?: Maybe<Scalars["String"]>;
  /** URL резюме, если есть */
  resumeUrl?: Maybe<Scalars["String"]>;
  /** Гражданство РФ */
  russianCitizenship?: Maybe<Scalars["Boolean"]>;
  /** Зарплатные ожидания кандидата (за смену) */
  salaryExpectation?: Maybe<Scalars["Int"]>;
  /** Фамилия */
  secondName?: Maybe<Scalars["String"]>;
  /** Пол сотрудника */
  sex?: Maybe<CandidateSex>;
  /** Максимальная дата смены из всех смен сотрудника */
  shiftMaxDate?: Maybe<Scalars["Date"]>;
  /** Минимальная дата смены из всех смен сотрудника */
  shiftMinDate?: Maybe<Scalars["Date"]>;
  /** Источник */
  source?: Maybe<Source>;
  /** Статус */
  status?: Maybe<CandidateStatus>;
  /** Название таймзоны кандидата(UTC+2 ---> UTC+12) */
  timezone?: Maybe<Scalars["String"]>;
  /** Время до вакансии на транспорте */
  transitDuration?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  /** ID из users. Последний кто обновлял кандидата */
  updatedBy?: Maybe<Scalars["Int"]>;
  /** Менеджер, последний вносивший изменения в кандидата */
  updater?: Maybe<ManagerType>;
  /** Количество подходящих вакансий по прямой */
  vacancyCount?: Maybe<Scalars["Int"]>;
  /** Время до вакансии пешком */
  walkingDuration?: Maybe<Scalars["Int"]>;
};

export type CandidateTypeResponse = CandidateType | Error;

/** Update type candidate */
export type CandidateUpdateType = {
  actualAddress: AddressInputType;
  /** Возраст */
  age?: InputMaybe<Scalars["Int"]>;
  /** Бейдж,Идентификационный номер бейджа сотрудника */
  badge?: InputMaybe<Scalars["String"]>;
  /** Расчетная дата увольнения */
  calcDismissDate?: InputMaybe<Scalars["Date"]>;
  /** Адрес для отправки карты зарплатного проекта */
  cardDeliveryAddress?: InputMaybe<AddressInputType>;
  /** Гражданство, id из словаря dict_citizenship, заполняется вручную менеджером */
  citizenshipId?: InputMaybe<Scalars["Int"]>;
  /** Город, указывается вручную менеджером */
  city?: InputMaybe<Scalars["String"]>;
  /** ID компании */
  companyId?: InputMaybe<Scalars["Int"]>;
  /** Список контактов */
  contacts?: InputMaybe<Array<ContactUpdateForm>>;
  /** Дата рождения сотрудника */
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  /** ID департамента */
  departmentId?: InputMaybe<Scalars["Int"]>;
  /** Не выходил на смену */
  didntWork?: InputMaybe<Scalars["Boolean"]>;
  /** Дата увольнения сотрудника */
  dismissDate?: InputMaybe<Scalars["Date"]>;
  /** Причина увольнения */
  dismissReason?: InputMaybe<Scalars["String"]>;
  /** Категории прав кандидата,поля id из словаря driver_license */
  driverLicense?: InputMaybe<Array<Scalars["String"]>>;
  /** Email */
  email?: InputMaybe<Scalars["String"]>;
  /** Общий стаж работника в месяцах */
  experienceFull?: InputMaybe<Scalars["Int"]>;
  /** Стаж в компании в месяцах */
  experienceInCompany?: InputMaybe<Scalars["Int"]>;
  /** Имя */
  firstName?: InputMaybe<Scalars["String"]>;
  /** Полное ФИО */
  fullName: Scalars["String"];
  /** Наличие чата */
  hasChat?: InputMaybe<Scalars["Boolean"]>;
  /** Наличие автомобиля */
  haveCar?: InputMaybe<Scalars["Boolean"]>;
  /** Кандидат в черном списке, да/нет */
  inBlacklist?: InputMaybe<Scalars["Boolean"]>;
  /** Спам,да/нет */
  isSpam?: InputMaybe<Scalars["Boolean"]>;
  /** Сумма тарифа за день по последней отработанной смене */
  lastShiftRate?: InputMaybe<Scalars["Decimal"]>;
  /** Комментарий менеджера */
  managerComment?: InputMaybe<Scalars["String"]>;
  /** ID актуального менеджера для кандидата */
  managerId?: InputMaybe<Scalars["Int"]>;
  metro?: InputMaybe<Array<MetroItemInputType>>;
  /** ID первого менеджера, работавшего с кандидатом */
  ownerId?: InputMaybe<Scalars["Int"]>;
  /** Отчество */
  patronymic?: InputMaybe<Scalars["String"]>;
  /** Занимаемая должность, id из словаря position */
  positionId?: InputMaybe<Scalars["Int"]>;
  /** Массив должностей, на которые откликался кандидат */
  positions?: InputMaybe<Array<CandidatePositionItemInput>>;
  /** Для импорта из LT */
  positionsFromLt?: InputMaybe<Array<Scalars["String"]>>;
  /** Массив должностей, на которые откликался кандидат */
  positionsNew?: InputMaybe<Array<Scalars["Int"]>>;
  /** Последняя дата приёма */
  receptionLastDate?: InputMaybe<Scalars["Date"]>;
  /** Регион, указывается вручную менеджером */
  region?: InputMaybe<Scalars["String"]>;
  /** URL резюме, если есть */
  resumeUrl?: InputMaybe<Scalars["String"]>;
  /** Гражданство РФ */
  russianCitizenship?: InputMaybe<Scalars["Boolean"]>;
  /** Зарплатные ожидания кандидата (за смену) */
  salaryExpectation?: InputMaybe<Scalars["Int"]>;
  /** Фамилия */
  secondName?: InputMaybe<Scalars["String"]>;
  /** Пол сотрудника */
  sex?: InputMaybe<CandidateSex>;
  /** Максимальная дата смены из всех смен сотрудника */
  shiftMaxDate?: InputMaybe<Scalars["Date"]>;
  /** Минимальная дата смены из всех смен сотрудника */
  shiftMinDate?: InputMaybe<Scalars["Date"]>;
  /** Источник */
  source?: InputMaybe<Source>;
  /** Статус */
  status?: CandidateStatus;
  /** Название таймзоны кандидата(UTC+2 ---> UTC+12) */
  timezone?: InputMaybe<Scalars["String"]>;
};

/** Citizenship Dictionary */
export type CitizenshipType = {
  __typename?: "CitizenshipType";
  id: Scalars["Int"];
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** Словарь городов */
export type CityInputType = {
  /** Район */
  areaWithType?: InputMaybe<Scalars["String"]>;
  /** Название города */
  city?: InputMaybe<Scalars["String"]>;
  /** fias_id города */
  cityFiasId?: InputMaybe<Scalars["String"]>;
  /** Тип населенного пункта */
  cityType?: InputMaybe<Scalars["String"]>;
  /** Страна */
  country?: InputMaybe<Scalars["String"]>;
  /** Поддомен */
  domain?: InputMaybe<Scalars["String"]>;
  /** Федеральный округ */
  federalDistrict?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  /** Ключ */
  key?: InputMaybe<Scalars["String"]>;
  /** Координаты */
  location?: InputMaybe<CoordsInputType>;
  /** Название региона */
  region?: InputMaybe<Scalars["String"]>;
  /** Тип региона */
  regionType?: InputMaybe<Scalars["String"]>;
  /** Название региона с указанием типа */
  regionWithType?: InputMaybe<Scalars["String"]>;
  /** Часовой пояс */
  timezone?: InputMaybe<Scalars["String"]>;
};

export type CityResponse = CityType | Error;

/** Словарь городов */
export type CityType = {
  __typename?: "CityType";
  /** Район */
  areaWithType?: Maybe<Scalars["String"]>;
  /** Название города */
  city?: Maybe<Scalars["String"]>;
  /** fias_id города */
  cityFiasId?: Maybe<Scalars["String"]>;
  /** Тип населенного пункта */
  cityType?: Maybe<Scalars["String"]>;
  /** Страна */
  country?: Maybe<Scalars["String"]>;
  /** Поддомен */
  domain?: Maybe<Scalars["String"]>;
  /** Федеральный округ */
  federalDistrict?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  /** Ключ */
  key?: Maybe<Scalars["String"]>;
  /** Координаты */
  location?: Maybe<CoordsType>;
  /** Название региона */
  region?: Maybe<Scalars["String"]>;
  /** Тип региона */
  regionType?: Maybe<Scalars["String"]>;
  /** Название региона с указанием типа */
  regionWithType?: Maybe<Scalars["String"]>;
  /** Часовой пояс */
  timezone?: Maybe<Scalars["String"]>;
};

/** City with fias_id */
export type CityWithFiasType = {
  __typename?: "CityWithFiasType";
  city: Scalars["String"];
  fiasId: Scalars["String"];
};

/** City With Vacancy Dictionary */
export type CityWithVacancyType = {
  __typename?: "CityWithVacancyType";
  /** Название */
  city?: Maybe<Scalars["String"]>;
  /** fias_id города */
  cityFiasId?: Maybe<Scalars["String"]>;
  /** Количество вакансий в городе */
  count?: Maybe<Scalars["Int"]>;
  /** Поддомен */
  domain?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  /** Название региона с указанием типа */
  regionWithType?: Maybe<Scalars["String"]>;
};

/** Claim Categories Dictionary */
export type ClaimCategoriesType = {
  __typename?: "ClaimCategoriesType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/**
 *
 *     Категория заявки
 *
 *     EDUCATION = "Обучение"
 *     WORK_WITH_US = "Работа у нас"
 *     SEARCH_WORK = "Поиск работы"
 *     PARTNERSHIP = "Партнерство"
 *     RESERVE = "Резерв"
 *     OTHER = "Другое"
 *
 */
export enum ClaimCategory {
  Education = "EDUCATION",
  Other = "OTHER",
  Partnership = "PARTNERSHIP",
  Reserve = "RESERVE",
  SearchWork = "SEARCH_WORK",
  WorkWithUs = "WORK_WITH_US",
}

/** Claim list available filters */
export type ClaimFilterType = {
  /** key of dictionaries -> claimCategory */
  category?: InputMaybe<ClaimCategory>;
  city?: InputMaybe<Scalars["String"]>;
  department?: InputMaybe<Scalars["Int"]>;
  /** period created */
  period?: InputMaybe<PeriodType>;
  /** search query by name */
  search?: InputMaybe<Scalars["String"]>;
  /** key of dictionaries -> source */
  source?: InputMaybe<Source>;
  /** key of dictionaries -> claimStatuses */
  status?: InputMaybe<ClaimStatus>;
};

/** Input type for create claim */
export type ClaimInputType = {
  address?: InputMaybe<AddressInputType>;
  category?: ClaimCategory;
  chatLink?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  contacts: ContactsInputType;
  departmentId?: InputMaybe<Scalars["Int"]>;
  extra?: InputMaybe<ExtraInputType>;
  firstName?: InputMaybe<Scalars["String"]>;
  managerComment?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["Int"]>;
  ownerId?: InputMaybe<Scalars["Int"]>;
  patronymic?: InputMaybe<Scalars["String"]>;
  position: Scalars["String"];
  salary?: InputMaybe<Scalars["Int"]>;
  secondName?: InputMaybe<Scalars["String"]>;
  /** The mail to which the decision on the application was sent */
  sendTo?: InputMaybe<Scalars["String"]>;
  source: Source;
  /** URL source */
  sourceUrl?: InputMaybe<Scalars["String"]>;
  /** Claim status, default - new */
  status?: ClaimStatus;
  userComment?: InputMaybe<Scalars["String"]>;
  userContent?: InputMaybe<Scalars["String"]>;
  userWish?: InputMaybe<Scalars["String"]>;
  utm?: InputMaybe<UtmInputType>;
};

export type ClaimListResponse = ClaimListSuccess | Error;

export type ClaimListSuccess = {
  __typename?: "ClaimListSuccess";
  data: Array<ClaimType>;
  meta: PageInfoType;
};

/**
 *
 *     Статус заявки
 *
 *     NEW = "Новый"
 *     NOT_THROUGH = "Не дозвонились"
 *     CALLBACK = "Нужно перезвонить"
 *     DOESNT_GET_TOUCH = "Не выходит на связь"
 *     DENIED = "Отказано"
 *     REFUSAL_CANDIDATE = "Отказ кандидата"
 *     TRANSFERRED_TO_BUSINESS = "Передано в бизнес"
 *     TRANSFERRED_TO_REPLIES = "Передано в отклики"
 *     OTHER = "Другое"
 *     ON_DISTRIBUTION = "На распределении"
 *
 */
export enum ClaimStatus {
  Callback = "CALLBACK",
  Denied = "DENIED",
  DoesntGetTouch = "DOESNT_GET_TOUCH",
  New = "NEW",
  NotThrough = "NOT_THROUGH",
  OnDistribution = "ON_DISTRIBUTION",
  Other = "OTHER",
  RefusalCandidate = "REFUSAL_CANDIDATE",
  TransferredToBusiness = "TRANSFERRED_TO_BUSINESS",
  TransferredToReplies = "TRANSFERRED_TO_REPLIES",
}

/** Claim Statuses Dictionary */
export type ClaimStatusesType = {
  __typename?: "ClaimStatusesType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** A single claim */
export type ClaimType = {
  __typename?: "ClaimType";
  address?: Maybe<AddressType>;
  candidate?: Maybe<CandidateType>;
  /** Candidate id */
  candidateId?: Maybe<Scalars["String"]>;
  category: ClaimCategory;
  chatLink?: Maybe<Scalars["String"]>;
  /** Company id */
  companyId: Scalars["Int"];
  contacts: ContactsType;
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  /** Department id */
  departmentId: Scalars["Int"];
  expiredCallTime?: Maybe<Scalars["DateTime"]>;
  extra?: Maybe<ExtraType>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  /** Is claim in work */
  inWork?: Maybe<Scalars["Boolean"]>;
  isSent: Scalars["Boolean"];
  manager?: Maybe<ManagerType>;
  managerComment?: Maybe<Scalars["String"]>;
  /** Manager who updated claim */
  managerId?: Maybe<Scalars["Int"]>;
  owner?: Maybe<OwnerType>;
  /** First manager who updated claim */
  ownerId?: Maybe<Scalars["Int"]>;
  patronymic?: Maybe<Scalars["String"]>;
  position: Scalars["String"];
  /** Refusal id */
  refusalId?: Maybe<Scalars["Int"]>;
  /** Refusal reason */
  refusalText?: Maybe<Scalars["String"]>;
  /** Reply id */
  replyId?: Maybe<Scalars["Int"]>;
  salary?: Maybe<Scalars["Int"]>;
  secondName?: Maybe<Scalars["String"]>;
  /** The mail to which the decision on the application was sent */
  sendTo?: Maybe<Scalars["String"]>;
  source: Source;
  /** URL source */
  sourceUrl?: Maybe<Scalars["String"]>;
  status: ClaimStatus;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  /** Last manager who updated the claim */
  updatedBy?: Maybe<Scalars["Int"]>;
  userComment?: Maybe<Scalars["String"]>;
  userContent?: Maybe<Scalars["String"]>;
  userWish?: Maybe<Scalars["String"]>;
  utm?: Maybe<UtmType>;
};

export type ClaimTypeResponse = ClaimType | Error;

/** Input type for update claim */
export type ClaimUpdateType = {
  /** Applicant's address  */
  address?: InputMaybe<AddressInputType>;
  /** Claim category */
  category?: InputMaybe<ClaimCategory>;
  /** Applicant's contacts  */
  contacts?: InputMaybe<ContactsInputType>;
  /** Has the message been sent */
  isSent?: InputMaybe<Scalars["Boolean"]>;
  /** Manager comment */
  managerComment?: InputMaybe<Scalars["String"]>;
  /** Manager who updated the claim */
  managerId?: InputMaybe<Scalars["Int"]>;
  /** Manager who first updated the claim */
  ownerId?: InputMaybe<Scalars["Int"]>;
  /** Desired position id */
  position?: InputMaybe<Scalars["Int"]>;
  /** Refusal reason */
  refusalId?: InputMaybe<Scalars["Int"]>;
  /** Refusal reason */
  refusalText?: InputMaybe<Scalars["String"]>;
  /** The mail to which the decision on the application was sent */
  sendTo?: InputMaybe<Scalars["String"]>;
  /** Claim status */
  status?: ClaimStatus;
  /** Last manager who updated the claim */
  updatedBy?: InputMaybe<Scalars["Int"]>;
};

/** A company form. */
export type CompanyInputType = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["Int"]>;
  phone?: InputMaybe<Scalars["String"]>;
  shortDescription?: InputMaybe<Scalars["String"]>;
};

export type CompanyListResponse = CompanyListSuccess | Error;

export type CompanyListSuccess = {
  __typename?: "CompanyListSuccess";
  data: Array<CompanyType>;
};

/** A company settings form. */
export type CompanySettingsInputType = {
  /** Время окончания звонков */
  callTimeEnd?: InputMaybe<Scalars["Time"]>;
  /** Разрешенное время начала звонков */
  callTimeStart?: InputMaybe<Scalars["Time"]>;
  /** Активация просрочки откликов */
  enabledExpire?: Scalars["Boolean"];
  /** Активация функционала "Нужно перезвонить" */
  enabledRecall?: Scalars["Boolean"];
  expireDepartmentId?: InputMaybe<Scalars["Int"]>;
  expireManagerId?: InputMaybe<Scalars["Int"]>;
  /** Количество часов, через которое отклик уходит в просрок */
  expireValue?: InputMaybe<Scalars["Int"]>;
  /** Количество часов, через которое проставляется статус "Нужно перезвонить" */
  recallValue?: InputMaybe<Scalars["Int"]>;
  /** Конец рабочего дня */
  workHoursEnd?: InputMaybe<Scalars["String"]>;
  /** Конец рабочего дня в пятницу */
  workHoursEndFriday?: InputMaybe<Scalars["String"]>;
  /** Начало рабочего дня */
  workHoursStart?: InputMaybe<Scalars["String"]>;
  /** Начало рабочего дня в пятницу */
  workHoursStartFriday?: InputMaybe<Scalars["String"]>;
};

/** A company settings. */
export type CompanySettingsType = {
  __typename?: "CompanySettingsType";
  /** Время окончания звонков */
  callTimeEnd?: Maybe<Scalars["Time"]>;
  /** Разрешенное время начала звонков */
  callTimeStart?: Maybe<Scalars["Time"]>;
  companyId: Scalars["Int"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  /** Активация просрочки откликов */
  enabledExpire: Scalars["Boolean"];
  /** Активация функционала "Нужно перезвонить" */
  enabledRecall: Scalars["Boolean"];
  expireDepartmentId?: Maybe<Scalars["Int"]>;
  expireManagerId?: Maybe<Scalars["Int"]>;
  /** Количество часов, через которое отклик уходит в просрок */
  expireValue?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  /** Количество часов, через которое проставляется статус "Нужно перезвонить" */
  recallValue?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  /** Конец рабочего дня */
  workHoursEnd?: Maybe<Scalars["String"]>;
  /** Конец рабочего дня в пятницу */
  workHoursEndFriday?: Maybe<Scalars["String"]>;
  /** Начало рабочего дня */
  workHoursStart?: Maybe<Scalars["String"]>;
  /** Начало рабочего дня в пятницу */
  workHoursStartFriday?: Maybe<Scalars["String"]>;
};

export type CompanySettingsTypeResponse = CompanySettingsType | Error;

/** A single company. */
export type CompanyType = {
  __typename?: "CompanyType";
  blocked?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  id: Scalars["Int"];
  logo?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  owner: OwnerType;
  ownerId: Scalars["Int"];
  phone: Scalars["String"];
  shortDescription?: Maybe<Scalars["String"]>;
  stats?: Maybe<StatsType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CompanyTypeResponse = CompanyType | Error;

/** A contact form. */
export type ContactForm = {
  /** Телефон */
  contact?: InputMaybe<Scalars["String"]>;
  /** Main phone */
  isMain?: InputMaybe<Scalars["Boolean"]>;
};

/** A single contact */
export type ContactType = {
  __typename?: "ContactType";
  /** Телефон */
  contact?: Maybe<Scalars["String"]>;
  /** id contact */
  id?: Maybe<Scalars["String"]>;
  /** Main phone */
  isMain?: Maybe<Scalars["Boolean"]>;
};

/** A contact form. */
export type ContactUpdateForm = {
  /** Телефон */
  contact?: InputMaybe<Scalars["String"]>;
  /** id contact */
  id?: InputMaybe<Scalars["String"]>;
  /** Main phone */
  isMain?: InputMaybe<Scalars["Boolean"]>;
};

/** Contacts */
export type ContactsInputType = {
  /** Email */
  email?: InputMaybe<Scalars["String"]>;
  /** Имя и фамилия */
  fullName: Scalars["String"];
  /** Телефон */
  phone?: InputMaybe<Scalars["String"]>;
};

/** Contacts */
export type ContactsType = {
  __typename?: "ContactsType";
  /** Email */
  email?: Maybe<Scalars["String"]>;
  /** Имя и фамилия */
  fullName: Scalars["String"];
  /** Телефон */
  phone?: Maybe<Scalars["String"]>;
};

/** A single metatag */
export type ContentBannerType = {
  __typename?: "ContentBannerType";
  announcement?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  href?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  pageId?: Maybe<Scalars["String"]>;
  photoUrl?: Maybe<Scalars["String"]>;
  slot?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
};

/** Represents a paginated relationship between two entities */
export type ContentBannerTypeListListingResponseType = {
  __typename?: "ContentBannerTypeListListingResponseType";
  data: Array<ContentBannerType>;
  meta: PageInfoType;
};

export type ContentBannerTypeResponse = ContentBannerType | Error;

/** Input type Banners */
export type ContentPageInputType = {
  description?: InputMaybe<Scalars["String"]>;
  keywords?: InputMaybe<Scalars["String"]>;
  ogDescription?: InputMaybe<Scalars["String"]>;
  ogTitle?: InputMaybe<Scalars["String"]>;
  photoUrl?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

/** A single metatag */
export type ContentPageType = {
  __typename?: "ContentPageType";
  createdAt?: Maybe<Scalars["DateTime"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  keywords?: Maybe<Scalars["String"]>;
  ogDescription?: Maybe<Scalars["String"]>;
  ogTitle?: Maybe<Scalars["String"]>;
  photoUrl?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

/** Represents a paginated relationship between two entities */
export type ContentPageTypeListListingResponseType = {
  __typename?: "ContentPageTypeListListingResponseType";
  data: Array<ContentPageType>;
  meta: PageInfoType;
};

export type ContentPageTypeResponse = ContentPageType | Error;

/** Geometry Coords */
export type CoordsInputType = {
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
};

/** Geometry Coords */
export type CoordsType = {
  __typename?: "CoordsType";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** Input type daily plan */
export type DailyPlanInputType = {
  /** Категория должности, id из словаря dict_position_category */
  categoryId: Scalars["Int"];
  /** Плановое количество критичных кандидатов */
  criticalPeopleCount?: Scalars["Int"];
  /** ID менеджера, для которого составлен план */
  managerId: Scalars["Int"];
  /** Плановое количество кандидатов */
  peopleCount?: Scalars["Int"];
  /** Дата, на которую составлен план, YYYY-MM-DD */
  planDate: Scalars["DateTime"];
};

export type DailyPlanListResponse = DailyPlanListSuccess | Error;

export type DailyPlanListSuccess = {
  __typename?: "DailyPlanListSuccess";
  data: Array<DailyPlanType>;
};

/** A single daily plan */
export type DailyPlanType = {
  __typename?: "DailyPlanType";
  /** Категория должности, id из словаря dict_position_category */
  categoryId: Scalars["Int"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Плановое количество критичных кандидатов */
  criticalPeopleCount: Scalars["Int"];
  /** Количество закрытых критичных кандидатов */
  criticalPeopleCountClosed: Scalars["Int"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  /** ID менеджера, для которого составлен план */
  managerId: Scalars["Int"];
  /** Плановое количество кандидатов */
  peopleCount: Scalars["Int"];
  /** Количество закрытых кандидатов */
  peopleCountClosed: Scalars["Int"];
  /** Дата, на которую составлен план */
  planDate: Scalars["DateTime"];
  /** ID постановщика плана */
  plannerId: Scalars["Int"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DailyPlanTypeResponse = DailyPlanType | Error;

/** A department form. */
export type DepartmentForm = {
  companyId: Scalars["Int"];
  groupId?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  ownerId?: InputMaybe<Scalars["Int"]>;
  vpbxApiKey?: InputMaybe<Scalars["String"]>;
  vpbxApiSalt?: InputMaybe<Scalars["String"]>;
};

/** Department group form. */
export type DepartmentGroupFormType = {
  name?: InputMaybe<Scalars["String"]>;
};

export type DepartmentGroupListResponse = DepartmentGroupListSuccess | Error;

export type DepartmentGroupListSuccess = {
  __typename?: "DepartmentGroupListSuccess";
  data: Array<DepartmentGroupType>;
};

/** Department group. */
export type DepartmentGroupType = {
  __typename?: "DepartmentGroupType";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DepartmentGroupTypeResponse = DepartmentGroupType | Error;

export type DepartmentListResponse = DepartmentListSuccess | Error;

export type DepartmentListSuccess = {
  __typename?: "DepartmentListSuccess";
  data: Array<DepartmentType>;
};

/** A single department. */
export type DepartmentType = {
  __typename?: "DepartmentType";
  companyId: Scalars["Int"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  group?: Maybe<DepartmentGroupType>;
  groupId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  name: Scalars["String"];
  owner: OwnerType;
  ownerId?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  vpbxApiKey?: Maybe<Scalars["String"]>;
  vpbxApiSalt?: Maybe<Scalars["String"]>;
};

export type DepartmentTypeResponse = DepartmentType | Error;

export type Dictionaries = {
  __typename?: "Dictionaries";
  areas: Array<AreasType>;
  candidateStatuses: Array<CandidateStatusesType>;
  cities: Array<CityType>;
  citiesWithVacancies: Array<CityWithVacancyType>;
  citizenships: Array<CitizenshipType>;
  claimCategories: Array<ClaimCategoriesType>;
  claimStatuses: Array<ClaimStatusesType>;
  departments: Array<DepartmentType>;
  driverLicense: Array<DriverLicenseType>;
  employers: Array<EmployerType>;
  employments: Array<EmploymentType>;
  experience: Array<ExperienceType>;
  languageLevel: Array<LanguageLevelType>;
  metro: Array<MetroType>;
  nationalities: Array<NationalityType>;
  positionCategories: Array<PositionCategoryType>;
  positions: Array<PositionType>;
  professionalRole: Array<ProfessionalRoleType>;
  refusalReasons: Array<RefusalReasonType>;
  replyStatuses: Array<ReplyStatusesType>;
  sort: Array<SortType>;
  sortDirection: Array<SortDirectionType>;
  sources: Array<SourceType>;
  specialization: Array<SpecializationType>;
  userRoles: Array<UserRolesType>;
  vacancyRelevance: Array<VacancyRelevanceModelType>;
  vacancyStates: Array<VacancyStatesType>;
  vacancyStatuses: Array<VacancyStatusesType>;
  vacancyTag: Array<VacancyTagType>;
};

export type DictionariesResponse = Dictionaries | Error;

/** Driver License Dictionary */
export type DriverLicenseType = {
  __typename?: "DriverLicenseType";
  id: Scalars["String"];
  /** Ключ */
  key: Scalars["String"];
};

/** Employer Dictionary */
export type EmployerType = {
  __typename?: "EmployerType";
  id: Scalars["Int"];
  /** Ключ */
  key: Scalars["String"];
  /** Логотип */
  logo?: Maybe<Scalars["String"]>;
  /** Значение */
  name: Scalars["String"];
};

/** Employment Dictionary */
export type EmploymentType = {
  __typename?: "EmploymentType";
  id: Scalars["Int"];
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

export type Error = {
  __typename?: "Error";
  errors?: Maybe<Array<Scalars["String"]>>;
  message: Scalars["String"];
  statusCode: Scalars["Int"];
};

/** Experience HH Dictionary */
export type ExperienceType = {
  __typename?: "ExperienceType";
  id: Scalars["String"];
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** Extra */
export type ExtraInputType = {
  remoteId?: InputMaybe<Scalars["String"]>;
  remoteUrl?: InputMaybe<Scalars["String"]>;
  remoteVacancyId?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Scalars["String"]>;
};

/** Extra */
export type ExtraType = {
  __typename?: "ExtraType";
  remoteId?: Maybe<Scalars["String"]>;
  remoteUrl?: Maybe<Scalars["String"]>;
  remoteVacancyId?: Maybe<Scalars["String"]>;
  service?: Maybe<Scalars["String"]>;
};

export type File = {
  __typename?: "File";
  url: Scalars["String"];
};

export type FileResponse = Error | File;

/** A call filter form */
export type HotCallFilterType = {
  hasResult?: InputMaybe<Scalars["Boolean"]>;
  phone?: InputMaybe<Scalars["String"]>;
  sourceId?: InputMaybe<Scalars["String"]>;
  sourceType?: InputMaybe<SourceHotCall>;
  status?: InputMaybe<Scalars["String"]>;
};

/** A call form */
export type HotCallInputType = {
  contacts?: InputMaybe<ContactsInputType>;
  finishAt?: InputMaybe<Scalars["DateTime"]>;
  isMango?: InputMaybe<Scalars["Boolean"]>;
  managerId: Scalars["String"];
  managerRemoteId: Scalars["String"];
  notThroughReason?: InputMaybe<Scalars["String"]>;
  result?: InputMaybe<Scalars["String"]>;
  sourceId: Scalars["String"];
  sourceType: SourceHotCall;
  startAt?: InputMaybe<Scalars["DateTime"]>;
  status: Scalars["String"];
};

export type HotCallListResponse = Error | HotCallListSuccess;

export type HotCallListSuccess = {
  __typename?: "HotCallListSuccess";
  data: Array<HotCallType>;
  meta: PageInfoType;
};

/** Create hot call plan */
export type HotCallPlanInputType = {
  __typename?: "HotCallPlanInputType";
  /** Плановое количество звонков */
  countCalls?: Maybe<Scalars["Int"]>;
  /** Дата создания */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Кем создан */
  createdBy?: Maybe<Scalars["Int"]>;
  /** Дата начала действия плана */
  dateBeginning?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["String"]>;
  /** Для кого создан */
  userId?: Maybe<Scalars["Int"]>;
};

/** A single hot call plan */
export type HotCallPlanType = {
  __typename?: "HotCallPlanType";
  /** Плановое количество звонков */
  countCalls?: Maybe<Scalars["Int"]>;
  /** Название департамента */
  departmentName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  /** Для кого создан план */
  user?: Maybe<Scalars["String"]>;
  /** ID менеджера */
  userId?: Maybe<Scalars["Int"]>;
};

export type HotCallPlanTypeResponse = Error | HotCallPlanInputType;

/** A single call */
export type HotCallType = {
  __typename?: "HotCallType";
  contacts?: Maybe<ContactsType>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  finishAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  managerId: Scalars["String"];
  managerRemoteId: Scalars["String"];
  notThroughReason?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
  sourceId: Scalars["String"];
  sourceType: SourceHotCall;
  startAt?: Maybe<Scalars["DateTime"]>;
  status: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type HotCallTypeResponse = Error | HotCallType;

export type HotCallsPlanListResponse = Error | HotCallsPlanListSuccess;

export type HotCallsPlanListSuccess = {
  __typename?: "HotCallsPlanListSuccess";
  data: Array<HotCallPlanType>;
};

/** Language Level Dictionary */
export type LanguageLevelType = {
  __typename?: "LanguageLevelType";
  id: Scalars["String"];
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** Metro line Dictionary */
export type LineType = {
  __typename?: "LineType";
  id?: Maybe<Scalars["Int"]>;
  /** Название линии */
  key?: Maybe<Scalars["String"]>;
};

/** A single user. */
export type ManagerType = {
  __typename?: "ManagerType";
  city?: Maybe<Scalars["String"]>;
  companyId?: Maybe<Scalars["Int"]>;
  departmentId?: Maybe<Scalars["Int"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  phone?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  secondName: Scalars["String"];
};

/** Metro Item */
export type MetroItemInputType = {
  /** Растояние от рабочего места до станции */
  distance?: InputMaybe<Scalars["Float"]>;
  /** Название линии */
  line?: InputMaybe<Scalars["String"]>;
  /** Название станции */
  name: Scalars["String"];
};

/** Metro Item */
export type MetroItemType = {
  __typename?: "MetroItemType";
  /** Растояние от рабочего места до станции */
  distance?: Maybe<Scalars["Float"]>;
  /** Название линии */
  line?: Maybe<Scalars["String"]>;
  /** Название станции */
  name: Scalars["String"];
};

/** Metro Dictionary */
export type MetroType = {
  __typename?: "MetroType";
  /** ID города из словаря dict_city */
  cityId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  /** Название станции */
  key: Scalars["String"];
  /** Линия */
  line?: Maybe<LineType>;
};

/** Create/Update/Delete operations of the API. */
export type Mutation = {
  __typename?: "Mutation";
  /** Accept add to black list */
  acceptAddToBlackList: CandidateTypeResponse;
  /** Add candidate to black list */
  addCandidateToBlackList: CandidateTypeResponse;
  /** Add category for position */
  addCategoryForPosition: PositionResponse;
  /** Assign a subdomain to a city */
  assignDomainToCity: CityResponse;
  /** Change candidate status */
  changeCandidateStatus: CandidateTypeResponse;
  /** Change claim status */
  changeClaimStatus: ClaimTypeResponse;
  /** Change owner */
  changeOwnerVacancy: Scalars["Boolean"];
  /** Change reply status */
  changeReplyStatus: ReplyTypeResponse;
  /** Change vacancy tag colors */
  changeTagColor: VacancyTagResponse;
  /** Change user password */
  changeUserPassword: Scalars["Boolean"];
  /** Change status vacancy */
  changeVacancyStatus: VacancyTypeResponse;
  /** Show/hide claim */
  changeVisibleClaim: ClaimTypeResponse;
  /** Show/hide reply */
  changeVisibleReply: ReplyTypeResponse;
  /** Show/hide vacancy */
  changeVisibleVacancy: VacancyTypeResponse;
  /** Convert claim to reply */
  convertClaimToReply: ReplyTypeResponse;
  /** Create a banner */
  createBanner: ContentBannerTypeResponse;
  /** Create a candidate */
  createCandidate: CandidateTypeResponse;
  /** Create a city */
  createCity: CityResponse;
  /** Create a claim */
  createClaim: ClaimType;
  /** Create a company */
  createCompany: CompanyTypeResponse;
  /** Create daily plan for manager */
  createDailyPlan: DailyPlanTypeResponse;
  /** Create a department */
  createDepartment: DepartmentTypeResponse;
  /** Create department group */
  createDepartmentGroup: DepartmentGroupTypeResponse;
  /** Create a hot call */
  createHotCall: HotCallTypeResponse;
  /** Create mango user */
  createMangoUser: UserTypeResponse;
  /** Create match position from LT */
  createMatchPosition: PositionMatchResponse;
  /** Create a metatag */
  createMetatag: ContentPageTypeResponse;
  /** Create a nationality */
  createNationality: NationalityResponse;
  /** Create a position */
  createPosition: PositionResponse;
  /** Create a position category */
  createPositionCategory: PositionCategoryResponse;
  /** Create a refusal */
  createRefusal: RefusalResponse;
  /** Create a reply */
  createReply: ReplyTypeResponse;
  /** Create a user */
  createUser: UserTypeResponse;
  /** Create a vacancy */
  createVacancy: VacancyTypeResponse;
  /** Create a vacancy for MyGig */
  createVacancyOld: Scalars["Boolean"];
  /** Decline claim with reason */
  declineClaim: ClaimTypeResponse;
  /** Decline reply with reason */
  declineReply: ReplyTypeResponse;
  /** Decline vacancy */
  declineVacancy: VacancyTypeResponse;
  /** Delete a banner */
  deleteBanner: Scalars["Boolean"];
  /** Delete a city */
  deleteCity: Scalars["Boolean"];
  /** Delete a company */
  deleteCompany: Scalars["Boolean"];
  /** Delete a department */
  deleteDepartment: Scalars["Boolean"];
  /** Delete a metatag */
  deleteMetatag: Scalars["Boolean"];
  /** Delete a nationality */
  deleteNationality: Scalars["Boolean"];
  /** Delete oktell credentials for user */
  deleteOktellCredentials: UserTypeResponse;
  /** Delete a position */
  deletePosition: Scalars["Boolean"];
  /** Delete a refusal */
  deleteRefusal: Scalars["Boolean"];
  /** Delete a user */
  deleteUser: UserTypeResponse;
  /** Delete a vacancy */
  deleteVacancy: Scalars["Boolean"];
  /** Hide in check vacancy */
  hideVacancy: VacancyTypeResponse;
  /** Publish vacancy on Rabotut */
  publishVacancy: VacancyTypeResponse;
  /** Save oktell credentials for user */
  saveOktellCredentials: UserTypeResponse;
  /** Set company settings */
  setCompanySettings: CompanySettingsTypeResponse;
  /** Setup call_completed */
  setupCall: CandidateTypeResponse;
  /** Stop publish vacancy on Rabotut */
  stopPublishVacancy: VacancyTypeResponse;
  /** Transfer candidate */
  transferCandidate: CandidateTypeResponse;
  /** Transfer claim to new manager */
  transferClaim: ClaimTypeResponse;
  /** Transfer reply to new manager */
  transferReply: ReplyTypeResponse;
  /** Transfer reply list to new manager */
  transferReplyList: ReplyTransferredListResponse;
  /** Transfer vacancy to new manager */
  transferVacancy: VacancyTypeResponse;
  /** Update a banner */
  updateBanner: ContentBannerTypeResponse;
  /** Update a candidate */
  updateCandidate: CandidateTypeResponse;
  /** Update candidate only request fields */
  updateCandidateOnlyRequestFields: CandidateTypeResponse;
  /** Update a claim */
  updateClaim: ClaimTypeResponse;
  /** Update a company */
  updateCompany: CompanyTypeResponse;
  /** Update a department */
  updateDepartment: DepartmentTypeResponse;
  /** Update a hot call */
  updateHotCall: HotCallTypeResponse;
  /** Create hot call plan for manager */
  updateHotCallPlan: HotCallPlanTypeResponse;
  /** Update a metatag */
  updateMetatag: ContentPageTypeResponse;
  /** Update a nationality */
  updateNationality: NationalityResponse;
  /** Update a position count candidate */
  updatePositionCount: PositionPeopleCountResponse;
  /** Update a refusal */
  updateRefusal: RefusalResponse;
  /** Update relation position */
  updateRelationPosition: PositionRelationResponse;
  /** Update a reply */
  updateReply: ReplyTypeResponse;
  /** Update a user */
  updateUser: UserTypeResponse;
  /** Update a vacancy */
  updateVacancy: VacancyTypeResponse;
  /** Update vacancy integration extra data */
  updateVacancyExtra: Scalars["Boolean"];
};

/** Create/Update/Delete operations of the API. */
export type MutationAcceptAddToBlackListArgs = {
  id: Scalars["String"];
};

/** Create/Update/Delete operations of the API. */
export type MutationAddCandidateToBlackListArgs = {
  id: Scalars["String"];
};

/** Create/Update/Delete operations of the API. */
export type MutationAddCategoryForPositionArgs = {
  categoryId: Scalars["Int"];
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationAssignDomainToCityArgs = {
  domain: Scalars["String"];
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeCandidateStatusArgs = {
  callTime?: InputMaybe<Scalars["DateTime"]>;
  comment?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  refusalId?: InputMaybe<Scalars["Int"]>;
  status: CandidateStatus;
  vacancyId?: InputMaybe<Scalars["Int"]>;
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeClaimStatusArgs = {
  id: Scalars["Int"];
  status: ClaimStatus;
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeOwnerVacancyArgs = {
  newUserId: Scalars["Int"];
  oldUserId: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeReplyStatusArgs = {
  id: Scalars["Int"];
  status: ReplyStatus;
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeTagColorArgs = {
  color?: InputMaybe<Scalars["String"]>;
  colorBg?: InputMaybe<Scalars["String"]>;
  key: Scalars["String"];
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeUserPasswordArgs = {
  id: Scalars["Int"];
  password: Scalars["String"];
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeVacancyStatusArgs = {
  id: Scalars["Int"];
  status: VacancyStatus;
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeVisibleClaimArgs = {
  channel: Scalars["String"];
  id: Scalars["Int"];
  inWork: Scalars["Boolean"];
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeVisibleReplyArgs = {
  channel: Scalars["String"];
  id: Scalars["Int"];
  inWork: Scalars["Boolean"];
};

/** Create/Update/Delete operations of the API. */
export type MutationChangeVisibleVacancyArgs = {
  channel: Scalars["String"];
  id: Scalars["Int"];
  inBlocked?: InputMaybe<Scalars["Boolean"]>;
  inWork: Scalars["Boolean"];
};

/** Create/Update/Delete operations of the API. */
export type MutationConvertClaimToReplyArgs = {
  claimId: Scalars["Int"];
  vacancyId: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateBannerArgs = {
  form: BannerInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateCandidateArgs = {
  form: CandidateInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateCityArgs = {
  form: CityInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateClaimArgs = {
  form: ClaimInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateCompanyArgs = {
  form: CompanyInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateDailyPlanArgs = {
  form: DailyPlanInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateDepartmentArgs = {
  form: DepartmentForm;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateDepartmentGroupArgs = {
  form: DepartmentGroupFormType;
  listDepartments: Array<Scalars["Int"]>;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateHotCallArgs = {
  form: HotCallInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateMangoUserArgs = {
  userId: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateMatchPositionArgs = {
  form: PositionsMathLtForm;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateMetatagArgs = {
  form: ContentPageInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateNationalityArgs = {
  form: NationalityInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreatePositionArgs = {
  form: PositionInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreatePositionCategoryArgs = {
  form: PositionCategoryInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateRefusalArgs = {
  form: RefusalInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateReplyArgs = {
  form: ReplyInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateUserArgs = {
  form: UserForm;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateVacancyArgs = {
  form: VacancyInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationCreateVacancyOldArgs = {
  input: NewVacancy;
};

/** Create/Update/Delete operations of the API. */
export type MutationDeclineClaimArgs = {
  id: Scalars["Int"];
  reasonKey?: InputMaybe<Scalars["String"]>;
  refusalText?: InputMaybe<Scalars["String"]>;
  status: ClaimStatus;
};

/** Create/Update/Delete operations of the API. */
export type MutationDeclineReplyArgs = {
  id: Scalars["Int"];
  reasonKey?: InputMaybe<Scalars["String"]>;
  refusalText?: InputMaybe<Scalars["String"]>;
  status: ReplyStatus;
};

/** Create/Update/Delete operations of the API. */
export type MutationDeclineVacancyArgs = {
  id: Scalars["Int"];
  refusalId: Scalars["Int"];
  refusalText?: InputMaybe<Scalars["String"]>;
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteBannerArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteCityArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteCompanyArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteDepartmentArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteMetatagArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteNationalityArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteOktellCredentialsArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationDeletePositionArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteRefusalArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteUserArgs = {
  id: Scalars["Int"];
  newUserId?: InputMaybe<Scalars["Int"]>;
};

/** Create/Update/Delete operations of the API. */
export type MutationDeleteVacancyArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationHideVacancyArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationPublishVacancyArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationSaveOktellCredentialsArgs = {
  form: OktellCredentialsForm;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationSetCompanySettingsArgs = {
  form: CompanySettingsInputType;
};

/** Create/Update/Delete operations of the API. */
export type MutationSetupCallArgs = {
  id: Scalars["String"];
  vacancyId?: InputMaybe<Scalars["Int"]>;
};

/** Create/Update/Delete operations of the API. */
export type MutationStopPublishVacancyArgs = {
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationTransferCandidateArgs = {
  replyId: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationTransferClaimArgs = {
  id: Scalars["Int"];
  managerComment: Scalars["String"];
  newUserId: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationTransferReplyArgs = {
  id: Scalars["Int"];
  managerComment: Scalars["String"];
  newUserId: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationTransferReplyListArgs = {
  ids: Array<Scalars["Int"]>;
  newUserId: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationTransferVacancyArgs = {
  id: Scalars["Int"];
  newUserId: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateBannerArgs = {
  form: BannerInputType;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateCandidateArgs = {
  form: CandidateUpdateType;
  id: Scalars["String"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateCandidateOnlyRequestFieldsArgs = {
  form: CandidateUpdateType;
  id: Scalars["String"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateClaimArgs = {
  form: ClaimUpdateType;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateCompanyArgs = {
  form: CompanyInputType;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateDepartmentArgs = {
  form: DepartmentForm;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateHotCallArgs = {
  form: HotCallInputType;
  id: Scalars["ID"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateHotCallPlanArgs = {
  countCalls: Scalars["Int"];
  managerId: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateMetatagArgs = {
  form: ContentPageInputType;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateNationalityArgs = {
  form: NationalityInputType;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdatePositionCountArgs = {
  count: Scalars["Int"];
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateRefusalArgs = {
  form: RefusalInputType;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateRelationPositionArgs = {
  form: PositionsRelationsForm;
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateReplyArgs = {
  form: ReplyUpdateType;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateUserArgs = {
  form: UserForm;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateVacancyArgs = {
  form: VacancyInputType;
  id: Scalars["Int"];
};

/** Create/Update/Delete operations of the API. */
export type MutationUpdateVacancyExtraArgs = {
  data: VacancyExtraInput;
  id: Scalars["Int"];
  type: VacancyExtraSourceType;
};

/** Nationality input type */
export type NationalityInputType = {
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

export type NationalityResponse = Error | NationalityType;

/** Nationality Dictionary */
export type NationalityType = {
  __typename?: "NationalityType";
  id?: Maybe<Scalars["Int"]>;
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** Address old */
export type NewAddress = {
  /** Район в регионе */
  area?: InputMaybe<Scalars["String"]>;
  /** Тип района в регионе */
  areaType?: InputMaybe<Scalars["String"]>;
  /** Корпус */
  block?: InputMaybe<Scalars["String"]>;
  /** Тип корпуса */
  blockType?: InputMaybe<Scalars["String"]>;
  /** Название города, например: Москва, Котлас, Благовещенск */
  city?: InputMaybe<Scalars["String"]>;
  /** Тип города, например: г */
  cityType?: InputMaybe<Scalars["String"]>;
  /** Подъезд */
  entrance?: InputMaybe<Scalars["String"]>;
  geolat?: InputMaybe<Scalars["Float"]>;
  geolon?: InputMaybe<Scalars["Float"]>;
  /** Номер дома */
  house?: InputMaybe<Scalars["String"]>;
  /** Тип дома */
  houseType?: InputMaybe<Scalars["String"]>;
  /** Регион, например: Алтайский, Амурская, Башкортостан */
  region?: InputMaybe<Scalars["String"]>;
  /** Тип региона, например: край, обл, респ */
  regionType?: InputMaybe<Scalars["String"]>;
  /** Населенный пункт */
  settlement?: InputMaybe<Scalars["String"]>;
  /** Тип населенного пункта */
  settlementType?: InputMaybe<Scalars["String"]>;
  /** Улица */
  street?: InputMaybe<Scalars["String"]>;
  /** Тип улицы */
  streetType?: InputMaybe<Scalars["String"]>;
  /** Полный адрес, например: г Новосибирск, ул Державина, д 47; Московская обл, г Домодедово, село Вельяминово; Московская обл, г Люберцы, ул Юбилейная, д 2А */
  value?: InputMaybe<Scalars["String"]>;
};

/** CallCenterInfo */
export type NewCallCenter = {
  /** Доп. мотивация */
  additionalMotivation?: InputMaybe<Scalars["String"]>;
  /** Адреса мест работ */
  addresses?: InputMaybe<Scalars["String"]>;
  /** Компенсация за оформление ЛМК */
  compensationMedicalBook?: InputMaybe<Scalars["String"]>;
  /** Описание компании */
  description?: InputMaybe<Scalars["String"]>;
  /** Вод. права (категории) */
  driverLicense?: InputMaybe<Array<Scalars["String"]>>;
  /** Опыт работы */
  experience?: InputMaybe<Scalars["String"]>;
  /** Оплата проезда */
  farePayment?: InputMaybe<Scalars["String"]>;
  /** Категория товара */
  goodsCategory?: InputMaybe<Scalars["String"]>;
  /** Мобильная связь (оплата) */
  mobilePayment?: InputMaybe<Scalars["Boolean"]>;
  /** Выплаты по АПД */
  payoutsRfp?: InputMaybe<Scalars["String"]>;
  /** Личный авто */
  personalCar?: InputMaybe<Scalars["Boolean"]>;
  /** Наличие КПК */
  presenceOfPda?: InputMaybe<Scalars["String"]>;
  /** Название магазина, торговой точки */
  project?: InputMaybe<Scalars["String"]>;
  /** Требование по оформлению иностранных граждан */
  registrationOfForeignCitizens?: InputMaybe<Scalars["String"]>;
  /** Виды оформления */
  typesOfJobRegistration?: InputMaybe<Scalars["String"]>;
  /** Вакцинация */
  vaccination?: InputMaybe<Scalars["String"]>;
};

/** Vacancy Form for MyGig */
export type NewVacancy = {
  addressWork: NewAddress;
  ageFrom?: InputMaybe<Scalars["Int"]>;
  ageTo?: InputMaybe<Scalars["Int"]>;
  callCenter: NewCallCenter;
  claims: Scalars["String"];
  description: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  employer: Scalars["Int"];
  fio: Scalars["String"];
  metro?: InputMaybe<Scalars["String"]>;
  nationality?: InputMaybe<Scalars["Int"]>;
  phone: Scalars["String"];
  position: Scalars["Int"];
  salaryFrom?: InputMaybe<Scalars["Int"]>;
  salaryTo?: InputMaybe<Scalars["Int"]>;
  shortDescription: Scalars["String"];
  terms: Scalars["String"];
  typeEmployment: Scalars["Int"];
  viewContact?: InputMaybe<Scalars["Boolean"]>;
};

/** User oktell credentials form */
export type OktellCredentialsForm = {
  oktellId?: InputMaybe<Scalars["String"]>;
  oktellLogin: Scalars["String"];
  oktellPassword: Scalars["String"];
};

/** A single user. */
export type OwnerType = {
  __typename?: "OwnerType";
  city?: Maybe<Scalars["String"]>;
  companyId?: Maybe<Scalars["Int"]>;
  departmentId?: Maybe<Scalars["Int"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  phone?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  secondName: Scalars["String"];
};

/** Pagination info */
export type PageInfoType = {
  __typename?: "PageInfoType";
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  total: Scalars["Int"];
};

/** Pagination config */
export type PaginationType = {
  limit?: Scalars["Int"];
  offset?: Scalars["Int"];
};

/** Period created */
export type PeriodType = {
  /** date start */
  dateFrom?: InputMaybe<Scalars["DateTime"]>;
  /** date end */
  dateTo?: InputMaybe<Scalars["DateTime"]>;
};

/** Position Category input type */
export type PositionCategoryInputType = {
  key?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type PositionCategoryResponse = Error | PositionCategoryType;

/** Position Category Dictionary */
export type PositionCategoryType = {
  __typename?: "PositionCategoryType";
  id?: Maybe<Scalars["Int"]>;
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** Position input type */
export type PositionInputType = {
  key?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  restriction?: InputMaybe<PositionRestrictions>;
};

/** Position for LT */
export type PositionLtType = {
  __typename?: "PositionLTType";
  categoryId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
  restriction?: Maybe<PositionRestrictions>;
};

export type PositionListResponseSuccess = {
  __typename?: "PositionListResponseSuccess";
  data: Array<PositionsRelationsType>;
};

export type PositionMatchResponse = Error | PositionsMathLtType;

export type PositionPeopleCountResponse = Error | PositionPeopleCountType;

/** Position People Count */
export type PositionPeopleCountType = {
  __typename?: "PositionPeopleCountType";
  countPeople?: Maybe<Scalars["Int"]>;
  countPeopleNew?: Maybe<Scalars["Int"]>;
  position?: Maybe<PositionLtType>;
};

export type PositionRelationListResponse = Error | PositionListResponseSuccess;

export type PositionRelationResponse = Error | PositionsRelationsType;

export type PositionResponse = Error | PositionType;

/**
 *
 *     Ограничения у должностей
 *
 *     ONLY_MALE = 'Только мужчины'
 *     ONLY_FEMALE = 'Только женщины'
 *
 */
export enum PositionRestrictions {
  OnlyFemale = "ONLY_FEMALE",
  OnlyMale = "ONLY_MALE",
}

/** Position Dictionary */
export type PositionType = {
  __typename?: "PositionType";
  categoryId?: Maybe<Scalars["Int"]>;
  countPeople?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
  restriction?: Maybe<PositionRestrictions>;
};

/** A position form. */
export type PositionsForm = {
  __typename?: "PositionsForm";
  /** id category */
  categoryId?: Maybe<Scalars["Int"]>;
  /** id */
  id?: Maybe<Scalars["Int"]>;
  /** Key */
  key?: Maybe<Scalars["String"]>;
  /** Name position */
  name?: Maybe<Scalars["String"]>;
  restriction?: Maybe<PositionRestrictions>;
};

/** A position matching form */
export type PositionsMathLtForm = {
  /** Id position in RBT */
  positionId?: InputMaybe<Scalars["Int"]>;
  /** Name position in  CRM LT */
  positionName?: InputMaybe<Scalars["String"]>;
};

/** A single matching */
export type PositionsMathLtType = {
  __typename?: "PositionsMathLTType";
  /** id */
  id?: Maybe<Scalars["String"]>;
  /** Id position in RBT */
  positionId?: Maybe<Scalars["Int"]>;
  /** Name position in  CRM LT */
  positionName?: Maybe<Scalars["String"]>;
};

export type PositionsRelationsForm = {
  /** Avito tags */
  avitoTags?: InputMaybe<Scalars["String"]>;
  /** New category id */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Id position from */
  positionIdFrom: Scalars["Int"];
  /** Id position to */
  positionIdTo: Array<Scalars["Int"]>;
  restriction?: InputMaybe<PositionRestrictions>;
};

export type PositionsRelationsType = {
  __typename?: "PositionsRelationsType";
  /** Avito tags */
  avitoTags?: Maybe<Scalars["String"]>;
  /** New category id */
  categoryId?: Maybe<Scalars["Int"]>;
  /** Id position from */
  positionIdFrom: Scalars["Int"];
  /** Id position to */
  positionIdTo: Array<Scalars["Int"]>;
  restriction?: Maybe<PositionRestrictions>;
};

/** Private vacancy list available filters */
export type PrivateVacancyFilterType = {
  /** vacancy city */
  city?: InputMaybe<Scalars["String"]>;
  /** vacancy city fias id */
  cityFiasId?: InputMaybe<Scalars["String"]>;
  /** vacancy date of creation */
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** vacancy employer name */
  employer?: InputMaybe<Scalars["String"]>;
  /** vacancy employment key of dict_employments */
  employment?: InputMaybe<Scalars["String"]>;
  /** vacancy metro station name */
  metro?: InputMaybe<Scalars["String"]>;
  /** vacancy owner */
  ownerId?: InputMaybe<Scalars["Int"]>;
  /** vacancy position key of dict_positions */
  position?: InputMaybe<Scalars["String"]>;
  /** vacancy call center info project */
  project?: InputMaybe<Scalars["String"]>;
  relevance?: InputMaybe<VacancyRelevance>;
  /** range of salary */
  salary?: InputMaybe<SalaryInputType>;
  /** salary employment key of dict_employments */
  salaryFrom?: InputMaybe<Scalars["Int"]>;
  /** search query by name. 2+ symbols */
  search?: InputMaybe<Scalars["String"]>;
  /** vacancy posting site */
  source?: InputMaybe<VacancySource>;
  /** vacancy state key of dict vacancy_state */
  state?: InputMaybe<VacancyState>;
  /** vacancy status key of dict_vacancy_status */
  status?: InputMaybe<VacancyStatus>;
  /** tag key */
  tag?: InputMaybe<Scalars["String"]>;
};

export type PrivateVacancyListResponse = Error | PrivateVacancyListSuccess;

export type PrivateVacancyListSuccess = {
  __typename?: "PrivateVacancyListSuccess";
  data: Array<VacancyType>;
  meta: PageInfoType;
};

/** Professional Role Dictionary */
export type ProfessionalRoleType = {
  __typename?: "ProfessionalRoleType";
  id: Scalars["String"];
  /** Уровень в дереве */
  level?: Maybe<Scalars["Int"]>;
  /** Название */
  name: Scalars["String"];
  /** Родительский элемент в дереве */
  parentId: Scalars["String"];
};

/** A single public vacancy list item */
export type PublicVacancyListItemType = {
  __typename?: "PublicVacancyListItemType";
  address: AddressType;
  contacts: ContactsType;
  employer?: Maybe<EmployerType>;
  employment?: Maybe<EmploymentType>;
  id: Scalars["Int"];
  location?: Maybe<CoordsType>;
  manager: OwnerType;
  metro?: Maybe<Array<MetroItemType>>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<PositionType>;
  relevance?: Maybe<VacancyRelevance>;
  salary: SalaryType;
  shortDescription?: Maybe<Scalars["String"]>;
  showContacts: Scalars["Boolean"];
  tag?: Maybe<VacancyTagType>;
};

/** Represents a paginated relationship between two entities */
export type PublicVacancyListItemTypeListListingResponseType = {
  __typename?: "PublicVacancyListItemTypeListListingResponseType";
  data: Array<PublicVacancyListItemType>;
  meta: PageInfoType;
};

/** Read operations of the API */
export type Query = {
  __typename?: "Query";
  /** Determine a business hour */
  businessHour: Scalars["Boolean"];
  /** Read a candidate */
  candidate: CandidateTypeResponse;
  /** Read all candidates */
  candidateList: CandidateListResponse;
  /** Read all candidates by vacancy */
  candidateListByVacancy: CandidateListResponse;
  /** Read candidates suitable for vacancy */
  candidateSuitableList: CandidateListResponse;
  /** Read cities with vacancies by manager */
  cityWithVacancyByOwner: Array<CityWithFiasType>;
  /** Read a claim */
  claim: ClaimTypeResponse;
  /** Read all claims */
  claimList: ClaimListResponse;
  /** Read unassigned claims */
  claimUnassignedList: ClaimListResponse;
  /** Read a company */
  company: CompanyTypeResponse;
  /** Read all companies */
  companyList: CompanyListResponse;
  /** Read a company settings */
  companySettings: CompanySettingsTypeResponse;
  /** Read a content banner */
  contentBanner: ContentBannerType;
  /** Read a content banner by slot */
  contentBannerBySlot: Array<ContentBannerType>;
  /** Read all content's banner */
  contentBannersList: ContentBannerTypeListListingResponseType;
  /** Read a content page */
  contentPage: ContentPageType;
  /** Read a content page by url */
  contentPageByUrl: ContentPageType;
  /** Read all content's page */
  contentPageList: ContentPageTypeListListingResponseType;
  /** Get daily plan for manager */
  dailyPlanList: DailyPlanListResponse;
  /** Read a department */
  department: DepartmentType;
  /** Read all department groups */
  departmentGroupList: DepartmentGroupListResponse;
  /** Read all departments */
  departmentList: DepartmentListResponse;
  /** Read all departments by group */
  departmentListByGroup: DepartmentListResponse;
  /** Load dictionaries */
  dictionaries: DictionariesResponse;
  /** Download xls */
  downloadReplies: FileResponse;
  /** Download candidate resume */
  downloadResume: FileResponse;
  /** Download xls */
  downloadVacancies: FileResponse;
  /** Read a hot call */
  hotCall: HotCallTypeResponse;
  /** Read a hot call list */
  hotCallList: HotCallListResponse;
  /** Get daily plan hot calls */
  hotCallsPlanList: HotCallsPlanListResponse;
  /** Read authorized user */
  me: UserTypeResponse;
  /** Position relations */
  positionRelations: PositionRelationListResponse;
  /** Read a private vacancy */
  privateVacancy: VacancyTypeResponse;
  /** Read all vacancies */
  privateVacancyList: PrivateVacancyListResponse;
  /** Read published vacancies */
  publicVacancyList: PublicVacancyListItemTypeListListingResponseType;
  /** Read published vacancies map */
  publicVacancyMap: Scalars["GeoJSON"];
  /** Read a reply */
  reply: ReplyTypeResponse;
  /** Read all replies */
  replyList: ReplyListResponse;
  /** Read all replies by candidate */
  replyListByCandidate: ReplyListResponse;
  /** Read list claims and replies */
  replyListByCategory: ReplyListResponse;
  /** Read a user */
  user: UserTypeResponse;
  /** Read all users */
  userList: UserListResponse;
  /** Read list users for transfer reply */
  userTransferList: UserListResponse;
  /** Read a vacancy */
  vacancy: VacancyTypeResponse;
  /** Vacancies count */
  vacancyCount: VacancyCountType;
  /** Read all vacancies by category */
  vacancyListByCategory: PrivateVacancyListResponse;
  /** Read vacancies suitable for candidate */
  vacancySuitableList: SuitableVacancyListResponse;
};

/** Read operations of the API */
export type QueryCandidateArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryCandidateListArgs = {
  filter?: InputMaybe<CandidateFilterType>;
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryCandidateListByVacancyArgs = {
  filter?: InputMaybe<CandidateFilterType>;
  pagination?: InputMaybe<PaginationType>;
  vacancyId: Scalars["Int"];
};

/** Read operations of the API */
export type QueryCandidateSuitableListArgs = {
  filter?: InputMaybe<CandidateSuitableFilterType>;
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryCityWithVacancyByOwnerArgs = {
  ownerId: Scalars["Int"];
};

/** Read operations of the API */
export type QueryClaimArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryClaimListArgs = {
  filter?: InputMaybe<ClaimFilterType>;
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryClaimUnassignedListArgs = {
  filter?: InputMaybe<ClaimFilterType>;
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryContentBannerArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryContentBannerBySlotArgs = {
  slot: Scalars["String"];
};

/** Read operations of the API */
export type QueryContentBannersListArgs = {
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryContentPageArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryContentPageByUrlArgs = {
  url: Scalars["String"];
};

/** Read operations of the API */
export type QueryContentPageListArgs = {
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryDailyPlanListArgs = {
  date?: InputMaybe<Scalars["DateTime"]>;
};

/** Read operations of the API */
export type QueryDepartmentArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryDepartmentListByGroupArgs = {
  groupId: Scalars["Int"];
};

/** Read operations of the API */
export type QueryDownloadResumeArgs = {
  id: Scalars["String"];
};

/** Read operations of the API */
export type QueryHotCallArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryHotCallListArgs = {
  form?: InputMaybe<HotCallFilterType>;
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryHotCallsPlanListArgs = {
  departmentId: Scalars["Int"];
};

/** Read operations of the API */
export type QueryPrivateVacancyArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryPrivateVacancyListArgs = {
  filter?: InputMaybe<PrivateVacancyFilterType>;
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryPublicVacancyListArgs = {
  filter?: InputMaybe<VacancyFilterType>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<VacancySortType>;
};

/** Read operations of the API */
export type QueryPublicVacancyMapArgs = {
  filter?: InputMaybe<VacancyMapFilterType>;
};

/** Read operations of the API */
export type QueryReplyArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryReplyListArgs = {
  filter?: InputMaybe<ReplyFilterType>;
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryReplyListByCandidateArgs = {
  candidateId: Scalars["String"];
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryReplyListByCategoryArgs = {
  categoryId: Scalars["Int"];
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryUserArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryUserListArgs = {
  filter?: InputMaybe<UserFilterType>;
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryVacancyArgs = {
  id: Scalars["ID"];
};

/** Read operations of the API */
export type QueryVacancyListByCategoryArgs = {
  categoryId: Scalars["Int"];
  pagination?: InputMaybe<PaginationType>;
};

/** Read operations of the API */
export type QueryVacancySuitableListArgs = {
  filter?: InputMaybe<VacancySuitableFilterType>;
  pagination?: InputMaybe<PaginationType>;
};

/** Refusal Input Type */
export type RefusalInputType = {
  /** Days to change status */
  daysToChange?: InputMaybe<Scalars["Int"]>;
  key?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<RefusalType>;
};

/** Refusal Reasons Dictionary */
export type RefusalReasonType = {
  __typename?: "RefusalReasonType";
  /** Days to change status */
  daysToChange?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type RefusalResponse = Error | RefusalReasonType;

/**
 *
 *     Причины отказа
 *
 *     CLAIM = 'Заявка'
 *     REPLY = 'Отклик'
 *     VACANCY = 'Вакансия'
 *     CANDIDATE_REFUSAL = 'Отказ кандидата'
 *     CANDIDATE_DENIED = 'Отказано кандидату'
 *     ALL = 'Все'
 *
 */
export enum RefusalType {
  All = "ALL",
  CandidateDenied = "CANDIDATE_DENIED",
  CandidateRefusal = "CANDIDATE_REFUSAL",
  Claim = "CLAIM",
  Reply = "REPLY",
  Vacancy = "VACANCY",
}

/** Vacancy extra data */
export type ReplyExtraInput = {
  remoteId?: InputMaybe<Scalars["String"]>;
  remoteUrl?: InputMaybe<Scalars["String"]>;
  remoteVacancyId?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Scalars["String"]>;
};

/** Reply list available filters */
export type ReplyFilterType = {
  /** key of dictionaries -> positions_category */
  categoryId?: InputMaybe<Scalars["String"]>;
  /** reply city */
  city?: InputMaybe<Scalars["String"]>;
  /** department id */
  department?: InputMaybe<Scalars["Int"]>;
  /** vacancy employer name */
  employer?: InputMaybe<Scalars["String"]>;
  /** reply employment key of dict_employments */
  employment?: InputMaybe<Scalars["String"]>;
  /** owner id */
  owner?: InputMaybe<Scalars["Int"]>;
  /** period created */
  period?: InputMaybe<PeriodType>;
  /** key of dictionaries -> positions */
  position?: InputMaybe<Scalars["String"]>;
  /** search query by name */
  search?: InputMaybe<Scalars["String"]>;
  /** key of dictionaries -> source */
  source?: InputMaybe<Source>;
  /** key of dictionaries -> replyStatuses */
  status?: InputMaybe<ReplyStatus>;
  /** vacancy id */
  vacancy?: InputMaybe<Scalars["Int"]>;
  /** vacancy city fias id */
  vacancyCityFiasId?: InputMaybe<Scalars["String"]>;
};

/** Input type reply */
export type ReplyInputType = {
  actionPayClick?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<AddressInputType>;
  age?: InputMaybe<Scalars["Int"]>;
  chatLink?: InputMaybe<Scalars["String"]>;
  /** Citizenship */
  citizenshipId?: InputMaybe<Scalars["Int"]>;
  cityAdsClick?: InputMaybe<Scalars["String"]>;
  /** Claim */
  claimId?: InputMaybe<Scalars["Int"]>;
  /** Company */
  companyId?: InputMaybe<Scalars["Int"]>;
  contacts: ContactsInputType;
  /** Department */
  departmentId?: InputMaybe<Scalars["Int"]>;
  /** Employment */
  employmentId?: InputMaybe<Scalars["Int"]>;
  /** Processing expiration */
  expiredTime?: InputMaybe<Scalars["DateTime"]>;
  /** extra data from integrators */
  extra?: InputMaybe<ReplyExtraInput>;
  firstName?: InputMaybe<Scalars["String"]>;
  managerComment?: InputMaybe<Scalars["String"]>;
  /** Manager */
  managerId?: InputMaybe<Scalars["Int"]>;
  /** Owner */
  ownerId?: InputMaybe<Scalars["Int"]>;
  patronymic?: InputMaybe<Scalars["String"]>;
  positionId: Scalars["Int"];
  russianCitizenship?: InputMaybe<Scalars["Boolean"]>;
  secondName?: InputMaybe<Scalars["String"]>;
  source: Source;
  /** status reply, default - new */
  status?: InputMaybe<ReplyStatus>;
  userComment?: InputMaybe<Scalars["String"]>;
  userContent?: InputMaybe<Scalars["String"]>;
  utm?: InputMaybe<UtmInputType>;
  /** Vacancy */
  vacancyId: Scalars["Int"];
};

export type ReplyListResponse = Error | ReplyListSuccess;

export type ReplyListSuccess = {
  __typename?: "ReplyListSuccess";
  data: Array<ReplyType>;
  meta: PageInfoType;
};

/**
 *
 *     Статус отклика
 *
 *     NEW = "Новый"
 *     INTERVIEW = "Cобеседование"
 *     CANDIDATE = "Кандидат"
 *     TRANSFERRED_TO_HR_FROM_CC = "Передано МПП от КЦ"
 *     DENIED = "Отказано"
 *     EXPIRED = "Просрочен"
 *     NOT_PROCESSED = "Не обработан"
 *     DECORATED = "Оформлен"
 *     REFUSAL_CANDIDATE = "Отказ кандидата"
 *     DOESNT_GET_TOUCH = "Не выходит на связь"
 *     CALLBACK = "Нужно перезвонить"
 *     NOT_THROUGH = "Не дозвонились"
 *     RESERVE = "Резерв"
 *     OTHER = "Другое"
 *
 */
export enum ReplyStatus {
  Callback = "CALLBACK",
  Candidate = "CANDIDATE",
  Decorated = "DECORATED",
  Denied = "DENIED",
  DoesntGetTouch = "DOESNT_GET_TOUCH",
  Expired = "EXPIRED",
  Interview = "INTERVIEW",
  New = "NEW",
  NotProcessed = "NOT_PROCESSED",
  NotThrough = "NOT_THROUGH",
  Other = "OTHER",
  RefusalCandidate = "REFUSAL_CANDIDATE",
  Reserve = "RESERVE",
  TransferredToHrFromCc = "TRANSFERRED_TO_HR_FROM_CC",
}

/** Reply Statuses Dictionary */
export type ReplyStatusesType = {
  __typename?: "ReplyStatusesType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

export type ReplyTransferredListResponse = Error | ReplyTransferredListSuccess;

export type ReplyTransferredListSuccess = {
  __typename?: "ReplyTransferredListSuccess";
  data: Array<ReplyType>;
};

/** A single reply */
export type ReplyType = {
  __typename?: "ReplyType";
  actionPayClick?: Maybe<Scalars["String"]>;
  address?: Maybe<AddressType>;
  age?: Maybe<Scalars["Int"]>;
  candidate?: Maybe<CandidateType>;
  candidateId?: Maybe<Scalars["String"]>;
  chatLink?: Maybe<Scalars["String"]>;
  citizenshipId?: Maybe<Scalars["Int"]>;
  cityAdsClick?: Maybe<Scalars["String"]>;
  claimId?: Maybe<Scalars["Int"]>;
  companyId: Scalars["Int"];
  contacts: ContactsType;
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  departmentId: Scalars["Int"];
  employmentId?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["DateTime"]>;
  expiredAtAfterTransferFromCc?: Maybe<Scalars["DateTime"]>;
  expiredCallTime?: Maybe<Scalars["DateTime"]>;
  expiredTime?: Maybe<Scalars["DateTime"]>;
  expiredTimeAfterTransferFromCc?: Maybe<Scalars["DateTime"]>;
  firstName?: Maybe<Scalars["String"]>;
  hasChat?: Maybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  inWork?: Maybe<Scalars["Boolean"]>;
  isCreatedAtBusinessHours?: Maybe<Scalars["Boolean"]>;
  isExpiredAtBusinessHours?: Maybe<Scalars["Boolean"]>;
  isMain?: Maybe<Scalars["Boolean"]>;
  isTransferFromCcAtBusinessHours?: Maybe<Scalars["Boolean"]>;
  isTransferred?: Maybe<Scalars["Boolean"]>;
  manager?: Maybe<ManagerType>;
  managerComment?: Maybe<Scalars["String"]>;
  managerId?: Maybe<Scalars["Int"]>;
  notCallCount?: Maybe<Scalars["Int"]>;
  owner?: Maybe<OwnerType>;
  ownerId: Scalars["Int"];
  patronymic?: Maybe<Scalars["String"]>;
  positionId: Scalars["Int"];
  refusalId?: Maybe<Scalars["Int"]>;
  refusalText?: Maybe<Scalars["String"]>;
  russianCitizenship?: Maybe<Scalars["Boolean"]>;
  s2Id?: Maybe<Scalars["String"]>;
  secondName?: Maybe<Scalars["String"]>;
  source: Source;
  status: ReplyStatus;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  userComment?: Maybe<Scalars["String"]>;
  userContent?: Maybe<Scalars["String"]>;
  utm?: Maybe<UtmType>;
  vacancy?: Maybe<VacancyType>;
  vacancyId?: Maybe<Scalars["Int"]>;
};

export type ReplyTypeResponse = Error | ReplyType;

/** Update type reply */
export type ReplyUpdateType = {
  address?: InputMaybe<AddressInputType>;
  contacts?: InputMaybe<ContactsInputType>;
};

/** Salary */
export type SalaryInputType = {
  /** Валюта */
  currency?: Scalars["String"];
  /** От */
  from?: Scalars["Int"];
  /** До/после вычета налогов */
  gross?: Scalars["Boolean"];
  /** До */
  to?: Scalars["Int"];
};

/** Salary */
export type SalaryType = {
  __typename?: "SalaryType";
  /** Валюта */
  currency: Scalars["String"];
  /** От */
  from: Scalars["Int"];
  /** До/после вычета налогов */
  gross: Scalars["Boolean"];
  /** До */
  to: Scalars["Int"];
};

/** A socket message */
export type SocketMessageType = {
  __typename?: "SocketMessageType";
  content: Scalars["String"];
  owner: Scalars["Int"];
  timestamp: Scalars["DateTime"];
};

/**
 *
 *     Сортировка
 *
 *     RANDOM = 'В случайном порядке'
 *     ID = 'По ID'
 *     DATE = 'По дате публикации'
 *     SALARY = 'По ЗП'
 *
 */
export enum Sort {
  Date = "DATE",
  Id = "ID",
  Random = "RANDOM",
  Salary = "SALARY",
}

/**
 *
 *     Направление сортировки
 *
 *     ASC = 'По возрастанию'
 *     DESC = 'По убыванию'
 *
 */
export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

/** Sort Dictionary */
export type SortDirectionType = {
  __typename?: "SortDirectionType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** Sort Dictionary */
export type SortType = {
  __typename?: "SortType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/**
 *
 *     Источник
 *
 *     WEBSITE = 'Сайт'
 *     CLAIM = 'Заявка'
 *     REPLY = 'Отклик'
 *     TELEGRAM = 'Telegram'
 *     HH = 'HeadHunter'
 *     AVITO = 'Avito.Работа'
 *     VK = 'Вконтакте' # 1
 *     NERAB = 'Нераб'
 *     BCR = 'BCR'
 *     MOBILE = 'Mobile'
 *     VP = 'Ваш Персонал'
 *
 */
export enum Source {
  Avito = "AVITO",
  Bcr = "BCR",
  Claim = "CLAIM",
  Hh = "HH",
  Mobile = "MOBILE",
  Nerab = "NERAB",
  Reply = "REPLY",
  Telegram = "TELEGRAM",
  Vk = "VK",
  Vp = "VP",
  Website = "WEBSITE",
}

/**
 *
 *     Источник
 *
 *     CLAIM = 'Заявка'
 *     REPLY = 'Отклик'
 *     CANDIDATE = 'Кандидат'
 *
 *
 */
export enum SourceHotCall {
  Candidate = "CANDIDATE",
  Claim = "CLAIM",
  Reply = "REPLY",
}

/** Source Dictionary */
export type SourceType = {
  __typename?: "SourceType";
  id: Scalars["Int"];
  /** Ключ */
  key: Scalars["String"];
  logo?: Maybe<Scalars["String"]>;
  /** Значение */
  name: Scalars["String"];
};

/** Specialization Dictionary */
export type SpecializationType = {
  __typename?: "SpecializationType";
  id: Scalars["String"];
  /** Уровень в дереве */
  level?: Maybe<Scalars["Int"]>;
  /** Название */
  name: Scalars["String"];
  /** Родительский элемент в дереве */
  parentId: Scalars["String"];
};

/** Stats */
export type StatsType = {
  __typename?: "StatsType";
  replyCount?: Maybe<Scalars["Int"]>;
  userCount?: Maybe<Scalars["Int"]>;
  vacancyCount?: Maybe<Scalars["Int"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  avitoInfo: AvitoMessageType;
  calls: HotCallType;
  count: Scalars["Int"];
  messages: SocketMessageType;
  ping: Scalars["Boolean"];
};

export type SubscriptionCountArgs = {
  target?: Scalars["Int"];
};

export type SubscriptionMessagesArgs = {
  channel: Scalars["String"];
};

export type SuitableVacancyListResponse = Error | SuitableVacancyListSuccess;

export type SuitableVacancyListSuccess = {
  __typename?: "SuitableVacancyListSuccess";
  data: Array<VacancySuitableType>;
  meta: PageInfoType;
};

/** UTM */
export type UtmInputType = {
  campaign?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  medium?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  term?: InputMaybe<Scalars["String"]>;
};

/** UTM */
export type UtmType = {
  __typename?: "UTMType";
  campaign?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  medium?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  term?: Maybe<Scalars["String"]>;
};

/** User list available filters */
export type UserFilterType = {
  /** users's company */
  companyId?: InputMaybe<Scalars["Int"]>;
  /** users's department */
  departmentId?: InputMaybe<Scalars["Int"]>;
  /** users's role */
  role?: InputMaybe<UserRole>;
  search?: InputMaybe<Scalars["String"]>;
};

/** A user form. */
export type UserForm = {
  city?: InputMaybe<Scalars["String"]>;
  companyId: Scalars["Int"];
  departmentId: Scalars["Int"];
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  role?: UserRole;
  secondName?: InputMaybe<Scalars["String"]>;
  tz?: InputMaybe<Scalars["String"]>;
};

export type UserListResponse = Error | UserListSuccess;

export type UserListSuccess = {
  __typename?: "UserListSuccess";
  data: Array<UserType>;
  meta: PageInfoType;
};

/**
 *
 *     Роль пользователя
 *
 *     ADMIN = "Суперадминистратор"
 *     MANAGER = "Менеджер"
 *     OWNER = "Владелец"
 *     MODERATOR = "Супермодератор"
 *     HRBP = "HR BP"
 *     EMPLOYEE_CC = "Сотрудник КЦ"
 *
 */
export enum UserRole {
  Admin = "ADMIN",
  EmployeeCc = "EMPLOYEE_CC",
  Hrbp = "HRBP",
  Manager = "MANAGER",
  Moderator = "MODERATOR",
  Owner = "OWNER",
}

/** User Roles Dictionary */
export type UserRolesType = {
  __typename?: "UserRolesType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** A single user. */
export type UserType = {
  __typename?: "UserType";
  city?: Maybe<Scalars["String"]>;
  company?: Maybe<CompanyType>;
  companyId: Scalars["Int"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  department?: Maybe<DepartmentType>;
  departmentId: Scalars["Int"];
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  idpId?: Maybe<Scalars["String"]>;
  isDeleted?: Maybe<Scalars["Boolean"]>;
  mangoId?: Maybe<Scalars["Int"]>;
  oktellId?: Maybe<Scalars["String"]>;
  oktellLogin?: Maybe<Scalars["String"]>;
  oktellPassword?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  role: UserRole;
  s2Id?: Maybe<Scalars["Int"]>;
  secondName?: Maybe<Scalars["String"]>;
  tz?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UserTypeResponse = Error | UserType;

/** Vacancy additional avito fields data */
export type VacancyAvitoInput = {
  /** Сфера, значение поля id из словаря specialization_avito */
  businessArea?: InputMaybe<Scalars["Int"]>;
  /** Опыт, значение поля key из словаря experience_avito */
  experience?: InputMaybe<Scalars["String"]>;
  /** Название вакансии на Авито */
  name?: InputMaybe<Scalars["String"]>;
};

/** Vacancy additional avito fields data */
export type VacancyAvitoType = {
  __typename?: "VacancyAvitoType";
  /** Сфера, значение поля id из словаря specialization_avito */
  businessArea?: Maybe<Scalars["Int"]>;
  /** Опыт, значение поля key из словаря experience_avito */
  experience?: Maybe<Scalars["String"]>;
  /** Название вакансии на Авито */
  name?: Maybe<Scalars["String"]>;
};

/** Count all published vacancy */
export type VacancyCountType = {
  __typename?: "VacancyCountType";
  count: Scalars["Int"];
};

/** Vacancy extra data */
export type VacancyExtraInput = {
  publicationFinishTime?: InputMaybe<Scalars["String"]>;
  remoteId?: InputMaybe<Scalars["String"]>;
  remoteUrl?: InputMaybe<Scalars["String"]>;
};

/**
 *
 *     Тип дополнительных данных публикации
 *
 *     AVITO = "AVITO"
 *     HH = "HH"
 *
 */
export enum VacancyExtraSourceType {
  Avito = "AVITO",
  Hh = "HH",
}

/** Vacancy extra data */
export type VacancyExtraType = {
  __typename?: "VacancyExtraType";
  publicationFinishTime?: Maybe<Scalars["String"]>;
  remoteId?: Maybe<Scalars["String"]>;
  remoteUrl?: Maybe<Scalars["String"]>;
};

/** Public vacancy list available filters */
export type VacancyFilterType = {
  /** vacancy city */
  city?: InputMaybe<Scalars["String"]>;
  /** vacancy city fias id */
  cityFiasId?: InputMaybe<Scalars["String"]>;
  /** vacancy employment key of dict_employments */
  employment?: InputMaybe<Scalars["String"]>;
  /** vacancy metro station name */
  metro?: InputMaybe<Scalars["String"]>;
  /** vacancy position key of dict_positions */
  position?: InputMaybe<Scalars["String"]>;
  /** range of salary */
  salary?: InputMaybe<SalaryInputType>;
  /** salary employment key of dict_employments */
  salaryFrom?: InputMaybe<Scalars["Int"]>;
  /** search query by name. 2+ symbols */
  search?: InputMaybe<Scalars["String"]>;
  /** tag key */
  tag?: InputMaybe<Scalars["String"]>;
};

/** Vacancy additional hh fields data */
export type VacancyHhInput = {
  /** Можно с инвалидностью */
  acceptHandicapped?: InputMaybe<Scalars["Boolean"]>;
  /** Можно до 18 лет */
  acceptKids?: InputMaybe<Scalars["Boolean"]>;
  /** Возможно временное трудоустройство */
  acceptTemporary?: InputMaybe<Scalars["Boolean"]>;
  /** Населенный пункт, значение поля id из словаря area */
  area?: InputMaybe<Scalars["String"]>;
  /** Требуемая категория водительских прав, массив значений поля id из словаря driver_license */
  driverLicense?: InputMaybe<Array<Scalars["String"]>>;
  /** Опыт, значение поля id из словаря experience */
  experience?: InputMaybe<Scalars["String"]>;
  /** Уровень владения иностранным языком, значение поля id из словаря language_level */
  languages?: InputMaybe<Scalars["String"]>;
  /** Название вакансии на HH */
  name?: InputMaybe<Scalars["String"]>;
  /** Профессиональная роль, массив значений поля id из словаря professional_roles */
  professionalRoles?: InputMaybe<Array<Scalars["String"]>>;
};

/** Vacancy additional hh fields data */
export type VacancyHhType = {
  __typename?: "VacancyHHType";
  /** Можно с инвалидностью */
  acceptHandicapped?: Maybe<Scalars["Boolean"]>;
  /** Можно до 18 лет */
  acceptKids?: Maybe<Scalars["Boolean"]>;
  /** Возможно временное трудоустройство */
  acceptTemporary?: Maybe<Scalars["Boolean"]>;
  /** Населенный пункт, значение поля id из словаря area */
  area?: Maybe<Scalars["String"]>;
  /** Требуемая категория водительских прав, массив значений поля id из словаря driver_license */
  driverLicense?: Maybe<Array<Scalars["String"]>>;
  /** Опыт, значение поля id из словаря experience */
  experience?: Maybe<Scalars["String"]>;
  /** Уровень владения иностранным языком, значение поля id из словаря language_level */
  languages?: Maybe<Scalars["String"]>;
  /** Название вакансии на HH */
  name?: Maybe<Scalars["String"]>;
  /** Профессиональная роль, массив значений поля id из словаря professional_roles */
  professionalRoles?: Maybe<Array<Scalars["String"]>>;
};

/** Vacancy Form */
export type VacancyInputType = {
  /** Дополнительные поля для публикации на avito.ru */
  additionalAvito?: InputMaybe<VacancyAvitoInput>;
  /** Дополнительные поля для публикации на hh.ru */
  additionalHh?: InputMaybe<VacancyHhInput>;
  /** Адрес, значение из словаря city */
  address: AddressInputType;
  age?: InputMaybe<AgeInputType>;
  callCenterInfo: NewCallCenter;
  /** Гражданство, id из словаря citizenship */
  citizenshipId?: InputMaybe<Scalars["Int"]>;
  /** Fias_id, значение поля city_fias_id из словаря city */
  city?: InputMaybe<Scalars["String"]>;
  /** Требования */
  claims: Scalars["String"];
  /** Контактные данные */
  contacts: ContactsInputType;
  /** Контрагент */
  counterparty?: InputMaybe<Scalars["String"]>;
  /** Описание вакансии */
  description: Scalars["String"];
  /** Компания, id из словаря employer */
  employerId: Scalars["Int"];
  /** Тип занятости, id из словаря employment */
  employmentId: Scalars["Int"];
  /** Kladr_id адреса вакансии */
  kladrId?: InputMaybe<Scalars["String"]>;
  /** Наличие медицинской книжки */
  medicalBook?: InputMaybe<Scalars["Boolean"]>;
  /** Массив значений из словаря metro, только для г.Москва */
  metro?: InputMaybe<Array<MetroItemInputType>>;
  /** Название вакансии */
  name?: InputMaybe<Scalars["String"]>;
  /** ID гражданств из словаря nationality */
  nationalityId?: InputMaybe<Array<Scalars["Int"]>>;
  /** Количество человек */
  peopleCount?: InputMaybe<Scalars["Int"]>;
  /** Наличие телефона */
  phonePresence?: InputMaybe<Scalars["Boolean"]>;
  platformTags?: InputMaybe<Scalars["String"]>;
  /** Должность, id из словаря position */
  positionId?: Scalars["Int"];
  /** Приоритет */
  priority?: InputMaybe<Scalars["Boolean"]>;
  /** Ставка */
  rate?: InputMaybe<Scalars["String"]>;
  /** Причина */
  reason?: InputMaybe<Scalars["String"]>;
  /** Регион, значение поля region из словаря city */
  region?: InputMaybe<Scalars["String"]>;
  /** Актуальность вакансии, ENUM */
  relevance?: VacancyRelevance;
  /** Ответственный */
  responsiblePerson?: InputMaybe<Scalars["String"]>;
  /** Маршрут */
  route?: InputMaybe<Scalars["String"]>;
  /** Зарплата */
  salary: SalaryInputType;
  /** Расписание */
  schedule?: InputMaybe<Scalars["String"]>;
  /** Конец смены */
  shiftEnd?: InputMaybe<Scalars["DateTime"]>;
  /** Начало смены */
  shiftStart?: InputMaybe<Scalars["DateTime"]>;
  /** Краткое описание */
  shortDescription?: InputMaybe<Scalars["String"]>;
  /** Нужно ли указывать контактные данные менеджера на сайте */
  showContacts?: Scalars["Boolean"];
  /** Подразделение */
  subdivision?: InputMaybe<Scalars["String"]>;
  /** Тег вакансии, значение из словаря tag */
  tagKey?: InputMaybe<Scalars["String"]>;
  /** Условия */
  terms: Scalars["String"];
  /** Торговая точка */
  tradePoint?: InputMaybe<Scalars["String"]>;
};

/** Public vacancy list available filters */
export type VacancyMapFilterType = {
  /** vacancy city */
  city?: InputMaybe<Scalars["String"]>;
  /** vacancy city fias id */
  cityFiasId?: InputMaybe<Scalars["String"]>;
  /** vacancy employment key of dict_employments */
  employment?: InputMaybe<Scalars["ID"]>;
  /** vacancy metro station name */
  metro?: InputMaybe<Scalars["ID"]>;
  /** vacancy position key of dict_positions */
  position?: InputMaybe<Scalars["ID"]>;
  /** range of salary */
  salary?: InputMaybe<SalaryInputType>;
  /** salary employment key of dict_employments */
  salaryFrom?: InputMaybe<Scalars["Int"]>;
  /** search query by name. 2+ symbols */
  search?: InputMaybe<Scalars["String"]>;
  /** tag key */
  tag?: InputMaybe<Scalars["String"]>;
};

/** Nationality item */
export type VacancyNationalityItemType = {
  __typename?: "VacancyNationalityItemType";
  nationality?: Maybe<NationalityType>;
};

/**
 *
 *     Статус вакансии
 *
 *     RELEVANT = "Актуальна"
 *     NOT_RELEVANT = "Не актуальна"
 *
 */
export enum VacancyRelevance {
  NotRelevant = "NOT_RELEVANT",
  Relevant = "RELEVANT",
}

/** Vacancy Relevance Dictionary */
export type VacancyRelevanceModelType = {
  __typename?: "VacancyRelevanceModelType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** Public vacancy list sort */
export type VacancySortType = {
  sortDirection: SortDirection;
  value: Sort;
};

/**
 *
 *     Площадка размещения вакансии
 *
 *     RABOTUT = "Сайт rabotut.ru"
 *     AVITO = "avito.ru"
 *     HH = "hh.ru"
 *
 */
export enum VacancySource {
  Avito = "AVITO",
  Hh = "HH",
  Rabotut = "RABOTUT",
}

/**
 *
 *     Состояние вакансии
 *
 *     ALL = "Все"
 *     EXCEPT_REMOTE = "Кроме удаленных"
 *     ONLY_REMOTE = "Только удаленные"
 *
 */
export enum VacancyState {
  All = "ALL",
  ExceptRemote = "EXCEPT_REMOTE",
  OnlyRemote = "ONLY_REMOTE",
}

/** Vacancy States Dictionary */
export type VacancyStatesType = {
  __typename?: "VacancyStatesType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/**
 *
 *     Статус вакансии
 *
 *     DRAFT = "Черновик"
 *     MODERATION = "На модерации"
 *     PUBLISHED = "Опубликована"
 *     REFUSED = "Заблокирована"
 *     ARCHIVED = "В архиве"
 *
 */
export enum VacancyStatus {
  Archived = "ARCHIVED",
  Draft = "DRAFT",
  InWork = "IN_WORK",
  Moderation = "MODERATION",
  Published = "PUBLISHED",
  Refused = "REFUSED",
}

/** Vacancy Statuses Dictionary */
export type VacancyStatusesType = {
  __typename?: "VacancyStatusesType";
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** Vacancy list available filters */
export type VacancySuitableFilterType = {
  /** ID of candidate */
  candidateId?: InputMaybe<Scalars["String"]>;
};

/** A single vacancy with duration */
export type VacancySuitableType = {
  __typename?: "VacancySuitableType";
  additionalAvito?: Maybe<VacancyAvitoType>;
  additionalHh?: Maybe<VacancyHhType>;
  address: AddressType;
  age: AgeType;
  callCenterInfo: CallCenterInfoType;
  candidateCount?: Maybe<Scalars["Int"]>;
  citizenship?: Maybe<CitizenshipType>;
  city?: Maybe<Scalars["String"]>;
  /** Требования */
  claims?: Maybe<Scalars["String"]>;
  closedAt?: Maybe<Scalars["DateTime"]>;
  contacts: ContactsType;
  /** Контрагент */
  counterparty?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  departmentId?: Maybe<Scalars["Int"]>;
  /** Описание вакансии */
  description?: Maybe<Scalars["String"]>;
  employer?: Maybe<EmployerType>;
  employment?: Maybe<EmploymentType>;
  extraAvito?: Maybe<VacancyExtraType>;
  extraHh?: Maybe<VacancyExtraType>;
  id: Scalars["Int"];
  inBlocked?: Maybe<Scalars["Boolean"]>;
  inWork?: Maybe<Scalars["Boolean"]>;
  /** Kladr_id адреса вакансии */
  kladrId?: Maybe<Scalars["String"]>;
  location?: Maybe<CoordsType>;
  manager: OwnerType;
  managersIds?: Maybe<Array<Scalars["Int"]>>;
  /** Наличие медицинской книжки */
  medicalBook?: Maybe<Scalars["Boolean"]>;
  metro?: Maybe<Array<MetroItemType>>;
  /** Название вакансии */
  name?: Maybe<Scalars["String"]>;
  nationalities?: Maybe<Array<VacancyNationalityItemType>>;
  /** Количество человек */
  peopleCount?: Maybe<Scalars["Int"]>;
  peopleCountClose?: Maybe<Scalars["Int"]>;
  peopleCountOpen?: Maybe<Scalars["Int"]>;
  /** Наличие телефона */
  phonePresence?: Maybe<Scalars["Boolean"]>;
  platformTags?: Maybe<Scalars["String"]>;
  position?: Maybe<PositionType>;
  positionId?: Maybe<Scalars["Int"]>;
  /** Приоритет */
  priority?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["DateTime"]>;
  publishedOnAvito?: Maybe<Scalars["Boolean"]>;
  publishedOnHh?: Maybe<Scalars["Boolean"]>;
  publishedOnRabotut?: Maybe<Scalars["Boolean"]>;
  /** Ставка */
  rate?: Maybe<Scalars["String"]>;
  /** Причина */
  reason?: Maybe<Scalars["String"]>;
  refusal?: Maybe<RefusalReasonType>;
  /** Refusal reason */
  refusalText?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  relevance: VacancyRelevance;
  replyCount?: Maybe<Scalars["Int"]>;
  replyCountNew?: Maybe<Scalars["Int"]>;
  /** Ответственный */
  responsiblePerson?: Maybe<Scalars["String"]>;
  /** Маршрут */
  route?: Maybe<Scalars["String"]>;
  salary: SalaryType;
  /** Расписание */
  schedule?: Maybe<Scalars["String"]>;
  /** Конец смены */
  shiftEnd?: Maybe<Scalars["DateTime"]>;
  /** Начало смены */
  shiftStart?: Maybe<Scalars["DateTime"]>;
  /** Краткое описание */
  shortDescription?: Maybe<Scalars["String"]>;
  /** Нужно ли указывать контактные данные менеджера на сайте */
  showContacts: Scalars["Boolean"];
  status: VacancyStatus;
  /** Подразделение */
  subdivision?: Maybe<Scalars["String"]>;
  tag?: Maybe<VacancyTagType>;
  /** Условия */
  terms?: Maybe<Scalars["String"]>;
  /** Торговая точка */
  tradePoint?: Maybe<Scalars["String"]>;
  /** Время до кандидата на транспорте */
  transitDuration?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  /** Время до кандидата пешком */
  walkingDuration?: Maybe<Scalars["Int"]>;
};

export type VacancyTagResponse = Error | VacancyTagType;

/** Vacancy Tag Dictionary */
export type VacancyTagType = {
  __typename?: "VacancyTagType";
  /** Цвет тега в вакансии */
  color?: Maybe<Scalars["String"]>;
  /** Цвет фона тега в вакансии */
  colorBg?: Maybe<Scalars["String"]>;
  /** Ключ */
  key: Scalars["String"];
  /** Значение */
  name: Scalars["String"];
};

/** A single vacancy */
export type VacancyType = {
  __typename?: "VacancyType";
  additionalAvito?: Maybe<VacancyAvitoType>;
  additionalHh?: Maybe<VacancyHhType>;
  address: AddressType;
  age: AgeType;
  callCenterInfo: CallCenterInfoType;
  candidateCount?: Maybe<Scalars["Int"]>;
  citizenship?: Maybe<CitizenshipType>;
  city?: Maybe<Scalars["String"]>;
  /** Требования */
  claims?: Maybe<Scalars["String"]>;
  closedAt?: Maybe<Scalars["DateTime"]>;
  contacts: ContactsType;
  /** Контрагент */
  counterparty?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  departmentId?: Maybe<Scalars["Int"]>;
  /** Описание вакансии */
  description?: Maybe<Scalars["String"]>;
  employer?: Maybe<EmployerType>;
  employment?: Maybe<EmploymentType>;
  extraAvito?: Maybe<VacancyExtraType>;
  extraHh?: Maybe<VacancyExtraType>;
  id: Scalars["Int"];
  inBlocked?: Maybe<Scalars["Boolean"]>;
  inWork?: Maybe<Scalars["Boolean"]>;
  /** Kladr_id адреса вакансии */
  kladrId?: Maybe<Scalars["String"]>;
  location?: Maybe<CoordsType>;
  manager: OwnerType;
  managersIds?: Maybe<Array<Scalars["Int"]>>;
  /** Наличие медицинской книжки */
  medicalBook?: Maybe<Scalars["Boolean"]>;
  metro?: Maybe<Array<MetroItemType>>;
  /** Название вакансии */
  name?: Maybe<Scalars["String"]>;
  nationalities?: Maybe<Array<VacancyNationalityItemType>>;
  /** Количество человек */
  peopleCount?: Maybe<Scalars["Int"]>;
  peopleCountClose?: Maybe<Scalars["Int"]>;
  peopleCountOpen?: Maybe<Scalars["Int"]>;
  /** Наличие телефона */
  phonePresence?: Maybe<Scalars["Boolean"]>;
  platformTags?: Maybe<Scalars["String"]>;
  position?: Maybe<PositionType>;
  positionId?: Maybe<Scalars["Int"]>;
  /** Приоритет */
  priority?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["DateTime"]>;
  publishedOnAvito?: Maybe<Scalars["Boolean"]>;
  publishedOnHh?: Maybe<Scalars["Boolean"]>;
  publishedOnRabotut?: Maybe<Scalars["Boolean"]>;
  /** Ставка */
  rate?: Maybe<Scalars["String"]>;
  /** Причина */
  reason?: Maybe<Scalars["String"]>;
  refusal?: Maybe<RefusalReasonType>;
  /** Refusal reason */
  refusalText?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  relevance: VacancyRelevance;
  replyCount?: Maybe<Scalars["Int"]>;
  replyCountNew?: Maybe<Scalars["Int"]>;
  /** Ответственный */
  responsiblePerson?: Maybe<Scalars["String"]>;
  /** Маршрут */
  route?: Maybe<Scalars["String"]>;
  salary: SalaryType;
  /** Расписание */
  schedule?: Maybe<Scalars["String"]>;
  /** Конец смены */
  shiftEnd?: Maybe<Scalars["DateTime"]>;
  /** Начало смены */
  shiftStart?: Maybe<Scalars["DateTime"]>;
  /** Краткое описание */
  shortDescription?: Maybe<Scalars["String"]>;
  /** Нужно ли указывать контактные данные менеджера на сайте */
  showContacts: Scalars["Boolean"];
  status: VacancyStatus;
  /** Подразделение */
  subdivision?: Maybe<Scalars["String"]>;
  tag?: Maybe<VacancyTagType>;
  /** Условия */
  terms?: Maybe<Scalars["String"]>;
  /** Торговая точка */
  tradePoint?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type VacancyTypeResponse = Error | VacancyType;

import React, { useEffect } from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { Replies } from "components/redezign/Replies";
import { useAppDispatch } from "services/store/store";
import {
  fetchDailyPlanList,
  fetchVacanciesDictionaries,
} from "services/store/modules/vacanciesList/actions";

export const RepliesPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(fetchDailyPlanList({ date: new Date() })),
      dispatch(fetchVacanciesDictionaries()),
    ]);
  }, [dispatch]);

  return <ContentLayout centralColumn={{ content: <Replies /> }} />;
};

import { DaDataAddressSuggestion } from "react-dadata";
import { UserType, VacancyType } from "graphql/types/types";
import { PublicationStatus } from "common/const/avito";
import { IAvitoVacInfo } from "components/Vacancy/types";
import { PublicationStatus as Status } from "./PublicationStatusModal";

export const getDescription = ({
  description,
  shortDescription,
  terms,
  claims,
  platformTags,
}: Pick<
  VacancyType,
  "description" | "shortDescription" | "claims" | "terms" | "platformTags"
>) => {
  let descriptionTotal = "";

  descriptionTotal += shortDescription
    ? "<p><strong>Краткое описание:</strong></p>" + shortDescription
    : "";

  descriptionTotal += description
    ? "<p><strong>Описание:</strong></p>" + description
    : "";

  descriptionTotal += terms ? "<p><strong>Условия:</strong></p>" + terms : "";

  descriptionTotal += claims
    ? "<p><strong>Требования:</strong></p>" + claims
    : "";

  descriptionTotal += platformTags
    ? "<p><strong>Ключевые слова:</strong></p>" + platformTags
    : "";

  return descriptionTotal;
};

export const getAddress = (addressSuggestion: DaDataAddressSuggestion) => {
  const { data, value } = addressSuggestion || {};
  return {
    region: data?.region || "",
    city: data?.city || "",
    settlement: data?.settlement || "",
    street_type: data?.street_type || "",
    street: data?.street || "",
    house_type: data?.house_type || "",
    house: data?.house || "",
    value: value || "",
    lat: data?.geo_lat ? parseFloat(data.geo_lat) : null,
    lng: data?.geo_lon ? parseFloat(data.geo_lon) : null,
  };
};

export const getSalary = (
  salaryFrom: VacancyType["salary"]["from"] | string,
  salaryTo: VacancyType["salary"]["to"] | string
) => ({
  from: Number(salaryFrom) ?? 0,
  to: Number(salaryTo) ?? 0,
  currency: "RUR",
  gross: true,
});

export const getContacts = (managerId: number, managerList: UserType[]) => {
  const manager = managerList.find((m) => m.id === managerId);

  return {
    fullName: manager ? `${manager.firstName} ${manager.secondName}` : "",
    phone: manager?.phone || "",
    email: manager?.email || "",
  };
};

export const getIsDisabledStatuses = (platform?: IAvitoVacInfo | null) => {
  const { publication_status: publicationStatus } = platform || {};

  return publicationStatus
    ? [
        PublicationStatus.Active,
        PublicationStatus.NotAuthorized,
        PublicationStatus.Activated,
      ].includes(publicationStatus)
    : false;
};

export const EMPTY_PUBLICATION: Status = {
  status: "success" as const,
  message: null,
};

export const OPTION_CATEGORY_OF_CAR = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "M", value: "M" },
];

export const OPTION_BLACK_LIST = [
  { label: "Да", value: true },
  { label: "Нет", value: false },
];

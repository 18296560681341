import React, { useCallback, useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  isLoadingVacancyActivity,
  vacancyActivityMeta,
  currentVacancyActivity,
  clearVacancyActivity,
  getVacancyActivity
} from "services/store/modules/vacancies";
import { Notification } from "components/UI/Notifications/Notification";
import { VacancyType } from "graphql/types/types";
import { Loader } from "components/UI/Loader";
import { Button } from "components/UI/Button";
import { HistoryItem, Props as HistoryProps } from "components/HistoryItem";
import { CommentItem, Props as CommentProps } from "components/CommentItem";
import { IVacancyComment, IVacancyHistory, ModelType } from "services/store/modules/vacancies/types";

import "./styles.scss";

type Props = {
  vacancyId: VacancyType["id"];
  activeKey: string;
};

export const VacancyAllHistory: React.FC<Props> = ({ vacancyId, activeKey }) => {
  const dispatch = useAppDispatch();

  const { page, size, total } = useAppSelector(vacancyActivityMeta);
  const allHistoryList = useAppSelector(currentVacancyActivity);
  const loading = useAppSelector(isLoadingVacancyActivity);

  const isShowPagination = useMemo(
    () => total > page * size,
    [page, size, total]
  );

  const getVacancyAllHistory = useCallback(() => {
    dispatch(
      getVacancyActivity({
        id: vacancyId,
        page: page || 0,
      })
    );
  }, [page, vacancyId, dispatch]);

  useEffect(() => {
    if (activeKey !== "all") {
      dispatch(clearVacancyActivity());
    } else {
      getVacancyAllHistory();
    }
  }, [activeKey, dispatch, getVacancyAllHistory]);

  useEffect(() => {
    return () => {
      dispatch(clearVacancyActivity());
    };
  }, []);

  const componentByModelType: Record<ModelType, React.FC<HistoryProps & CommentProps>> = {
    comment: CommentItem,
    event: HistoryItem,
  }
  

  if (loading) {
    return <Loader color="blue" size="large" />;
  }

  return (
    <div className="historyList">
      {allHistoryList.length ? (
        <>
          {allHistoryList.map((item) => {
            const ItemComponent = componentByModelType[item.modelType];
            return (
              <ItemComponent className="historyList-item" key={item.id} history={item as IVacancyHistory} comment={item as IVacancyComment} />
            );
          })}

          {isShowPagination && (
            <Button
              className="historyList-showMore"
              variant="link"
              onClick={getVacancyAllHistory}
            >
              Показать еще
            </Button>
          )}
        </>
      ) : (
        <Notification type="warning" title="Пока нет истории" />
      )}
    </div>
  );
};

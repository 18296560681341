import { useState } from "react";
import { EDIT_REPLY_STATUS_MODERATOR } from "../../common/const/status";
import { Modal, Select, Input } from "antd";
import { replyDecline } from "../../services/store/modules/replies";
import toaster from "components/UI/Notifications/Notification";
import { IReply } from "../../services/store/modules/replies/types";
import { IStatus } from "../../services/store/modules/directory/types";
import { useSelector } from "react-redux";
import {
  refusalReasons,
  replyStatuses,
} from "../../services/store/modules/directory";
import {
  CandidateStatus,
  CandidateType,
  RefusalReasonType,
  RefusalType,
  ReplyStatus,
} from "../../graphql/types/types";
import { IResponseReplyDecline } from "./type";
import { NotThrowPopup } from "../Calls/NotThrowPopup";
import { isLoadingCallsSelector } from "services/store/modules/calls";
import { changeCandidateStatus } from "services/store/modules/candidate/actions";
import { useAppDispatch } from "services/store/store";

const { TextArea } = Input;

interface IReplyStatusSwitcherProps {
  item?: IReply;
  status?: string;
  disabled?: boolean;
  onChange: (status: string, notThroughReason?: string) => void;
  onReplyDecline: (e: IResponseReplyDecline) => void;
  candidate?: CandidateType;
}

export const ReplyStatusSwitcher = ({
  item,
  status,
  candidate,
  disabled,
  onChange,
  onReplyDecline,
}: IReplyStatusSwitcherProps) => {
  const dispatch = useAppDispatch();
  //Data
  const [isModalDenied, setModalDenied] = useState(false);
  const [typeDenied, setTypeDenied] = useState<string | undefined>(undefined);
  const [commentDenied, setCommentDenied] = useState("");
  const [isStatus, setStatus] = useState<string>();
  const replyStatusDictionary = useSelector(replyStatuses);
  const reasonsRefusal = useSelector(refusalReasons);
  const isLoading = useSelector(isLoadingCallsSelector);

  // Computed
  const statusList = () => {
    if (replyStatusDictionary?.length) {
      return replyStatusDictionary.filter((item: IStatus) =>
        EDIT_REPLY_STATUS_MODERATOR.includes?.((item?.key || "") as ReplyStatus)
      );
    }
    return [];
  };
  const access = (key: string) => {
    if (
      (ReplyStatus.NotThrough === key || ReplyStatus.DoesntGetTouch === key) &&
      (ReplyStatus.NotThrough === status ||
        ReplyStatus.DoesntGetTouch === status)
    ) {
      return true;
    }
    return false;
  };
  const editStatus = (key: string, reason?: string) => {
    if (ReplyStatus.Denied === key || ReplyStatus.RefusalCandidate === key) {
      setStatus(key);
      setModalDenied(true);
    } else {
      onChange(key, reason);
      setStatus(undefined);
    }
  };

  const mapDataToOptions = () => {
    if (reasonsRefusal.length) {
      const data = reasonsRefusal || [];
      const replyReasons = data.filter(
        (item) =>
          item.type === (RefusalType.Reply || "REPLY") ||
          item.type === (RefusalType.All || "ALL")
      );
      return replyReasons.map((el: RefusalReasonType) => (
        <Select.Option value={el?.key || "---"} key={el.key}>
          {el?.name}
        </Select.Option>
      ));
    }
  };

  // Methors
  const closeModal = () => {
    setModalDenied(false);
    setTypeDenied(undefined);
    setCommentDenied("");
  };
  const saveReplyDecline = async () => {
    if (item?.id && isStatus && typeDenied && commentDenied.length) {
      replyDecline(item?.id, isStatus, typeDenied, commentDenied)
        .then(() => {
          setModalDenied(false);
          setTypeDenied(undefined);
          setCommentDenied("");
          const reasons = reasonsRefusal?.filter(
            (item) =>
              item.type === (RefusalType.Reply || "REPLY") ||
              item.type === (RefusalType.All || "ALL")
          );
          const refusalId = reasons.find((item) => item.key === typeDenied);
          onReplyDecline({
            id: item.id,
            status: isStatus,
            typeDenied: refusalId?.id || -1,
            commentDenied: commentDenied || "",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (candidate?.id && isStatus && commentDenied.length) {
      const payload = {
        id: candidate.id,
        status: isStatus as CandidateStatus,
        comment: commentDenied,
      };
      try {
        const result = await dispatch(changeCandidateStatus(payload)).unwrap();
        !result?.changeCandidateStatus
          ? toaster.error({ title: "Ошибка изменения статуса" })
          : toaster.success({ title: "Статус изменен" });
      } catch (error) {
        toaster.error(
          { title: `Ошибка: ${error.message}` },
          { autoClose: 3000 }
        );
      } finally {
        setModalDenied(false);
        setTypeDenied(undefined);
        setCommentDenied("");

        onReplyDecline({
          id: candidate.id,
          status: isStatus,
          typeDenied: -1,
          commentDenied: commentDenied || "",
        });
      }
    } else {
      toaster.error({ title: "Все поля обязательны для заполнения" });
    }
  };

  return (
    <div className="status-panel">
      <h4 className="status-panel__title">Изменить статус</h4>
      <div className="status-list">
        {!!statusList().length &&
          statusList().map((item) =>
            item?.key === "NOT_THROUGH" ? (
              <NotThrowPopup
                isLoading={isLoading}
                key={item.key}
                onChange={(e) => editStatus("NOT_THROUGH", e)}
              />
            ) : (
              <button
                disabled={isLoading || disabled || access(item?.key || "")}
                key={item.key}
                className={`status-button status--${item.key} ${
                  disabled ? "status-button--disabled" : ""
                }`}
                onClick={() => editStatus(item?.key || "")}
              >
                {item.name}
              </button>
            )
          )}
      </div>
      <Modal
        title={
          <div className="reply-modal">
            <h3 className="reply-modal__title">Причина отказа</h3>
            {item?.id ? (
              <span className="reply-modal__text">Выберите причину отказа</span>
            ) : (
              <span className="reply-modal__text">Укажите комментарий</span>
            )}
          </div>
        }
        centered
        maskClosable={false}
        onCancel={closeModal}
        visible={isModalDenied}
        footer={[
          <button className="modal-button__cancel" onClick={closeModal} key="2">
            Отменить
          </button>,
          <button
            className="modal-button__ok"
            onClick={saveReplyDecline}
            key="1"
          >
            Принять
          </button>,
        ]}
      >
        <div>
          {item?.id && (
            <Select
              placeholder="Выберете причину"
              className="modal-select"
              value={typeDenied}
              onChange={(event) => {
                setTypeDenied(event);
              }}
            >
              {mapDataToOptions()}
            </Select>
          )}
          <label className="modal-textarea">
            Комментарий
            <TextArea
              rows={4}
              value={commentDenied}
              onChange={(event) => setCommentDenied(event.target.value)}
            />
          </label>
        </div>
      </Modal>
    </div>
  );
};

import React from "react";

import { Button } from "components/UI/Button";
import { Excel, Mail } from "components/UI/icons";

import "./styles.scss";

type Props = {
  reportUrl?: string;
  toggleOpenSend: () => void;
};

export const FilterActions: React.FC<Props> = ({
  reportUrl,
  toggleOpenSend,
}) => {
  return (
    <div className="filter-actions">
      <Button
        icon={<Mail />}
        className="filter-actions-item"
        type="button"
        variant="primary"
        onClick={toggleOpenSend}
      >
        E-mail
      </Button>

      {reportUrl ? (
        <a href={reportUrl} download="report" className="filter-actions-item">
          <Button icon={<Excel />} type="button" variant="primary">
            Выгрузить xls
          </Button>
        </a>
      ) : null}
    </div>
  );
};

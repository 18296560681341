import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CandidateType, DailyPlanType, ReplyType } from "graphql/types/types";
import { getReply, getReplyListByCategory } from "./actions";
import { getInitialState } from "services/store/helpers";
import { AddReplayPayload, DisabledRepliesState } from "./types";

export type RepliesListByIdState = ReplyType[];

const repliesListByCategorySlice = createSlice({
  name: "repliesListByCategory",
  initialState: getInitialState<RepliesListByIdState>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReplyListByCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReplyListByCategory.fulfilled, (state, { payload }) => {
        state.data = payload?.replyListByCategory.data ?? [];
        state.meta = payload?.replyListByCategory.meta ?? null;
        state.loading = false;
      })
      .addCase(getReplyListByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

type ActiveCategoryState = {
  activeCategory: null | DailyPlanType["categoryId"];
};

const activeCategoryInitialState: ActiveCategoryState = {
  activeCategory: null,
};

const activeCategorySlice = createSlice({
  name: "activeCategory",
  initialState: activeCategoryInitialState,
  reducers: {
    setActiveCategory(
      state,
      { payload }: PayloadAction<DailyPlanType["categoryId"] | null>
    ) {
      state.activeCategory = payload;
    },
  },
});

const replySlice = createSlice({
  name: "reply",
  initialState: getInitialState<ReplyType>(),
  reducers: {
    setReplyInWork(state, { payload }: PayloadAction<{ inWork: boolean }>) {
      if (!state.data) return;
      state.data.inWork = payload.inWork;
    },
    updateCandidateInReply(state, { payload }: PayloadAction<CandidateType>) {
      if (!state.data) return;
      state.data.candidate = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReply.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReply.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.reply;
      })
      .addCase(getReply.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

const disabledRepliesInitialState: DisabledRepliesState = {
  list: {},
};

const disabledRepliesSlice = createSlice({
  name: "disabledReplies",
  initialState: disabledRepliesInitialState,
  reducers: {
    addReply(state, { payload }: PayloadAction<AddReplayPayload>) {
      state.list = {
        ...state.list,
        [payload.replyId]: payload,
      };
    },
    removeReplay(
      state,
      { payload }: PayloadAction<{ replyId: ReplyType["id"] }>
    ) {
      delete state.list[payload.replyId];
    },
  },
});

export const { setActiveCategory } = activeCategorySlice.actions;
export const { setReplyInWork, updateCandidateInReply } = replySlice.actions;
export const { addReply, removeReplay } = disabledRepliesSlice.actions;

export const repliesListReducer = combineReducers({
  activeCategory: activeCategorySlice.reducer,
  repliesListByCategory: repliesListByCategorySlice.reducer,
  reply: replySlice.reducer,
  disabledReplies: disabledRepliesSlice.reducer,
});

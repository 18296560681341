import React from "react";

import { ClaimType } from "graphql/types/types";
import Text from "components/UI/Text";
import { SOURCE_TYPE } from "common/const/source";
import { getUtmDetails } from "./helpers";

import "./styles.scss";

interface Props {
  claim: ClaimType;
}

interface DetailProps {
  label: string;
  value: React.ReactNode;
}

const Detail: React.FC<DetailProps> = ({ label, value }) => (
  <div className="reply-detail">
    <Text className="reply-detail-label">{label}</Text>
    <Text className="reply-detail-value">{value}</Text>
  </div>
);

export const ClaimDetails: React.FC<Props> = ({ claim }) => {
  const details = [
    {
      label: "Источник:",
      value: SOURCE_TYPE[claim.source],
    },
    {
      label: "UTM:",
      value: (
        <>
          {getUtmDetails(claim.utm).map(({ label, value }) => (
            <Text key={label} className="reply-detail-value">
              {label}: {value}
            </Text>
          ))}
        </>
      ),
    },
    {
      label: "Информация:",
      value: claim.userContent ?? "Не указана",
    },
  ];

  return (
    <div className="reply-details global-scrollbar">
      {details.map(({ label, value }, index) => (
        <Detail label={label} value={value} key={index} />
      ))}
    </div>
  );
};

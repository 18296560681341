import React from "react";

import { useAppSelector } from "services/store/store";
import { Props as TableProps, Table } from "components/UI/Table";
import { PlanHeader } from "./components/PlanHeader";
import { FieldPlanCount } from "./components/FieldPlanCount";
import { PlansName } from "common/const/plans";
import {
  selectPlansData,
  selectPlansLoading,
} from "services/store/modules/plans/selectors";
import { HotCallPlanType } from "graphql/types/types";

import "./styles.scss";

type Props = {
  planName: PlansName;
};

export const Plan: React.FC<Props> = ({ planName }) => {
  const callsPlanData = useAppSelector(selectPlansData);
  const callsPlanLoading = useAppSelector(selectPlansLoading);

  const columns: TableProps<HotCallPlanType>["columns"] = [
    {
      title: "ФИО пользователя",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Департамент",
      dataIndex: "departmentName",
      key: "departmentName",
    },
    {
      title: "План",
      dataIndex: "countCalls",
      key: "countCalls",

      render: (countCalls, { userId }) => (
        <FieldPlanCount
          initialValue={countCalls || 0}
          managerId={userId || 0}
        />
      ),
    },
  ];

  return (
    <div className="plan">
      <PlanHeader />
      <Table
        className="plan-table global-scrollbar"
        dataSource={callsPlanData}
        columns={columns}
        loading={callsPlanLoading}
      />
    </div>
  );
};

import React from "react";
import cn from "classnames/dedupe";
import { Dropdown as AntDropdown, DropdownProps } from "antd";
import type { MenuProps } from "antd";

import "./styles.scss";

export interface Props extends DropdownProps {
  className?: string;
  items: MenuProps["items"];
  overlayClassName?: string;
}

export const Dropdown: React.FC<Props> = ({
  className,
  overlayClassName,
  children,
  items,
  ...props
}) => {
  return (
    <AntDropdown
      className={cn("ui-dropdown", className)}
      overlayClassName={cn("ui-dropdown-overlay", overlayClassName)}
      menu={{ items }}
      {...props}
    >
      {children}
    </AntDropdown>
  );
};

import React from "react";
import { Circles } from "react-loader-spinner";

interface Props {
  size?: Size;
  className?: string;
  loading: boolean;
}

type Size = "small" | "normal" | "large";
type Value = "24" | "40" | "60";

const SIZE: Record<Size, Value> = {
  small: "24",
  normal: "40",
  large: "60",
};

export const Spinner: React.FC<Props> = ({
  size = "normal",
  loading,
  className,
}) => {
  return (
    <Circles
      wrapperClass={className}
      visible={loading}
      height={SIZE[size]}
      width={SIZE[size]}
      color="#6366F1"
    />
  );
};

import { useState } from "react";
import { ViewMode } from "components/ViewModeToggle";

export const useViewMode = (initialMode: ViewMode = "grid") => {
  const [mode, setMode] = useState<ViewMode>(initialMode);

  const handleChangeMode = (newMode: ViewMode) => setMode(newMode);

  return { mode, handleChangeMode };
};

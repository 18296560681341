import React, { useCallback, useState } from "react";
import { Moment } from "moment";

import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectCandidateStatuses } from "services/store/modules/candidate/selectors";
import {
  CandidateStatus,
  CandidateType,
  RefusalReasonType,
  VacancyType,
} from "graphql/types/types";
import { removeCandidate } from "services/store/modules/candidates";
import { updateCandidateInReply } from "services/store/modules/repliesList";
import { useCandidateStatus } from "common/hooks/candidate/useCandidateStatus";
import { Option } from "components/UI/Cascader";
import { selectRefusalReasonsByType } from "services/store/modules/directory";
import { getSelectOptions } from "components/UI/Select/helpers";
import FormDatePicker from "components/UI/Form/FormDatePicker";
import { CustomDropDown } from "./CustomDropDown";
import { ChevronDown, ChevronUp } from "components/UI/icons";

import "./styles.scss";

interface Props {
  candidate: CandidateType;
  status: CandidateStatus;
  vacancyId?: VacancyType["id"];
  disable?: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

export const FieldStatus: React.FC<Props> = ({
  status,
  candidate,
  vacancyId,
  setIsModalOpen,
}) => {
  const dispatch = useAppDispatch();

  const { updateCandidateStatus } = useCandidateStatus({ isShowToaster: true });

  const candidateStatuses = useAppSelector(selectCandidateStatuses);
  const refusalReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, "CANDIDATE_REFUSAL")
  );
  const deniedReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, "CANDIDATE_DENIED")
  );

  const [candidateStatus, setCandidateStatus] =
    useState<CandidateStatus>(status);
  const [open, setOpen] = useState(false);

  const onChange = (status: string) => {
    setCandidateStatus(status as CandidateStatus);
  };

  const getChildrenOptions = (status: CandidateStatus) => {
    const type =
      (status === CandidateStatus.RefusalCandidate && "reason") ||
      (status === CandidateStatus.Callback && "date") ||
      (status === CandidateStatus.Denied && "denied") ||
      null;
    const renderByType = {
      reason: getSelectOptions(refusalReasons),
      denied: getSelectOptions(deniedReasons),
      date: [
        {
          label: (
            <FormDatePicker
              label="Дата и время"
              style={{ width: "200px" }}
              format="DD.MM.YYYY HH:mm"
              placeholder="DD.MM.YYYY HH:mm"
              size="small"
              showTime
              onClick={(e) => e.stopPropagation()}
              onOk={(date) => {
                updateStatus(candidateStatus, undefined, date);
              }}
            />
          ),
          value: "date",
        },
      ],
    };

    if (!type) return [];

    return renderByType[type];
  };

  const updateStatus = useCallback(
    async (
      status: CandidateStatus,
      reason?: RefusalReasonType["id"] | null,
      date?: Moment | null
    ) => {
      await updateCandidateStatus({
        id: candidate.id,
        status,
        callTime: date,
        refusalId: reason ?? undefined,
        vacancyId,
      })
        .then((result) => {
          dispatch(removeCandidate({ candidateId: candidate.id }));

          if (result?.changeCandidateStatus) {
            dispatch(updateCandidateInReply(result.changeCandidateStatus));
          }
          if (status === CandidateStatus.Candidate) {
            setIsModalOpen(true);
          }
        })
        .finally(() => {
          setOpen(true);
        });
    },
    [candidate.id, dispatch, setIsModalOpen, updateCandidateStatus, vacancyId]
  );

  const options: Option[] = candidateStatuses.map(({ key, name }) => ({
    value: key,
    label: name,
    children: getChildrenOptions(key as CandidateStatus),
  }));

  return (
    <CustomDropDown
      options={options}
      done={open}
      updateStatus={updateStatus}
      changeStatus={onChange}
    >
      <div className="field-status">
        <div className="field-status-label">Выберите статус</div>

        <span>{open ? <ChevronUp /> : <ChevronDown />}</span>
      </div>
    </CustomDropDown>
  );
};

import { urlExpression } from "common/const/regexp";
import { UrlParams } from "../commonTypes/types";

export const getIsLink = (link: string) => {
  const regex = new RegExp(urlExpression);

  return regex.test(link);
};

export const getUrl = (route: string) => `https://${route}`;

export function getUrlParams(): UrlParams {
  const params = new URLSearchParams(window.location.search);
  const result: UrlParams = {};

  params.forEach((value, key) => {
    result[key as keyof UrlParams] = value;
  });

  return result;
}

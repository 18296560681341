import axios from "axios";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "components/ErrorFallback";
import { LOG_LEVEL, payloadConstructor } from "services/logger/log";

export const ErrorBoundary: React.FC = ({ children }) => {
  const onError = (error: Error, component: { componentStack: string }) => {
    const { componentStack } = component;
    const data = {
      level: LOG_LEVEL.ERROR,
      message: error?.message || "unexpected exception",
      component: componentStack || "-",
    };
    const LOKI_URL = process.env.REACT_APP_LOKI_HOST || "";
    const requestPayload = payloadConstructor(data);
    return axios.post(LOKI_URL, requestPayload);
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};

import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router";

export const ErrorFallback: React.FC = () => {
  const history = useHistory();

  const toHomePage = () => {
    history.push("/");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "5rem",
      }}
    >
      <Result
        status="500"
        title="Ошибка"
        subTitle="Извините, что-то пошло не так."
        extra={
          <Button type="primary" onClick={toHomePage}>
            На главную
          </Button>
        }
      />
    </div>
  );
};

import { forwardRef, Ref } from "react";
import { Select as AntSelect, SelectProps, RefSelectProps } from "antd";
import cn from "classnames/dedupe";

import { SizeType } from "../types";

import "./styles.scss";

interface Props extends SelectProps {
  size?: SizeType;
  mode?: "multiple" | "tags";
}

const Select = forwardRef<RefSelectProps, Props>(
  (
    { className, size = "large", popupClassName, ...props },
    ref: Ref<RefSelectProps>
  ) => {
    return (
      <AntSelect
        ref={ref}
        className={cn("ui-select", { [`ui-select-${size}`]: size }, className)}
        popupClassName={cn("ui-select-dropdown", popupClassName)}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        size={size}
        {...props}
      />
    );
  }
);

export default Select;

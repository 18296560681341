import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames/dedupe";

import { useAppSelector } from "services/store/store";
import { Title } from "components/UI/Title";
import { Table, Props as TableProps } from "components/UI/Table";
import { Spinner } from "components/UI/Spinner";
import { Tooltip } from "components/UI/Tooltip";
import { Unlock, Lock, Copy } from "components/UI/icons";
import {
  selectCandidateSearch,
  selectCandidateSearchData,
} from "services/store/modules/candidates/selectors";
import { CandidateType } from "graphql/types/types";
import { FiltersState } from "./components/CandidatesFilters";
import Text from "components/UI/Text";
import { Pagination } from "components/UI/Pagination";
import { useCopyToClipboard } from "common/hooks/useCopyToClipboard";
import { ROUTE } from "routes";
import { getMainPhone } from "common/helpers/phone";
import { PaginationPayload } from "components/UI/Pagination";
import { renderLetters } from "./helpers";
import { getSessionIds, setSessionIds } from "common/utils/cookies";

import "./styles.scss";

type Props = {
  className?: string;
  formData: FiltersState;
  getCandidateList: (
    filter?: FiltersState,
    pagination?: PaginationPayload
  ) => void;
};

export const Candidates: React.FC<Props> = ({ formData, getCandidateList }) => {
  const history = useHistory();
  const [, copy] = useCopyToClipboard();

  const { loading, meta } = useAppSelector(selectCandidateSearch);
  const candidatesList = useAppSelector(selectCandidateSearchData);

  const columns: TableProps<CandidateType>["columns"] = [
    {
      title: "Фамилия",
      dataIndex: "secondName",
      key: "secondName",
      render: (secondName) => renderLetters(secondName, formData["secondName"]),
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName) => renderLetters(firstName, formData["firstName"]),
    },
    {
      title: "ID бейдж",
      dataIndex: "badge",
      key: "badge",
    },
    {
      title: "ID CRM",
      dataIndex: "id",
      key: "id",
      width: 160,
      render: (id) => (
        <Tooltip
          placement="bottomRight"
          title={
            <div
              className="candidates-container-table-copyTooltip"
              onClick={(e) => e.stopPropagation()}
            >
              {id}
              <div
                className="candidates-container-table-copy"
                onClick={() => copy(id)}
              >
                <Copy />
              </div>
            </div>
          }
        >
          <div className="candidates-container-table-id">{id}</div>
        </Tooltip>
      ),
    },
    {
      title: "Телефон",
      dataIndex: "contacts",
      key: "contacts",
      render: (contacts) => {
        const { contact } = getMainPhone(contacts) || {};
        return <div>{contact ?? ""}</div>;
      },
    },
    {
      title: "",
      dataIndex: "inBlacklist",
      key: "inBlacklist",
      width: 50,
      render: (inBlacklist) =>
        inBlacklist ? (
          <Tooltip
            placement="bottomRight"
            title="Кандидат добавлен в черный список"
          >
            <div>
              <Lock />
            </div>
          </Tooltip>
        ) : (
          <Tooltip placement="bottomRight" title="Кандидат не в черном списке">
            <div>
              <Unlock width={18} height={18} />
            </div>
          </Tooltip>
        ),
    },
  ];

  const openedCandidatesIds = useMemo(
    () => getSessionIds("openedCandidatesIds"),
    []
  );

  const handleRowClick = (candidateId: string) => {
    setSessionIds("openedCandidatesIds", candidateId);
    history.push(`${ROUTE.CANDIDATE}/${candidateId}`);
  };

  return (
    <div className="candidates-container">
      <Title type="h3">Результаты</Title>

      <div className="candidates-container-table">
        {loading ? (
          <Spinner className="candidates-container-spinner" loading={loading} />
        ) : (
          <>
            {candidatesList?.length ? (
              <Table
                tableLayout="fixed"
                onRow={(candidate) => ({
                  onClick: () => handleRowClick(candidate.id),
                  className: cn({
                    "candidate-row--highlighted": openedCandidatesIds.includes(
                      candidate.id
                    ),
                  }),
                })}
                dataSource={candidatesList}
                columns={columns}
              />
            ) : (
              <Text>Кандидаты не найдены</Text>
            )}
          </>
        )}
      </div>

      <Pagination
        className="candidates-container-pagination"
        total={meta?.total}
        limit={meta?.limit}
        offset={meta?.offset}
        onChange={(pagination) => getCandidateList(formData, pagination)}
      />
    </div>
  );
};

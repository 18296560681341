// @ts-nocheck
import { Component } from "react";
import queryString from "query-string";
import Cookies from "js-cookie";

export class Authorizer extends Component {
  public preparedAuth() {
    const parsedQuery = queryString.parse(window.location.search);
    if (
      parsedQuery?.access_token &&
      parsedQuery?.exp &&
      parsedQuery?.refresh &&
      parsedQuery?.refresh_exp
    ) {
      const dateNow = new Date().getTime();
      const expiredDateAccess = new Date(
        parseInt(parsedQuery.exp as string, 10) * 1000 + dateNow
      );
      const expiredDateRefresh = new Date(
        parseInt(parsedQuery.refresh_exp as string, 10) * 1000 + dateNow
      );
      const rootDomain = process.env.REACT_APP_ROOT_DOMAIN;
      let domain = "";
      if (rootDomain?.indexOf(":") >= 0) {
        [domain] = rootDomain.split(":");
      } else {
        domain = rootDomain;
      }
      Cookies.set("access_token", parsedQuery.access_token, {
        expires: expiredDateAccess,
        domain: domain,
      });
      Cookies.set("refresh_token", parsedQuery.refresh, {
        expires: expiredDateRefresh,
        domain: domain,
      });
      /**
       * # case login
       * <- /login
       * -> /test-list
       *
       * # case logout
       * <- /test/123/321
       * -> /login?redirectUrl=/test/123/321
       *
       * # case logout preview
       * <- /test/123/123?mode=preview
       * -> /login?redirectUrl=/test/123/123?mode=preview
       *
       * # case google authorizer
       * <- /authorizer?token=bla&redirectUrl=/test/123/123?mode=preview
       * -> /test/123/123?mode=preview
       *
       * # case google button press simple
       * <- /login
       * -> /api/auth?redirectUrl=/authorizer
       *
       * # case google button press tricky
       * <- /test/123/123?mode=preview
       * -> /api/auth?redirectUrl=/authorizer?redirectUrl=/test/123/123?mode=preview
       */
      if (parsedQuery?.state) {
        const redirectTo = parsedQuery?.state as string;
        const targetURL = new URL(redirectTo, window.location.origin);
        window.location.replace(targetURL.toString());
      } else {
        window.location.replace("/");
      }
    } else {
      window.location.replace("/404");
    }
  }

  // * Hooks
  componentDidMount() {
    this.preparedAuth();
  }

  // *  Template
  render() {
    return null;
  }
}

export default Authorizer;

import React from "react";

export const Lock: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2499 8.25H3.74988C2.92145 8.25 2.24988 8.92157 2.24988 9.75V15C2.24988 15.8284 2.92145 16.5 3.74988 16.5H14.2499C15.0783 16.5 15.7499 15.8284 15.7499 15V9.75C15.7499 8.92157 15.0783 8.25 14.2499 8.25Z"
        stroke="#E62249"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24988 8.25V5.25C5.24988 4.25544 5.64497 3.30161 6.34823 2.59835C7.05149 1.89509 8.00532 1.5 8.99988 1.5C9.99444 1.5 10.9483 1.89509 11.6515 2.59835C12.3548 3.30161 12.7499 4.25544 12.7499 5.25V8.25"
        stroke="#E62249"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React from "react";
import { useHistory } from "react-router";
import cn from "classnames";
import { useViewMode } from "common/hooks/useViewMode";

import { Card } from "components/UI/Card";
import { ViewModeToggle } from "components/ViewModeToggle";
import { ROUTE } from "routes";
import { getPlansCards } from "./helpers";
import { PlansName } from "common/const/plans";

import "./styles.scss";

export const Plans: React.FC = () => {
  const history = useHistory();

  const { mode, handleChangeMode } = useViewMode("grid");

  const handleClick = (plansName: PlansName) => {
    history.push(`${ROUTE.PLAN}/${plansName}`);
  };

  const planCards = getPlansCards(handleClick);

  return (
    <div className="plans">
      <div className="plans-header">
        <ViewModeToggle mode={mode} onChangeMode={handleChangeMode} />
      </div>

      <div className={cn("plans-cards", `plans-cards--${mode}`)}>
        {planCards.map(({ title, icon, isVisible, onClick }) => (
          <>
            {isVisible ? (
              <Card
                className={cn("plans-card", `plans-card--${mode}`)}
                mode={mode}
                title={title}
                icon={icon}
                onClick={onClick}
              />
            ) : null}
          </>
        ))}
      </div>
    </div>
  );
};

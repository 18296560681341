import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import "./index.css";
import App from "./App";
import "antd/dist/antd.min.css";

ReactDOM.render(
  <ConfigProvider locale={ruRU}>
    <App />
  </ConfigProvider>,
  document.getElementById("root")
);

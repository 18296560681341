import React, { useState } from "react";
import { Title } from "components/UI/Title";
import { useAppSelector } from "services/store/store";
import { positions } from "services/store/modules/directory";
import { FieldPositionCount } from "./components/FieldPositionCount";
import { Props as TableProps, Table } from "components/UI/Table";
import { PositionType } from "graphql/types/types";
import { Info } from "components/UI/icons/Info";
import { Modal } from "components/UI/Modal";
import { Button } from "components/UI/Button";

import "./styles.scss";

export const MultiVacancyDistribution: React.FC = () => {
  const positionsDirectory = useAppSelector(positions);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const columns: TableProps<PositionType>["columns"] = [
    {
      title: "Должность",
      dataIndex: "name",
      key: "name",
    },
    {
      title: (
        <span>
          <Info width={20} height={20} />
        </span>
      ),
      dataIndex: "countPeople",
      key: "countPeople",
      width: 120,
      className: "multi-vacancy-name",
      render: (countPeople, { id }) => (
        <FieldPositionCount
          id={id}
          countPeople={countPeople}
          isOpen={openModal}
          setModalMessage={setModalMessage}
        />
      ),
    },
  ];

  return (
    <div className="multi-vacancy-container">
      <Title type="h4" color="blue">
        Распределение мультивакансий
      </Title>
      {positionsDirectory.length && (
        <Table
          className="multi-vacancy-container--table global-scrollbar"
          tableLayout="fixed"
          dataSource={positionsDirectory}
          columns={columns}
        />
      )}
      <Modal open={isOpenModal} footer={false} onCancel={closeModal}>
        <div className="multi-vacancy-container-modal">
          <Title type="h2">{modalMessage}</Title>
          <Button
            className="multi-vacancy-container-modal-btn"
            onClick={closeModal}
          >
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
};

import React from "react";

export const LineChartOutlined: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66667 1.33337L2 9.33337H8L7.33333 14.6667L14 6.66671H8L8.66667 1.33337Z"
        stroke="#0057AC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { gql } from "@apollo/client";

export const NATIONALITIES_DICTIONARIES = gql`
  query dictionaries {
    dictionaries {
      ... on Dictionaries {
        nationalities {
          id
          key
          name
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

import React, { useState, useRef } from "react";
import cn from "classnames/dedupe";

import Input, { InputProps } from "components/UI/Input";
import { IconStatus, Status } from "components/UI/iconStatus";
import { Edit, Close, CornerUpRight } from "components/UI/icons";
import { useHover } from "common/hooks/useHover";
import { useToggle } from "common/hooks/useToggle";
import { useDebounceCallback } from "common/hooks/useDebounceCallback";

import "./styles.scss";

type Props = {
  classNames?: string;
  fieldValue: string | number;
  onSave?: (value: string | number) => Promise<void>;
  type?: InputProps["type"];
};

export const FieldInput: React.FC<Props> = ({
  classNames,
  fieldValue,
  onSave,
  type,
}) => {
  const [isEdit, toggleIsEdit] = useToggle(false);
  const [status, setStatus] = useState<Status | null>(null);
  const [value, setValue] = useState(fieldValue);

  const localRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(localRef);
  const debounceStatus = useDebounceCallback(() => setStatus(null), 3000);

  const handleSave = async () => {
    setStatus("pending");
    try {
      await onSave?.(value);
      setStatus("success");
    } catch (error) {
      setStatus("error");
    } finally {
      toggleIsEdit();
      debounceStatus();
    }
  };

  const handleEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const classNameList = cn("table-field", classNames, {
    "table-field-edit": isEdit,
    "table-field-hover": isHover,
  });

  const saveIcon = (
    <span
      className="table-field-icon"
      onClick={(e) => {
        e.stopPropagation();
        handleSave();
      }}
    >
      <CornerUpRight />
    </span>
  );

  const content = isEdit ? (
    <Input
      className="table-field-input"
      value={value}
      suffix={saveIcon}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleEnterClick}
      onClick={(e) => e.stopPropagation()}
      type={type}
    />
  ) : (
    <span className="table-field-value">{fieldValue}</span>
  );

  return (
    <div className={classNameList} ref={localRef}>
      {content}
      {!status ? (
        <span className="table-field-icon" onClick={toggleIsEdit}>
          {isEdit ? (
            <Close width={16} height={16} />
          ) : (
            <span className="table-field-icon-edit">
              <Edit width={16} height={16} color="#0057AC" />
            </span>
          )}
        </span>
      ) : (
        <span className="table-field-icon">
          <IconStatus status={status} />
        </span>
      )}
    </div>
  );
};

import React from "react";
import { IconType } from "common/commonTypes/types";

export const Report: React.FC<IconType> = ({
  width = 20,
  height = 20,
  color = "#0057AC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 10.8334H9.16667M14.1667 7.50002H9.16667M14.1667 14.1667H9.16667M13.3333 1.66669V4.16669M6.66667 1.66669V4.16669M5.83333 18.3334H14.1667C16.0076 18.3334 17.5 16.841 17.5 15V6.25002C17.5 4.40907 16.0076 2.91669 14.1667 2.91669H5.83333C3.99238 2.91669 2.5 4.40907 2.5 6.25002V15C2.5 16.841 3.99238 18.3334 5.83333 18.3334Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.08341 7.50002C7.08341 7.96026 6.71032 8.33335 6.25008 8.33335C5.78984 8.33335 5.41675 7.96026 5.41675 7.50002C5.41675 7.03978 5.78984 6.66669 6.25008 6.66669C6.71032 6.66669 7.08341 7.03978 7.08341 7.50002Z"
        fill={color}
      />
      <path
        d="M7.08341 10.8333C7.08341 11.2936 6.71032 11.6667 6.25008 11.6667C5.78984 11.6667 5.41675 11.2936 5.41675 10.8333C5.41675 10.3731 5.78984 10 6.25008 10C6.71032 10 7.08341 10.3731 7.08341 10.8333Z"
        fill={color}
      />
      <path
        d="M7.08341 14.1666C7.08341 14.6269 6.71032 15 6.25008 15C5.78984 15 5.41675 14.6269 5.41675 14.1666C5.41675 13.7064 5.78984 13.3333 6.25008 13.3333C6.71032 13.3333 7.08341 13.7064 7.08341 14.1666Z"
        fill={color}
      />
    </svg>
  );
};

import axios from "axios";

const TOKEN = process.env.REACT_APP_FIAS_TOKEN;

const API_URLS: Record<API, string> = {
  API: process.env.REACT_APP_API_URL ?? "",
  AVITO: process.env.REACT_APP_AVITO_INTEGRATOR_URL ?? "",
  HH: process.env.REACT_APP_HH_INTEGRATOR_URL ?? "",
};

type Method = "get" | "put" | "post" | "delete";
type API = "API" | "AVITO" | "HH";

export const apiRequest = <Response, Params = {}>(
  type: API,
  method: Method,
  endpoint: string,
  params?: Params
) => {
  return axios[method]<Response>(`${API_URLS[type]}${endpoint}`, params, {
    headers: {
      headers: { Authorization: `Token ${TOKEN}` },
    },
  });
};

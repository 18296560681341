import React from "react";
import { IconType } from "common/commonTypes/types";

export const List: React.FC<IconType> = ({
  width = 20,
  height = 20,
  color = "#000000",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20825 6.66669C5.20825 6.32151 5.48807 6.04169 5.83325 6.04169H14.1666C14.5118 6.04169 14.7916 6.32151 14.7916 6.66669C14.7916 7.01186 14.5118 7.29169 14.1666 7.29169H5.83325C5.48807 7.29169 5.20825 7.01186 5.20825 6.66669ZM5.20825 10C5.20825 9.65484 5.48807 9.37502 5.83325 9.37502H14.1666C14.5118 9.37502 14.7916 9.65484 14.7916 10C14.7916 10.3452 14.5118 10.625 14.1666 10.625H5.83325C5.48807 10.625 5.20825 10.3452 5.20825 10ZM5.83325 12.7084C5.48807 12.7084 5.20825 12.9882 5.20825 13.3334C5.20825 13.6785 5.48807 13.9584 5.83325 13.9584H14.1666C14.5118 13.9584 14.7916 13.6785 14.7916 13.3334C14.7916 12.9882 14.5118 12.7084 14.1666 12.7084H5.83325Z"
        fill={color}
      />
    </svg>
  );
};

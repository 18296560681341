import { gql } from "@apollo/client";

export const CANDIDATES_LIST = gql`
  query candidateList(
    $filter: CandidateFilterType
    $pagination: PaginationType
  ) {
    candidateList(filter: $filter, pagination: $pagination) {
      ... on CandidateListSuccess {
        data {
          id
          age
          badge
          calcDismissDate
          callCompleted
          cardDeliveryAddress {
            block
            blockType
            city
            cityType
            houseType
            house
            lat
            lng
            region
            value
            streetType
            street
            settlement
            regionType
          }
          actualAddress {
            block
            blockType
            city
            cityType
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            street
            streetType
            value
          }
          contacts {
            contact
            id
            isMain
          }
          citizenshipId
          city
          companyId
          didntWork
          createdAt
          timezone
          dateOfBirth
          deletedAt
          departmentId
          dismissReason
          dismissDate
          driverLicense
          email
          experienceFull
          experienceInCompany
          fullName
          firstName
          hasChat
          hasHistory
          haveCar
          updatedAt
          status
          source
          shiftMinDate
          shiftMaxDate
          sex
          secondName
          salaryExpectation
          russianCitizenship
          region
          receptionLastDate
          positions {
            key
          }
          positionId
          positionsNew {
            createdAt
            id
            position {
              categoryId
              id
              key
              name
              restriction
            }
          }
          ownerId
          patronymic
          metro {
            distance
            line
            name
          }
          comments {
            comment
            createdAt
            manager {
              city
              companyId
              departmentId
              email
              firstName
              id
              phone
              role
              secondName
            }
          }
          managerId
          managerComment
          lastShiftRate
          isSpam
          inBlacklist
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

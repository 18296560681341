import { gql } from "@apollo/client";

export const HOT_CALLS_PLAN_LIST = gql`
  query hotCallsPlanList($departmentId: Int!) {
    hotCallsPlanList(departmentId: $departmentId) {
      ... on HotCallsPlanListSuccess {
        data {
          countCalls
          departmentName
          id
          user
          userId
        }
      }
      ... on Error {
        statusCode
        message
        errors
      }
    }
  }
`;

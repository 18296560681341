import {
  AuthState,
  IAuthenticateData,
  IMeResponse,
  IPayloadRestore,
  IPayloadUserList,
  IUserListResponse,
} from "./types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import { client as apollo } from "../../../../graphql/apollo";
import { ApolloQueryResult } from "@apollo/client";
import getCookie from "../../../../common/helpers/cookies";
import { ME } from "../../../../graphql/requests/query/me";
import Cookies from "js-cookie";
import { IUser } from "../user/types";
import { IPropLog, Logger } from "../../../logger/log";
import { USER_LIST } from "../../../../graphql/requests/query/userList";

export const STORAGE_KEYS = {
  ACCESS: "auth:token",
  REFRESH: "auth:refresh",
  EXP: "auth:exp",
  ROLE: "auth:role",
  TYPE_TOKEN: "auth:typeToken",
  USER_ID: "auth:userID",
  USER_INFO: "auth:userInfo",
  IS_MENU_OPEN: "auth:menu",
};

export const initialState: AuthState = {
  token: localStorage.getItem(STORAGE_KEYS.ACCESS) || "",
  refreshToken: localStorage.getItem(STORAGE_KEYS.REFRESH) || "",
  expired: localStorage.getItem(STORAGE_KEYS.EXP) || "",
  isLoading: false,
  error: "",
  successMessage: "",
  userData: undefined,
  isMenuOpen: !!localStorage.getItem("auth:menu") || true,
  isAuthLoading: false,
};
export const authModule = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData(
      state: AuthState,
      { payload }: PayloadAction<IAuthenticateData>
    ) {
      state.token = payload?.AccessToken || "";
      state.refreshToken = payload?.RefreshToken || "";
      state.expired = payload?.expired || "";
      if (payload.userData) {
        state.userData = payload.userData;
      }
    },
    setAuthInfo(state: AuthState, { payload }: PayloadAction<IUser>) {
      state.userData = payload;
    },
    setLoading(state: AuthState, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setError(state: AuthState, { payload }) {
      state.error = payload;
    },
    setSuccess(state: AuthState, { payload }: PayloadAction<string>) {
      state.successMessage = payload;
    },
    setMenu(state: AuthState, { payload }: PayloadAction<boolean>) {
      localStorage.setItem(STORAGE_KEYS.IS_MENU_OPEN, String(payload));
      state.isMenuOpen = payload;
    },
    setAuthLoading(state: AuthState, { payload }: PayloadAction<boolean>) {
      state.isAuthLoading = payload;
    },
  },
});

// * mutations
export const {
  setAuthData,
  setAuthInfo,
  setLoading,
  setError,
  setMenu,
  setAuthLoading,
} = authModule.actions;
// * getters
export const isAuth = () =>
  !!getCookie("access_token") || getCookie("refresh_token");
export const role = (state: RootState) => state.auth.userData?.role;
export const authInfo = (state: RootState) => state.auth.userData;
export const isMenuOpen = (state: RootState) => state.auth.isMenuOpen;

export const isAuthLoadingSelector = (state: RootState) =>
  state.auth.isAuthLoading;

// * actions

export const signOut = createAsyncThunk<AuthState>(
  "auth/signOut",
  // @ts-ignore
  async (payload: IPayloadRestore, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));

    // @ts-ignore
    const cookieDomain = process.env?.REACT_APP_ROOT_DOMAIN;
    Cookies.remove("access_token", { domain: cookieDomain });
    Cookies.remove("refresh_token", { domain: cookieDomain });
    Cookies.remove("openedCandidatesIds");
    thunkAPI.dispatch(
      setAuthData({
        AccessToken: "",
        RefreshToken: "",
        expired: "",
        userData: undefined,
      })
    );
    thunkAPI.dispatch(setLoading(false));
  }
);

export const authInfoThunk = createAsyncThunk<AuthState>(
  STORAGE_KEYS.USER_INFO,
  // @ts-ignore
  async (payload: string, thunkAPI) => {
    thunkAPI.dispatch(setAuthLoading(true));
    try {
      const res: ApolloQueryResult<IMeResponse> = await apollo.query({
        query: ME,
      });
      const data = res?.data?.me;
      thunkAPI.dispatch(setAuthInfo(data));
    } catch (e) {
      console.error(e);
      thunkAPI.dispatch(setError(e.message));
    } finally {
      thunkAPI.dispatch(setAuthLoading(false));
    }
  }
);
export const toSetMenu = createAsyncThunk(
  STORAGE_KEYS.IS_MENU_OPEN,
  async (payload: boolean, thunkAPI) => {
    thunkAPI.dispatch(setMenu(payload));
  }
);

export const sendLog = createAsyncThunk(
  "auth/sendLog",
  async (payload: IPropLog, thunkAPI) => {
    const logger = Logger.get();
    // @ts-ignore
    const userData = thunkAPI.getState()?.auth?.userData;
    const payloadData = {
      ...payload,
      user: userData?.id || "-",
      role: userData?.role || "-",
    };
    await logger.send(payloadData as IPropLog);
  }
);

export const getUserList = createAsyncThunk(
  "auth/userList",
  async (payload: IPayloadUserList, { rejectWithValue }) => {
    try {
      const response = await apollo.query({
        query: USER_LIST,
        variables: {
          ...payload,
        },
      });
      return response.data.userList;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
const userListSlice = createSlice({
  name: "userList",
  initialState: {
    data: {} as IUserListResponse,
    loading: true,
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});
export const userListReducer = userListSlice.reducer;
export const authModuleReducer = authModule.reducer;

export default {
  userListReducer,
  authModuleReducer,
};

import { useCallback, useEffect, useRef, useState } from "react";
import { UnregisterCallback, Location } from "history";
import { useHistory } from "react-router-dom";
import { matchPath } from "react-router";

import { MESSAGE_CONFIRM } from "common/const/messages";
import { useBeforeUnload } from "./useBeforeUnload";

export type Props = {
  isFactor: boolean;
  message?: string;
  blackRoute?: string;
};

export function useBeforeunloadRouteConfirm({
  isFactor,
  message,
  blackRoute = "",
}: Props) {
  const history = useHistory();
  const unblockRef = useRef<UnregisterCallback | null>(null);

  const [isVisible, setIsVisible] = useState(false);
  const [pathname, setPathname] = useState<string | null>(null);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const closeAndNavigate = useCallback(() => {
    if (unblockRef.current) {
      unblockRef.current();
      unblockRef.current = null;
    }

    if (pathname) {
      history.push(pathname);
      setPathname(null);
    }
  }, [history, pathname]);

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (isFactor) {
        event.preventDefault();
        event.returnValue = message ?? MESSAGE_CONFIRM;
        return message ?? MESSAGE_CONFIRM;
      }
    },
    [isFactor, message]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    unblockRef.current = history.block((location: Location) => {
      /**
       * Переделать на массив blackRoutes, если нужно будет не показывать модалку для списка урлов
       */
      const { path = "" } = matchPath(location.pathname, blackRoute) || {};

      if (isFactor && !path) {
        setIsVisible(true);
        setPathname(location.pathname);
        return false;
      }
    });

    return () => {
      if (unblockRef.current) unblockRef.current();
    };
  }, [history, isFactor, blackRoute]);

  return { isVisible, close, closeAndNavigate };
}

import { gql } from "@apollo/client";

export const VACANCIES_DICTIONARIES = gql`
  query dictionaries {
    dictionaries {
      ... on Dictionaries {
        positions {
          key
          name
          id
        }
        positionCategories {
          id
          key
          name
        }
        vacancyStatuses {
          key
          name
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

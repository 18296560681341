import { gql } from "@apollo/client";

export const TRANSFER_REPLY_LIST = gql`
  mutation transferReplyList($ids: [Int!]!, $newUserId: Int!) {
    transferReplyList(ids: $ids, newUserId: $newUserId) {
      ... on ReplyTransferredListSuccess {
        data {
          candidateId
          address {
            block
            blockType
            city
            cityType
            house
            lat
            houseType
            lng
            region
            regionType
            settlement
            street
            streetType
            value
          }
          actionPayClick
          age
          chatLink
          citizenshipId
          cityAdsClick
          claimId
          companyId
          contacts {
            email
            fullName
            phone
          }
          deletedAt
          createdAt
          departmentId
          employmentId
          expiredAt
          expiredAtAfterTransferFromCc
          expiredCallTime
          expiredTime
          expiredTimeAfterTransferFromCc
          firstName
          hasChat
          id
          isCreatedAtBusinessHours
          isExpiredAtBusinessHours
          isMain
          isTransferFromCcAtBusinessHours
          manager {
            city
            companyId
            departmentId
            email
            id
            firstName
            phone
            role
            secondName
          }
          isTransferred
          managerComment
          managerId
          notCallCount
          owner {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
          ownerId
          patronymic
          positionId
          refusalId
          refusalText
          russianCitizenship
          s2Id
          source
          secondName
          status
          updatedAt
          updatedBy
          userComment
          userContent
          utm {
            campaign
            content
            medium
            source
            term
          }
          vacancyId
          vacancy {
            id
            name
            additionalAvito {
              businessArea
              experience
              name
            }
            additionalHh {
              acceptHandicapped
              acceptKids
              acceptTemporary
              area
              driverLicense
              experience
              languages
              name
              professionalRoles
            }
            address {
              block
              blockType
              city
              cityType
              house
              houseType
              lat
              lng
              region
              regionType
              settlement
              street
              streetType
              value
            }
            age {
              from
              to
            }
            callCenterInfo {
              addresses
              description
              driverLicense
              experience
              farePayment
              goodsCategory
              mobilePayment
              personalCar
              presenceOfPda
              project
              vaccination
            }
            citizenship {
              id
              key
              name
            }
            contacts {
              email
              fullName
              phone
            }
            employer {
              id
              key
              logo
              name
            }
            employment {
              id
              key
              name
            }
            extraAvito {
              publicationFinishTime
              remoteId
              remoteUrl
            }
            extraHh {
              publicationFinishTime
              remoteId
              remoteUrl
            }
            location {
              lat
              lng
            }
            manager {
              city
              companyId
              departmentId
              email
              firstName
              phone
              id
              role
              secondName
            }
            salary {
              currency
              from
              gross
              to
            }
            refusal {
              id
              key
              name
              type
            }
            tag {
              color
              colorBg
              key
              name
            }
            candidateCount
            city
            claims
            closedAt
            counterparty
            createdAt
            deletedAt
            description
            kladrId
            medicalBook
            metro {
              distance
              line
              name
            }
            peopleCount
            peopleCountOpen
            peopleCountClose
            phonePresence
            position {
              categoryId
              id
              key
              name
              restriction
            }
            positionId
            priority
            publishedAt
            publishedOnAvito
            publishedOnHh
            publishedOnRabotut
            reason
            refusalText
            region
            relevance
            replyCount
            replyCountNew
            responsiblePerson
            route
            schedule
            shiftEnd
            shiftStart
            shortDescription
            showContacts
            status
            subdivision
            terms
            tradePoint
            updatedAt
          }
        }
      }
      ... on Error {
        message
        statusCode
        errors
      }
    }
  }
`;

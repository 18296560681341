export const normalizedDate = (date: string) => {
  if (date) {
    const UTC = date;
    return new Date(UTC).toLocaleString("ru-RU", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  }
  return date;
};

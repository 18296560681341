import React from "react";
import { Modal } from "components/UI/Modal";
import { Title } from "components/UI/Title";
import Text from "components/UI/Text";
import { Button } from "components/UI/Button";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectVacancyData } from "services/store/modules/vacancy/selectors";
import {
  clearVacancy,
  decrementPeopleCountOpen,
} from "../../services/store/modules/vacancy";
import { removeCookies } from "../../common/utils/cookies";

type modeType = "candidate" | "vacancy";

interface CandidateModalsProps {
  isOpen: boolean;
  mode: modeType;
  title?: string;
  text?: string;
  onClick?: () => void;
  onClose: () => void;
}

export const CandidateModals: React.FC<CandidateModalsProps> = ({
  isOpen,
  title,
  text,
  onClose,
  onClick,
  mode,
}) => {
  const dispatch = useAppDispatch();
  const vacancy = useAppSelector(selectVacancyData);
  const history = useHistory();

  const peopleCountOpen = vacancy?.peopleCountOpen ?? 0;
  const peopleCountClose = vacancy?.peopleCountClose ?? 0;
  const lastPosition = peopleCountOpen - 1 === peopleCountClose;

  const titleMessage = lastPosition
    ? "Вы набрали необходимое количество кандидатов по данной вакансии"
    : `Потребность в количестве ${peopleCountOpen} уменьшена`;

  const textMessage = lastPosition
    ? "Перейдите к другим вакансиям"
    : "Вы будете переведены к списку вакансии";

  const handleLastPosition = () => {
    dispatch(clearVacancy());
    removeCookies("openedRecommendVacancy");
    history.push("/vacancies-view");
  };

  const handleCandidateMode = () =>
    lastPosition ? handleLastPosition() : history.goBack();

  const handleVacancyMode = () => {
    dispatch(decrementPeopleCountOpen());
    onClose();
    if (lastPosition) {
      handleLastPosition();
    }
  };

  const handleOkClick = () =>
    mode === "candidate" ? handleCandidateMode() : handleVacancyMode();

  return (
    <Modal open={isOpen} footer={false} onCancel={onClose}>
      <div className="candidates-actions-modal">
        <Title type="h2">{title ?? titleMessage}</Title>
        <Text
          text={text ?? textMessage}
          className="candidates-actions-modal-text"
        />
        <Button
          className="candidates-actions-modal-btn"
          onClick={handleOkClick}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SocketsState } from "./types";
import { IOktellCallData } from "common/helpers/oktellResponseParser";
import { ICall } from "../calls/types";

export const initialState: SocketsState = {
  statusSocket: "Оффлайн",
  statusWorker: "Оффлайн",
  workerClientId: "",
  oktellStatus: "Оффлайн",
  isActiveCall: false,
  currentOktellCallData: undefined,
  oktellUserLogin: "",
  currentHotCallData: undefined,
};

export const socketsModule = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    setStatusSocket(state, { payload }: PayloadAction<string>) {
      state.statusSocket = payload;
    },
    setStatusWorker(state, { payload }: PayloadAction<string>) {
      state.statusWorker = payload;
    },
    setWorkerClientId(state, { payload }: PayloadAction<string>) {
      state.workerClientId = payload;
    },
    setOktellStatus(state, { payload }: PayloadAction<string>) {
      state.oktellStatus = payload;
    },
    setActiveCall(state, { payload }: PayloadAction<boolean>) {
      state.isActiveCall = payload;
    },
    setCurrentCallOktellData(
      state,
      { payload }: PayloadAction<IOktellCallData | undefined>
    ) {
      state.currentOktellCallData = payload;
    },
    setOktellUserLogin(state, { payload }: PayloadAction<string>) {
      state.oktellUserLogin = payload;
    },
    setCurrentHotCallData(
      state,
      { payload }: PayloadAction<ICall | undefined>
    ) {
      state.currentHotCallData = payload;
    },
  },
});

export const {
  setStatusSocket,
  setStatusWorker,
  setWorkerClientId,
  setOktellStatus,
  setActiveCall,
  setCurrentCallOktellData,
  setOktellUserLogin,
  setCurrentHotCallData,
} = socketsModule.actions;

export default socketsModule.reducer;

import React from "react";
import { TreeSelect as AntTreeSelect, TreeSelectProps } from "antd";
import "./styles.scss";
import cn from "classnames/dedupe";

export const { SHOW_PARENT } = AntTreeSelect;

interface CustomSelectProps extends TreeSelectProps {
  error?: string;
}

export const TreeSelect: React.FC<CustomSelectProps> = ({
  className,
  popupClassName,
  error,
  ...props
}) => {
  return (
    <AntTreeSelect
      className={cn("ui-tree-select", className, {
        "ui-tree-select--error": error,
      })}
      popupClassName={cn("ui-tree-select-popup", popupClassName)}
      {...props}
    />
  );
};

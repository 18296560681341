import { gql } from "@apollo/client";

export const HOT_CALL_LIST = gql`
  query hotCallList($form: HotCallFilterType, $pagination: PaginationType) {
    hotCallList(form: $form, pagination: $pagination) {
      ... on HotCallListSuccess {
        data {
          id
          contacts {
            email
            fullName
            phone
          }
          createdAt
          deletedAt
          finishAt
          id
          managerId
          managerRemoteId
          result
          sourceId
          sourceType
          startAt
          status
          updatedAt
          notThroughReason
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        statusCode
        message
        errors
      }
    }
  }
`;

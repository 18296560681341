export const OPTION_GENDER = [
  { label: "Мужской", value: "MALE" },
  { label: "Женский", value: "FEMALE" },
];

export const OPTION_CITIZENSHIP = [
  { label: "РФ", value: "true" },
  { label: "Не гражданин РФ", value: "false" },
];

export const OPTION_CAR = [
  { label: "Да", value: "true" },
  { label: "Нет", value: "false" },
];

export const OPTION_TERMINATION_REASON = [
  {
    label: "По собственному желанию",
    value: "own_accord",
  },
  {
    label: "По инициативе работодателя",
    value: "employer_initiative",
  },
  {
    label: "Автоувольнение",
    value: "self_dismissal",
  },
];

import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { isActiveCallSelector } from "../services/store/modules/sockets/selectors";

const ProgressWrapper = styled.div`
  font-size: 14px;
`;

export const Timer = () => {
  const [second, setSecond] = useState<number>(0);

  const isActiveCall = useSelector(isActiveCallSelector);

  //* computed
  const secondsToHms = () => {
    const d = second;
    const m = Math.floor(d / 60);
    const s = Math.floor((d % 3600) % 60);
    const mDisplay = m && m >= 0 ? (m < 10 ? 0 + `${m}` : m?.toString()) : "00";
    const sDisplay = s && s >= 0 ? (s < 10 ? 0 + `${s}` : s?.toString()) : "00";
    return mDisplay + ":" + sDisplay;
  };

  React.useEffect(() => {
    if (isActiveCall) {
      const interval = setInterval(() => {
        const now = second + 1;
        if (now > 0) {
          setSecond(now);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  });

  return (
    <div>
      <ProgressWrapper>{secondsToHms()}</ProgressWrapper>
    </div>
  );
};

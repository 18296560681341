import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCallsPlanList } from "./actions";
import { getInitialState } from "../../helpers";
import { HotCallPlanType } from "graphql/types/types";
import { UpdatePlanPayload } from "./types";

const callsPlanListSlice = createSlice({
  name: "callsPlanList",
  initialState: getInitialState<HotCallPlanType[]>(),
  reducers: {
    updatePlanCount: (state, { payload }: PayloadAction<UpdatePlanPayload>) => {
      if (!state.data) return;

      state.data = state.data.map((plan) => {
        if (plan.userId === payload.userId) {
          plan.countCalls = payload.countCalls;
        }
        return plan;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCallsPlanList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCallsPlanList.fulfilled, (state, { payload }) => {
        state.data = payload.hotCallsPlanList.data;
        state.loading = false;
      })
      .addCase(getCallsPlanList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

export const { updatePlanCount } = callsPlanListSlice.actions;
export const plansReducer = callsPlanListSlice.reducer;

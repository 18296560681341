import React from "react";

export const CornerUpRight: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3335 11.3334L2.66683 8.66671L5.3335 6.00004"
        stroke="#A6ACB9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 3.33329L13.3335 7.66663C13.3335 8.21893 12.8858 8.66663 12.3335 8.66663L2.66683 8.66663"
        stroke="#A6ACB9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

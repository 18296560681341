import React, { useEffect, useCallback } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAppSelector } from "services/store/store";
import { ContentLayout } from "layouts/ContentLayout";
import { Candidates } from "components/redezign/Candidates";
import {
  CandidatesFilters,
  FiltersState,
  INITIAL_STATE,
} from "components/redezign/Candidates/components/CandidatesFilters";
import { useAppDispatch } from "services/store/store";
import { fetchCandidateSearchList } from "services/store/modules/candidates/action";
import { PaginationPayload } from "components/UI/Pagination";
import { selectCandidateSearch } from "services/store/modules/candidates/selectors";
import { useLocalStorage } from "common/hooks/useLocalStorage";
import { PAGINATION } from "common/const/pagination";

export const CandidatesPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [form, setForm] = useLocalStorage<FiltersState | null>(
    "candidates-form",
    null
  );
  const { meta } = useAppSelector(selectCandidateSearch);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    getValues,
  } = useForm<FiltersState>({
    defaultValues: form ?? INITIAL_STATE,
  });

  const getCandidateList = useCallback(
    (
      filter?: FiltersState,
      pagination: PaginationPayload = PAGINATION.CANDIDATES
    ) => {
      const payload = {
        pagination,
        filter: filter ?? {},
      };

      dispatch(fetchCandidateSearchList(payload));
      filter && setForm(filter);
    },
    [dispatch, setForm]
  );

  const updateCandidateForm: SubmitHandler<FiltersState> = useCallback(
    async (formData) => {
      getCandidateList(formData);
    },
    [getCandidateList]
  );

  const handleReset = () => {
    setForm(null);
    reset(INITIAL_STATE);
    getCandidateList(INITIAL_STATE);
  };

  useEffect(() => {
    getCandidateList(getValues(), {
      offset: meta?.offset ?? PAGINATION.CANDIDATES.offset,
      limit: PAGINATION.CANDIDATES.limit,
    });
  }, []);

  return (
    <ContentLayout
      centralColumn={{
        content: (
          <Candidates
            formData={getValues()}
            getCandidateList={getCandidateList}
          />
        ),
        width: 18,
      }}
      leftColumn={{
        width: 6,
        content: (
          <CandidatesFilters
            control={control}
            isDirty={isDirty}
            onSubmit={handleSubmit(updateCandidateForm)}
            onReset={handleReset}
          />
        ),
      }}
    />
  );
};

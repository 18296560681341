import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation updateUser($id: Int!, $form: UserForm!) {
    updateUser(id: $id, form: $form) {
      ... on UserType {
        id
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const CLAIM_LIST = gql`
  query claimList($filter: ClaimFilterType, $pagination: PaginationType) {
    claimList(filter: $filter, pagination: $pagination) {
      ... on ClaimListSuccess {
        data {
          firstName
          secondName
          patronymic
          address {
            block
            blockType
            city
            cityType
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            street
            streetType
            value
          }
          category
          companyId
          chatLink
          salary
          contacts {
            email
            fullName
            phone
          }
          extra {
            service
            remoteUrl
            remoteId
            remoteVacancyId
          }
          createdAt
          deletedAt
          departmentId
          expiredCallTime
          id
          isSent
          managerComment
          ownerId
          position
          refusalId
          refusalText
          source
          replyId
          sendTo
          sourceUrl
          status
          updatedAt
          updatedBy
          userComment
          userContent
          userWish
          utm {
            campaign
            content
            medium
            source
            term
          }
          manager {
            id
            city
            email
            firstName
            phone
            role
            secondName
            departmentId
            companyId
          }
          owner {
            id
            city
            email
            firstName
            phone
            role
            secondName
            departmentId
            companyId
          }
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        statusCode
        errors
        message
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const POSITIONS_DICTIONARIES = gql`
  query dictionaries {
    dictionaries {
      ... on Dictionaries {
        positions {
          categoryId
          countPeople
          id
          key
          name
          restriction
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

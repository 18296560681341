import React, { useState, useCallback, useRef } from "react";
import { useAppDispatch } from "services/store/store";
import { getDateFormat } from "common/utils/date";
import { useToggle } from "common/hooks/useToggle";
import { useHover } from "common/hooks/useHover";
import { updateCandidateInList } from "services/store/modules/candidates";
import { useCandidateStatus } from "common/hooks/candidate/useCandidateStatus";
import { CandidateCommentType, CandidateType } from "graphql/types/types";
import { Tooltip } from "components/UI/Tooltip";
import { MessageSquare } from "components/UI/icons";
import { PlusCircle } from "components/UI/icons/PlusCircle";
import { Modal } from "components/UI/Modal";
import FormTextArea from "components/UI/Form/FormTextArea";
import { Button } from "components/UI/Button";
import { updateCandidateInReply } from "services/store/modules/repliesList";

import "./styles.scss";

function renderComments(comments: CandidateCommentType[]) {
  return (
    <div>
      {comments.map((commentObj, index) => (
        <div key={index}>
          {getDateFormat(commentObj.createdAt, "DD.MM.YY")}:{" "}
          {commentObj.comment}
        </div>
      ))}
    </div>
  );
}

interface CommentColumnProps {
  comments: CandidateCommentType[] | null;
  candidate: CandidateType;
  canAddComment: boolean;
  disable?: boolean;
}

const SUCCESS_MESSAGE = "Комментарий успешно добавлен";
const ERROR_MESSAGE = "Комментарий не добавлен";

export const CommentColumn: React.FC<CommentColumnProps> = ({
  comments,
  candidate,
  canAddComment,
  disable,
}) => {
  const dispatch = useAppDispatch();

  const { updateCandidateStatus } = useCandidateStatus({
    isShowToaster: true,
    titleSuccess: SUCCESS_MESSAGE,
    titleError: ERROR_MESSAGE,
  });

  const [isEdit, toggleIsEdit] = useToggle(false);
  const secondNameRef = useRef<HTMLDivElement>(null);

  const isHover = useHover(secondNameRef);

  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { id, status } = candidate;

  const updateCandidateForm = useCallback(
    async (comment: string) => {
      if (!status) return;

      setIsLoading(true);

      const result = await updateCandidateStatus({
        id,
        status,
        comment,
        vacancyId: null,
      });

      if (result?.changeCandidateStatus) {
        dispatch(updateCandidateInList(result.changeCandidateStatus));
        dispatch(updateCandidateInReply(result.changeCandidateStatus));
      }

      setIsLoading(false);
    },
    [status, updateCandidateStatus, id, dispatch]
  );
  const submitComment = async () => {
    await updateCandidateForm(value);
    toggleIsEdit();
    setValue("");
  };
  return (
    <div
      className="comment-container"
      ref={disable ? undefined : secondNameRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {comments?.length ? (
        <div>
          <Tooltip placement="bottomRight" title={renderComments(comments)}>
            <div>
              <MessageSquare />
            </div>
          </Tooltip>
          <div>{comments.length}</div>
        </div>
      ) : null}
      {canAddComment && isHover && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            toggleIsEdit();
          }}
        >
          <PlusCircle />
        </span>
      )}
      <Modal
        title="Оставить комментарий"
        visible={isEdit}
        onCancel={(e) => {
          e.stopPropagation();
          toggleIsEdit();
        }}
        footer={null}
      >
        <div>
          <FormTextArea
            label="Комментарий"
            size="middle"
            style={{ height: "105px", width: "90%" }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button loading={isLoading} onClick={submitComment}>
            Отправить
          </Button>
        </div>
      </Modal>
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { getInitialState } from "services/store/helpers";
import { HotCallType } from "graphql/types/types";
import { fetchCall } from "./action";

const callSlice = createSlice({
  name: "callSlice",
  initialState: getInitialState<HotCallType>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCall.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCall.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(fetchCall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const callsMangoReducer = callSlice.reducer;

import { RootState } from "../../rootReducer";
import { createSelector } from "reselect";
import { IUser } from "./types";
import { DefaultRootState } from "react-redux";
import { UserRole } from "graphql/types/types";

export const userList = (state: RootState) => state.user.userList;
export const userListMetaSelector = (state: RootState) =>
  state.user.userListMeta;
export const getUserByIDSelector = (state: RootState) => state.user.getUserByID;

export const selectUsersById = createSelector(userList, (state) =>
  state.reduce<Record<number, IUser>>((acc, user) => {
    acc[user.id] = user;
    return acc;
  }, {})
);

export const selectUsersByRoles = createSelector(
  userList,
  (_: DefaultRootState, roles: UserRole[]) => roles,
  (list, roles) =>
    roles.reduce<IUser[]>((acc, role) => {
      return [...acc, ...list.filter((user) => user.role === role)];
    }, [])
);

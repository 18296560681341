import { ClaimType } from "graphql/types/types";

export const getValidComments = ({
  userComment,
  userContent,
  userWish,
}: ClaimType): string[] =>
  [userComment || "", userContent || "", userWish || ""].filter(
    (comment) => comment !== ""
  );

import React, { useMemo, useRef, useState } from "react";
import cn from "classnames/dedupe";

import { useAppSelector } from "services/store/store";
import { PositionsRelationsType } from "graphql/types/types";
import { useHover } from "common/hooks/useHover";
import { useToggle } from "common/hooks/useToggle";
import { Edit, Close, CornerUpRight } from "components/UI/icons";
import { positions as positionsList } from "services/store/modules/directory";
import { filterOption, getSelectOptions } from "components/UI/Select/helpers";
import Select from "components/UI/Select";
import { useDebounceCallback } from "common/hooks/useDebounceCallback";
import { IconStatus, Status } from "components/UI/iconStatus";
import { getPositionById } from "services/store/modules/directory/getters";

import "./styles.scss";

type Props = {
  positions: PositionsRelationsType["positionIdTo"];
  positionsRelations: PositionsRelationsType;
  className?: string;
  updatePositionsRelations: (
    positionsValue: PositionsRelationsType
  ) => Promise<void>;
};

export const FieldPosition: React.FC<Props> = ({
  positions,
  positionsRelations,
  className,
  updatePositionsRelations,
}) => {
  const vacancyPositions = useAppSelector(positionsList);

  const positionRef = useRef<HTMLDivElement>(null);

  const isHover = useHover(positionRef);
  const [isEdit, toggleIsEdit] = useToggle(false);

  const [positionsIds, setPositionsIds] = useState(positions);
  const [status, setStatus] = useState<Status | null>(null);

  const debounceStatus = useDebounceCallback(setStatus, 3000);

  const positionNames = useMemo(
    () =>
      positions.map(
        (position) => getPositionById(vacancyPositions, position)?.name ?? ""
      ),
    [positions, vacancyPositions]
  );

  const changePositionsRelations = async () => {
    setStatus("pending");
    await updatePositionsRelations({
      ...positionsRelations,
      positionIdTo: positionsIds,
    })
      .then(() => setStatus("success"))
      .catch(() => setStatus("error"))
      .finally(() => {
        toggleIsEdit();
        debounceStatus(null);
      });
  };

  const handleChangeEditClick = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    toggleIsEdit();
  };

  const saveIcon = (
    <span
      className="field-position-icon"
      onClick={(e) => {
        e.stopPropagation();
        changePositionsRelations();
      }}
    >
      <CornerUpRight />
    </span>
  );

  return (
    <div className={cn("field-position", className)} ref={positionRef}>
      {isEdit ? (
        <Select
          mode="multiple"
          maxTagCount="responsive"
          className="field-position-select"
          value={positionsIds}
          options={getSelectOptions(vacancyPositions)}
          showSearch={true}
          optionFilterProp="children"
          maxTagPlaceholder={(tags) => `Ещё ${tags.length}`}
          filterOption={filterOption}
          suffixIcon={saveIcon}
          showArrow={true}
          onChange={(v) => setPositionsIds(v)}
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        <div
          className="field-position-short"
          title={positions.map((position) => position).join(", ")}
        >
          {positionNames.map((position) => (
            <span key={position}>{position ? `${position}; ` : ""}</span>
          ))}
        </div>
      )}
      {(isHover || isEdit) && !status && (
        <span className="field-position-icon" onClick={handleChangeEditClick}>
          {isEdit ? (
            <Close width={16} height={16} />
          ) : (
            <Edit width={16} height={16} />
          )}
        </span>
      )}
      {status && (
        <span className="field-position-icon">
          <IconStatus status={status} />
        </span>
      )}
    </div>
  );
};

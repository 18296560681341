import React, { HTMLAttributes } from "react";
import { Header } from "antd/es/layout/layout";

interface IDashboardHeaderProps extends HTMLAttributes<HTMLDivElement> {
  layout: string;
}

export const DashboardHeader: React.FC<IDashboardHeaderProps> = ({
  className,
  children,
}) => {
  return <Header className={className}>{children}</Header>;
};

import { gql } from "@apollo/client";

export const COMPANY_SETTINGS = gql`
  query companySettings {
    companySettings {
      ... on CompanySettingsType {
        id
        companyId
        createdAt
        deletedAt
        enabledExpire
        enabledRecall
        expireDepartmentId
        expireManagerId
        expireValue
        recallValue
        updatedAt
        workHoursEnd
        workHoursEndFriday
        workHoursStart
        workHoursStartFriday
        callTimeEnd
        callTimeStart
      }
      ... on Error {
        statusCode
        message
        errors
      }
    }
  }
`;

import React, { CSSProperties } from "react";
import { Col, Row } from "antd";
import cn from "classnames";

import "./styles.scss";

type Props = {
  leftColumn?: Column;
  rightColumn?: Column;
  centralColumn: Column;
};

type Column = {
  width?: number;
  content: React.ReactNode;
  className?: string;
  style?: CSSProperties;
};

export const ContentLayout: React.FC<Props> = ({
  leftColumn,
  centralColumn: {
    className,
    width = 24,
    style,
    content,
  },
  rightColumn,
}) => (
  <>
    <Row className="content-layout">
      {leftColumn && (
        <Col className="content-layout-left" span={leftColumn.width}>
          {leftColumn.content}
        </Col>
      )}
      <Col className={cn("content-layout-center", className)} span={width} style={style}>
        {content}
      </Col>
      {rightColumn && (
        <Col className="content-layout-right" span={rightColumn.width}>
          {rightColumn.content}
        </Col>
      )}
    </Row>
  </>
);

import React from "react";

import { CandidateHistoryItem } from "api/history";
import { Title } from "components/UI/Title";
import Text from "components/UI/Text";
import { COLOR_BY_CANDIDATE_STATUS } from "common/const/colors";
import { CANDIDATE_STATUS_TYPE } from "common/const/status";
import { useAppSelector } from "services/store/store";
import { getDateFormat } from "common/utils/date";
import { selectUsersById } from "services/store/modules/user/selectors";
import { RefusalReasonType } from "graphql/types/types";

import "./styles.scss";

interface Props {
  historyItems: CandidateHistoryItem[];
  refusalReasonsByKey: Record<string, RefusalReasonType>;
}

export const CandidateHistory: React.FC<Props> = ({
  historyItems,
  refusalReasonsByKey,
}) => {
  const usersById = useAppSelector(selectUsersById);

  const getStatus = (status: string) => {
    const list = status.split(":");

    return list.length
      ? `${CANDIDATE_STATUS_TYPE[list[0]] || ""} ${
          list.length > 1 ? "-" : ""
        } ${refusalReasonsByKey[list[1]]?.name || ""}`
      : "";
  };

  return (
    <>
      <div className="candidate-history global-scrollbar">
        <Title type="h4" color="grey">
          Активность
        </Title>
        {historyItems.map(
          ({ id, old_value, created_at, new_value, user_id }) => {
            const user = usersById[user_id];
            return (
              <div className="candidate-history-item" key={id}>
                <Text className="candidate-history-item-text">
                  Смена статуса
                </Text>
                <span
                  className="candidate-history-item-status"
                  style={{ color: COLOR_BY_CANDIDATE_STATUS[old_value] }}
                >
                  {getStatus(old_value)}
                </span>
                →
                <span
                  className="candidate-history-item-status"
                  style={{ color: COLOR_BY_CANDIDATE_STATUS[new_value] }}
                >
                  {getStatus(new_value)}
                </span>
                <Text className="candidate-history-item-manager">
                  {user
                    ? `${user.firstName} ${user.secondName}`
                    : "Система CRM Rabotut"}
                </Text>
                <Text className="candidate-history-item-date">
                  {getDateFormat(created_at, "DD.MM.YY HH:mm")}
                </Text>
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

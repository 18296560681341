import axios from "axios";
import { pickBy } from "lodash";

const LOKI_URL = process.env.REACT_APP_LOKI_HOST ?? "";

const LOKI_LABEL = process.env.REACT_APP_LOKI_LABEL;

export const LOG_LEVEL = {
  INFO: "info",
  ERROR: "error",
  DEBUG: "debug",
  WARNING: "warning",
};

export interface IPropLog {
  level: string;
  message: string;
  component?: string;
  field?: string;
  role?: string;
  user?: string;
}

export const payloadConstructor = (e: IPropLog) => {
  const data = {
    level: e?.level,
    message: e?.message,
    component: e?.component,
    field: e?.field,
    user: e?.user,
    role: e?.role,
  };

  const preparedData = pickBy(data);

  let payloadString = "";

  Object.entries(preparedData).map(([key, value]) => {
    if (key && value) {
      const val = `${key?.replace(/ /g, "_")}=${value?.replace(/ /g, "_")}`;
      payloadString += " " + val;
    }
    return null;
  });
  const nanoSec = Date.now() * 1_000_000;
  const dateString = nanoSec?.toString();

  return {
    streams: [
      {
        stream: {
          host: LOKI_LABEL,
        },
        values: [[dateString, payloadString]],
      },
    ],
  };
};

export class Logger {
  private static instance: Logger;

  private constructor() {}

  public static get(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  send(data: IPropLog) {
    const requestPayload = payloadConstructor(data);
    console.log("requestPayload", requestPayload);
    return axios.post(LOKI_URL, requestPayload);
  }
}

import React from "react";
import { Form } from "antd";
import { PropsTags, Tag } from "../../Tag";
import classNames from "classnames";
import "./styles.scss";

export interface TagData {
  name: string;
  color: PropsTags["color"];
}

interface FormTagProps extends PropsTags {
  label: string;
  tags: TagData[];
}

const FormTag: React.FC<FormTagProps> = ({
  className,
  label,
  tags,
  ...props
}) => {
  const formTagClassName = classNames("ui-form-tag", className);

  return (
    <Form.Item label={label} className={formTagClassName}>
      {tags.map(({ color, name }) => (
        <Tag key={name} color={color || "default"} {...props}>
          {name}
        </Tag>
      ))}
    </Form.Item>
  );
};

export default FormTag;

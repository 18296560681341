import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";

export const selectVacancy = (state: RootState) => state.vacancy.currentVacancy;

export const selectVacancyData = createSelector(
  selectVacancy,
  (state) => state.data
);

export const selectVacancyLoading = createSelector(
  selectVacancy,
  (state) => state.loading
);

export const selectSavedVacancy = (state: RootState) =>
  state.vacancy.savedVacancy;

export const selectSavedVacancyData = createSelector(
  selectSavedVacancy,
  (state) => state.data
);

import { Source } from "graphql/types/types";

export const SOURCE_TYPE: Record<Source, string> = {
  [Source.Website]: "Rabotut.ru",
  [Source.Claim]: "Заявка",
  [Source.Reply]: "Отклик",
  [Source.Telegram]: "Telegram",
  [Source.Hh]: "HeadHunter",
  [Source.Avito]: "Avito.Работа",
  [Source.Vk]: "Вконтакте",
  [Source.Nerab]: "Нераб",
  [Source.Bcr]: "BCR",
  [Source.Mobile]: "Mobile",
  [Source.Vp]: "Ваш Персонал",
};

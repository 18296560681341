import React, { forwardRef } from "react";
import { Table as AntTable } from "antd";
import type { TableProps } from "antd/es/table";

import "./styles.scss";

export type Props<T extends object> = TableProps<T>;

function TableInner<T extends object>(
  { bordered = true, ...props }: Props<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div ref={ref}>
      <AntTable<T>
        className="ui-table"
        rowClassName="ui-table-row"
        bordered={bordered}
        pagination={false}
        {...props}
      />
    </div>
  );
}

export const Table = forwardRef(TableInner) as <T extends object>(
  props: Props<T> & { ref?: React.Ref<HTMLDivElement> }
) => React.ReactElement;

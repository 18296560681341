import React from "react";
import { Popover } from "components/UI/Popover";
import {
  isOktellAccessSelector,
  oktellStatusSelector,
  statusSocketSelector,
  statusWorkerSelector,
} from "services/store/modules/sockets/selectors";
import { useAppSelector } from "services/store/store";
import { IndicatorBlock } from "../IndicatorBlock";
import "./styles.scss";

interface IndicatorServiceWorkersProps {
  isCollapsed: boolean;
}

export const IndicatorServiceWorkers: React.FC<
  IndicatorServiceWorkersProps
> = ({ isCollapsed }) => {
  const statusWorker = useAppSelector(statusWorkerSelector);
  const statusSocket = useAppSelector(statusSocketSelector);
  const oktellStatus = useAppSelector(oktellStatusSelector);
  const isAllIndicator = useAppSelector(isOktellAccessSelector);

  const indicators = [
    { status: statusWorker, label: "Service Worker" },
    { status: statusSocket, label: "Web Socket" },
    { status: oktellStatus, label: "Oktell" },
  ];

  return (
    <Popover
      content={
        <div className="popover-content">
          {indicators.map(({ status, label }) => (
            <IndicatorBlock
              key={label}
              isOnline={status === "Онлайн"}
              label={label}
            />
          ))}
        </div>
      }
      trigger="click"
      placement={isCollapsed ? "right" : "top"}
    >
      <div className="debug">
        <div className="debug-content">
          <IndicatorBlock
            isOnline={isAllIndicator}
            label={isAllIndicator ? "Онлайн" : "Оффлайн"}
            isCollapsed={isCollapsed}
          />
        </div>
      </div>
    </Popover>
  );
};

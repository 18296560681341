import { gql } from "@apollo/client";

export const UPDATE_POSITION_COUNT = gql`
  mutation updatePositionCount($id: Int!, $count: Int!) {
    updatePositionCount(id: $id, count: $count) {
      ... on PositionPeopleCountType {
        countPeople
        countPeopleNew
        position {
          categoryId
          id
          key
          name
          restriction
        }
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;

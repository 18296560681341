import React from "react";
import { IconType } from "common/commonTypes/types";

export const Ellipsis: React.FC<IconType> = ({
  width = 25,
  height = 24,
  color = "#A6ACB9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 12C14.75 10.7574 13.7426 9.75 12.5 9.75C11.2574 9.75 10.25 10.7574 10.25 12C10.25 13.2426 11.2574 14.25 12.5 14.25C13.7426 14.25 14.75 13.2426 14.75 12Z"
        fill={color}
      />
      <path
        d="M22.25 12C22.25 10.7574 21.2426 9.75 20 9.75C18.7574 9.75 17.75 10.7574 17.75 12C17.75 13.2426 18.7574 14.25 20 14.25C21.2426 14.25 22.25 13.2426 22.25 12Z"
        fill={color}
      />
      <path
        d="M7.25 12C7.25 10.7574 6.24264 9.75 5 9.75C3.75736 9.75 2.75 10.7574 2.75 12C2.75 13.2426 3.75736 14.25 5 14.25C6.24264 14.25 7.25 13.2426 7.25 12Z"
        fill={color}
      />
    </svg>
  );
};

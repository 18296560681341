import React from "react";
import { Input as AntInput, InputProps as AntInputProps, InputRef } from "antd";
import classNames from "classnames";
import "./styles.scss";

export interface InputProps extends AntInputProps {}

export const Input = React.forwardRef<InputRef, InputProps>(
  (
    {
      style,
      className,
      disabled,
      required,
      status,
      type,
      size = "large",
      ...props
    },
    ref
  ) => {
    const inputClassNames = classNames(
      "ui-input",
      {
        "ui-input--disabled": disabled,
        "ui-input--required": required,
        "ui-input--error": status === "error",
        "ui-input--number": type === "number",
      },
      className
    );
    return (
      <AntInput
        ref={ref}
        type={type}
        className={inputClassNames}
        style={{ ...style }}
        disabled={disabled}
        size={size}
        {...props}
      />
    );
  }
);

export default Input;

import React from "react";
import { Checkbox as AntCheckbox } from "antd";
import { CheckboxGroupProps } from "antd/es/checkbox";
import classNames from "classnames";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import "./styles.scss";

interface CheckboxProps extends CheckboxGroupProps {
  type: "single" | "multi";
  checked?: boolean;
  onSingleChange?: (e: CheckboxChangeEvent) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  type,
  onChange,
  onSingleChange,
  checked,
  className,
  disabled,
  style,
  children,
  ...props
}) => {
  const checkboxClassNames = classNames(
    "ui-checkbox",
    {
      "ui-checkbox--disabled": disabled,
    },
    className
  );
  return (
    <>
      {type === "single" ? (
        <AntCheckbox
          className={checkboxClassNames}
          style={style}
          disabled={disabled}
          checked={checked}
          onChange={onSingleChange}
          {...props}
        >
          {children}
        </AntCheckbox>
      ) : (
        <AntCheckbox.Group
          className={checkboxClassNames}
          style={style}
          disabled={disabled}
          onChange={onChange}
          {...props}
        />
      )}
    </>
  );
};

export default Checkbox;

// @ts-nocheck
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActivityType,
  IRefusal,
  IReply,
  IReplyComment,
  IReplyCommentRequestPayload,
  IReplyHistory,
  IReplyRequestPayload,
  IReplyResponse,
  RepliesState,
} from "./types";
import { RootState } from "../../rootReducer";
import { client as apollo } from "graphql/apollo";
import { cloneDeep } from "lodash";
import { REPLY_LIST } from "graphql/requests/query/replyList";
import { VACANCY } from "graphql/requests/query/vacancy";
import { REPLY_ITEM } from "graphql/requests/query/reply";
import { IActivityMeta, IActivityRequestPayload, IMeta } from "../../types";
import { DECLINE_REPLY } from "graphql/requests/mutation/declineReply";
import { CHANGE_REPLY_STATUS } from "graphql/requests/mutation/changeReplyStatus";
import { IDepartment } from "../directory/types";
import {
  ContactsType,
  MutationTransferReplyListArgs,
  ReplyUpdateType,
  UserRole,
} from "graphql/types/types";
import { UPDATE_REPLY } from "graphql/requests/mutation/updateReply";
import axios from "axios";
import { camelize } from "common/helpers/keyConverter";
import { IHistoryUser, IUser } from "../user/types";
import { fillUserHistoryList, fillUsersHistory } from "../user/action";
import { normalizedDate } from "common/helpers/toISOStringWithTimezone";
import toaster from "components/UI/Notifications/Notification";
import { replyStatusResolver } from "common/helpers/status";
import { TRANSFER_REPLY_LIST } from "../../../../graphql/requests/mutation/transferReplyList";

export const initialState: RepliesState = {
  replyList: [],
  replyMeta: {
    total: 0,
    limit: 10,
    offset: 0,
  },
  currentReplyHistory: [],
  currentReply: undefined,
  isLoadingReplyActivity: false,
  replyComments: [],
  replyActivity: [],
  replyCommentsMeta: {
    total: 0,
    page: 0,
    size: 10,
  },
  replyHistoryMeta: {
    total: 0,
    page: 0,
    size: 10,
  },
  replyActivityMeta: {
    total: 0,
    page: 0,
    size: 10,
  },
};

export const repliesModule = createSlice({
  name: "replies",
  initialState,
  reducers: {
    setReplyList(state: RepliesState, { payload }: PayloadAction<IReply[]>) {
      state.replyList = payload;
    },
    setReplyMeta(state: RepliesState, { payload }: PayloadAction<IMeta>) {
      state.replyMeta = payload;
    },
    setCurrentReplyHistory(
      state: RepliesState,
      { payload }: PayloadAction<IReplyHistory[]>
    ) {
      const list = state.currentReplyHistory;
      state.currentReplyHistory = [...list, ...payload];
    },
    setCurrentReply(state: RepliesState, { payload }: PayloadAction<IReply>) {
      state.currentReply = payload;
    },
    setLoadingReplyActivity(
      state: RepliesState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isLoadingReplyActivity = payload;
    },
    setReplyComments(
      state: RepliesState,
      { payload }: PayloadAction<IReplyComment[]>
    ) {
      const list = state.replyComments;
      state.replyComments = [...list, ...payload];
    },
    setReplyActivity(
      state: RepliesState,
      { payload }: PayloadAction<ActivityType[]>
    ) {
      const list = state.replyActivity;
      state.replyActivity = [...list, ...payload];
    },
    setReplyHistoryMeta(
      state: RepliesState,
      { payload }: PayloadAction<IActivityMeta>
    ) {
      state.replyHistoryMeta = payload;
    },
    setReplyCommentsMeta(
      state: RepliesState,
      { payload }: PayloadAction<IActivityMeta>
    ) {
      state.replyCommentsMeta = payload;
    },
    setReplyActivityMeta(
      state: RepliesState,
      { payload }: PayloadAction<IActivityMeta>
    ) {
      state.replyActivityMeta = payload;
    },
    clearReplyHistory(state: RepliesState) {
      state.currentReplyHistory = [];
      state.replyHistoryMeta = {
        total: 0,
        page: 0,
        size: 10,
      };
    },
    clearReplyActivity(state: RepliesState) {
      state.replyActivity = [];
      state.replyActivityMeta = {
        total: 0,
        page: 0,
        size: 10,
      };
    },
    clearReplyComments(state: RepliesState) {
      state.replyComments = [];
      state.replyCommentsMeta = {
        total: 0,
        page: 0,
        size: 10,
      };
    },
  },
});
// * mutations
export const {
  setReplyList,
  setReplyMeta,
  setCurrentReplyHistory,
  setCurrentReply,
  setLoadingReplyActivity,
  setReplyComments,
  setReplyActivity,
  setReplyHistoryMeta,
  setReplyCommentsMeta,
  setReplyActivityMeta,
  clearReplyHistory,
  clearReplyActivity,
  clearReplyComments,
} = repliesModule.actions;

// * getters
export const replyList = (state: RootState) => state.replies.replyList;
export const replyMeta = (state: RootState) => state.replies.replyMeta;
export const currentReplyHistory = (state: RootState) => {
  const userHistoryList = state.user.historyUserList;
  const activities = state.replies.currentReplyHistory;
  const data = activities.map((item: IReplyHistory) => {
    const created = item.createdAt;
    const preparedDate = normalizedDate(created);
    const el = {
      ...item,
      manager: item?.userId ? (userHistoryList?.[item?.userId] as IUser) : 0,
      createdAt: preparedDate,
      dateSortValue: new Date(created)?.valueOf(),
    };
    if (item?.field === "owner_id" || item?.field === "manager_id") {
      el.newManager = userHistoryList?.[item?.newValue as number] as IUser;
      el.oldManager = userHistoryList?.[item?.oldValue as number] as IUser;
    }
    return el;
  }) as IReplyHistory[];
  return data.sort(
    (a: IReplyHistory, b: IReplyHistory) =>
      (b?.dateSortValue || 0) - (a?.dateSortValue || 0)
  );
};

export const currentReply = (state: RootState) => state.replies.currentReply;
export const isLoadingReplyActivity = (state: RootState) =>
  state.replies.isLoadingReplyActivity;
export const currentReplyComments = (state: RootState) => {
  const userHistoryList = state.user.historyUserList;
  const comments = state.replies.replyComments;
  const data = comments.map((item: IReplyComment) => {
    const created = item.createdAt;
    const preparedDate = normalizedDate(created);
    return {
      ...item,
      manager: item?.userId ? userHistoryList?.[item?.userId] : undefined,
      createdAt: preparedDate,
      dateSortValue: new Date(created)?.valueOf(),
    };
  }) as IReplyComment[];
  return data.sort(
    (a: IReplyComment, b: IReplyComment) =>
      (b?.dateSortValue || 0) - (a?.dateSortValue || 0)
  );
};
export const currentReplyActivity = (state: RootState) => {
  const userHistoryList = state.user.historyUserList;
  const userActivityList = state.replies.replyActivity;
  const data = userActivityList.map((item: ActivityType) => {
    const created = item.createdAt;
    const preparedDate = normalizedDate(created);
    const el = {
      ...item,
      manager: item?.userId ? (userHistoryList?.[item?.userId] as IUser) : 0,
      createdAt: preparedDate,
      dateSortValue: new Date(created)?.valueOf(),
    } as ActivityType;
    if (
      item.modelType === "event" &&
      (item?.field === "owner_id" || item?.field === "manager_id")
    ) {
      if ("newManager" in el) {
        el.newManager = userHistoryList?.[item?.newValue as number] as IUser;
      }
      if ("oldManager" in el) {
        el.oldManager = userHistoryList?.[item?.oldValue as number] as IUser;
      }
    }
    return el;
  }) as ActivityType[];
  return data.sort(
    (a: ActivityType, b: ActivityType) =>
      (b?.dateSortValue || 0) - (a?.dateSortValue || 0)
  );
};
export const replyHistoryMeta = (state: RootState) =>
  state.replies.replyHistoryMeta;
export const replyCommentsMeta = (state: RootState) =>
  state.replies.replyCommentsMeta;
export const replyActivityMeta = (state: RootState) =>
  state.replies.replyActivityMeta;

// * actions
export const getReplies = createAsyncThunk(
  "user/onSearch",
  async (payload: IReplyRequestPayload, thunkAPI) => {
    try {
      let filtersForRequest = cloneDeep(payload?.filter);
      delete filtersForRequest?.["search"];
      const res = await apollo.query<IReplyResponse>({
        query: REPLY_LIST,
        variables: {
          pagination: payload.pagination,
          filter: payload.filter,
        },
      });
      // @ts-ignore
      const rootState = thunkAPI.getState();
      // @ts-ignore
      const userData = rootState?.auth?.userData;
      // @ts-ignore
      const departmentList = rootState?.directory?.departments || [];
      if (res?.data?.replyList?.data) {
        const replyList: IReply[] = res.data.replyList.data.map((item) => ({
          ...item,
          manager: item.manager?.departmentId
            ? {
                ...item.manager,
                department: departmentList.find(
                  (el: IDepartment) =>
                    el.id ===
                    parseInt(item.manager?.departmentId?.toString() || "", 10)
                ),
              }
            : item.manager,
          status: [UserRole.Hrbp, UserRole.Manager].includes(userData?.role)
            ? replyStatusResolver(item.status, item?.isCreatedAtBusinessHours)
            : item.status,
        }));
        thunkAPI.dispatch(setReplyList(replyList));
      }
      if (res?.data?.replyList?.meta) {
        const replyMeta: IMeta = res?.data?.replyList?.meta;
        thunkAPI.dispatch(setReplyMeta(replyMeta));
      }
    } catch (err) {
      console.error(err);
      thunkAPI.dispatch(setReplyList([]));
      const defaultMeta = {
        total: 0,
        limit: 10,
        offset: 0,
      };
      thunkAPI.dispatch(setReplyMeta(defaultMeta));
    }
  }
);

export async function toSwitchReplyStatus(id: number, status: string) {
  try {
    const res = await apollo.mutate({
      mutation: CHANGE_REPLY_STATUS,
      variables: {
        id: id,
        status: status,
      },
    });
    return res?.data?.changeReplyStatus;
  } catch (err) {
    console.error(err);
  }
}

export async function vacancyById(id: number) {
  try {
    const res = await apollo.query({
      query: VACANCY,
      variables: {
        id: id,
      },
    });
    return res;
  } catch (err) {
    console.error(err);
  }
}
export async function replyById(id: number) {
  try {
    const res = await apollo.query({
      query: REPLY_ITEM,
      variables: {
        id: id,
      },
    });
    return res;
  } catch (err) {
    console.error(err);
  }
}

export async function replyDecline(
  id: number,
  statusID: string,
  refusalID: string,
  text: string
) {
  try {
    await apollo.query({
      query: DECLINE_REPLY,
      variables: {
        id: id,
        status: statusID,
        reasonKey: refusalID,
        refusalText: text,
      },
    });
  } catch (err) {
    console.error(err);
  }
}

export const updateReply = createAsyncThunk(
  "reply/updateReply",
  async (payload: { form: ReplyUpdateType; id?: number }, thunkAPI) => {
    const { id } = payload;
    const { form } = payload;

    const formData = {
      address: form?.address,
      contacts: form?.contacts,
    };
    try {
      const res = await apollo.mutate({
        mutation: UPDATE_REPLY,
        variables: {
          id: id,
          form: formData,
        },
      });
      if (res?.data?.updateReply) {
        const reply = res.data.updateReply;
        if (reply?.id) {
          // @ts-ignore
          const list = thunkAPI.getState()?.replies?.replyList;
          const current = list.find((item: IReply) => item.id === id);
          const copy = cloneDeep(current);
          if (current) {
            copy.contacts = reply.contacts;
            copy.address = reply.address;
          }

          const newList = list.filter((item: IReply) => item.id !== reply.id);
          const payload = [copy, ...newList];

          thunkAPI.dispatch(setReplyList(payload));
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const getCurrentReplyHistory = createAsyncThunk(
  "reply/getHistory",
  async (payload: IActivityRequestPayload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoadingReplyActivity(true));
      const { id, page } = payload;

      const nextPage = page + 1;

      const size = 10;
      // @ts-ignore
      const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
      const res = await axios.get(
        `${activityApiUrl}/history/reply/${id}?page=${nextPage}&size=${size}`
      );
      if (res?.data?.items?.length) {
        const preparedData = camelize(res?.data.items).sort(
          (a: IReplyHistory, b: IReplyHistory) =>
            new Date(b.createdAt)?.valueOf() - new Date(a.createdAt)?.valueOf()
        );
        const data = preparedData.map((item: IReplyHistory) => {
          const el = item as IReplyHistory;
          if (item.field === "manager_id" || item.field === "owner_id") {
            // @ts-ignore
            const historyUserCollection: IHistoryUser =
              thunkAPI.getState()?.user?.historyUserList;
            if (!historyUserCollection?.[item?.newValue as number]) {
              const payload = [item.newValue];
              thunkAPI.dispatch(fillUserHistoryList(payload));
            } else {
              el.newManager = historyUserCollection?.[
                item?.newValue as number
              ] as IUser;
            }
            if (!historyUserCollection?.[item?.oldValue as number]) {
              const payload = [item.oldValue];
              thunkAPI.dispatch(fillUserHistoryList(payload));
            } else {
              el.oldManager = historyUserCollection?.[
                item?.oldValue as number
              ] as IUser;
            }
          }
          if (
            item.field === "contacts" ||
            item.field === "refusal" ||
            item.field === "address"
          ) {
            if (item?.newValue) {
              const newValue = JSON.parse(item.newValue as string);
              el.newValue = camelize(newValue) as
                | ContactsType
                | IUser
                | IRefusal;
            }
            if (item.oldValue) {
              const oldValue = JSON.parse(item.oldValue as string);
              el.oldValue = camelize(oldValue) as
                | ContactsType
                | IUser
                | IRefusal;
            }
          }
          return el;
        });
        const meta = {
          page: res?.data?.page,
          size: res?.data?.size,
          total: res?.data?.total,
        };
        thunkAPI.dispatch(setReplyHistoryMeta(meta));
        thunkAPI.dispatch(setCurrentReplyHistory(data));
        thunkAPI.dispatch(fillUsersHistory());
      } else {
        console.error("activity not found ");
        thunkAPI.dispatch(setCurrentReplyHistory([]));
      }
    } catch (err) {
      console.error(`reply id ${payload}`);
      console.error(err);
      toaster.error({
        title: err?.message || "Ошибка загрузки истории изменения отклика",
      });
    } finally {
      thunkAPI.dispatch(setLoadingReplyActivity(false));
    }
  }
);

export const getCurrentReplyComments = createAsyncThunk(
  "reply/getComments",
  async (payload: IActivityRequestPayload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoadingReplyActivity(true));

      const { id, page } = payload;

      const nextPage = page + 1;

      const size = 10;

      // @ts-ignore
      const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
      const res = await axios.get(
        `${activityApiUrl}/comment/reply/${id}?page=${nextPage}&size=${size}`
      );
      if (res?.data?.items?.length) {
        // @ts-ignore
        const historyUserCollection: IHistoryUser =
          thunkAPI.getState()?.user?.historyUserList;
        const loadedUserIdList: string[] = Object.keys(historyUserCollection);
        const payloadIdList: string[] = [];

        const data = camelize(res.data.items) as IReplyComment[];
        data.map((item) => {
          const userId = item?.userId;
          if (
            userId &&
            !loadedUserIdList?.includes(String(userId)) &&
            !payloadIdList?.includes(String(userId))
          ) {
            payloadIdList.push(String(userId));
          }
          return null;
        });
        if (payloadIdList?.length) {
          thunkAPI.dispatch(fillUserHistoryList(payloadIdList));
        }
        const meta = {
          page: res?.data?.page,
          size: res?.data?.size,
          total: res?.data?.total,
        };

        thunkAPI.dispatch(setReplyCommentsMeta(meta));
        thunkAPI.dispatch(setReplyComments(data));
      }
    } catch (err) {
      console.error(`reply id ${payload}`);
      console.error(err);
      toaster.error({ title: err?.message || "Ошибка загрузки комментариев" });
    } finally {
      thunkAPI.dispatch(setLoadingReplyActivity(false));
    }
  }
);

export const createReplyComment = createAsyncThunk(
  "reply/createComment",
  async (payload: IReplyCommentRequestPayload, thunkAPI) => {
    const { userId, replyId, content } = payload;
    try {
      thunkAPI.dispatch(setLoadingReplyActivity(true));
      // @ts-ignore
      const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
      await axios.post(`${activityApiUrl}/comment/reply/`, {
        user_id: userId,
        content: content,
        reply_id: replyId,
      });
    } catch (err) {
      console.error(`reply id ${replyId}`);
      console.error(err);
      toaster.error({ title: err?.message || "Ошибка отправки комментария" });
    } finally {
      thunkAPI.dispatch(setLoadingReplyActivity(false));
    }
  }
);

export const getReplyActivity = createAsyncThunk(
  "reply/getReplyActivity",
  async (payload: IActivityRequestPayload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoadingReplyActivity(true));

      const { id, page } = payload;

      const nextPage = page + 1;

      const size = 10;
      // @ts-ignore
      const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
      const res = await axios.get(
        `${activityApiUrl}/reply/${id}?page=${nextPage}&size=${size}`
      );
      if (res?.data?.items?.length) {
        const allActivity = camelize(res.data.items) || [];
        //* заполняем пользователей
        // @ts-ignore
        const historyUserCollection: IHistoryUser =
          thunkAPI.getState()?.user?.historyUserList;
        const loadedUserIdList: string[] = Object.keys(historyUserCollection);
        const payloadIdList: string[] = [];
        allActivity.map((item: ActivityType) => {
          const userId = item?.userId;
          if (
            userId &&
            !loadedUserIdList?.includes(String(userId)) &&
            !payloadIdList?.includes(String(userId))
          ) {
            payloadIdList.push(String(userId));
          }
          return null;
        });
        if (payloadIdList?.length) {
          thunkAPI.dispatch(fillUserHistoryList(payloadIdList));
        }

        const commentList = allActivity.filter(
          (item: ActivityType) => item.modelType === "comment"
        );
        const historyList = allActivity.filter(
          (item: ActivityType) => item.modelType === "event"
        );

        const preparedHistoryList = historyList.map((item: IReplyHistory) => {
          const el = item as IReplyHistory;
          if (item.field === "manager_id" || item.field === "owner_id") {
            if (!historyUserCollection?.[item?.newValue as number]) {
              const payload = [item.newValue];
              thunkAPI.dispatch(fillUserHistoryList(payload));
              el.newManager = undefined;
            } else {
              el.newManager = historyUserCollection?.[
                item?.newValue as number
              ] as IUser;
            }
            if (!historyUserCollection?.[item?.oldValue as number]) {
              const payload = [item.oldValue];
              thunkAPI.dispatch(fillUserHistoryList(payload));
              el.oldManager = undefined;
            } else {
              el.oldManager = historyUserCollection?.[
                item?.oldValue as number
              ] as IUser;
            }
          }
          if (
            item.field === "contacts" ||
            item.field === "refusal" ||
            item.field === "address"
          ) {
            if (item?.newValue) {
              const newValue = JSON.parse(item.newValue as string);
              el.newValue = camelize(newValue) as
                | ContactsType
                | IUser
                | IRefusal;
            }
            if (item.oldValue) {
              const oldValue = JSON.parse(item.oldValue as string);
              el.oldValue = camelize(oldValue) as
                | ContactsType
                | IUser
                | IRefusal;
            }
          }
          return el;
        });

        const allActivityList = [...commentList, ...preparedHistoryList];

        const meta = {
          page: res?.data?.page,
          size: res?.data?.size,
          total: res?.data?.total,
        };

        thunkAPI.dispatch(setReplyActivityMeta(meta));
        thunkAPI.dispatch(setReplyActivity(allActivityList));
      }
    } catch (err) {
      console.error(`reply id ${payload}`);
      console.error(err);
      toaster.error({ title: err?.message || "Ошибка загрузки активности" });
    } finally {
      thunkAPI.dispatch(setLoadingReplyActivity(false));
    }
  }
);

export const transferReplyList = createAsyncThunk(
  "replies/transferReplyList",
  async (payload: MutationTransferReplyListArgs) => {
    try {
      await apollo.mutate({
        mutation: TRANSFER_REPLY_LIST,
        variables: payload,
      });
      toaster.success({ title: "Отклики переданы успешно" });
    } catch (err) {
      toaster.error({
        title: err?.message || "Ошибка передачи откликов комментария",
      });
    }
  }
);

export default repliesModule.reducer;

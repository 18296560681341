import React from "react";

export const ErrorPublish: React.FC = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="8" fill="#AC0000" fillOpacity="0.1" />
      <path
        d="M34 18L18 34"
        stroke="#E62249"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 18L34 34"
        stroke="#E62249"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import styled from "styled-components";
import instagram from "../assets/icons/inst.svg";
import vkontakte from "../assets/icons/vk.svg";
import odnoklassniki from "../assets/icons/odk.svg";
import logo from "../assets/images/logo_light.svg";

import "components/UI/styles/palette.scss";

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  padding: 40px 10.5%;
  background-color: $blue-color;
  color: $white-color;
  width: 100%;
`;

const Policy = styled.div`
  display: block;
  padding: 0 0 7px;
  font-weight: 500;
  text-align: center;
  font-size: 0.8rem;
  color: $white-color;
`;

const PolicyLink = styled.a`
  color: $white-color;
  text-decoration-line: underline;
`;

const Copyrights = styled.div`
  font-size: 0.8rem;
`;
const FooterColumn = styled.div`
  padding-bottom: 20px;
  :nth-child(n + 2) {
    padding-top: 15px;
  }
`;
const FooterImage = styled.img`
  width: 60%;
`;
const FooterLink = styled.a`
  display: block;
  display: flex;
  justify-content: center;
  background-color: $white-color;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  position: relative;
`;

const FooterIcons = styled.div`
  width: 155px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const FooterInfo = styled.p`
  font-weight: 700;
  margin-bottom: 20px;
`;

const FooterInfoBox = styled.div`
  padding: 0 0 20px;
`;
const FooterLinkItem = styled.div`
  padding-bottom: 7px;
  display: flex;
  flex-direction: column;
`;

const FooterCommonLink = styled.a`
  color: var(--main-color);
  font-weight: 700;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const FooterInfoLink = styled(FooterCommonLink)`
  display: block;
`;
const FooterInfoTel = styled(FooterCommonLink)`
  font-size: 1.2rem;
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterColumn>
        <img src={logo} alt="logo" />
        <FooterIcons>
          <FooterLink href="https://vk.com/rabotutru" target="_blank">
            <FooterImage src={vkontakte} alt="icon-vk" />
          </FooterLink>
          <FooterLink
            href="https://www.instagram.com/rabotut/?igshid=1pam587nzahtc"
            target="_blank"
          >
            <FooterImage src={instagram} alt="icon-inst" />
          </FooterLink>
          <FooterLink href="https://ok.ru/group/58725888819340" target="_blank">
            <FooterImage src={odnoklassniki} alt="icons-odk" />
          </FooterLink>
        </FooterIcons>
      </FooterColumn>

      <FooterColumn>
        <FooterLinkItem>
          <FooterCommonLink href="https://rabotut.ru/contacts">
            Контакты
          </FooterCommonLink>
          <FooterCommonLink href="https://rabotut.ru/company">
            О компании
          </FooterCommonLink>
        </FooterLinkItem>
      </FooterColumn>

      <FooterColumn>
        <FooterInfoBox>
          <FooterInfo> Для получения информации: </FooterInfo>
          <FooterInfoTel href="tel:88007008484">8 800 700 84 84</FooterInfoTel>
          <FooterInfoLink href="mailto:sos@rabotut.ru">
            sos@rabotut.ru
          </FooterInfoLink>
          <FooterInfoLink href="mailto:Info@rabotut.ru">
            Info@rabotut.ru
          </FooterInfoLink>
        </FooterInfoBox>

        <Policy>
          <PolicyLink href="/rabotut_policy.pdf" target="_blank">
            Политика
          </PolicyLink>{" "}
          использования персональных данных
        </Policy>
        <Copyrights> OOO Работут © 2019. Все права защищены.</Copyrights>
        {/* <Copyrights>
          2017-{new Date().getFullYear()} — Rabotut. Все права защищены
        </Copyrights> */}
      </FooterColumn>
    </FooterContainer>
  );
};

import React, { useEffect } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  useWatch,
  UseFormSetValue,
} from "react-hook-form";
import classNames from "classnames";
import { FormInputMask } from "components/UI/Form/FormInputMask";
import { ContactType } from "graphql/types/types";
import { Button } from "components/UI/Button";
import { Plus } from "components/UI/icons/Pluse";
import { Close } from "components/UI/icons";
import { getUpdatedContacts, getEmptyContact } from "./helpers";
import { PHONE_MASK } from "common/const/masks";
import { FiltersFormState } from "../types";

import "./styles.scss";

interface CandidatePhoneProps {
  control: Control<FiltersFormState>;
  disabledForm: boolean;
  errors: FieldErrors<FiltersFormState>;
  setValue: UseFormSetValue<FiltersFormState>;
}

const CandidatePhone: React.FC<CandidatePhoneProps> = ({
  control,
  disabledForm,
  errors,
  setValue,
}) => {
  const contacts = useWatch({ control, name: "contacts" });

  const addNewContact = () => {
    contacts.push(getEmptyContact());
    setValue("contacts", contacts);
  };

  const updateContact = (contactId: ContactType["id"], value: string) => {
    setValue("contacts", getUpdatedContacts(contacts, contactId, value));
  };

  const removeContact = (id: ContactType["id"]) => {
    setValue(
      "contacts",
      contacts.filter((contact) => contact.id !== id)
    );
  };

  useEffect(() => {
    if (disabledForm) {
      setValue(
        "contacts",
        contacts.filter(({ id }) => !id?.includes("temp"))
      );
    }
  }, [disabledForm]);

  return (
    <div className="candidate-phone-container">
      <>
        {contacts.length ? (
          <>
            {contacts.map(({ id, isMain }, index) => (
              <Controller
                name={`contacts.${index}.contact` as const}
                control={control}
                key={id}
                render={({ field: { value }, fieldState: { error } }) => (
                  <FormInputMask
                    disabled={disabledForm}
                    mask={PHONE_MASK}
                    error={
                      errors.contacts?.[index]?.contact?.message ??
                      error?.message
                    }
                    placeholder="+7"
                    label={`Телефон ${
                      isMain || index === 0 ? "(осн)" : "(доп)"
                    }`}
                    value={value || ""}
                    onChange={(e) => updateContact(id, e.target.value)}
                    className={classNames("candidate-phone-container-input", {
                      "wide-input": contacts.length === 1,
                    })}
                    suffix={
                      !disabledForm &&
                      !(isMain || index === 0) && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            removeContact(id);
                          }}
                          className="candidate-phone-container-input-icon"
                        >
                          <Close width={16} height={16} color="#A6ACB9" />
                        </span>
                      )
                    }
                  />
                )}
              />
            ))}
          </>
        ) : (
          <span>Добавьте контакт</span>
        )}
      </>
      {!disabledForm && contacts.length < 3 && (
        <Button
          variant="primary"
          icon={<Plus />}
          className="candidate-phone-container-btn"
          onClick={addNewContact}
        />
      )}
    </div>
  );
};

export default CandidatePhone;

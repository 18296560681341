import React from "react";

import { Modal } from "components/UI/Modal";
import { Button } from "components/UI/Button";
import { SuccessPublish } from "components/UI/icons/SuccessPublish";
import { Title } from "components/UI/Title";
import { ErrorPublish } from "components/UI/icons/ErrorPublish";
import {
  getSecondTextByStatus,
  getTextByStatus,
  getTitleByStatus,
} from "./helpers";
import { Platform } from "common/const/platform";

import "./styles.scss";

export type PublicationStatus = {
  status: PublishStatus;
  message: string | null;
};

export interface PublishModalProps {
  isVisible: boolean;
  onClose: () => void;
  jobBoard: Platform;
  publication: PublicationStatus;
}

export type PublishStatus = "success" | "error";

export const PublicationStatusModal: React.FC<PublishModalProps> = ({
  isVisible,
  onClose,
  publication: { status, message },
  jobBoard,
}) => {
  const Icon = status === "success" ? SuccessPublish : ErrorPublish;

  return (
    <Modal
      title={null}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      closable={false}
      noSidePanel={true}
      size="normal"
    >
      <div className="modal-content">
        <div className="modal-content-header">
          <Icon />
          <Title type="h3" className="modal-content-header-title">
            Вакансия <br /> {getTitleByStatus(jobBoard)[status]}
          </Title>
        </div>

        <div className="modal-content-body">
          <p className="modal-content-body-text">
            {getTextByStatus(jobBoard)[status]}
            <br />
            {getSecondTextByStatus(message ?? "")[status]}
          </p>
        </div>

        <Button onClick={onClose} className="modal-content-close">
          Закрыть
        </Button>
      </div>
    </Modal>
  );
};

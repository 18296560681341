import React from "react";

export const SuccessPublish: React.FC = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="8" fill="#0057AC" fillOpacity="0.1" />
      <path
        d="M39.3332 24.7734V26C39.3315 28.8753 38.4005 31.6729 36.679 33.9758C34.9574 36.2787 32.5376 37.9633 29.7803 38.7786C27.0231 39.5938 24.0762 39.4959 21.3791 38.4995C18.6821 37.503 16.3794 35.6615 14.8145 33.2494C13.2495 30.8374 12.5062 27.9841 12.6954 25.1151C12.8846 22.2461 13.9961 19.5151 15.8642 17.3295C17.7323 15.1438 20.2569 13.6205 23.0614 12.9869C25.866 12.3532 28.8002 12.6431 31.4265 13.8134"
        stroke="#0057AC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.3333 15.3333L26 28.68L22 24.68"
        stroke="#0057AC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

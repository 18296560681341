import { AddressInputType } from "graphql/types/types";
import { DaDataAddress, DaDataAddressSuggestion } from "react-dadata";

export const transformToDaDataAddressSuggestion = (
  address?: AddressInputType | null
): DaDataAddressSuggestion => {
  return {
    value: address?.value ?? "",
    data: {
      city: address?.city || "",
      city_type: address?.cityType || "",
      geo_lat: address?.lat?.toString() || "",
      geo_lon: address?.lng?.toString() || "",
      region: address?.region || "",
      region_type: address?.regionType || "",
      street: address?.street || "",
      street_type: address?.streetType || "",
      house: address?.house || "",
      house_type: address?.houseType || "",
      block: address?.block || "",
      block_type: address?.blockType || "",
      settlement: address?.settlement || "",
    } as DaDataAddress,
    unrestricted_value: address?.value ?? "",
  };
};

export const transformToAddressInputType = (
  addressSuggestion?: DaDataAddressSuggestion
): AddressInputType => {
  const { data, value } = addressSuggestion || {};

  return {
    city: data?.city || "",
    cityType: data?.city_type || "",
    lat: data?.geo_lat ? parseFloat(data.geo_lat) : null,
    lng: data?.geo_lon ? parseFloat(data.geo_lon) : null,
    region: data?.region || "",
    regionType: data?.region_type || "",
    value: value || "",
    street: data?.street || "",
    streetType: data?.street_type || "",
    house: data?.house || "",
    houseType: data?.house_type || "",
    block: data?.block || "",
    blockType: data?.block_type || "",
    settlement: data?.settlement || "",
  };
};

import { generateUid } from "common/utils/id";
import { ContactType } from "graphql/types/types";

export const getEmptyContact = () => ({
  contact: "+7",
  /**
   * временный id для работы с контактами в форме, удаляются перед отправкой
   */
  id: `temp-${generateUid()}`,
  isMain: false,
});

export const getUpdatedContacts = (contacts: ContactType[], contactId: ContactType["id"], value: string) => {
  return contacts.map((contactItem) => ({
    ...(contactItem.id === contactId
      ? {
        ...contactItem,
        contact: value,
      } 
      : contactItem)
  }));
};
import React from "react";
import ReactInputMask, { Props as InputMaskProps } from "react-input-mask";
import classNames from "classnames";

import type { SizeType } from "../types";

import "./styles.scss";

export type Props = InputMaskProps & {
  suffix?: React.ReactNode;
  size?: SizeType;
};

export const InputMask: React.FC<Props> = ({
  className,
  disabled,
  required,
  suffix,
  maskPlaceholder = "",
  size = "large",
  mask,
  ...props
}) => {
  const inputMaskClassNames = classNames(
    "ui-input-mask",
    {
      "ui-input-mask--disabled": disabled,
      "ui-input-mask--required": required,
      [`ui-input-mask--${size}`]: size,
    },
    className
  );

  return (
    <div className="ui-input-mask-wrapper">
      <ReactInputMask
        mask={mask}
        className={inputMaskClassNames}
        maskPlaceholder={maskPlaceholder}
        {...props}
      />
      {suffix && <div className="ui-input-mask-suffix">{suffix}</div>}
    </div>
  );
};

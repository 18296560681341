import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemState } from "services/store/types";
import {
  DailyPlanType,
  Dictionaries,
  SuitableVacancyListSuccess,
  VacancyStatus,
  VacancyType,
} from "graphql/types/types";
import {
  fetchDailyPlanList,
  fetchVacanciesDictionaries,
  fetchVacancyListByCategory,
  fetchVacancySuitableList,
} from "./actions";
import {
  DisabledVacanciesState,
  VacancyListByCategory,
  AddVacancyPayload,
} from "./types";
import { getInitialState } from "../../helpers";

type DailyPlanListState = ItemState<DailyPlanType[]>;

const initialState: DailyPlanListState = {
  data: [],
  loading: true,
  error: null,
};

const dailyPlanListSlice = createSlice({
  name: "dailyPlanList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyPlanList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDailyPlanList.fulfilled, (state, { payload }) => {
        state.data = payload?.dailyPlanList.data ?? [];
        state.loading = false;
      })
      .addCase(fetchDailyPlanList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

type ActiveCategoryState = {
  activeCategory: null | DailyPlanType["categoryId"];
};

const activeCategorySlice = createSlice({
  name: "activeCategory",
  initialState: {
    activeCategory: null,
  } as ActiveCategoryState,
  reducers: {
    setActiveCategory(
      state,
      { payload }: PayloadAction<DailyPlanType["categoryId"] | null>
    ) {
      state.activeCategory = payload;
    },
  },
});

type ActiveVacancyState = {
  id: null | VacancyType["id"];
};

const activeVacancyInitialState: ActiveVacancyState = {
  id: null,
};

const activeVacancyIdSlice = createSlice({
  name: "activeVacancyId",
  initialState: activeVacancyInitialState,
  reducers: {
    setActiveVacancyId(
      state,
      { payload }: PayloadAction<ActiveVacancyState["id"]>
    ) {
      state.id = payload;
    },
  },
});

const disabledVacanciesInitialState: DisabledVacanciesState = {
  list: {},
};

const disabledVacancyListSlice = createSlice({
  name: "disabledVacancyList",
  initialState: disabledVacanciesInitialState,
  reducers: {
    addVacancy(state, { payload }: PayloadAction<AddVacancyPayload>) {
      state.list = {
        ...state.list,
        [payload.vacancyId]: payload,
      };
    },
    removeVacancy(
      state,
      { payload }: PayloadAction<{ vacancyId: VacancyType["id"] }>
    ) {
      delete state.list[payload.vacancyId];
    },
  },
});

type VacanciesListByCategoryState = ItemState<VacancyListByCategory>;

const vacanciesListByCategorySlice = createSlice({
  name: "dailyPlanList",
  initialState: {
    data: {} as VacancyListByCategory,
    loading: true,
    error: null,
  } as VacanciesListByCategoryState,
  reducers: {
    deleteVacancy(
      state,
      { payload }: PayloadAction<{ id: VacancyType["id"] }>
    ) {
      state.data.data = state.data.data.filter(
        ({ id: vacancyId }) => vacancyId !== payload.id
      );
    },
    setVacancy(state, { payload }: PayloadAction<VacancyType>) {
      state.data.data = state.data.data.map((vacancy) => {
        return vacancy.id === payload.id ? payload : vacancy;
      });
    },
    setVacancyStatus(
      state,
      {
        payload,
      }: PayloadAction<{ status: VacancyStatus; id: VacancyType["id"] }>
    ) {
      state.data.data = state.data.data.map((vacancy) => {
        return vacancy.id === payload.id
          ? {
              ...vacancy,
              status: payload.status,
            }
          : vacancy;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacancyListByCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVacancyListByCategory.fulfilled, (state, { payload }) => {
        state.data =
          payload?.vacancyListByCategory ?? ({} as VacancyListByCategory);
        state.loading = false;
      })
      .addCase(fetchVacancyListByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

type VacanciesDictionariesState = ItemState<Dictionaries>;

const vacanciesDictionariesSlice = createSlice({
  name: "vacanciesDictionaries",
  initialState: {
    data: {} as Dictionaries,
    loading: true,
    error: null,
  } as VacanciesDictionariesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacanciesDictionaries.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVacanciesDictionaries.fulfilled, (state, { payload }) => {
        state.data = payload?.dictionaries ?? ({} as Dictionaries);
        state.loading = false;
      })
      .addCase(fetchVacanciesDictionaries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

type VacancySuitableList = SuitableVacancyListSuccess;

const vacancySuitableListSlice = createSlice({
  name: "vacancySuitableList",
  initialState: getInitialState<VacancySuitableList>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacancySuitableList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVacancySuitableList.fulfilled, (state, { payload }) => {
        state.data = payload.vacancySuitableList;
        state.loading = false;
      })
      .addCase(fetchVacancySuitableList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

export const { setActiveCategory } = activeCategorySlice.actions;
export const { deleteVacancy, setVacancy, setVacancyStatus } =
  vacanciesListByCategorySlice.actions;
export const { addVacancy, removeVacancy } = disabledVacancyListSlice.actions;
export const { setActiveVacancyId } = activeVacancyIdSlice.actions;

export const vacanciesListReducer = combineReducers({
  activeCategory: activeCategorySlice.reducer,
  activeVacancy: activeVacancyIdSlice.reducer,
  dailyPlanList: dailyPlanListSlice.reducer,
  dictionaries: vacanciesDictionariesSlice.reducer,
  vacanciesListByCategory: vacanciesListByCategorySlice.reducer,
  disabledVacancyList: disabledVacancyListSlice.reducer,
  vacancySuitableList: vacancySuitableListSlice.reducer,
});

import React, { useRef, useState } from "react";
import cn from "classnames/dedupe";

import {
  PositionRestrictions,
  PositionsRelationsType,
} from "graphql/types/types";
import { useHover } from "common/hooks/useHover";
import { useToggle } from "common/hooks/useToggle";
import { Edit, Close, CornerUpRight } from "components/UI/icons";
import Select from "components/UI/Select";
import { useDebounceCallback } from "common/hooks/useDebounceCallback";
import { IconStatus, Status } from "components/UI/iconStatus";
import { POSITION_RESTRICTIONS_OPTIONS } from "common/const/options";

import "./styles.scss";

type Props = {
  restriction: PositionsRelationsType["restriction"];
  positionsRelations: PositionsRelationsType;
  className?: string;
  updatePositionsRelations: (
    positionsValue: PositionsRelationsType
  ) => Promise<void>;
};

const nameByRestriction: Record<PositionRestrictions, string> = {
  [PositionRestrictions.OnlyFemale]: "Только женщины",
  [PositionRestrictions.OnlyMale]: "Только мужчины",
};

export const FieldRestriction: React.FC<Props> = ({
  restriction,
  positionsRelations,
  className,
  updatePositionsRelations,
}) => {
  const positionRef = useRef<HTMLDivElement>(null);

  const isHover = useHover(positionRef);
  const [isEdit, toggleIsEdit] = useToggle(false);

  const [restrictionValue, setRestrictionValue] = useState(() => restriction);
  const [status, setStatus] = useState<Status | null>(null);

  const debounceStatus = useDebounceCallback(setStatus, 3000);

  const handleChangeEditClick = (
    e: React.SyntheticEvent<HTMLSpanElement>,
    type?: string
  ) => {
    e.stopPropagation();
    if (type === "close") {
      setRestrictionValue(null);
    }
    toggleIsEdit();
  };

  const changePositionsRelations = async () => {
    setStatus("pending");
    await updatePositionsRelations({
      ...positionsRelations,
      restriction: restrictionValue,
    })
      .then(() => setStatus("success"))
      .catch(() => setStatus("error"))
      .finally(() => {
        toggleIsEdit();
        debounceStatus(null);
      });
  };

  const restrictionName = restriction
    ? nameByRestriction[restriction]
    : "Выбрать исключение";

  const saveIcon = (
    <span
      className="field-position-icon"
      onClick={(e) => {
        e.stopPropagation();
        changePositionsRelations();
      }}
    >
      <CornerUpRight />
    </span>
  );

  return (
    <div className={cn("field-position", className)} ref={positionRef}>
      {isEdit ? (
        <Select
          className="field-position-select"
          value={restrictionValue}
          options={POSITION_RESTRICTIONS_OPTIONS}
          suffixIcon={saveIcon}
          showArrow={true}
          onChange={(v) => setRestrictionValue(v)}
        />
      ) : (
        <div className="field-position-short" title={restrictionName}>
          {restrictionName}
        </div>
      )}
      {(isHover || isEdit) && !status && (
        <>
          {isEdit ? (
            <span
              className="field-position-icon"
              onClick={(e) => handleChangeEditClick(e, "close")}
            >
              <Close width={16} height={16} />
            </span>
          ) : (
            <span
              className="field-position-icon"
              onClick={handleChangeEditClick}
            >
              <Edit width={16} height={16} />
            </span>
          )}
        </>
      )}
      {status && (
        <span className="field-position-icon">
          <IconStatus status={status} />
        </span>
      )}
    </div>
  );
};

import React from "react";
import { useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { Report } from "components/redezign/Report";
import { ReportName, titleByReportName } from "common/const/reports";
import { useChangeTitle } from "common/hooks/header/useChangeTitle";

export interface RouteParams {
  id: ReportName;
}

export const ReportPage: React.FC = () => {
  const { id: reportName } = useParams<RouteParams>();

  useChangeTitle({ title: titleByReportName[reportName] });

  return (
    <ContentLayout
      centralColumn={{ content: <Report reportName={reportName} /> }}
    />
  );
};

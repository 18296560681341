import { ApolloQueryResult } from "@apollo/client";
import { ItemState } from "./types";

export const getInitialState = <T>(): ItemState<T | null> => ({
  data: null,
  loading: false,
  error: null,
  meta: null,
  url: "",
});

export const buildItemsById = <T>(list: T[], key: keyof T) =>
  list.reduce<Record<string, T>>(
    (acc, item) => ({
      ...acc,
      [String(item[key])]: item,
    }),
    {}
  );

export const isErrorResponse = <T extends object>(
  response: ApolloQueryResult<Error | T>
): response is ApolloQueryResult<Error> => {
  return "errors" in response;
};

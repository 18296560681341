import React from "react";
import { IconType } from "common/commonTypes/types";

export const Grid: React.FC<IconType> = ({
  width = 20,
  height = 20,
  color = "#0057AC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 3.33331C12.4128 3.33331 11.6666 4.07951 11.6666 4.99998V6.66665C11.6666 7.58712 12.4128 8.33331 13.3333 8.33331H14.9999C15.9204 8.33331 16.6666 7.58712 16.6666 6.66665V4.99998C16.6666 4.07951 15.9204 3.33331 14.9999 3.33331H13.3333ZM4.99992 11.6666C4.07944 11.6666 3.33325 12.4128 3.33325 13.3333V15C3.33325 15.9205 4.07944 16.6666 4.99992 16.6666H6.66659C7.58706 16.6666 8.33325 15.9205 8.33325 15V13.3333C8.33325 12.4128 7.58706 11.6666 6.66659 11.6666H4.99992ZM3.33325 4.99998C3.33325 4.07951 4.07944 3.33331 4.99992 3.33331H6.66659C7.58706 3.33331 8.33325 4.07951 8.33325 4.99998V6.66665C8.33325 7.58712 7.58706 8.33331 6.66659 8.33331H4.99992C4.07944 8.33331 3.33325 7.58712 3.33325 6.66665V4.99998ZM13.3333 11.6666C12.4128 11.6666 11.6666 12.4128 11.6666 13.3333V15C11.6666 15.9205 12.4128 16.6666 13.3333 16.6666H14.9999C15.9204 16.6666 16.6666 15.9205 16.6666 15V13.3333C16.6666 12.4128 15.9204 11.6666 14.9999 11.6666H13.3333Z"
        fill={color}
      />
    </svg>
  );
};

import { Platform, platformName } from "common/const/platform";
import { PublishStatus } from ".";

const publicationMessageByPlatform: Record<Platform, string> = {
  avito: "успешно передана на публикацию",
  headhunter: "успешно опубликована",
  rabotut: "успешно опубликована",
  zarplata: "успешно опубликована",
};

export const getTitleByStatus = (
  platform: Platform
): Record<PublishStatus, string> => ({
  success: publicationMessageByPlatform[platform],
  error: "не была опубликована",
});

export const getTextByStatus = (
  platform: Platform
): Record<PublishStatus, React.ReactNode> => ({
  success: `На сайте ${platformName[platform]}`,
  error: "К сожалению, произошла ошибка при публикации вакансии.",
});

export const getSecondTextByStatus = (
  message = ""
): Record<PublishStatus, React.ReactNode> => ({
  success: (
    <>
      Отклики на вакансию будут размещены в вкладке{" "}
      <span className="highlight">Отклики</span>
    </>
  ),
  error: message,
});

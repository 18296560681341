import { gql } from "@apollo/client";

export const CHANGE_CLAIM_STATUS = gql`
  mutation changeClaimStatus($id: Int!, $status: ClaimStatus!) {
    changeClaimStatus(id: $id, status: $status) {
      ... on ClaimType {
        id
        address {
          block
          blockType
          city
          cityType
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        category
        chatLink
        companyId
        contacts {
          email
          fullName
          phone
        }
        createdAt
        deletedAt
        departmentId
        expiredCallTime
        extra {
          remoteId
          remoteUrl
          remoteVacancyId
          service
        }
        isSent
        manager {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        managerComment
        managerId
        owner {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        ownerId
        position
        refusalId
        refusalText
        replyId
        salary
        sendTo
        source
        sourceUrl
        status
        updatedAt
        updatedBy
        userComment
        userContent
        userWish
        utm {
          campaign
          content
          medium
          source
          term
        }
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;
